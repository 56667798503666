@import "../mixins/media";

.order-parts-img {
  width: 100%;
  height: 42px;
  @include media_desktop() {
      height: 59px;
  }

  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media_desktop() {
    justify-content: space-around;
  }

  .left {
    flex: 1;
    @include media_desktop() {
      display: none;
    }
  }

  .center {
    flex: 1;
    text-align: center;
    @include media_desktop() {
      text-align: left;
      margin-left: 24px;
    }
  }

  .right {
    flex: 1;
    margin-left: auto;
    margin-right: 8px;
    @include media_desktop() {
      margin-right: 15px;
    }
    display: flex;
    justify-content: flex-end;
  }

  .page-title {
    color: #fff;
    line-height: 15px;
    font-size: 15px;
    font-weight: 300;
    white-space: nowrap;

    @include media_desktop() {
      line-height: 18px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.order-parts-img__help-button {
  background: none;
  color: #fcfcfc;
  line-height: 20px;
  height: 20px;
  opacity: 0.8;

  font-size: 12px;
  font-weight: 300;

  &:hover{
    opacity: 0.45;
  }

  @include media_desktop() {
    font-size: 14px;
    font-weight: 600;
  }


  border: none;
  padding: 0;
  display: flex;

  .icon {
    margin-left: 7px;
    display: inline-block;
  }
}

.preview-3d-header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: $zindex-header;
}
