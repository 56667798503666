@import "../mixins/media";

.brandsite__selectguide {
  .top-img {
    img {
      width: 100%;

      @include media_mobile{
        height: 121.33333vw;
        object-fit: cover;
        object-position: 32.5% 50%;
        max-height: 255px;
        @media screen and(max-width:449px) {
          max-height: unset;
        }
      }
    }
  }
  .parts-discription {
    background-color: #f5f5f5;
    color: #555555;
    padding-bottom: 30px;

    .topic-path {
      display: flex;
      font-family: $font-family-HiraginoKakuGothicProN;
      font-size: 13px;
      font-weight: 300;
      padding: 10px;

      a {
        color: #000;
      }
    }

    [class^="parts-discription__"] {
      background-color: #fff;
      box-shadow: 0px 3px 6px #00000025;
      font-family: $font-product;
      padding: 40px;
      width: calc(100vw - 20px);
      max-width: 748px;
      border-radius: 8px;
      margin: 0 auto 50px;
      //@media screen and(max-width:600px) {
      //  width: 90vw;
      //}
      @include media_mobile{
        padding: 40px 20px;
      }
      @media screen and(max-width:449px) {
        padding: 30px 20px;
      }

      & .sakura {
        display: inline-block;
        width: 34px;
        margin: 0 6px 5px 0;
      }
      & .title {
        display: inline;
        font-size: 24px;
        font-weight: 600;
        @media screen and(max-width:449px) {
          font-size: 17px;
        }
      }
      & .subtitle {
        font-weight: 600;
        font-size: 20px;
        border-bottom: solid #707070 1px;
        margin: 40px 0 0;
        @media screen and(max-width:449px) {
          font-size: 16px;
          margin: 30px 0 0;
        }
      }
      & .subheading {
        font-size: 20px;
        padding-bottom: 0px;
        margin: 40px 0 0 0;
        @media screen and(max-width:449px) {
          font-size: 16px;
          margin: 30px 0 0;
        }
      }
      & [class^="discription-"] {
        font-size: 15px;
        font-weight: 300;
        margin: 10px 0 0;
      }
      & .table-div {
        overflow-x: scroll;
      }
      & .order-parts-help-contents {
        margin: 0;
      }
    }

    &__1 {
      margin: 50px auto !important;
    }
    &__2 {
      .shaft {
        width: 100%;
        margin: 10px 0;
      }
      .dura {
        width: 100%;
        margin: 10px 0;
      }
    }
    &__3 {
    }
    &__4 {
    }
    &__5 {
    }
    &__6 {
    }
    &__7 {
    }
    &__8 {
    }
  }
}
