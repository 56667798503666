@import "../mixins/media";
@import "../mixins/clearfix";
@import "../mixins/projects";
@import "../mixins/btn";
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  @include container;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  @include media_desktop {
    margin-top: 20px;
    flex-direction: row;
  }
  .ec-inlineBtn {
    font-weight: normal;
  }
  & &__detail {
    padding: 0;
    width: 100%;
    @include media_desktop {
      padding: 0 16px;
      width: 66.66666%;
    }

    .ec-inlineBtn {
      @include ec-inlineBtn();
    }

    .ec-inlineBtn {
      @include ec-inlineBtn();
    }
  }
  & &__summary {
    width: 100%;
    .ec-inlineBtn {
      display: inline-block;
    }
    @include media_desktop {
      width: 33.33333%;
      padding: 0 16px;
      .ec-inlineBtn {
        display: none;
      }
    }
  }
  .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc;
    @include media_desktop {
      border-top: none;
    }
  }
}

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px;
  & &__items {
    @include borderBottom;
    @include borderTop;
  }
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 60px;
  p {
    margin-bottom: 0;
  }
  @include clearfix;
  & &__change {
    display: inline-block;
    margin-left: 10px;
    float: right;
  }
  & &__account {
    margin-bottom: 16px;
    padding: 0 0 0 13px;
  }
}

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery {
  margin-bottom: 60px;

  div {
  }
  & &__title {
    padding: 10px 0 10px 13px;
    font-weight: bold;
    font-size: 18px;
    position: relative;
  }
  & &__change {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
  }
  & &__items {
    @include borderBottom;
    @include borderTop;
  }
  & &__address {
    margin: 10px 0 18px;
    padding: 0 0 0 13px;

    p {
      margin: 0;
    }
  }
  & &__actions {
    padding: 0 0 0 13px;
  }
  & &__edit {
    padding: 0 0 0 13px;
  }
}

.ec-afterservice {
  margin-bottom: 60px;

  .ec-rectHeading {
    h2 {
      img {
        width: 34px;
        margin: 0 10px 3px 0px;
      }
    }
  }
  .ec-rectHeading-attention {
    font-size: 12px;
    font-weight: 300;
    padding: 0 0 0 13px;
  }
  & &__description {
    font-size: 12px;
    font-weight: 300;
    padding: 10px 0 0 13px;
  }
  & &__title {
    padding: 20px 0 0 13px;
    font-weight: bold;
    font-size: 18px;
    position: relative;
  }
  & &__change {
    padding-top: 10px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
  }
  & &__noselect {
    padding: 0 0 30px 13px;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    color: red;
  }
  .store_select_btn {
    @include blockBtn-action;
    width: 180px;
    border-radius: 30px;
    background: $color-ecsite-button;
    color: #fff;
    height: 45px;
    line-height: 45px;
    box-shadow: $box-shadow;
    border: none;
    transition: all 0.25s ease 0s;
    margin: 0 0 0 13px;
    @include media_mobile {
      margin: 0 auto;
    }
    &:hover {
      background: $color-ecsite-button-hover;
    }
  }
  & &__btn_modal {
    display: block;
    border: solid #b8bec4 1px;
    padding: 10px;
  }
  .non_store_select_label {
    padding: 30px 0 0 13px;
    input {
      cursor: pointer;
    }
  }
}

.ec-afterservice__ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  width: 100%;
  height: 100%;

  .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;

    .ec-modal-wrap {
      width: 680px;
      height: 75%;
      position: fixed;
      border-radius: 5px;
      border: none;
      background-color: #fff;
      display: flex;
      @include media_mobile {
        width: 100vw;
        bottom: 0;
        height: 80%;
      }

      .ec-modal-wrap-div {
        height: 100%;
        width: 100%;
        flex-shrink: 0;
        // overflow: scroll;

        .ec-modal-wrap__header {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%;
        }

        .ec-modal-wrap__body {
          width: 100%;
          padding: 20px 45px;
          overflow-y: auto;
          -ms-overflow-style: none;
          scrollbar-width: none; /* Firefox 対応 */
          @include media_mobile {
            padding: 20px 15px;
          }
        }
        .ec-modal-wrap__body::-webkit-scrollbar {
          display: none;
        }
        .ec-modal-wrap__footer {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%;
        }
      }
    }
  }
}

// サポート店選択用
.select_support_store_div {
  // サポート店 header
  .select_store_header {
    height: 110px;
    position: relative;

    .select_store_header_inner {
      margin: 0 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      webkit-transform: translateY(-50%);

      .select_store_title {
        font-size: 18px;
        font-weight: bold;
      }

      .select_store_descrption {
        font-size: 13px;
      }
    }
  }

  // サポート店 body
  .select_store_body {
    height: calc(100% - 190px);

    .accshow {
      transition: 0.8s;
      display: flex;
      flex-wrap: wrap;
    }
    .accbox {
      /*ラベル*/
      .area_name {
        display: block;
        margin: 5px 0;
        padding: 10px 12px;
        //      font-weight: bold;
        background: #333333;
        cursor: pointer;
        transition: all 0.5s;
        color: white;
        border-radius: 4px;
        &:hover {
          background: #555555;
          color: #dddddd;
        }
      }

      /*アイコンを表示*/
      .area_name::before {
        //      content: "▶︎";
        padding-right: 5px;
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        font-weight: 900;
      }

      /*チェックは隠す*/
      input {
        display: none;
      }

      /*中身を非表示にしておく*/
      .accshow {
        height: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.8s;
        display: flex;
        flex-wrap: wrap;
      }
      /*クリックで中身表示*/
      .cssacc:checked + label + .accshow {
        height: auto;
        padding: 5px;
        opacity: 1;
        @include media_mobile {
          padding: 0px;
        }
      }

      /*アイコンを入れ替える*/
      .cssacc:checked + .area_name::before {
        font-family: "Font Awesome 5 Free";
        content: "\f078";
        font-weight: 900;
      }

      .pref_bar {
        width: 100%;
        margin: 10px;

        .pref_name_box {
          float: left;
          display: block;
          width: 50px;
          height: 30px;
          text-align: center;

          .pref_checkbox {
            display: none;
          }

          .pref_name {
            // display:inline-block;
            margin: auto;
            font-size: 11px;
            line-height: 30px;
            color: #333333;
            cursor: pointer;
            padding: 0px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        // .pref_checkbox:checked + .pref_name::before {
        //   cursor: pointer;
        //   background-color: #333333;
        //   border-radius: 24px;
        //   text-align: center;
        //   color: #ffffff;
        // }
        .pref_name_checked {
          background-color: #333333;
          border-radius: 24px;
          text-align: center;
          .pref_name {
            color: #ffffff;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .pref_container {
        width: 100%;

        .pref_info {
          width: 100%;
          display: none;
          flex-wrap: wrap;
        }
      }
    }
  }

  // サポート店 footer
  .select_store_footer {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 120px;
      color: #fff;
      padding: 9px;
      border: none;
      margin-right: 20px;
    }
    .ec-modal_cancel {
      background-color: $color-ecsite-button-cancel;
      border-radius: 20px;
      &:hover {
        background-color: $color-ecsite-button-cancel-hover;
      }
    }
    .ec-modal_next {
      background: $color-ecsite-button;
      border-radius: 20px;
      &:hover {
        background: $color-ecsite-button-hover;
      }
    }
  }
}

.store_info_container {
  width: 165px;
  min-height: 170px;
  margin: 10px;
  background-color: white;
  flex-shrink: 0;
  float: left;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  overflow: hidden;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  @media screen and (max-width: 584px) {
    width: calc(50% - 8px);
    margin: 7.5px;

    &:nth-child(odd) {
      margin-left: 0px;
    }
    &:nth-child(even) {
      margin-right: 0px;
    }
  }

  .store_image {
    height: 110px;
    width: 165px;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 584px) {
      width: 100%;
    }
    @media screen and (max-width: 584px) {
      width: 100%;
    }

    img {
      width: 165px;
      height: 110px;
      object-fit: cover;
      @media screen and (max-width: 584px) {
        width: 100%;
      }
      @media screen and (max-width: 584px) {
        width: 100%;
      }
    }
  }

  .store_info {
    height: auto;
    padding: 15px 10px 12px 10px;

    .store_name {
      font-weight: bold;
      font-size: 12px;
      line-height: 1.4;
    }

    .store_address {
      font-size: 10px;
      line-height: 1.3;
      margin-top: 5px;
    }

    .store_url {
      text-align: center;
      font-size: 10px;
      line-height: 1.3;
      margin-top: 10px;
    }
  }
  .store_cheked {
    width: 54px;
    height: 18px;
    position: absolute;
    top: 98px;
    left: 5px;
    display: none;
    z-index: 2000;
  }
}

#selected_support_store {
  width: 100%;
  .store_cheked {
    display: none;
  }
  .store_image {
    width: 110px;
    height: 110px;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    float: left;
    margin-right: 20px;
  }

  .store_info {
    height: 110px;
    padding: 6px;

    .store_name {
      font-weight: bold;
      font-size: 12px;
    }

    .store_address {
      font-size: 0.5vw;
    }

    .store_url {
      font-size: 10px;
    }
  }
}

.attention_div {
  // 同意画面 header
  .attention_header {
    height: 80px;
    position: relative;

    .attention_header_inner {
      margin: 0 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      webkit-transform: translateY(-50%);

      .attention_title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  } //.attention_header

  .attention_body {
    height: calc(100% - 260px);

    .attention_body_inner {
      .description_section {
        font-weight: bold;
        font-size: 16px;
        line-height: 4;
      }
      .description_sentence {
        font-size: 13px;
      }
      ul {
        margin: 15px 0px;
      }
      li {
        font-size: 13px;
        list-style: none;
      }
    }
  }

  .attention_footer {
    width: 100%;
    height: 180px;

    .attention_footer_agree {
      width: 100%;
      height: 100px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      background-color: #f8f8f8;
      padding: 10px auto;

      span {
        font-size: 12px;
        font-weight: bold;
        display: block;
        padding: 15px 0 10px;
      }

      .temp_div {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        label {
          padding: 8px 15px 8px 30px;
          border-radius: 5px;
          border: solid #e8e8e8 1px;
          cursor: pointer;
        }
        input {
          display: none;
        }
        .disagree::before,
        .agree::before {
          content: url(/html/template/default/assets/img/ec-site/img-non-checked.svg);
          margin: 0 5px 0 -15px;
        }
        input:checked + .disagree::before,
        input:checked + .agree::before {
          content: url(/html/template/default/assets/img/ec-site/img-checked.svg);
          margin: 0 5px 0 -15px;
        }
        .disagree {
          color: #d54c4c;
          background-color: #fff;
          margin: 0 20px;
        }
        #disagree {
          position: absolute;
          top: 9px;
          left: 215px;
          z-index: 1;
        }

        .agree {
          color: #007cff;
          background-color: #fff;
          margin: 0 20px;
        }
        #agree {
          position: absolute;
          top: 9px;
          left: 377px;
          z-index: 1;
        }
        #agree:checked ~ .agree {
          color: white;
          background-color: #007cff;
        }
        #disagree:checked ~ .disagree {
          color: white;
          background-color: #d54c4c;
        }
      }
    }

    .attention_footer_complete {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      .ec-modal_prev {
        background-color: $color-ecsite-button-cancel;
        border-radius: 20px;
        width: 120px;
        color: #fff;
        padding: 9px;
        border: none;
        margin-right: 20px;
        &:hover {
          background-color: $color-ecsite-button-cancel-hover;
        }
      }
      .ec-modal_next {
        background: $color-ecsite-button;
        border-radius: 20px;
        width: 120px;
        color: #fff;
        padding: 9px;
        border: none;
        margin-right: 20px;
        &:hover {
          background: $color-ecsite-button-hover;
        }
      }
    }
  }
}
/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment {
  margin-bottom: 60px;

  .ec-radio {
    padding: 0 0 0 15px;
  }
  .ec-input {
    padding: 10px 0 0 13px;
  }
}

/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 60px;

  @include media_desktop {
    margin-bottom: 0;
    padding: 0 0 20px;
  }
  .ec-input {
    padding: 10px 0 0 13px;

    textarea {
      height: 96px;
    }
  }
}

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px 100px;
  @include media_desktop {
    margin: 0 10% 10%;
  }
  .ec-inlineBtn {
    @include ec-inlineBtn();
  }

  & &__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
  }
  & &__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  & &__item {
    display: table;
    padding: 16px;
    background: #f4f4f4;
    margin-bottom: 16px;
  }
  & &__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%;
    img {
      width: 100%;
    }
  }
  & &__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size: 16px;
  }
  & &__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }
  & &__itemtSize {
    margin-bottom: 10px;
  }
  & &__itemtPrice {
  }
  & &__itemtNumber {
  }
  & &__select {
    margin-bottom: 5px;
  }
  & &__selectAddress {
    display: inline-block;
    label {
      font-size: 16px;
      font-weight: normal;
    }
    select {
      min-width: 100%;
      @include media_desktop {
        min-width: 350px;
      }
    }
  }
  & &__selectNumber {
    display: inline-block;
    margin-left: 30px;
    label {
      font-size: 16px;
      font-weight: normal;
    }
    input {
      display: inline-block;
      margin-left: 10px;
      width: 80px;
    }
  }
  & &__actions {
    .ec-blockBtn--action {
      margin-bottom: 8px;
    }
    .ec-blockBtn--cancel {
      background-color: $color-ecsite-button-cancel;
    }
  }
  & &__new {
    margin-bottom: 20px;
    .btn {
      @include ec-inlineBtn();
    }
  }
}

.ec-role-shopping-progress {
  @include media_desktop {
    padding: 30px 0 0;
  }
  .ec-pageHeader {
    h1 {
      border-top: none;
    }
  }
}
