@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: #fff;
  & &__contentTop {
    padding: 0;
  }

  & &__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }
  & &__main {
    width: 100%;
  }
  & &__mainBottom {
    background-color: $color-ecsite-2;
    overflow: hidden;
  }
  & &__mainWithColumn {
    width: 100%;
    @include media_desktop() {
      width: 75%;
    }
  }
  & &__mainBetweenColumn {
    width: 100%;
    @include media_desktop() {
      width: 50%;
    }
  }
  & &__left,
  & &__right {
    display: none;
    @include media_desktop() {
      display: block;
      width: 25%;
    }
  }
}

.ec-headerRole {
  @include container;
  padding-top: 15px;
  position: relative;
  &::after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
  & &__title {
    width: 100%;
  }
  & &__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right;
    @include media_desktop {
      display: none;
    }
  }
}

#serach-open {
  display: none;
}
#serach-open:checked + .ec-headerNaviRole__search {
  display: inline-block !important;
}
.ec-headerNaviRole {
  @include container;

  position: relative;
  z-index: $zindex-header;

  background-color: $color-ecsite-1;
  @include media_desktop {
    height: 86px;
  }
  @include media_mobile {
    height: 56px;
  }

  display: flex;
  justify-content: space-around;
  padding: 0;

  .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & &__inner {
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin: 0 auto;
  }

  & &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 0;
    @include media_desktop {
      //min-width: 42%;
      min-width:318px;
    }
    @include media_mobile {
      min-width: 260px;
      @media screen and (max-width: 360px) {
        //width: 80vw;
        min-width: 260px;
      }
    }

    &__kamon {
      display: block;
      padding: 0;
      background-image: url(/html/template/default/assets/svg/kamon-mb.png);

      @include media_desktop {
        width:34.36px;
        height: 33.11px;
        background-size:34.36px 33.11px;
        margin: 0 0 0 27px;
      }
      @include media_mobile {
        width: 28.6px;
        height: 27.38px;
        background-size:28.6px 27.38px;
        margin: 0 0 0 11px;
      }

      //&::before {
      //  content: url(/html/template/default/assets/svg/kamon-mb.png);
      //  display: inline-block;
      //  object-fit: fill;


      //  @include media_desktop {
      //    width:34.36px;
      //    height: 33.11px;
      //  }
      //  @include media_mobile {
      //    width: 28.6px;
      //    width: 27.38px;
      //  }
      //}
    }

    &__title {
      text-align: left;
      width: auto;
      height: auto;
      display: block;

      @include media_desktop {
        //width: 131.05px;
        //height:28.23px;
        margin: -1px 0 0px 4.9px;
      }
      @include media_mobile {
        //width: 131.05px;
        //height:24.32px;
        margin: 1px 0 0px 5.6px;
      }

    }
    &__subtitle {
      width: calc(50% - 50px);
      font-size: 10px;
      white-space: nowrap;
    }

    .kamon {
      width: 29.35px;
      height: 28.08px;
      margin: 15.5px 4.6px 15.5px 17.9px;
      @include media_mobile {
        margin: 14px 4.6px 14px 9.9px;
      }
    }

    &__header_logo_type {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 1em;

      &__image {
        display: inline-block;
        width: 123.22px;
        height: 26.55px;
      }
    }

    &__subtitle {
      color: #fff;
      padding: 0px 0 0 0;
      line-height: 13px;
      font-family: serif;
      white-space: nowrap;

      @include media_desktop{
        font-size: 12px;
        margin: 13px 0 0 11.7px;
      }
      @include media_mobile {
        font-size: 10px;
        margin: 17px 0 0 8.8px;
      }
    }
  }

  & &__search {
    display: none;
    @include media_desktop() {
      display: none;
      @include reset_link;
      position: absolute;
      top: -5px;
      left: -260px;
      width: 300px;
    }
  }
  & &__openBtn {
    &__img {
      height: 100%;
      margin: 0 0 6px;
    }
  }
  & &__navSP {
    display: block;
    @include media_desktop() {
      display: none;
      @include reset_link;
    }
  }

  & &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
  }

  & &__nav {
    display: inline-block;
    padding: 4px 0 0 0;
    @include reset_link;
    @include media_mobile {
      display: none;
    }
  }
  & &__cart {
    display: inline-block;
    @include reset_link;
  }

  & &__hamburger-menu {
    padding: 0 0px 0 10px;
    height: 59px;
    width: 59px;

    #menu-btn-check {
      display: none;
    }

    .menu-btn {
      cursor: pointer;
      display: flex;
      height: 59px;
      width: 59px;
      justify-content: center;
      align-items: center;

      @include media_mobile {
        height: 56px;
        width: 56px;
      }

      .menu-btn__span,
      .menu-btn__span:before,
      .menu-btn__span:after {
        content: "";
        display: block;
        height: 2px;
        width: 28px;
        border-radius: 0px;
        background-color: #fff;
        position: absolute;
      }
      .menu-btn__span:before {
        bottom: 10px;
      }
      .menu-btn__span:after {
        top: 10px;
      }
    }

    //humburger menu
    a {
      color: #f5f5f5;
      text-decoration: none;
      white-space: nowrap;
    }
    li {
      padding: 20px 20px;
      list-style: none;
      text-align: left;
    }

    /* ボタン */
    .btn-menu {
      border: 1px solid #999;
      background: transparent;
      color: #fff;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      line-height: 1;
    }

    /* メニュー */
    .side_menu_cover {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zindex-sidebar;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);

      .close_button{
        position: absolute;
        top: 0px;
        right: 250px;
        z-index: 1210;
        background: #333333;
        width: 40px;
        height: 40px;
        &:hover{
          opacity: 0.7;
        }
      }

      span {
        content: "";
        display: none;
        height: 2px;
        width: 20px;
        border-radius: 0px;
        background-color: #fff;
        position: absolute;
        top: 18px;
        right: 10px;
        z-index: $zindex-sidebar + 10;
      }
      span:nth-child(1):not(.en):not(.sub-text) {
        transform: rotate(-45deg);
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }
    }
    #menu-btn-check:checked ~ .menu-btn .side_menu_cover {
      display: block;
    }
    #menu-btn-check:checked ~ .menu-btn .side_menu_cover span {
      display: block;
    }

    .side_menu_nav {
      display: none;
      background: #333333;
      font-family: sans-serif;
      position: absolute;
      z-index: $zindex-sidebar + 10;
      overflow-x: hidden;
      overflow-y: scroll;
      text-align: left;
      top: 0;
      right: 0;
      width: 250px;
      transition: 0.5s;
      box-shadow: 0 0 10px;
      @media screen and (min-width: 1130px) {
        right: 0px;
        top: 0;
      }

      a:hover{
        opacity: 0.6;
      }

      ul {
        padding: 0;
        margin: 0;
      }
      &__ul1 {
        padding: 0px 0 25px 0 !important;
        background-color: #fff;

        .sitemenu-title {
          font-weight: 600;
          padding: 15px 0 0 15px;
          font-size: 12px;
          .en{
            font-size: 14px;
            font-weight: 600;
            display: inline !important;
            position: initial;
          }
        }
        p {
          font-weight: 600;
          padding: 0 0 0 10px;
          margin: 0 !important;
        }
        li {
          border-bottom: solid #d8d8d8 1px;
          font-weight: bold;
          font-size: 14px;
          width: auto;
          margin: 0 10px 0 10px;
        }
        .sitelink {
          padding: 20px 5px 0 10px;
          a {
            margin: 0 0 13px;
          }
          img {
            width: 15px;
            padding: 0 0 3px;
          }
        }
        a {
          color: #333;
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          text-align: left;
        }

        .orderlink{
          padding: 17px 5px 0 10px;
          a {
            margin: 0 0 13px;
          }
          img {
            width: 13px;
            height: auto;
            padding: 0px 1.45px 1px 1px;
          }          
        }
        .EClink{
          padding: 17px 5px 0 10px;
          a {
            margin: 0 0 13px;
          }
          img {
            width: 15px;
            height: auto;
            padding: 0px 0px 0 0px;
            margin: 0 0 0 -1px;
          }          
        }

        .subtitle-margin {
          margin: 0 0 13px 20px !important;
          p{
            font-weight: 600;
          }

        }
      }
      &__ul2 {
        padding: 20px 15px !important;
        background-color: #f5f5f5;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;


        p {
          width: 100%;
          font-size: 14px;
          text-align: initial;
          margin: 0 0 9px 0px;
        }
        li {
          border-bottom: solid #d8d8d8 1px;
          font-weight: bold;
          font-size: 14px;
          width:calc(50% - 7.5px);
          display: block;
          text-align: initial;
          padding: 0 !important;
          margin: 0 !important;
          .sub-text{
            font-weight: normal;
            font-size: 10px;
            position: initial;
            display: inline !important;
            background: none;
          }
        }
        a {
          display: block;
          padding: 15px 0px 11px 0px !important;
          margin: 0 0 0 0!important;
          color: #333;
        }
      }
      &__div1 {
        background-color: #fff;
        padding: 20px 15px !important;
        width: 100%;

        img {
          color: #000 !important;
        }
        p {
          width: 100%;
          font-size: 14px;
          text-align: initial;
          margin: 0 0 9px 0px;
        }
        a {
          color: #333;
        }
        input {
          border: solid #cccccc 1px;
          padding: 5px 9px;
          margin: 9px;
        }
        input::placeholder {
          font-weight: 100;
          font-size: 10px;
        }

        &__search {
          .searchform {
            display: flex;
          }
          .ec-headerSearch {
            padding: 0px;

            &__category {
              display: none;
              width: 100px;
              .category_id {
                font-size: 8px;
              }
            }
            &__keyword {
              //width: 180px;
              width:100%;
              height: 36px;
              border-radius: 0;
              .search-name {
                margin: 0;
                padding: 0.2em 40px 0.5em 1em;
              }
            }
            &__keywordBtn {
              right: 5px;
              top: 5px;
            }
            &__closeBtn {
              display: none;
            }
          }
        }
      }
      &__ul3 {
        background-color: #333333;
        li {
          font-weight: 300;
          font-size: 13px;
          border-bottom: solid #999999 1px;

          img {
            width: 15px;
            padding: 0 0 4px 0;
            margin: 0 10px 0 0;
          }
        }
      }
      &__ul4 {
        background-color: #333333;
        padding: 20px !important;

        li {
          font-weight: 300;
          font-size: 13px;
          padding: 10px 0px;
          text-align: left;
        }
      }
    }
    #menu-btn-check:checked ~ .menu-btn .side_menu_nav {
      display: block;
      height: 100vh;
      cursor: initial;
      padding: 0 0 83px 0;
    }
  }
}

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  //display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;

  .fas {
    vertical-align: top;
  }

  @include media_desktop {
    display: none;
  }
}
.ec-headerNavSP.is-active {
  display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  @include commonStyle();
  & &__title {
    text-align: center;
    h1 {
      margin: 0;
      padding: 0;
    }
    a {
      display: inline-block;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 20px;

      @include media_desktop() {
        font-size: 40px;
      }
      font-weight: bold;
      color: black;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    text-align: center;
    @include media_desktop() {
      font-size: 16px;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: #0092c4;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  text-align: right;
  & &__item {
    color: #000;
    margin-left: 0;
    display: inline-block;
    width: 35px;
    height: 22px;
    position: relative;
  
  &:hover .ec-headerNaviRole__openBtn,
  &:hover a .ec-headerNav__itemIcon,
  &:hover a .ec-cartNavi__icon{
    cursor: pointer;

    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    }
    40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    }
    70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    }
    90% {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    }
  }
  @keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    }
    40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    }
    70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    }
    90% { -webkit-transform: translate3d(0,-2px,0); transform: translate3d(0,-2px,0);
    }
  } 


    &__ec-icon {
      height: 35px;
      width: 25px;
      cursor: pointer;
    }
  }
  @include media_mobile {
    & &__item:nth-of-type(4) {
      display: block;
    }
    & &__item {
      display: none;
    }
  }
  & &__itemIcon {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: $color-ecsite-icon;
    @include media_desktop {
      margin-right: 0;
      font-size: 20px;
    }
  }
  & &__itemLink {
    display: none;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: middle;
    color: black;
    @include media_desktop {
      display: inline-block;
    }
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
  position: relative;
  @include clearfix;

  .searchform {
    @include media_desktop {
      display: flex;
    }
  }

  & &__category {
    float: none;
    @include media_desktop {
      float: left;
      width: 43%;
    }
    .ec-select {
      overflow: hidden;
      width: 100%;
      margin: 0;
      text-align: center;

      select {
        width: 100%;
        cursor: pointer;
        padding: 8px 24px 8px 8px;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        appearance: none;
        color: #fff;

        @include media_desktop {
          max-width: 165px;
          height: 36px;
        }

        option {
          color: #000;
        }

        &::-ms-expand {
          display: none;
        }
      }

      &.ec-select_search {
        position: relative;
        border: 0;
        background: #000;
        color: #fff;
        @include media_desktop {
          border-top-right-radius: inherit;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }
        @include media_mobile {
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
        }

        &::before {
          position: absolute;
          top: 0.8em;
          right: 0.4em;
          width: 0;
          height: 0;
          padding: 0;
          content: "";
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          pointer-events: none;
        }
      }
    }
  }
  & &__keyword {
    color: $clrDarkGray;
    border: 1px solid #ccc;
    background-color: #f6f6f6;

    @include media_desktop {
      width: 80%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    @include media_mobile {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    input[type="search"] {
      width: 100%;
      height: 34px;
      font-size: 16px;
      border: 0 none;
      padding: 0.5em 50px 0.5em 1em;
      box-shadow: none;
      background: none;
      box-sizing: border-box;
      margin-bottom: 0;
      @include media_desktop {
        font-size: 12px;
      }
    }
    .ec-icon {
      width: 22px;
      height: 22px;
    }
  }
  & &__keywordBtn {
    border: 0;
    background: none;
    position: absolute;
    right: 0;
    top: 5px;
    display: block;
    white-space: nowrap;
    z-index: 600;
  }
  & &__closeBtn {
    width: 20px;
    position: absolute;
    top: -15px;
    right: -10px;
  }
  & &__closeBtn {
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  @include container;
  display: none;
  @include media_desktop() {
    display: block;
    width: 100%;
    @include reset_link;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom;
  @include media_desktop {
    display: inline-block;
  }
}

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  @include media_desktop {
    float: left;
    width: auto;
  }
}

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #e8e8e8;
  margin: 0;
  padding: 16px;
  height: auto;
  color: #2e3233;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  @include media_desktop {
    text-align: center;
    border-bottom: none;
  }
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0;
  @include media_desktop {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 0.3s;
  @include media_desktop {
    overflow: hidden;
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black;
}

.ec-itemNav__nav > li:hover > a {
  background: #fafafa;
}

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333;
}

.ec-itemNav__nav > li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;
  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

.ec-itemNav__nav li ul li ul:before {
  @include media_desktop {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

.ec-itemNav__nav li ul li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

.ec-itemNav__nav li ul li ul li a {
  background: #7d7d7d;
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms;
  @include media_desktop() {
    display: none;
  }

  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #f8f8f8;
  }

  .ec-headerSearch {
    padding: 16px 8px 26px;
    background: #ebebeb;
    color: #636378;
    select {
      width: 100% !important;
    }
  }

  .ec-headerCategoryArea {
    .ec-headerCategoryArea__heading {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      padding: 1em 10px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      background: #f8f8f8;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ec-itemNav__nav li a {
      border-bottom: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      color: black;
      font-weight: normal;
      background: #f8f8f8;
    }

    .ec-itemNav__nav li ul li a {
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-weight: normal;
      background: white;
    }

    .ec-itemNav__nav > li:hover > a {
      background: #f8f8f8;
    }

    .ec-itemNav__nav > li:hover li:hover > a {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li a {
      padding-left: 40px;
      color: black;
      background: white;
    }

    .ec-itemNav__nav li:hover ul li ul li a:hover {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li ul li a {
      padding-left: 60px;
      font-weight: normal;
    }
  }
  .ec-headerLinkArea {
    background: black;

    .ec-headerLink__list {
      border-top: 1px solid #ccc;
    }

    .ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
    .ec-headerLink__icon {
      display: inline-block;
      width: 28px;
      font-size: 17px;
    }
  }
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;

  .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media_desktop {
    display: none;
  }
}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000;

  @include media_desktop() {
    display: none;
  }
}
.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all 0.3s;

  @include media_desktop {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all 0.3s;
  visibility: hidden;

  @include media_desktop {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;

  @include media_desktop {
    display: none;
  }
}

.section-header {
  border-bottom: solid black 1px;
  margin: 16px 15px 0 15px;
  font-weight: 600;
  font-size: 11px;
  padding-bottom: 5px;
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
  display: none;
}



/*ヘッダー横のサイト名の切り替え*/
body{
  .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before {
    content: "On-line Shop";
  }
}
body[id^="page_ordermade"]{
  .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before {
    content: "矢のオーダーメイド";
  }
}
body[id^="page_brand"],
body[class^="blog_site"]{
  .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before {
    content: "Brand Site";
  }
}

.brandsite-header-right {
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;

  margin-bottom: 0;
  font-family: serif;
  font-size: 13px;

  > li {
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 25px;

    > a {
      color: white;
    }
  }
}
