@import "../mixins/media";
@import "../mixins/btn";

@include media_desktop() {
  .normal-button {
    background-color: $btn-primary3-bg;
    color: $btn-primary-color;
    border: none;
    padding: 12px 36px 12px 39px;
    border-radius: 4px;
    margin: 20px auto;
    width: 135px;
  }
}

@include media_mobile() {
  .normal-button {
    background-color: $btn-primary3-bg;
    color: $btn-primary-color;
    border: none;
    padding: 12px 36px 12px 39px;
    border-radius: 4px;
    margin: 20px auto;
    width: 135px;
  }
}
.cancel {
  opacity: 50%;
}

.primary-button {
  @include btn-od-primary();

  padding: 12px 20px 12px 20px;
  text-align: center;

  &.dark {
    @include btn-od-primary-dark();
  }

  &.radius-170 {
    border-radius: 170px;
  }
}
