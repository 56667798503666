@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";

.deliver-point {
  .ec-role {
    padding: 30px 20px 0;
  }
  .ec-registerRole {
    margin: 0;
  }
}
