@import "../mixins/variables";
@import "../mixins/variables";

.zoom-dialog {
  //width: 315px;

  // override Modal.content
  .Modal {
    .content {
      padding: 20px;
      width: 415px;
      user-select: none;
    }
  }

  & &__div {
    width: 100%;
    height: auto;
    margin: 0px auto;
    background: #ededed;

    img{
      object-fit:cover;
      width: 100%;
    }

  }
  & &__category {
    font-size: 8px;
    text-align: left;
    display: block;
    margin: 17px 0 0 0;
    color:#505050;
    line-height: 1;
  }
  & &__name {
    background-color: #F7F7F7;
    padding: 10px;
    text-align: left;
    display: block;
    margin: 7px 0 0 0;
    line-height: 1;
  }
  & &__label {
    font-size: 8px;
    text-align: left;
    display: block;
    margin: 17px 0 0 0;
    color:#505050;
    line-height: 1;
  }
  & &__price {
    text-align: left;
    display: block;
    background-color: #F7F7F7;
    padding: 10px;
    margin: 7px 0 0 0;
    line-height: 1;

    &__comment {
      font-size: 8px;
      text-align: left;
      display: block;
      margin: 11px 0 0 0;
      color: #505050;
      line-height: 1;
    }

  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .normal-button {
      @include btn-od-default();
      border-radius: 45px;
      line-height: 1;
      background-color: #333333;
      padding: 15px 21px;
      color:#ffffff;

      &:hover{
        background-color: #000;
        color:#ffffff;
      }

    }
  }
}
