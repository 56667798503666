.order-option-dropdown {
  &--area {
    margin: 5px 5px 0px 5px;
  }

  &--dropdown {
    color: #ffffff;
    background-color: #4b4b4b;
    margin: 5px;
    cursor: pointer;

    &:nth-child(2) {
      margin-top: 0px;
    }
  }
}

.order-option-toggle {
  &--area {
    margin: 11px 10px 0px 10px;
    width: 432px;
  }

  &--toggle-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 3.097%;

    > .toggle-label {
      min-width: 100px;
      margin-right: auto;
      margin-bottom: 0px;
      line-height: 40px;
      font-weight: 600;
    }

    > .toggleContainer {
      width: 276px;
    }
  }
}
