.loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: $zindex-modal;
  display: none;
  justify-content: center;
  align-items: center;

  &.active {
    display: flex;
  }
}
