@media screen and (min-width: 768px) {
  .parts-container {
    display: flex;
    overflow: hidden;
    height: 100vh;

    & &__shaft-3d {
      width: 100vw;
      max-width: calc(100vw - 452px);
      position: relative;
    }

    & &__button--cart {
      position: absolute;
      bottom: 0;
      left: 0px;
      transition-property: left;
      transition-duration: 0.5s;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      width: 160px;
      height: 70px;
      border: none;
      pointer-events: none;
      opacity: 0.5;
      &:hover{
        opacity: 0.8;
      }
    }
    .move {
      left: 161px;
    }
    .view {
      pointer-events: inherit;
      opacity: 1;
    }
    & &__button--home {
      position: absolute;
      bottom: 0;
      display: none;
      left: -161px;
      transition-property: display, left;
      transition-duration: 0.5s;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      width: 160px;
      height: 70px;
      border: none;
      &:hover{
        opacity: 0.8;
      }
    }
    .move-none {
      left: 0px;
      display: inline;
    }

    //OrderPartsSelect.tsx
    .order-parts-select {
      @include fadeIn-use(fadeIn-left, 1s, 0.6s);
      //width: 221px;
      width: 18.805vh;
      max-width:  173px;
      height: 100%;
      position: absolute;
      top: 132.5px;
      left:8px;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      pointer-events: none;
      padding: 0;
    }

    .order-parts-select-button-div {
      width: 452px;
      height: 116px;
      background-color: unset;
      padding: 36px 0 35px 0;
      border-top: 1px #e5e5e5 solid;
      text-align: center;

      .normal-button {
        width: calc(50vw - 59px);
        height: 44px;
        max-width: 167px;
        font-size: 14px;
        line-height: 44px;
        border-radius: 22px;
        padding: 0;
        margin: 0 16px;
        border: none;

        &:hover {
          opacity: 0.75;
        }
      }
      .yes {
        color: #fff;
        background-color: #333;
      }
      .no {
        color: #333;
        background-color: #ddd;
      }
    }
  }
}
