@import "../mixins/media";

//DT版1366px, MB版375pxを基準に作成

$color-ecsite-1: #eabdbd;
$color-ecsite-2: rgb(198, 187, 235);

.back-primary-color {
  background-color: $color-ecsite-1;
}

.position-relative {
  position: relative;
}

#page_brand {
  width: 100vw;
  overflow-x: hidden;
}

// https://qiita.com/yuta-38/items/e0df334755d4f4a42d2d#2%E8%BE%BA%E3%82%92%E6%8C%87%E5%AE%9A%E3%81%97%E3%81%9F%E5%B0%8F%E3%81%95%E3%81%AA%E6%AD%A3%E6%96%B9%E5%BD%A2
.diagonal-background {
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;

        content: " ";
        display: inline-block;
        border-top: 100vh solid $color-ecsite-1;
        border-right: 100vw solid $color-ecsite-2;
    }
}

.theme-background-wrap {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.theme-background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-image: url('/html/template/default/assets/brandsite/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: multiply;
}

.theme-background-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #77d2dd, #89d2db 17.7%, #a6d2d8 28.1%, #fed2d0 68%, #edd5c1 100%, transparent);
}

.theme-background-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('/html/template/default/assets/brandsite/background-blue.png');
  background-repeat: no-repeat;
  background-size: cover;
  visibility: hidden;
}

#arrow-canvas {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}

.gradient-bg {
    background: linear-gradient(to right, #d13b9d, #9620c5 48%, #276acf 100%, transparent);
}

.gradient-txt {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.base-link {
  background: $color-ecsite-button;
  color: white;
  border-radius: 30px;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13px;
  line-height: 24px;

  @include media_desktop {
    padding-left: 18px;
    padding-right: 18px;
    font-size: 20px;
    line-height: 40px;
  }

  .suffix-icon {
    margin-left: 6px;
  }

  &:hover {
    background: $color-ecsite-button-hover;
    color: white;
    text-decoration: none;
  }
}

.sticky-top-nozindex {
  position: sticky;
  top: 0px;
}

.ml-22 {
  margin-left: 22px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-14 {
  margin-top: 14px;
}

.section-top {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;

    .logo {
      width: 91px;
      @include media_desktop {
        width: 148px;
      }
    }

    .logo-title {
      @include media_mobile {
        max-width: 148px;
      }
      margin-top: 6px;
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;

    .below-navi {
      color: white;
    }
  }
}

.section-hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .section-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 227.24px;
    font-weight: 600;
    font-size: 8vw;
    color: rgba(255, 255, 255, 0.6);

    @include media_mobile {
      font-size: 13vw;
    }
  }
}

.section-fullorder {
  height: 70vh;
  margin-left: 14vw;
  @include media_desktop {
    margin-left: 50vw;
  }

  .section-contents {
    max-width: 255px;
    @include media_desktop {
      max-width: 432px;
      min-height: 413.6px;
    }

    .title {
      width: 68%;

      max-width: 174px;
      @include media_desktop {
        max-width: 296px;
      }
    }

    .comment-1 {
      width: 100%;
    }

    .comment-3 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .comment-3-1 {
        height: 14px;
      }

      .comment-3-2 {
        height: 16px;
      }
    }
  }
}

.section-aboutus {
  height: 70vh;

  .section-contents {
    margin: 0 0 0 46px;
    @include media_desktop {
      margin: 0 0 0 20vw;
    }

    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    max-width: 232px;
    @include media_desktop {
      max-width: 410px;
      min-height: 311px;
    }

    .comment-1 {
      font-size: 18px;
      @include media_desktop {
        font-size: 27px;
      }

      font-weight: 100;
    }

    .comment-2 {
      font-size: 23px;
      @include media_desktop {
        font-size: 40px;
        line-height: 60px;
      }

      font-weight: 600;
      font-family: $font-product;
    }

    .comment-3 {
      font-size: 16px;
      font-weight: 300;
      line-height: 29px;
      height: 73px;

      @include media_mobile {
        max-width: 231px;
      }
    }

    .to-aboutus {
      margin-top: 30px;
      max-width: 160px;

      @include media_desktop {
        max-width: 236px;
      }
    }
  }

  .mt {
    margin-top: 17px;

    @include media_desktop {
      margin-top: 28px;
    }
  }
}

.section-shop {
  height: 70vh;

  margin-left: 14vw;
  @include media_desktop {
    margin-left: 50vw;
  }

  .section-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 453.18px;

    max-width: 274px;
    @include media_desktop {
      max-width: 401px;
    }

    .comment-1 {
      width: 100%;
    }

    .title {
      margin-top: 67px;
      width: 203px;

      @include media_desktop {
        width: 296px;
      }
    }

    .comment-2 {
      margin-top: 25px;

      @include media_desktop {
        margin-top: 35px;
      }

      width: 100%;
    }
  }
}


section#news,
section#contents,
section#category{
  @media screen and (max-width: 1189px) and (min-width: 768px){
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: unset;

  }
}

section#news {
  padding-top: 50vh;

  .ec-newsRole__news{
    background: #ffffff;
    @include media_mobile {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media_desktop {
      padding-top: 15px;
      padding-bottom: 15px;
    }

  }

  .ec-newsRole__newsItem{
    border-bottom: 1px #ccc solid;
    padding: 15px;
    margin-bottom: 0px;

  }
  .ec-newsRole__newsItem:last-child{
    border-bottom: unset;
  }
  .ec-newsRole__newsDescription{
    @include media_desktop {
      margin-top: 10px;
    }
  }
}


.section-layout {

  @include media_desktop {
    max-width: $max-width-dt;
    margin: 0 auto 0 auto;
  }

  @include media_mobile {
    .ec-secHeading,
    .section-heading {
      margin: 0 16px 15px 16px;
    }

    .ec-newsRole__news,
    .categorys-list,
    .contents-list {
      margin: 0 28px 0 28px;
    }
  }
}

.contents-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__item--wrapper {
    flex: 1;
    margin-bottom: 65px;
    max-width: 360px;
  }

  &__item {
    max-width: 320px;

    &--thumbnail {
      max-width: 320px;
      object-fit: cover;
    }

    &--title {
      margin-top: 18px;

      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
    }

    &--description {
      margin-top: 16px;

      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
    }

    &--link {
      margin-top: 8px;
      font-size: 10px;
      line-height: 30px;
      @include media_desktop {
        font-size: 12px;
        line-height: 36px;
      }
    }
  }
}

.section-heading {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4;

  &__separator {
    font-weight: 300;
    margin: 0 20px;
    display: inline-block;
    width: 1px;
    height: 14px;
  }

  &__title {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.15em;
    vertical-align: 2px;
  }
}


section#category{
  padding-bottom: 50px;
}

.categorys-list {
  max-width: $max-width-dt;

  margin: 0;
  padding: 0;
  width: auto;
  max-width: unset;
  @include media_mobile {
    margin: 0 0 0 2vw !important;
  }


  &__inner{
    margin: 0;
    padding: 0;
    width: 100%;

    //これがないと矢のstickyがFOOTER直前で死にます！(子要素のfloat:left;を解除)
    &::after{
      content: "";
      display: block;
      clear: both;
    }

    @include media_desktop {

      @media screen and (min-width: 1280px) {
        .grid-item{
          width: calc((100% / 3) - 42px);
          padding: 0;
          float: left;
        }
        .grid-item:nth-of-type(3n+1){
          margin: 31.5px 31.5px 31.5px 0px ;
        }
        .grid-item:nth-of-type(3n+2){
          margin: 31.5px ;
        }
        .grid-item:nth-of-type(3n+3){
          margin: 31.5px 0px 31.5px 31.5px ;
        }
      }

      @media screen and (max-width: 1279px) {
        .grid-item{
          overflow: hidden;
          margin: 0 10px 10px 0;
          width: calc((100% / 3) - (20px /3));
          float: left;
        }
        .grid-item:nth-of-type(3n+3){
          margin: 0 0px 10px 0;
        }
      }
    }
    @include media_mobile {
      .grid-item{
        width: calc(100% / 2);
        overflow: hidden;
        float: left;
        padding: 0 2vw 2vw 0;

        img {
          width: 100%;
          border-radius: 2.6664vw;
        }
      }
    }

  }



  &__item {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;


    img {
      transition: all 0.25s ease 0s;
      width: 100%;
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &:hover > img {
      opacity: 0.9;
      border-radius: 1.5vw;
    }
  }


}
