// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;

$clrRed: #de5d50;
$clrRich: #9a947e;
$clrGray: #f3f3f3;
$clrRightGray: #b8bec4;
$clrExtraGray: #636378;
$clrDarkGray: #333333;//#525263

$darkGray: #cccccc;

$danger: #f04444;
$cancel: #2d8ceb;

$header-navi-role-height: 90px;
$order-side-panel-width: 452px;

$zindex-floating: 1001; // 1001 ~ 1099     : フローティング要素
$zindex-header: 1100; // 1100            : ヘッダー
$zindex-overlay-back: 1150; // 1150            : Overlay(ドロワー背面)
$zindex-sidebar: 1200; // 1200            : ドロワー、サイドバー
$zindex-overlay-front: 1250; // 1250            : Overlay(ドロワー前面)
$zindex-modal: 1300; // 1300            : モーダル
$zindex-snackbar: 1400; // 1400            : Snackbar(通知ポップアップ)
$zindex-tooltip: 1500; // 1500            : Tooltip(要素の説明ポップアップ)

$brandsite-bg-color-1: #ebbdbd;
$brandsite-button-color-1: #fff;
$font-family-HiraginoKakuGothicProN: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
$color-purple: #884595;
$color-ecsite-icon: #fff;
$color-ecsite-1: #eabdbd;
$color-ecsite-2: #f8dddd;
$color-ecsite-3: #fff2f9;
$color-ecsite-button: linear-gradient(
  to right,
  rgba(209, 59, 157, 0.7) 0%,
  rgba(150, 32, 197, 0.7) 50%,
  rgba(39, 106, 207, 0.7) 100%
);
$color-ecsite-button-hover: linear-gradient(
  to right,
  rgba(209, 59, 157, 1) 0%,
  rgba(150, 32, 197, 1) 50%,
  rgba(39, 106, 207, 1) 100%
);

$color-ecsite-button-favorite: #c6bdeb;
$color-ecsite-button-favorite-hover:#C996D3;
$color-ecsite-button-cancel: #525263;
$color-ecsite-button-cancel-hover: #333333;
$color-ecsite-button-cancel2: #f0f0f0;
$color-ecsite-button-cancel2-hover: #dddddd;
$color-ecsite-mypage-navlist: #f0f0f0;
$color-ecsite-mypage-navlist-hover: #f5f7f8;
$color-ecsite-mypage-navlist-active: #c6bdeb;
$color-ecsite-mypage-navlist-active-hover: #C996D3;


//▼オーダーサイト向け
//黒ボタン
$ordersite-button-black-bg: #333333;
$ordersite-button-black-color: #ffffff;
$ordersite-button-black-bg-hover: #4B4B4B;
$ordersite-button-black-color-hover: #ffffff;

//白ボタン　※主にhome画面のサムネール内のUI(__add-cart-btn)(__close-btn)など
$ordersite-button-white-bg: #ffffff;
$ordersite-button-white-color: #333333;
$ordersite-button-white-bg-hover: #f5f5f5;
$ordersite-button-white-color-hover: #333333;

//タブ用ボタン(ピル型)　※home画面のタブなどに使用
$ordersite-tab-button-bg: transparent;
$ordersite-tab-button-color: #333333;
$ordersite-tab-button-bg-hover: #00000010;
$ordersite-tab-button-color-hover: #333333;

//タブ用ボタン(ピル型)--selected　※home画面のタブなどに使用
$ordersite-tab-button-selected-bg: #333333;
$ordersite-tab-button-selected-color: #ffffff;
$ordersite-tab-button-selected-bg-hover: #333333;
$ordersite-tab-button-selected-color-hover: #ffffff;


$ec-header-navi-role-heigth: 59px;

$container-height: calc(100vh - 59px); //headerとその下のメインコンテンツのみに画面表示にしたいときに使用
$container-height-mobile: calc(100vh - 56px); //headerとその下のメインコンテンツのみに画面表示にしたいときに使用

$font-product: serif;
$font-serif: serif;
$for-font-serif: normal normal normal 16px/27px serif; //fontfamily出なく、fontに当てる

$box-shadow: 0px 3px 6px #00000010;
$max-width-mb: 365px;
$max-width-dt: 1150px;
$max-width-dt-ecsite-top: 1366px;

$font-product: serif;

@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
