@import "../mixins/media";
@import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
#form_cart.ec-cartRole {
  padding-right: 0;
  padding-left: 0;
}

.ec-cartRole {
  @include container;
  margin: 0 auto 30px;
  &::before {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & &__progress {
    width: 100%;
    text-align: center;
  }
  & &__error {
    width: 100%;
    text-align: center;
    .ec-alert-warning {
      max-width: 80%;
      display: inline-block;
    }
  }
  & &__totalText {
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    @include media_desktop {
      margin-bottom: 30px;
      padding: 0;
    }
  }
  & &__cart {
    margin: 0;
    width: 100%;
    @include media_desktop {
      margin: 0 10%;
    }
  }
  .only-mobile-view {
    @include media_desktop {
      display: none;
    }
    @include media_mobile {
      width: 100%;
      font-weight: 900;
      margin: 10px 0;
    }
  }
  & &__cart2 {
    margin: 0;
    @include media_desktop {
      width: 80%;
      margin: 0 10% 0 20%;
    }
    @include media_mobile {
      width: 100%;

      .ec-cartRow {
        display: block;
        position: relative;
        border-bottom: 1px dotted #ccc;
      }
    }
  }

  /*アクションエリア*/
  & &__actions {
    text-align: right;
    width: 100%;
    max-width: 305px;
    margin-top: 30px;
    
    @include media_desktop {
      min-width: 250px;
      width: 25%;
      margin-right: 10%;
    }

    .ec-blockBtn--action {
      text-align: center;
    }
  }
    /*合計金額*/
    & &__total {
      padding: 15px 0 30px;
      font-weight: bold;
      font-size: 16px;
    }
      /*数字部分*/
      & &__totalAmount {
        margin-left: 30px;
        color: #de5d50;
        font-size: 24px;
      }

    /*レジに進むボタン*/
    .ec-blockBtn--action {    
      @include media_mobile {
        margin-bottom: 10px;
        height: 56px;
        line-height: 56px;
      }
      @include media_desktop {
        margin-bottom: 10px;
        height: 56px;
        line-height: 56px;
      }
    }
    /*お買い物を続けるボタン*/
    .ec-blockBtn--cancel {   
      @include media_mobile {
        margin-bottom: 10px;
        height: 56px;
        line-height: 56px;
      }
      @include media_desktop {
        margin-bottom: 10px;
        height: 56px;
        line-height: 56px;
      }
    }

}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
  @include media_desktop {
    margin-top: 50px;
    &:nth-child(1){
      border-top:none;
    }
  }
  @include media_mobile {
    margin-top:  30px;
  }
}


/*オーダーメイド仕様*/
.ordermade_list{
  /*全体のスタイル*/
  *{
    font-weight: normal !important;
  }

  /*外枠レイアウト*/
  @include media_mobile {
    margin-left:14%;
    margin-top: 10px;
  }
  @include media_desktop {
    width: 88%;
    margin-left:12%;
    margin-top: 10px;
  }

  /*見出し*/
  .heading{
    @include media_mobile {
      background: #f4f3f0;
      font-size: 14px;
      line-height: 1;
      padding: 15px 6px 10px;
      //font-weight:  bold !important;
    }
    @include media_desktop {
      background: #f4f3f0;
      font-size: 14px;
      line-height: 1;
      padding: 14px 12px;
    }

    .sp{
      display: none;
    
      @include media_desktop {
        display: none;
      }
    }
  }
  /*オーダーメイド仕様では下記の見出しを非表示にします。*/
  .ec-cartHeader{
   display: none; 
  }


  /*行ブロック .row-block*/
  .ec-cartRow{
    
    @include media_mobile {
      display:block;
      padding-left: 6px !important;
      padding-bottom: 10px;
      border-bottom: 1px #cccccc dotted;
      margin-bottom: 0;

    }
  }

  /*仕様テーブル内*/
    /*セル*/
    .ec-cartRow__contentColumn2,
    .ec-cartRow__amountColumn2{
      @include media_desktop {
        padding: 0 !important;
      }
    }
    .image_cell{

    }

    /*部位名*/
    .kind_name_cell{
      @include media_mobile {
        padding: 10px 0 3px;
        display: inline-block;
        width: calc(100% - 78px);
      }

      p{
        @include media_mobile {
          text-align: left !important;
          font-size: 14px;
          font-weight: bold !important;
          padding: 0;
        }
        @include media_desktop {
          text-align: left !important;
          font-size: 14px;
        }
      }
      
    }

    /*サマリー*/
    .summary_cell{
      @include media_mobile {
        font-size: 14px;
        display: inline-block;
        width: calc(100% - 78px);
      }
      @include media_desktop {
        font-size: 12px;
      }
      .ec-cartRow__summary2{

        div{
          
          @include media_desktop {
            padding: 2px 0px;
          }
        }
        
      }
    }
      /*小計*/
      .ec-cartRow__sutbtotalSP{
        display: none;
      }


    /*選び直すボタン*/
    .edit_parts_cell{
      @include media_mobile {
        font-size: 14px;
        text-align: right;
        padding-right: 0px !important;
        display: inline-block;
        width: 73px;
        padding: 0 !important;
      }
      @include media_desktop {
        font-size: 14px;
       text-align: right;
       padding-right: 10px !important;
      }

      .edit_parts_btn {
        @include media_mobile {
          text-align: center;
          line-height: 40px;
          height:40px;
          color: #525263;
        }
        @include media_desktop {
          text-align: center;
          line-height: 28px;
          color: #525263;
        }
        &:hover{
          opacity: 0.4;
          text-decoration: none;
        }
      }
    }
    


}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #f4f3f0;
  @include media_desktop {
    display: table-row;
  }
  & &__label {
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #f4f3f0;
    overflow-x: hidden;
    font-weight: bold;
  }
}
.ec-cartCompleteRole {
  @include container;
  padding: 0 0 100px;
  .ec-off4Grid__cell {
    width: 200px;
    margin: 0 auto !important;
  }
}
/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow {
  display: table-row;
  & &__delColumn {
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle;
    @include media_desktop {
      width: 12%;
    }
    .ec-icon {
      img {
        width: 1.5em;
        height: 1.5em;
        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
  & &__contentColumn {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table;
    @include media_desktop {
      display: table-cell;
    }
  }
  & &__contentColumn2 {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table;

    @include media_desktop {
      width: 15%;
      display: table-cell;

      button {
        background-color: #fff;
        border: solid #b8bec4 2.5px;
        padding: 4.5px 8px;
      }
    }
    @include media_mobile {
      button {
        background-color: #f5f7f8;
        border: solid #b8bec4 1px;
        padding: 10px;
      }
    }
    p {
      font-weight: 900;
      text-align: center;
      font-family: sans-serif;
    }
  }
  @include media_mobile {
    & &__contentColumn2 {
      border: none;
    }
    & &__contentColumn2:nth-of-type(1) {
      display: none;
    }
    & &__contentColumn2:nth-of-type(4) {
      padding: 10px;
    }
  }
  & &__img {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-right: 10px;
    @include media_desktop {
      display: inline-block;
      min-width: 80px;
      max-width: 100px;
      padding-right: 0;
    }
  }
  & &__summary {
    display: table-cell;
    margin-left: 5px;
    font-weight: 500;
    vertical-align: middle;
    width: 46%;
    @include media_desktop {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
    }
    .ec-cartRow__name {
      margin-bottom: 5px;
      a {
        font-weight: 600;
      }
    }
    .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal;
      @include media_desktop {
        display: none;
      }
    }
  }
  & &__summary2 {
    display: table-cell;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: middle;
    width: 100%;
    @include media_desktop {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
    }
  }
  & &__amountColumn {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    @include media_desktop {
      width: 16.66666667%;
    }

    .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px;
      @include media_desktop {
        display: block;
      }
    }
    .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px;
      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center;
      @include media_desktop {
        display: block;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountUpButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: 0.8em;
          height: 0.8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: 0.8em;
          height: 0.8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }

    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
  & &__amountColumn2 {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    @include media_desktop {
      width: 50%;
    }
    @include media_mobile {
      border: none;
    }
  }
  & &__subtotalColumn {
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%;
    @include media_desktop {
      display: table-cell;
    }
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow {
  & &__amountColumn {
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

.edit_parts_btn {
  display: inline-block;
  width: 73px;
  height: 28px;
  font-size: 12px;
  background: #F5F7F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
}
/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #f99;
  //margin-bottom: 20px;
  //↓
  margin: 50px 0 100px;
  padding: 30px 10px;
  border-radius: 9px;

  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
