@media screen and (max-width: 767px) {
  .customizedesign-container {
    position: absolute;
    top: 42px;
    left: 0;
    width: 100vw;
    height: calc(calc(var(--vh) * 100) - #{$ec-header-navi-role-heigth});
    z-index: 600;
    //background: rgba(255, 255, 255, 1);

    .order-design-select {
      height: 100%;
      overflow-y: scroll;

      .order-design-select-container {
        padding: 0 0 30px 0;
        margin: 30px 0 30px 0;
        display: block;

        &__comment {
          text-align: center;
          font-size: 16px;
          font-weight: 600;

          margin: 0 0 22px 0;
          line-height: 1.6;

        }
      }

      // refer: react-tabs/style/react-tabs.css
      .react-tabs {
        -webkit-tap-highlight-color: transparent;
      }

      .react-tabs__tab-list {

        padding: 0px;
        margin: 0;
        flex-direction: row;
        overflow-x: scroll;
        background-color: rgba(255,255,255,0.5);
        height: 56px;
        text-align: center;

        position: sticky;
        top: 0;
        left: 0;
        z-index: 700;
      }

      .react-tabs__tab {
        border-radius: 20px;
        display: inline-block;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 0;
        margin: 12px;
        width: 10em;
        cursor: pointer;
        font-size: 14px;
        height: 32px;
        line-height: 32px;

        background: $ordersite-tab-button-bg;
        color: $ordersite-tab-button-color;
        font-weight:600;
        //background-color: #f5f5f5;
        &:hover{
          background: $ordersite-tab-button-bg-hover;
          color: $ordersite-tab-button-color-hover;
        }
      }

      .react-tabs__tab--selected {
        background: $ordersite-tab-button-selected-bg;
        color: $ordersite-tab-button-selected-color;
        font-weight: 500;
        &:hover{
          background: $ordersite-tab-button-selected-bg-hover;
          color: $ordersite-tab-button-selected-color-hover;
        }
      }

      .react-tabs__tab--selected:before {
        //content: "▼";
      }

      .react-tabs__tab--disabled {
        cursor: default;
      }

      .react-tabs__tab:focus {
      }

      .react-tabs__tab:focus:after {
      }

      .react-tabs__tab-panel {
        display: none;
      }

      .react-tabs__tab-panel--selected {
        display: block;
        overflow: scroll;
      }
    }

    .react_flexbox {
      display: flex;
      justify-content: space-between;
      position: absolute;
      margin: 0 5vw;
      top: 5vw;
      width: 90vw;
    }

    .tab-label {
      height: 36px;
      color: #999;
      order: -1;
      cursor: pointer;
      flex: 1;
      text-align: center;
    }
    .tab-content {
      height: 46vw;
      width: 100vw;
      display: none;
      overflow-x: scroll;

      .order-img {
        width: 45vw;
        border: solid 1px;
        flex-shrink: 0;
      }
    }
    .tab-label::after {
      background: #f0f0f0;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 0;
    }
    .tab-switch:checked + .tab-label::after {
      background: $color-purple;
    }

    //クリックしたら
    .tab-switch:checked + .tab-label {
      color: #333;
    }
    .tab-switch:checked + .tab-label + .tab-content {
      display: flex;
      justify-content: space-between;
    }

    .tab-switch {
      display: none;
    }
  }
}
