@media screen and (max-width: 767px) {
  .parts-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // height: calc(var(--vh) * 100);
    height: calc(calc(var(--vh) * 100) - #{$ec-header-navi-role-heigth});
    top: $ec-header-navi-role-heigth; // /parts only

    & &__shaft-3d {
      // height: calc(calc(var(--vh) * 100) - #{$ec-header-navi-role-heigth}); // /parts only
      height: 100%;
      position: relative;
    }

    & &__div {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      width: 100vw;
      height: 76px;
      z-index:1000;
    }
    & &__button--cart {
      border: none;
      width: 33vw;
      height:  78%;
      background: none;
      color: #fff;
      position: absolute;
      right: 0;
      &:hover{
        opacity: 0.8;
      }
    }
    & &__button--3d {
      border: none;
      background: none;
      position: absolute;
      width: 70px;
      height: 70px;
      background: #dddddd;
      color: #6f6f6f;
      border-radius: 70px;
      top: -5px;
      right: 41.5vw;
      z-index: 950;

      @include media_desktop() {
        &:hover{
          opacity: 0.8;
        }
      }

      &.current-3d-view{
        background: #EB8379;
        color: white;
        pointer-events: none;
      }

    }
    & &__button--home {
      border: none;
      width: 33vw;
      height: 83%;
      background: none;
      color: #fff;
      position: absolute;
      left: 0;
      &:hover{
        opacity: 0.8;
      }
    }

    //OrderPartsSelect.tsx
    .order-parts-select {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-content:space-around;
      background-color: unset;
      position: absolute;
      --l-top-nav-height: calc(42px + 52px);
      --l-nav-height: calc(var(--l-top-nav-height) + 76px);
      height: calc(var(--vh) * 100 - var(--l-nav-height));
      top: var(--l-top-nav-height);
      width: 50vw;
      flex-wrap: wrap;
      pointer-events: none;
      transition: all .4s ease-in;

      &.current-3d-view{
        width: 100vw;
      }
    }

    .order-parts-select-button-div {
      background-color: unset;
      padding: 0 7px 14px 7px;

      .normal-button {
        width: calc(50vw - 21px);
        height: 44px;
        max-width: 167px;
        font-size: 14px;
        line-height: 44px;
        border-radius: 22px;
        padding: 0;
        margin: 0 7px;
      }
      .yes {
        color: #fff;
        background-color: #333;
      }
      .no {
        color: #333;
        background-color: #ddd;
      }
    }
  }
}
