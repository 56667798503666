@import "../mixins/media";
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole {
  @include container;
  @include media_desktop {
    margin: 80px auto;
    max-width: $max-width-dt;
  }

  & &__actions {
    padding-top: 20px;
  }
  p {
    margin: 16px 0;
  }
  .ec-off4Grid {
    &__cell {
      margin: 0 auto;
      width: auto;

      button {
        width: 300px !important;
      }
    }
  }
  h1 {
    border-top: none;
  }
}
.ec-contactConfirmRole {
  @include container;
  & &__actions {
    padding-top: 20px;
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}
.ec-contactCompleteRole {
  @include container;

  // FIXME: use the ec-contactConfrimRole class
  @include media_mobile() {
    margin-bottom: 16px;
  }
}
