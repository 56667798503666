@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  @include container;
  margin-bottom: 24px;
  ul {
    padding: 0;
    list-style: none;
  }
}
.ec-sliderItemRole {
  position: relative;
  @include container;
  margin-bottom: 24px;
  ul {
    padding: 0;
    list-style: none;
  }
  .item_nav {
    display: none;
    @include media_desktop {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
  .item_visual {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: $box-shadow;
  }
  .item_visual__ec-icon {
    position: absolute;
    z-index: 1;
    width: 60px;
    transform: rotate(180deg);

    @include media_mobile {
      display: none;
    }
  }
  .slideThumb {
    margin-bottom: 25px;
    width: 33%;
    opacity: 0.8;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width: 80%;
      box-shadow: $box-shadow;
    }
    //1枚目のサムネールにボーダーラインを付与（初期表示用）
    &:first-child img{
      @include media_desktop {
        border:3px solid #BAB3D6;
      }
    }
  }
}
