@charset "UTF-8";
@import url("../../../../../node_modules/normalize.css/normalize.css");
#page_ordermade_top {
  overflow: hidden; }

body {
  color: #333;
  transition: z-index 0ms 5.28455ms;
  background: #f6f6f6;
  margin: 0; }

a {
  text-decoration: none; }

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0; }

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/
/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263; }

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
@media only screen and (max-width: 767px) {
  .ec-pageHeader {
    padding: 24px 0; } }

.ec-pageHeader h1 {
  margin: 0 0 8px;
  border-bottom: 1px dotted #ccc;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold;
  font-family: serif;
  font-size: 24px; }
  @media only screen and (min-width: 768px) {
    .ec-pageHeader h1 {
      border-top: none;
      border-bottom: 1px solid #ccc;
      margin: 10px 16px 48px;
      padding: 8px;
      font-size: 32px;
      font-weight: bold; } }

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/
.ec-heading {
  margin: 24px 0; }

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/
.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-heading-bold {
      font-size: 18px; } }

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading h1,
.ec-rectHeading h2,
.ec-rectHeading h3,
.ec-rectHeading h4,
.ec-rectHeading h5,
.ec-rectHeading h6 {
  background: #f3f3f3;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: bold; }

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-reportHeading {
      border-top: 0;
      font-size: 32px; } }
  .ec-reportHeading h1,
  .ec-reportHeading h2,
  .ec-reportHeading h3,
  .ec-reportHeading h4,
  .ec-reportHeading h5,
  .ec-reportHeading h6,
  .ec-reportHeading p {
    font-weight: bold;
    font-size: 24px; }
    @media only screen and (min-width: 768px) {
      .ec-reportHeading h1,
      .ec-reportHeading h2,
      .ec-reportHeading h3,
      .ec-reportHeading h4,
      .ec-reportHeading h5,
      .ec-reportHeading h6,
      .ec-reportHeading p {
        font-size: 32px; } }
  .ec-reportHeading h2 {
    font-family: serif; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

Markup:
a(href="#").ec-link さくらのクラウド

Styleguide 1.2.1
*/
.ec-link {
  color: #0092c4;
  text-decoration: none;
  cursor: pointer; }
  .ec-link:hover {
    color: #33a8d0;
    text-decoration: none; }

/*
テキスト（太字）

テキストを太くするためのスタイルです。

Markup:
p.ec-font-bold この季節にぴったりな商品をご用意しました

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: bold; }

/*
テキスト（グレー）

テキストをグレーにするためのスタイルです。

Markup:
p.ec-color-grey 青色が美しい職人が仕上げた吹きガラス

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #9a947e; }

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

Markup:
p.ec-color-red ¥ 2,728 税込
p.ec-color-accent ¥ 2,728 税込

Styleguide 1.2.4
*/
.ec-color-red {
  color: #de5d50; }

.ec-color-accent {
  color: #de5d50; }

/*
フォントサイズ

フォントサイズを指定するためのスタイルです。

Markup:
.ec-font-size-1 さわやかな日差しが過ごしやすい季節
.ec-font-size-2 さわやかな日差しが過ごしやすい季節
.ec-font-size-3 さわやかな日差しが過ごしやすい季節
.ec-font-size-4 さわやかな日差しが過ごしやすい季節
.ec-font-size-5 さわやかな日差しが過ごしやすい季節
.ec-font-size-6 さわやかな日差しが過ごしやすい季節


Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px; }

.ec-font-size-2 {
  font-size: 14px; }

.ec-font-size-3 {
  font-size: 16px; }

.ec-font-size-4 {
  font-size: 20px; }

.ec-font-size-5 {
  font-size: 32px; }

.ec-font-size-6 {
  font-size: 40px; }

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

Markup:
p.ec-text-ac さわやかな日差しが過ごしやすい季節

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center; }

/*
価格テキスト

価格を表示するテキストです。

価格文字にスペースを取るほか、税込み等の表示を小さくする効果もあります。

spanを用いたインライン要素として利用します。

Markup:
div(style="color:#DE5D50;font-size:28px")
    span.ec-price
      span.ec-price__unit ¥
      span.ec-price__price 1,280
      span.ec-price__tax 税込

Styleguide 1.2.7
*/
.ec-price .ec-price__unit {
  font-size: 18px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__unit {
      font-size: 1em; } }

.ec-price .ec-price__price {
  display: inline-block;
  padding: 0 0.3em;
  font-size: 18px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__price {
      font-size: 1em; } }

.ec-price .ec-price__tax {
  font-size: 12px; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__tax {
      font-size: 0.57em; } }

/*
テキストの位置

テキストや、入れ子にしたインライン要素を
「左揃え」「中央揃え」「右揃え」に設定することができます。

Markup:
h3 左揃え
p.text-left
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 中央揃え
p.text-center
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 右揃え
p.text-right
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?

Styleguide 1.2.8
*/
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

ex [注文完了 （ログイン後、カートに商品を入れ注文完了まで行う）](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました
p.ec-reportDescription
      | ただいま、ご注文の確認メールをお送りさせていただきました。
      br
      | 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
      br
      | 今後ともご愛顧賜りますようよろしくお願い申し上げます。


Styleguide 1.2.9
*/
.ec-reportDescription {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4; }

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。 .ec-para-normalで16pxの余白をつけることができます。

Markup:
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block; }
  .ec-definitions dt, .ec-definitions--soft dt,
  .ec-definitions dd,
  .ec-definitions--soft dd {
    display: inline-block;
    margin: 0; }
  .ec-definitions dt, .ec-definitions--soft dt {
    font-weight: bold; }

.ec-definitions--soft dt {
  font-weight: normal; }

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/
.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom: 16px; }
  .ec-borderedDefs dl {
    display: flex;
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 10px 0 0;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dl {
        flex-wrap: nowrap;
        padding: 15px 0 4px; } }
  .ec-borderedDefs dt,
  .ec-borderedDefs dd {
    padding: 0; }
  .ec-borderedDefs dt {
    font-weight: normal;
    width: 100%;
    padding-top: 0; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dt {
        padding-top: 14px;
        width: 30%; } }
  .ec-borderedDefs dd {
    padding: 0;
    width: 100%;
    line-height: 2.5; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dd {
        width: 70%;
        line-height: 3; } }
  .ec-borderedDefs p {
    line-height: 1.4; }

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0; }
  .ec-list-chilled dt,
  .ec-list-chilled dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-list-chilled dt,
      .ec-list-chilled dd {
        padding: 16px 0; } }
  .ec-list-chilled dt {
    width: 30%; }
  .ec-list-chilled dd {
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-list-chilled dd {
        padding: 16px; } }

/*
ボーダーリスト

線が添えられたリストを表示します。

ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/
.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .ec-borderedList {
      border-top: 1px dotted #ccc; } }
  .ec-borderedList li {
    border-bottom: 1px dotted #ccc; }

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0; }
  .ec-list-chilled dt,
  .ec-list-chilled dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 16px 0; }
  .ec-list-chilled dt {
    width: 30%; }
  .ec-list-chilled dd {
    padding: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #f5f7f8;
  border-color: #ccc;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000010;
  border: none; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn:hover, .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn:active, .ec-inlineBtn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn.disabled, .ec-inlineBtn[disabled],
  fieldset[disabled] .ec-inlineBtn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #8c8c8c; }
  .ec-inlineBtn:hover {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
  .ec-inlineBtn:active, .ec-inlineBtn.active,
  .open > .ec-inlineBtn.dropdown-toggle {
    color: #525263;
    background-color: #d7dfe3;
    background-image: none;
    border-color: #adadad; }
    .ec-inlineBtn:active:hover, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:hover, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus,
    .open > .ec-inlineBtn.dropdown-toggle:hover,
    .open > .ec-inlineBtn.dropdown-toggle:focus,
    .open > .ec-inlineBtn.dropdown-toggle.focus {
      color: #525263;
      background-color: #c2ced4;
      border-color: #8c8c8c; }
  .ec-inlineBtn.disabled:hover, .ec-inlineBtn.disabled:focus, .ec-inlineBtn.disabled.focus, .ec-inlineBtn[disabled]:hover, .ec-inlineBtn[disabled]:focus, .ec-inlineBtn[disabled].focus,
  fieldset[disabled] .ec-inlineBtn:hover,
  fieldset[disabled] .ec-inlineBtn:focus,
  fieldset[disabled] .ec-inlineBtn.focus {
    background-color: #f5f7f8;
    border-color: #ccc; }
  .ec-inlineBtn .badge {
    color: #f5f7f8;
    background-color: #525263; }
  .ec-inlineBtn .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5cb1b1;
  border-color: #5cb1b1; }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--primary:hover, .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled],
  fieldset[disabled] .ec-inlineBtn--primary {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #fff;
    background-color: #479393;
    border-color: #2e6060; }
  .ec-inlineBtn--primary:hover {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active,
  .open > .ec-inlineBtn--primary.dropdown-toggle {
    color: #fff;
    background-color: #479393;
    background-image: none;
    border-color: #438d8d; }
    .ec-inlineBtn--primary:active:hover, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:hover, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus,
    .open > .ec-inlineBtn--primary.dropdown-toggle:hover,
    .open > .ec-inlineBtn--primary.dropdown-toggle:focus,
    .open > .ec-inlineBtn--primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b7b7b;
      border-color: #2e6060; }
  .ec-inlineBtn--primary.disabled:hover, .ec-inlineBtn--primary.disabled:focus, .ec-inlineBtn--primary.disabled.focus, .ec-inlineBtn--primary[disabled]:hover, .ec-inlineBtn--primary[disabled]:focus, .ec-inlineBtn--primary[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--primary:hover,
  fieldset[disabled] .ec-inlineBtn--primary:focus,
  fieldset[disabled] .ec-inlineBtn--primary.focus {
    background-color: #5cb1b1;
    border-color: #5cb1b1; }
  .ec-inlineBtn--primary .badge {
    color: #5cb1b1;
    background-color: #fff; }
  .ec-inlineBtn--primary .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #884595;
  border-color: #884595;
  border-radius: 30px;
  background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
  width: 40%;
  height: 45px;
  line-height: 27px;
  box-shadow: 0px 3px 6px #00000010;
  border: none; }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--action:hover, .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled],
  fieldset[disabled] .ec-inlineBtn--action {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #fff;
    background-color: #683572;
    border-color: #381d3e; }
  .ec-inlineBtn--action:hover {
    color: #fff;
    background-color: #683572;
    border-color: #62326b; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active,
  .open > .ec-inlineBtn--action.dropdown-toggle {
    color: #fff;
    background-color: #683572;
    background-image: none;
    border-color: #62326b; }
    .ec-inlineBtn--action:active:hover, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:hover, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus,
    .open > .ec-inlineBtn--action.dropdown-toggle:hover,
    .open > .ec-inlineBtn--action.dropdown-toggle:focus,
    .open > .ec-inlineBtn--action.dropdown-toggle.focus {
      color: #fff;
      background-color: #522a5a;
      border-color: #381d3e; }
  .ec-inlineBtn--action.disabled:hover, .ec-inlineBtn--action.disabled:focus, .ec-inlineBtn--action.disabled.focus, .ec-inlineBtn--action[disabled]:hover, .ec-inlineBtn--action[disabled]:focus, .ec-inlineBtn--action[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--action:hover,
  fieldset[disabled] .ec-inlineBtn--action:focus,
  fieldset[disabled] .ec-inlineBtn--action.focus {
    background-color: #884595;
    border-color: #884595; }
  .ec-inlineBtn--action .badge {
    color: #884595;
    background-color: #fff; }
  .ec-inlineBtn--action .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .ec-inlineBtn--action:hover {
    background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%); }

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263;
  border-radius: 30px;
  background: #525263;
  width: 40%;
  height: 45px;
  line-height: 27px;
  box-shadow: 0px 3px 6px #00000010;
  border: none; }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--cancel:hover, .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled],
  fieldset[disabled] .ec-inlineBtn--cancel {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #fff;
    background-color: #3b3b47;
    border-color: #18181d; }
  .ec-inlineBtn--cancel:hover {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active,
  .open > .ec-inlineBtn--cancel.dropdown-toggle {
    color: #fff;
    background-color: #3b3b47;
    background-image: none;
    border-color: #363642; }
    .ec-inlineBtn--cancel:active:hover, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:hover, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus,
    .open > .ec-inlineBtn--cancel.dropdown-toggle:hover,
    .open > .ec-inlineBtn--cancel.dropdown-toggle:focus,
    .open > .ec-inlineBtn--cancel.dropdown-toggle.focus {
      color: #fff;
      background-color: #2b2b34;
      border-color: #18181d; }
  .ec-inlineBtn--cancel.disabled:hover, .ec-inlineBtn--cancel.disabled:focus, .ec-inlineBtn--cancel.disabled.focus, .ec-inlineBtn--cancel[disabled]:hover, .ec-inlineBtn--cancel[disabled]:focus, .ec-inlineBtn--cancel[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--cancel:hover,
  fieldset[disabled] .ec-inlineBtn--cancel:focus,
  fieldset[disabled] .ec-inlineBtn--cancel.focus {
    background-color: #525263;
    border-color: #525263; }
  .ec-inlineBtn--cancel .badge {
    color: #525263;
    background-color: #fff; }
  .ec-inlineBtn--cancel .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .ec-inlineBtn--cancel:hover {
    background: #333333; }

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #f5f7f8;
  border-color: #ccc;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn:focus, .ec-blockBtn.focus, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn:hover, .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn:active, .ec-blockBtn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn.disabled, .ec-blockBtn[disabled],
  fieldset[disabled] .ec-blockBtn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #8c8c8c; }
  .ec-blockBtn:hover {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
  .ec-blockBtn:active, .ec-blockBtn.active,
  .open > .ec-blockBtn.dropdown-toggle {
    color: #525263;
    background-color: #d7dfe3;
    background-image: none;
    border-color: #adadad; }
    .ec-blockBtn:active:hover, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:hover, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus,
    .open > .ec-blockBtn.dropdown-toggle:hover,
    .open > .ec-blockBtn.dropdown-toggle:focus,
    .open > .ec-blockBtn.dropdown-toggle.focus {
      color: #525263;
      background-color: #c2ced4;
      border-color: #8c8c8c; }
  .ec-blockBtn.disabled:hover, .ec-blockBtn.disabled:focus, .ec-blockBtn.disabled.focus, .ec-blockBtn[disabled]:hover, .ec-blockBtn[disabled]:focus, .ec-blockBtn[disabled].focus,
  fieldset[disabled] .ec-blockBtn:hover,
  fieldset[disabled] .ec-blockBtn:focus,
  fieldset[disabled] .ec-blockBtn.focus {
    background-color: #f5f7f8;
    border-color: #ccc; }
  .ec-blockBtn .badge {
    color: #f5f7f8;
    background-color: #525263; }
  .ec-blockBtn .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5cb1b1;
  border-color: #5cb1b1;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--primary:hover, .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled],
  fieldset[disabled] .ec-blockBtn--primary {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #fff;
    background-color: #479393;
    border-color: #2e6060; }
  .ec-blockBtn--primary:hover {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active,
  .open > .ec-blockBtn--primary.dropdown-toggle {
    color: #fff;
    background-color: #479393;
    background-image: none;
    border-color: #438d8d; }
    .ec-blockBtn--primary:active:hover, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:hover, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus,
    .open > .ec-blockBtn--primary.dropdown-toggle:hover,
    .open > .ec-blockBtn--primary.dropdown-toggle:focus,
    .open > .ec-blockBtn--primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b7b7b;
      border-color: #2e6060; }
  .ec-blockBtn--primary.disabled:hover, .ec-blockBtn--primary.disabled:focus, .ec-blockBtn--primary.disabled.focus, .ec-blockBtn--primary[disabled]:hover, .ec-blockBtn--primary[disabled]:focus, .ec-blockBtn--primary[disabled].focus,
  fieldset[disabled] .ec-blockBtn--primary:hover,
  fieldset[disabled] .ec-blockBtn--primary:focus,
  fieldset[disabled] .ec-blockBtn--primary.focus {
    background-color: #5cb1b1;
    border-color: #5cb1b1; }
  .ec-blockBtn--primary .badge {
    color: #5cb1b1;
    background-color: #fff; }
  .ec-blockBtn--primary .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #884595;
  border-color: #884595;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 30px;
  background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
  color: #fff;
  height: 45px;
  line-height: 45px;
  box-shadow: 0px 3px 6px #00000010;
  border: none;
  transition: all 0.25s ease 0s; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--action:hover, .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled],
  fieldset[disabled] .ec-blockBtn--action {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #fff;
    background-color: #683572;
    border-color: #381d3e; }
  .ec-blockBtn--action:hover {
    color: #fff;
    background-color: #683572;
    border-color: #62326b; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active,
  .open > .ec-blockBtn--action.dropdown-toggle {
    color: #fff;
    background-color: #683572;
    background-image: none;
    border-color: #62326b; }
    .ec-blockBtn--action:active:hover, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:hover, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus,
    .open > .ec-blockBtn--action.dropdown-toggle:hover,
    .open > .ec-blockBtn--action.dropdown-toggle:focus,
    .open > .ec-blockBtn--action.dropdown-toggle.focus {
      color: #fff;
      background-color: #522a5a;
      border-color: #381d3e; }
  .ec-blockBtn--action.disabled:hover, .ec-blockBtn--action.disabled:focus, .ec-blockBtn--action.disabled.focus, .ec-blockBtn--action[disabled]:hover, .ec-blockBtn--action[disabled]:focus, .ec-blockBtn--action[disabled].focus,
  fieldset[disabled] .ec-blockBtn--action:hover,
  fieldset[disabled] .ec-blockBtn--action:focus,
  fieldset[disabled] .ec-blockBtn--action.focus {
    background-color: #884595;
    border-color: #884595; }
  .ec-blockBtn--action .badge {
    color: #884595;
    background-color: #fff; }
  .ec-blockBtn--action .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  @media only screen and (max-width: 767px) {
    .ec-blockBtn--action {
      margin: 0 auto; } }
  .ec-blockBtn--action:hover {
    background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%); }

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 30px;
  height: 45px;
  line-height: 45px;
  background-color: #c6bdeb;
  box-shadow: 0px 3px 6px #00000010;
  border: none;
  transition: all 0.25s ease 0s; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--cancel:hover, .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled],
  fieldset[disabled] .ec-blockBtn--cancel {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #fff;
    background-color: #3b3b47;
    border-color: #18181d; }
  .ec-blockBtn--cancel:hover {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active,
  .open > .ec-blockBtn--cancel.dropdown-toggle {
    color: #fff;
    background-color: #3b3b47;
    background-image: none;
    border-color: #363642; }
    .ec-blockBtn--cancel:active:hover, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:hover, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus,
    .open > .ec-blockBtn--cancel.dropdown-toggle:hover,
    .open > .ec-blockBtn--cancel.dropdown-toggle:focus,
    .open > .ec-blockBtn--cancel.dropdown-toggle.focus {
      color: #fff;
      background-color: #2b2b34;
      border-color: #18181d; }
  .ec-blockBtn--cancel.disabled:hover, .ec-blockBtn--cancel.disabled:focus, .ec-blockBtn--cancel.disabled.focus, .ec-blockBtn--cancel[disabled]:hover, .ec-blockBtn--cancel[disabled]:focus, .ec-blockBtn--cancel[disabled].focus,
  fieldset[disabled] .ec-blockBtn--cancel:hover,
  fieldset[disabled] .ec-blockBtn--cancel:focus,
  fieldset[disabled] .ec-blockBtn--cancel.focus {
    background-color: #525263;
    border-color: #525263; }
  .ec-blockBtn--cancel .badge {
    color: #525263;
    background-color: #fff; }
  .ec-blockBtn--cancel .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .ec-blockBtn--cancel:hover {
    background: #C996D3; }

/*
アイコンボタン

SVGアイコンを用いたアイコンボタンです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 2.2
*/
/*
アイコンボタン

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)

Markup:
a.ec-closeBtn
  .ec-icon
    img(src='/moc/icon/cross.svg', alt='close')

Styleguide 2.2.1
*/
.ec-closeBtn {
  cursor: pointer; }
  .ec-closeBtn .ec-icon img {
    display: inline-block;
    margin-right: 5px;
    width: 1em;
    height: 1em;
    position: relative;
    top: -1px;
    vertical-align: middle; }

/*
アイコンボタン(○)

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)



ex [お届け先編集画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/delivery)

Markup:
a.ec-closeBtn--circle
  .ec-icon
    img(src='/moc/icon/cross-white.svg', alt='close')

Styleguide 2.2.2
*/
.ec-closeBtn--circle {
  display: block;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: #b8bec4;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center; }
  .ec-closeBtn--circle .ec-icon img {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/
/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: none;
  position: fixed;
  width: 40px;
  height: 40px;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  opacity: 1;
  background-color: #9da3a988;
  border-radius: 50%; }
  .ec-blockTopBtn:hover {
    background-color: #4e555c88; }
  @media only screen and (min-width: 768px) {
    .ec-blockTopBtn {
      right: 30px;
      bottom: 30px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type="search"], .ec-halfInput input[type="search"], .ec-numberInput input[type="search"], .ec-zipInput input[type="search"], .ec-telInput input[type="search"], .ec-select input[type="search"], .ec-birth input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ec-input input[type="radio"], .ec-halfInput input[type="radio"], .ec-numberInput input[type="radio"], .ec-zipInput input[type="radio"], .ec-telInput input[type="radio"], .ec-select input[type="radio"], .ec-birth input[type="radio"],
.ec-input input[type="checkbox"],
.ec-halfInput input[type="checkbox"],
.ec-numberInput input[type="checkbox"],
.ec-zipInput input[type="checkbox"],
.ec-telInput input[type="checkbox"],
.ec-select input[type="checkbox"],
.ec-birth input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

.ec-input input[type="file"], .ec-halfInput input[type="file"], .ec-numberInput input[type="file"], .ec-zipInput input[type="file"], .ec-telInput input[type="file"], .ec-select input[type="file"], .ec-birth input[type="file"] {
  display: block; }

.ec-input input[type="range"], .ec-halfInput input[type="range"], .ec-numberInput input[type="range"], .ec-zipInput input[type="range"], .ec-telInput input[type="range"], .ec-select input[type="range"], .ec-birth input[type="range"] {
  display: block;
  width: 100%; }

.ec-input select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple], .ec-select select[multiple], .ec-birth select[multiple],
.ec-input select[size],
.ec-halfInput select[size],
.ec-numberInput select[size],
.ec-zipInput select[size],
.ec-telInput select[size],
.ec-select select[size],
.ec-birth select[size] {
  height: auto; }

.ec-input input[type="file"]:focus, .ec-halfInput input[type="file"]:focus, .ec-numberInput input[type="file"]:focus, .ec-zipInput input[type="file"]:focus, .ec-telInput input[type="file"]:focus, .ec-select input[type="file"]:focus, .ec-birth input[type="file"]:focus,
.ec-input input[type="radio"]:focus,
.ec-halfInput input[type="radio"]:focus,
.ec-numberInput input[type="radio"]:focus,
.ec-zipInput input[type="radio"]:focus,
.ec-telInput input[type="radio"]:focus,
.ec-select input[type="radio"]:focus,
.ec-birth input[type="radio"]:focus,
.ec-input input[type="checkbox"]:focus,
.ec-halfInput input[type="checkbox"]:focus,
.ec-numberInput input[type="checkbox"]:focus,
.ec-zipInput input[type="checkbox"]:focus,
.ec-telInput input[type="checkbox"]:focus,
.ec-select input[type="checkbox"]:focus,
.ec-birth input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-birth input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder {
    color: #999; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder {
    color: #999; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand, .ec-select input::-ms-expand, .ec-birth input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], .ec-select input[readonly], .ec-birth input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      font-size: 14px; } }

.ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
  cursor: pointer; }
  .ec-input select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus, .ec-select select:focus, .ec-birth select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #999; }
  .ec-input select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #999; }
  .ec-input select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand, .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled], .ec-input select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
      font-size: 14px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder {
    color: #999; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder {
    color: #999; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-birth textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], .ec-select textarea[readonly], .ec-birth textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
      font-size: 14px; } }

.ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus, .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  height: 40px;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      margin-bottom: 16px;
      border-radius: 5px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  height: auto;
  min-height: 100px; }

.ec-input p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p, .ec-select p, .ec-birth p {
  line-height: 1.4; }

.ec-input .ec-errorMessage, .ec-halfInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-telInput .ec-errorMessage, .ec-select .ec-errorMessage, .ec-birth .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #de5d50; }

.error.ec-input input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-select input, .error.ec-birth input,
.error.ec-input select,
.error.ec-halfInput select,
.error.ec-numberInput select,
.error.ec-zipInput select,
.error.ec-telInput select,
.error.ec-select select,
.error.ec-birth select {
  margin-bottom: 5px;
  border-color: #cf3f34;
  background: #fdf1f0; }

.ec-checkbox .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #de5d50; }

.ec-checkbox label {
  font-weight: 300; }

.error.ec-checkbox input,
.error.ec-checkbox label {
  border-color: #cf3f34;
  background: #fdf1f0; }

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type="text"] {
  display: inline-block;
  width: 47%;
  margin-left: 2%; }
  @media only screen and (min-width: 768px) {
    .ec-halfInput input[type="text"] {
      margin-left: 15px;
      width: 45%; } }

.ec-halfInput input[type="text"]:first-child {
  margin-left: 0; }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type="number"] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right; }
  @media only screen and (max-width: 767px) {
    .ec-numberInput input[type="number"] {
      width: 100%;
      display: block;
      text-align: left;
      border-radius: 9px;
      max-width: 100%; } }

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block; }
  .ec-zipInput input {
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 8em;
    font-size: 16px; }
  .ec-zipInput span {
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left: 5px; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px; }
    .ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
      width: 1em;
      height: 1em;
      position: relative;
      left: 3px;
      top: 3px; }
  .ec-zipInputHelp span {
    margin-left: 8px;
    display: inline-block;
    color: #0092c4;
    vertical-align: 3px; }

.ec-zipAuto {
  margin-bottom: 16px; }
  .ec-zipAuto .ec-inlineBtn {
    font-weight: normal; }

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em;
  text-align: left; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type="search"], .ec-halfInput input[type="search"], .ec-numberInput input[type="search"], .ec-zipInput input[type="search"], .ec-telInput input[type="search"], .ec-select input[type="search"], .ec-birth input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ec-input input[type="radio"], .ec-halfInput input[type="radio"], .ec-numberInput input[type="radio"], .ec-zipInput input[type="radio"], .ec-telInput input[type="radio"], .ec-select input[type="radio"], .ec-birth input[type="radio"],
.ec-input input[type="checkbox"],
.ec-halfInput input[type="checkbox"],
.ec-numberInput input[type="checkbox"],
.ec-zipInput input[type="checkbox"],
.ec-telInput input[type="checkbox"],
.ec-select input[type="checkbox"],
.ec-birth input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

.ec-input input[type="file"], .ec-halfInput input[type="file"], .ec-numberInput input[type="file"], .ec-zipInput input[type="file"], .ec-telInput input[type="file"], .ec-select input[type="file"], .ec-birth input[type="file"] {
  display: block; }

.ec-input input[type="range"], .ec-halfInput input[type="range"], .ec-numberInput input[type="range"], .ec-zipInput input[type="range"], .ec-telInput input[type="range"], .ec-select input[type="range"], .ec-birth input[type="range"] {
  display: block;
  width: 100%; }

.ec-input select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple], .ec-select select[multiple], .ec-birth select[multiple],
.ec-input select[size],
.ec-halfInput select[size],
.ec-numberInput select[size],
.ec-zipInput select[size],
.ec-telInput select[size],
.ec-select select[size],
.ec-birth select[size] {
  height: auto; }

.ec-input input[type="file"]:focus, .ec-halfInput input[type="file"]:focus, .ec-numberInput input[type="file"]:focus, .ec-zipInput input[type="file"]:focus, .ec-telInput input[type="file"]:focus, .ec-select input[type="file"]:focus, .ec-birth input[type="file"]:focus,
.ec-input input[type="radio"]:focus,
.ec-halfInput input[type="radio"]:focus,
.ec-numberInput input[type="radio"]:focus,
.ec-zipInput input[type="radio"]:focus,
.ec-telInput input[type="radio"]:focus,
.ec-select input[type="radio"]:focus,
.ec-birth input[type="radio"]:focus,
.ec-input input[type="checkbox"]:focus,
.ec-halfInput input[type="checkbox"]:focus,
.ec-numberInput input[type="checkbox"]:focus,
.ec-zipInput input[type="checkbox"]:focus,
.ec-telInput input[type="checkbox"]:focus,
.ec-select input[type="checkbox"]:focus,
.ec-birth input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-birth input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder {
    color: #999; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder {
    color: #999; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand, .ec-select input::-ms-expand, .ec-birth input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], .ec-select input[readonly], .ec-birth input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      font-size: 14px; } }

.ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
  cursor: pointer; }
  .ec-input select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus, .ec-select select:focus, .ec-birth select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #999; }
  .ec-input select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #999; }
  .ec-input select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand, .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled], .ec-input select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
      font-size: 14px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder {
    color: #999; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder {
    color: #999; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-birth textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], .ec-select textarea[readonly], .ec-birth textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    cursor: not-allowed; }
  @media only screen and (min-width: 768px) {
    .ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
      font-size: 14px; } }

.ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus, .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  height: 40px;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
      margin-bottom: 16px;
      border-radius: 5px; } }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  height: auto;
  min-height: 100px; }

.ec-input p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p, .ec-select p, .ec-birth p {
  line-height: 1.4; }

.ec-input .ec-errorMessage, .ec-halfInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-telInput .ec-errorMessage, .ec-select .ec-errorMessage, .ec-birth .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #de5d50; }

.error.ec-input input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-select input, .error.ec-birth input,
.error.ec-input select,
.error.ec-halfInput select,
.error.ec-numberInput select,
.error.ec-zipInput select,
.error.ec-telInput select,
.error.ec-select select,
.error.ec-birth select {
  margin-bottom: 5px;
  border-color: #cf3f34;
  background: #fdf1f0; }

.ec-checkbox .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #de5d50; }

.ec-checkbox label {
  font-weight: 300; }

.error.ec-checkbox input,
.error.ec-checkbox label {
  border-color: #cf3f34;
  background: #fdf1f0; }

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type="text"] {
  display: inline-block;
  width: 47%;
  margin-left: 2%; }
  @media only screen and (min-width: 768px) {
    .ec-halfInput input[type="text"] {
      margin-left: 15px;
      width: 45%; } }

.ec-halfInput input[type="text"]:first-child {
  margin-left: 0; }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type="number"] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right; }
  @media only screen and (max-width: 767px) {
    .ec-numberInput input[type="number"] {
      width: 100%;
      display: block;
      text-align: left;
      border-radius: 9px;
      max-width: 100%; } }

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block; }
  .ec-zipInput input {
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 8em;
    font-size: 16px; }
  .ec-zipInput span {
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left: 5px; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px; }
    .ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
      width: 1em;
      height: 1em;
      position: relative;
      left: 3px;
      top: 3px; }
  .ec-zipInputHelp span {
    margin-left: 8px;
    display: inline-block;
    color: #0092c4;
    vertical-align: 3px; }

.ec-zipAuto {
  margin-bottom: 16px; }
  .ec-zipAuto .ec-inlineBtn {
    font-weight: normal; }

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em;
  text-align: left; }

/*
フォーム部品(その他)

フォーム部品でテキストの入力以外の動作要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 3.2
*/
/*
ラジオ（水平）

水平に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　性別選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-radio
  label
    input(type="radio")
    span 男性
  label
    input(type="radio")
    span 女性

Styleguide 3.2.2
*/
.ec-radio label {
  margin-right: 20px; }

.ec-radio input {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px; }

.ec-radio span {
  font-weight: normal; }

/*
ラジオ(垂直)

垂直に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [購入画面 お支払方法](http://demo3.ec-cube.net/shopping)

Markup:
.ec-blockRadio
  label
    input(type="radio")
    span 郵便振替
  label
    input(type="radio")
    span 現金書留
  label
    input(type="radio")
    span 銀行振込
  label
    input(type="radio")
    span 代金引換

Styleguide 3.2.3
*/
.ec-blockRadio label {
  display: block; }

.ec-blockRadio span {
  padding-left: 10px;
  font-weight: normal; }

/*
セレクトボックス

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　都道府県選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-select
  select
    option 都道府県を選択
    option 北海道
    option 青森県
    option 岩手県
    option ...
.ec-select
  select
    option 選択して下さい
    option 公務員
    option コンサルタント
    option コンピュータ関連技術職
    option コンピュータ関連以外の技術職
    option ...

Styleguide 3.2.4
*/
.ec-selects {
  margin-bottom: 20px;
  border-bottom: 1px dotted #ccc; }

.ec-select {
  margin-bottom: 16px; }
  .ec-select select {
    display: inline-block;
    width: auto;
    border-radius: 6px;
    border: none;
    background-color: #f8f8f8;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(/html/template/default/assets/svg/ecsite_select-svg.svg);
    background-repeat: no-repeat;
    background-position: right 3px center;
    background-size: 6px 6px; }
    .ec-select select:focus {
      box-shadow: none; }
  .ec-select label {
    margin-right: 10px;
    font-weight: bold; }
  .ec-select label:nth-child(3) {
    margin-left: 10px;
    font-weight: bold; }

.ec-select__delivery {
  display: none;
  margin-right: 16px; }
  @media only screen and (min-width: 768px) {
    .ec-select__delivery {
      display: none; } }

.ec-select__time {
  display: none; }
  @media only screen and (min-width: 768px) {
    .ec-select__time {
      display: none; } }

/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth select {
  display: inline-block;
  width: auto;
  margin: 0 0 10px;
  border-radius: 6px;
  border: none;
  background-color: #f8f8f8;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/html/template/default/assets/svg/ecsite_select-svg.svg);
  background-repeat: no-repeat;
  background-position: right 3px center;
  background-size: 6px 6px; }
  .ec-birth select:focus {
    box-shadow: none; }
  @media only screen and (min-width: 768px) {
    .ec-birth select {
      margin: 0 8px 10px; } }

.ec-birth span {
  margin-left: 5px; }

/*
チェックボックス （水平）

水平に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　利用規約](http://demo3.ec-cube.net/entry)

Markup:
.ec-checkbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.6
*/
.ec-checkbox {
  padding: 0 0 20px; }
  .ec-checkbox label {
    display: inline-block; }
  .ec-checkbox input {
    cursor: pointer;
    margin-bottom: 10px; }
  .ec-checkbox span {
    font-weight: normal; }

/*
チェックボックス (垂直)

垂直に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

Markup:
.ec-blockCheckbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.7
*/
.ec-blockCheckbox label {
  display: block; }

.ec-blockCheckbox span {
  font-weight: normal; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォームラベル

フォームのラベルに関する要素を定義します。

sg-wrapper:
<div class="ec-registerRole">
  <div class="ec-off1Grid">
    <div class="ec-off1Grid__cell">
      <div class="ec-borderedDefs">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Styleguide 3.3
*/
/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px; }

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)


Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/
.ec-required {
  display: inline-block;
  margin-left: 0.8em;
  vertical-align: 2px;
  color: #de5d50;
  font-size: 12px;
  font-weight: normal; }
  @media only screen and (min-width: 768px) {
    .ec-required {
      margin-left: 1em; } }

/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px; }

.icon-close {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  width: 49px;
  height: 49px;
  text-align: center;
  line-height: 49px;
  cursor: pointer; }
  .icon-close:after {
    content: "×"; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/
/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid2 {
      display: flex; } }
  .ec-grid2 .ec-grid2__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid2 .ec-grid2__cell {
        width: 50%; } }
  .ec-grid2 .ec-grid2__cell2 {
    width: 80%;
    margin: 0 auto; }

/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid3 {
      display: flex; } }
  .ec-grid3 .ec-grid3__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell {
        width: 33.33333%; } }
  .ec-grid3 .ec-grid3__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell2 {
        width: 66.66667%; } }
  .ec-grid3 .ec-grid3__cell3 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell3 {
        width: 100%; } }

/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid4 {
      display: flex; } }
  .ec-grid4 .ec-grid4__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid4 .ec-grid4__cell {
        width: 25%; } }

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid6 {
      display: flex; } }
  .ec-grid6 .ec-grid6__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell {
        width: 16.66667%; } }
  .ec-grid6 .ec-grid6__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell2 {
        width: 33.33333%; } }
  .ec-grid6 .ec-grid6__cell3 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell3 {
        width: 50%; } }

/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid {
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off1Grid {
      display: block;
      margin: 0; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off1Grid {
      display: flex; } }
  .ec-off1Grid .ec-off1Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off1Grid .ec-off1Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 8.33333%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off1Grid .ec-off1Grid__cell {
      width: 83.33333%; } }

/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off2Grid {
      display: flex; } }
  .ec-off2Grid .ec-off2Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off2Grid .ec-off2Grid__cell {
        position: relative;
        min-height: 1px; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off2Grid .ec-off2Grid__cell {
      width: 66.66667%; } }

.ec-off2Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off2Grid {
      display: flex; } }
  .ec-off2Grid .ec-off2Grid__cell2 {
    margin: 0;
    background: #f3f4f4;
    width: 33.3%; }
    .ec-off2Grid .ec-off2Grid__cell2__description {
      width: 80%;
      margin: 37% auto 8%; }

/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off3Grid {
      display: flex; } }
  .ec-off3Grid .ec-off3Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off3Grid .ec-off3Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 25%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off3Grid .ec-off3Grid__cell {
      width: 50%; } }

/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off4Grid {
      display: flex; } }
  .ec-off4Grid .ec-off4Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off4Grid .ec-off4Grid__cell {
        margin-left: 33.33333%; }
        .ec-off4Grid .ec-off4Grid__cell button,
        .ec-off4Grid .ec-off4Grid__cell a {
          width: 100%; } }
    @media only screen and (max-width: 767px) {
      .ec-off4Grid .ec-off4Grid__cell button,
      .ec-off4Grid .ec-off4Grid__cell a {
        width: 100%; } }

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start; }

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end; }

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%; }
  .ec-imageGrid .ec-imageGrid__img {
    display: table-cell;
    padding: 10px;
    width: 100px; }
    @media only screen and (min-width: 768px) {
      .ec-imageGrid .ec-imageGrid__img {
        padding: 10px;
        width: 130px; } }
    .ec-imageGrid .ec-imageGrid__img img {
      width: 100%; }
  .ec-imageGrid .ec-imageGrid__content {
    vertical-align: middle;
    display: table-cell; }
    .ec-imageGrid .ec-imageGrid__content span {
      margin-left: 10px; }
    .ec-imageGrid .ec-imageGrid__content p {
      margin-bottom: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login {
  padding: 30px 13% 20px;
  height: auto;
  background: #f3f4f4;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .ec-login {
      margin: 0 16px 20px;
      padding: 30px 13% 60px; } }
  @media only screen and (max-width: 767px) {
    .ec-login {
      margin: 0 0 20px; } }
  .ec-login .ec-login__icon {
    text-align: center; }
  .ec-login .ec-icon {
    margin-bottom: 10px; }
    .ec-login .ec-icon img {
      width: 90px;
      height: 90px;
      display: inline-block; }
  .ec-login .ec-login__input {
    margin-bottom: 40px; }
    .ec-login .ec-login__input .ec-checkbox span {
      margin-left: 5px;
      font-weight: normal; }
  .ec-login .ec-login__actions {
    color: #fff; }
    .ec-login .ec-login__actions a {
      color: inherit;
      text-decoration: none; }
    .ec-login .ec-login__actions a:hover {
      text-decoration: none; }
    .ec-login .ec-login__actions button {
      width: 100%; }
  .ec-login .ec-login__link {
    margin-top: 5px;
    margin-left: 0; }
    @media only screen and (min-width: 768px) {
      .ec-login .ec-login__link {
        margin-left: 20px; } }
  .ec-login .ec-errorMessage {
    color: #de5d50;
    margin-bottom: 20px; }

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #f3f4f4; }
  @media only screen and (min-width: 768px) {
    .ec-guest {
      height: 440px;
      margin: 0 16px; } }
  .ec-guest .ec-guest__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .ec-guest .ec-guest__inner p {
      margin-bottom: 16px; }
  .ec-guest .ec-guest__actions {
    display: block;
    vertical-align: middle;
    text-align: center;
    color: #fff; }
    .ec-guest .ec-guest__actions a {
      color: inherit;
      text-decoration: none; }
    .ec-guest .ec-guest__actions a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-guest .ec-guest__actions a {
        width: 100%; } }
  .ec-guest .ec-guest__icon {
    font-size: 70px;
    text-align: center; }

.ec-cartNavi__icon {
  display: inline-block;
  font-size: 20px;
  color: #fff;
  display: inline-block;
  opacity: 1;
  visibility: visible;
  position: relative;
  cursor: pointer; }
  @media only screen and (max-width: 767px) {
    .ec-cartNavi__icon {
      display: none; } }

.ec-cartNavi__badge {
  display: inline-block;
  border-radius: 99999px;
  box-sizing: border-box;
  padding: 5px;
  height: 17px;
  font-size: 10px;
  line-height: 0.7;
  vertical-align: top;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  background-color: #de5d50;
  position: absolute;
  left: 60%;
  top: -10px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/
/*
商品アイテム（商品紹介B）

３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .ec-displayB {
      flex-direction: row; } }
  .ec-displayB .ec-displayB__cell {
    width: 100%;
    margin-bottom: 16px; }
    .ec-displayB .ec-displayB__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayB .ec-displayB__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayB .ec-displayB__cell {
        width: 31.4466%;
        margin-bottom: 0; } }
    .ec-displayB .ec-displayB__cell:hover {
      text-decoration: none; }
      .ec-displayB .ec-displayB__cell:hover img {
        opacity: 0.8; }
      .ec-displayB .ec-displayB__cell:hover a {
        text-decoration: none; }
  .ec-displayB .ec-displayB__img {
    margin-bottom: 15px; }
  .ec-displayB .ec-displayB__catch {
    margin-bottom: 15px;
    text-decoration: none;
    font-weight: bold;
    color: #9a947e; }
  .ec-displayB .ec-displayB__comment {
    margin-bottom: 14px;
    text-decoration: none;
    color: #525263;
    font-size: 14px; }
  .ec-displayB .ec-displayB__link {
    text-decoration: none;
    font-weight: bold;
    color: #9a947e; }

/*
商品アイテム（商品紹介C）

４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/
.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px; }
  .ec-displayC .ec-displayC__cell {
    width: 47%; }
    .ec-displayC .ec-displayC__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayC .ec-displayC__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayC .ec-displayC__cell {
        width: 22.8775%; } }
    .ec-displayC .ec-displayC__cell:hover a {
      text-decoration: none; }
    .ec-displayC .ec-displayC__cell:hover img {
      opacity: 0.8; }
  .ec-displayC .ec-displayC__img {
    display: block;
    width: 100%;
    margin-bottom: 15px; }
  .ec-displayC .ec-displayC__catch {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #9a947e; }
  .ec-displayC .ec-displayC__title {
    display: block;
    width: 100%;
    color: #525263; }
  .ec-displayC .ec-displayC__price {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #525263; }
  .ec-displayC .ec-displayC__price--sp {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #de5d50; }

/*
商品アイテム（商品紹介D）

６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/
.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse; }
  @media only screen and (min-width: 768px) {
    .ec-displayD {
      box-sizing: border-box;
      flex-wrap: nowrap; } }
  .ec-displayD .ec-displayD__cell {
    width: 30%;
    margin-bottom: 8px; }
    .ec-displayD .ec-displayD__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayD .ec-displayD__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayD .ec-displayD__cell {
        width: 14.3083%;
        margin-bottom: 16px; } }
    .ec-displayD .ec-displayD__cell:hover {
      text-decoration: none; }
      .ec-displayD .ec-displayD__cell:hover img {
        opacity: 0.8; }
  .ec-displayD .ec-displayD__img {
    display: block;
    width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath {
  letter-spacing: -0.4em;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  border-bottom: 1px dotted #ccc;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  color: #333333; }
  @media only screen and (min-width: 768px) {
    .ec-topicpath {
      padding: 60px 0 15px;
      border: 0;
      font-size: 16px; } }
  .ec-topicpath .ec-topicpath__item {
    font-size: 16px; }
    .ec-topicpath .ec-topicpath__item a {
      color: inherit;
      text-decoration: none; }
    .ec-topicpath .ec-topicpath__item a:hover {
      text-decoration: none; }
    .ec-topicpath .ec-topicpath__item a {
      border-bottom: solid black 1px;
      padding: 0 0 1px; }
      .ec-topicpath .ec-topicpath__item a:hover {
        opacity: 0.75; }
  .ec-topicpath .ec-topicpath__divider {
    color: #333333; }
  .ec-topicpath .ec-topicpath__item,
  .ec-topicpath .ec-topicpath__divider,
  .ec-topicpath .ec-topicpath__item--active {
    display: inline-block;
    min-width: 16px;
    text-align: center;
    position: relative;
    letter-spacing: normal; }
  .ec-topicpath .ec-topicpath__item--active {
    font-weight: bold; }
    .ec-topicpath .ec-topicpath__item--active a {
      color: inherit;
      text-decoration: none; }
    .ec-topicpath .ec-topicpath__item--active a:hover {
      text-decoration: none; }

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  list-style-type: none;
  margin: 80px auto;
  padding: 1em 0;
  text-align: center; }
  .ec-pager .ec-pager__item,
  .ec-pager .ec-pager__item--active {
    display: inline-block;
    min-width: 35px;
    padding: 0;
    margin: 0 6px;
    line-height: 35px;
    text-align: center;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000015; }
    .ec-pager .ec-pager__item a,
    .ec-pager .ec-pager__item--active a {
      color: inherit;
      text-decoration: none; }
    .ec-pager .ec-pager__item a:hover,
    .ec-pager .ec-pager__item--active a:hover {
      text-decoration: none; }
    .ec-pager .ec-pager__item a,
    .ec-pager .ec-pager__item--active a {
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none; }
    .ec-pager .ec-pager__item a:hover,
    .ec-pager .ec-pager__item--active a:hover {
      color: inherit; }
  .ec-pager .ec-pager__item--active {
    background: #EBBDBD; }
  .ec-pager .ec-pager__item:hover {
    background: #FFFFFF; }
  .ec-pager .ec-pager__item {
    background: #F3F3F3; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/
/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none; }
  @media only screen and (min-width: 768px) {
    .ec-progress {
      margin-bottom: 30px;
      padding: 0; } }
  .ec-progress .ec-progress__item {
    display: table-cell;
    position: relative;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    z-index: 10; }
    .ec-progress .ec-progress__item:after {
      content: "";
      position: absolute;
      display: block;
      background: #525263;
      width: 100%;
      height: 0.25em;
      top: 1.25em;
      left: 50%;
      margin-left: 1.5em\9;
      z-index: -1; }
    .ec-progress .ec-progress__item:last-child:after {
      display: none; }
  .ec-progress .ec-progress__number {
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: #525263;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%; }
    @media only screen and (min-width: 768px) {
      .ec-progress .ec-progress__number {
        line-height: 42px;
        width: 42px;
        height: 42px;
        font-size: 20px; } }
  .ec-progress .ec-progress__label {
    font-size: 12px; }
  .ec-progress .is-complete .ec-progress__number {
    background: #C6BDEB; }
  .ec-progress .is-complete .ec-progress__label {
    color: #C6BDEB; }

/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
@media only screen and (min-width: 768px) {
  .ec-cartNaviWrap {
    position: relative; } }

.ec-cartNavi {
  display: inline-block;
  padding: 10px 0 0 20px;
  width: auto;
  color: black;
  background: transparent; }
  @media only screen and (min-width: 768px) {
    .ec-cartNavi {
      display: flex;
      justify-content: space-between;
      border-radius: 99999px;
      box-sizing: border-box;
      padding: 12px 17px 10px;
      width: auto;
      min-width: 140px;
      height: 44px;
      white-space: nowrap;
      cursor: pointer;
      background: #f8f8f8; } }
  .ec-cartNavi .ec-cartNavi__icon {
    display: inline-block;
    font-size: 20px;
    display: inline-block;
    opacity: 1;
    visibility: visible;
    animation: fadeIn 200ms linear 0s;
    position: relative; }
  .ec-cartNavi .ec-cartNavi__badge {
    display: inline-block;
    border-radius: 99999px;
    box-sizing: border-box;
    padding: 5px;
    height: 17px;
    font-size: 10px;
    line-height: 0.7;
    vertical-align: top;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    background-color: #de5d50;
    position: absolute;
    left: 60%;
    top: -10px; }
    @media only screen and (min-width: 768px) {
      .ec-cartNavi .ec-cartNavi__badge {
        display: inline-block;
        min-width: 17px;
        position: relative;
        left: 0;
        top: 0; } }
  .ec-cartNavi .ec-cartNavi__price {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-cartNavi .ec-cartNavi__price {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        vertical-align: middle; } }

.ec-cartNavi.is-active .ec-cartNavi__icon:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900; }

.ec-cartNavi.is-active .ec-cartNavi__badge {
  display: none; }
  @media only screen and (min-width: 768px) {
    .ec-cartNavi.is-active .ec-cartNavi__badge {
      display: none; } }

/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 20;
  position: absolute;
  right: 0; }
  @media only screen and (min-width: 768px) {
    .ec-cartNaviIsset {
      margin-top: 10px;
      min-width: 256px;
      max-width: 256px; }
      .ec-cartNaviIsset::before {
        display: inline-block;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8.5px 10px 8.5px;
        border-color: transparent transparent #f8f8f8 transparent;
        position: absolute;
        top: -9px; } }
  .ec-cartNaviIsset .ec-cartNaviIsset__cart {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 16px;
    padding-bottom: 32px; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
      content: " ";
      display: table; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
      clear: both; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
    float: left;
    width: 45%; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartImage img {
      width: 100%; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
    float: right;
    width: 55%;
    padding-left: 16px;
    text-align: left;
    box-sizing: border-box; }
  .ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
    color: #fff;
    margin-bottom: 8px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
    margin-bottom: 8px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
    font-weight: bold; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTax {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 2px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentNumber {
    font-size: 14px; }

.ec-cartNaviIsset.is-active {
  display: block; }

/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/
.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0; }
  @media only screen and (min-width: 768px) {
    .ec-cartNaviNull {
      margin-top: 10px;
      min-width: 256px;
      max-width: 256px; }
      .ec-cartNaviNull::before {
        display: inline-block;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8.5px 10px 8.5px;
        border-color: transparent transparent #f8f8f8 transparent;
        position: absolute;
        top: -9px; } }
  .ec-cartNaviNull .ec-cartNaviNull__message {
    border: 1px solid #d9d9d9;
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #f99; }
    .ec-cartNaviNull .ec-cartNaviNull__message p {
      margin: 0; }

.ec-cartNaviNull.is-active {
  display: block; }

/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background: #f3f3f3;
  padding: 16px;
  margin-bottom: 16px; }
  .ec-totalBox .ec-totalBox__spec {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 8px; }
    .ec-totalBox .ec-totalBox__spec dt {
      font-weight: normal;
      text-align: left; }
    .ec-totalBox .ec-totalBox__spec dd {
      text-align: right; }
    .ec-totalBox .ec-totalBox__spec .ec-totalBox .ec-totalBox__spec__specTotal {
      color: #de5d50; }
  .ec-totalBox .ec-totalBox__total {
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: bold; }
  .ec-totalBox .ec-totalBox__paymentTotal {
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: bold; }
    .ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__price,
    .ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__taxLabel {
      color: #de5d50; }
  .ec-totalBox .ec-totalBox__price {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__price {
        font-size: 24px; } }
  .ec-totalBox .ec-totalBox__taxLabel {
    margin-left: 8px;
    font-size: 12px; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__taxLabel {
        font-size: 14px; } }
  .ec-totalBox .ec-totalBox__taxRate {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 8px;
    font-size: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__taxRate {
        font-size: 12px; } }
    .ec-totalBox .ec-totalBox__taxRate dt {
      font-weight: normal;
      text-align: left;
      margin-right: 8px; }
      .ec-totalBox .ec-totalBox__taxRate dt::before {
        content: "[ "; }
    .ec-totalBox .ec-totalBox__taxRate dd {
      text-align: right; }
      .ec-totalBox .ec-totalBox__taxRate dd::after {
        content: " ]"; }
  .ec-totalBox .ec-totalBox__pointBlock {
    padding: 18px 20px 10px;
    margin-bottom: 10px;
    background: #fff; }
  .ec-totalBox .ec-totalBox__btn {
    color: #fff; }
    .ec-totalBox .ec-totalBox__btn a {
      color: inherit;
      text-decoration: none; }
    .ec-totalBox .ec-totalBox__btn a:hover {
      text-decoration: none; }
    .ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
      font-size: 16px;
      font-weight: bold;
      width: 100%; }
    .ec-totalBox .ec-totalBox__btn .ec-blockBtn--cancel {
      margin-top: 8px;
      width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #f8f8f8; }
  @media only screen and (min-width: 768px) {
    .ec-news {
      margin-right: 3%; } }
  @media only screen and (min-width: 768px) {
    .ec-news {
      margin-bottom: 32px; } }
  .ec-news .ec-news__title {
    font-weight: bold;
    padding: 8px;
    font-size: 16px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-news .ec-news__title {
        padding: 16px;
        text-align: left;
        font-size: 24px; } }
  .ec-news .ec-news__items {
    padding: 0;
    list-style: none;
    border-top: 1px dotted #ccc; }

/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+b.ec-news
        +e.title 新着情報
        +e.UL.items
            +e.LI.item
                +b.ec-newsline.is_active
                    +e.info
                        +e.date 2016/09/29
                        +e.comment サイトオープンしました
                        +e.close
                            a.ec-closeBtn--circle
                                span.ec-closeBtn--circle__icon
                                    .ec-icon
                                        img(src='/moc/icon/angle-down-white.svg', alt='')
                    +e.description 一人暮らしからオフィスなどさまざまなシーンで あなたの生活をサポートするグッズをご家庭へお届けします！

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px; }
  .ec-newsline .ec-newsline__info {
    width: 100%;
    padding: 16px 0; }
    .ec-newsline .ec-newsline__info:after {
      content: " ";
      display: table; }
    .ec-newsline .ec-newsline__info:after {
      clear: both; }
  .ec-newsline .ec-newsline__date {
    display: inline-block;
    margin-right: 10px;
    float: left; }
  .ec-newsline .ec-newsline__comment {
    display: inline-block;
    float: left; }
  .ec-newsline .ec-newsline__close {
    float: right;
    display: inline-block;
    text-align: right; }
    .ec-newsline .ec-newsline__close .ec-closeBtn--circle {
      display: inline-block;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px; }
  .ec-newsline .ec-newsline__description {
    width: 100%;
    height: 0;
    transition: all 0.2s ease-out; }
  .ec-newsline.is_active .ec-newsline__description {
    height: auto;
    transition: all 0.2s ease-out;
    padding-bottom: 16px; }
  .ec-newsline.is_active .ec-icon img {
    transform: rotateX(180deg); }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/
/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole .ec-navlistRole__navlist {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  padding: 0;
  list-style: none; }
  .ec-navlistRole .ec-navlistRole__navlist a {
    color: inherit;
    text-decoration: none; }
  .ec-navlistRole .ec-navlistRole__navlist a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-navlistRole .ec-navlistRole__navlist {
      flex-wrap: nowrap; } }

.ec-navlistRole .ec-navlistRole__item {
  width: calc(50% - 1px);
  text-align: center;
  font-weight: 300;
  margin: 0.5px;
  border-radius: 4px;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .ec-navlistRole .ec-navlistRole__item {
      border-radius: 0; } }
  @media only screen and (min-width: 768px) {
    .ec-navlistRole .ec-navlistRole__item:first-child {
      border-radius: 10px 0 0 10px; } }
  @media only screen and (min-width: 768px) {
    .ec-navlistRole .ec-navlistRole__item:last-child {
      border-radius: 0 10px 10px 0; } }
  .ec-navlistRole .ec-navlistRole__item a {
    padding: 18px;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    background: #f0f0f0; }
    .ec-navlistRole .ec-navlistRole__item a:hover {
      background: #f5f7f8; }
  .ec-navlistRole .ec-navlistRole__item.active a {
    background: #c6bdeb;
    color: #ffffff; }
    .ec-navlistRole .ec-navlistRole__item.active a:hover {
      background: #C996D3; }

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/
/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px dotted #ccc;
  margin: 2em 0 2em 0;
  padding-bottom: 2em; }
  .ec-welcomeMsg:after {
    content: " ";
    display: table; }
  .ec-welcomeMsg:after {
    clear: both; }
  .ec-welcomeMsg textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-welcomeMsg img {
    max-width: 100%; }
  .ec-welcomeMsg html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-welcomeMsg *,
  .ec-welcomeMsg *::before,
  .ec-welcomeMsg *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-welcomeMsg img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-welcomeMsg {
      padding-left: 26px;
      padding-right: 26px; } }
  @media only screen and (min-width: 768px) {
    .ec-welcomeMsg {
      margin: 3em 0 3em 0;
      padding-bottom: 3em; } }
  .ec-welcomeMsg p {
    margin-top: 0.75em; }

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 16px; }

.ec-favoriteRole .ec-favoriteRole__itemList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .ec-favoriteRole .ec-favoriteRole__itemList a {
    color: inherit;
    text-decoration: none; }
  .ec-favoriteRole .ec-favoriteRole__itemList a:hover {
    text-decoration: none; }
  @media only screen and (max-width: 767px) {
    .ec-favoriteRole .ec-favoriteRole__itemList {
      justify-content: center; } }

.ec-favoriteRole .ec-favoriteRole__item {
  margin-bottom: 8px;
  /*レスポンシブ用*/
  width: 273px;
  position: relative;
  box-sizing: border-box;
  padding: 10px; }
  @media screen and (max-width: 1202px) {
    .ec-favoriteRole .ec-favoriteRole__item {
      width: calc((100vw - 112px) / 4); } }
  @media screen and (max-width: 767px) {
    .ec-favoriteRole .ec-favoriteRole__item {
      width: calc((100vw - 32px) / 3); } }
  @media screen and (max-width: 449px) {
    .ec-favoriteRole .ec-favoriteRole__item {
      width: calc((100vw - 32px) / 2); } }
  .ec-favoriteRole .ec-favoriteRole__item a.ec-favoriteRole__itemThumb:hover .ec-favoriteRole__item-image {
    opacity: 0.5;
    position: relative;
    z-index: 1; }
  .ec-favoriteRole .ec-favoriteRole__item a.ec-favoriteRole__itemThumb:hover ~ .ec-favoriteRole__itemTitle {
    text-decoration: underline; }
  .ec-favoriteRole .ec-favoriteRole__item a.ec-favoriteRole__itemThumb:hover ~ .ec-favoriteRole__itemPrice {
    text-decoration: underline; }
  .ec-favoriteRole .ec-favoriteRole__item-image {
    margin-bottom: 10px;
    text-align: center;
    /*レスポンシブ用*/
    height: calc(273px - 20px); }
    @media screen and (max-width: 1202px) {
      .ec-favoriteRole .ec-favoriteRole__item-image {
        height: calc(((100vw - 112px) / 4) - 20px); } }
    @media screen and (max-width: 767px) {
      .ec-favoriteRole .ec-favoriteRole__item-image {
        height: calc(((100vw - 32px) / 3) - 20px); } }
    @media screen and (max-width: 449px) {
      .ec-favoriteRole .ec-favoriteRole__item-image {
        height: calc(((100vw - 32px) / 2) - 20px); } }
  .ec-favoriteRole .ec-favoriteRole__item img {
    width: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; }
  .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    min-width: unset;
    min-height: unset;
    z-index: 100; }
    .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle .ec-icon img {
      width: 30px;
      height: 30px; }
    .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle:hover {
      opacity: 0.6; }

.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  height: auto;
  margin-bottom: 8px; }

.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin-bottom: 2px;
  font-size: 16px; }
  @media only screen and (max-width: 767px) {
    .ec-favoriteRole .ec-favoriteRole__itemTitle {
      font-size: 13px; } }

.ec-favoriteRole .ec-favoriteRole__itemPrice {
  font-weight: bold;
  margin-bottom: 0;
  font-size: 16px; }
  @media only screen and (max-width: 767px) {
    .ec-favoriteRole .ec-favoriteRole__itemPrice {
      font-size: 13px; } }

.ec-editRole .ec-off4Grid__cell {
  margin: 0 auto; }

.height-auto {
  height: auto !important; }

.shopping-guide {
  padding: 5vw 5vw 20vw;
  background-color: #eabdbd;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .shopping-guide {
      height: 300px;
      overflow: hidden; } }
  @media only screen and (min-width: 768px) {
    .shopping-guide .shopping-guide__openBtn {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .shopping-guide .shopping-guide__openBtn {
      background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
      color: #fff;
      border: none;
      padding: 10px 20px;
      width: 80vw;
      margin: 0 auto;
      display: block;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      opacity: 0.9; } }
  .shopping-guide .shopping-guide__title {
    font-size: 18px;
    font-weight: 800;
    font-family: unset; }
    .shopping-guide .shopping-guide__title span {
      font-size: 8px;
      font-weight: 300;
      margin: 0 0 0 10px; }
  .shopping-guide h1 {
    font-size: 22px;
    font-family: serif;
    margin: 50px 0 20px; }
  .shopping-guide__first-h1 {
    margin: 40px 0 20px !important; }
  .shopping-guide p {
    font-family: serif; }
    @media only screen and (min-width: 768px) {
      .shopping-guide p {
        font-size: 8px;
        line-height: 20px; } }
    @media only screen and (max-width: 767px) {
      .shopping-guide p {
        font-size: 14px; } }
  .shopping-guide ul {
    font-family: serif; }
    @media only screen and (min-width: 768px) {
      .shopping-guide ul {
        font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .shopping-guide ul {
        font-size: 14px; } }
    .shopping-guide ul li {
      line-height: 20px; }
  .shopping-guide__prefecture {
    list-style: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-role:after {
    content: " ";
    display: table; }
  .ec-role:after {
    clear: both; }
  .ec-role textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role img {
    max-width: 100%; }
  .ec-role html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role *,
  .ec-role *::before,
  .ec-role *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-role img {
    width: 100%; }

.ec-role:first-child {
  padding: 30px 30px 0px;
  margin: 10px auto 0 auto; }
  @media only screen and (max-width: 767px) {
    .ec-role:first-child {
      margin: -10px auto 0px auto;
      padding: 0px 20px; } }
  @media only screen and (min-width: 768px) {
    .ec-role:first-child .ec-pageHeader h1 {
      margin: 10px 0 48px;
      padding: 8px 0 18px; } }

.ec-role-complete,
.ec-role-activate {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-role-complete:after,
  .ec-role-activate:after {
    content: " ";
    display: table; }
  .ec-role-complete:after,
  .ec-role-activate:after {
    clear: both; }
  .ec-role-complete textarea,
  .ec-role-activate textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role-complete img,
  .ec-role-activate img {
    max-width: 100%; }
  .ec-role-complete html,
  .ec-role-activate html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role-complete *,
  .ec-role-complete *::before,
  .ec-role-complete *::after,
  .ec-role-activate *,
  .ec-role-activate *::before,
  .ec-role-activate *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-role-complete img,
  .ec-role-activate img {
    width: 100%; }

.ec-role-complete,
.ec-role-activate {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-role-complete:after,
  .ec-role-activate:after {
    content: " ";
    display: table; }
  .ec-role-complete:after,
  .ec-role-activate:after {
    clear: both; }
  .ec-role-complete textarea,
  .ec-role-activate textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role-complete img,
  .ec-role-activate img {
    max-width: 100%; }
  .ec-role-complete html,
  .ec-role-activate html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role-complete *,
  .ec-role-complete *::before,
  .ec-role-complete *::after,
  .ec-role-activate *,
  .ec-role-activate *::before,
  .ec-role-activate *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-role-complete img,
  .ec-role-activate img {
    width: 100%; }

.ec-role-relatedItem {
  background-color: #f8dddd; }

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole-common {
  padding: 40px 4px 300px;
  margin: 0 auto;
  max-width: 1150px;
  /*
  &.ec-mypageRole-history {
    padding: 0 0 50px;
    max-width: $max-width-dt;
    margin: 0 auto;
  
    .ec-blockBtn--action {
      text-align: center;
    }
  }
*/ }
  @media only screen and (max-width: 767px) {
    .ec-mypageRole-common {
      padding: 0px 0px 100px;
      margin-top: -10px; } }
  .ec-mypageRole-common .ec-mypageRole {
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #333333;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1150px; }
    .ec-mypageRole-common .ec-mypageRole:after {
      content: " ";
      display: table; }
    .ec-mypageRole-common .ec-mypageRole:after {
      clear: both; }
    .ec-mypageRole-common .ec-mypageRole textarea {
      /* for chrome fontsize bug */
      font-family: sans-serif; }
    .ec-mypageRole-common .ec-mypageRole img {
      max-width: 100%; }
    .ec-mypageRole-common .ec-mypageRole html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    .ec-mypageRole-common .ec-mypageRole *,
    .ec-mypageRole-common .ec-mypageRole *::before,
    .ec-mypageRole-common .ec-mypageRole *::after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit; }
    .ec-mypageRole-common .ec-mypageRole img {
      width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-mypageRole-common .ec-mypageRole {
        padding-left: 26px;
        padding-right: 26px; } }
    .ec-mypageRole-common .ec-mypageRole .ec-pageHeader h1 {
      font-family: serif; }
      @media only screen and (min-width: 768px) {
        .ec-mypageRole-common .ec-mypageRole .ec-pageHeader h1 {
          margin: 10px 0 48px;
          padding: 8px 0 18px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: #fff; }
  .ec-layoutRole .ec-layoutRole__contentTop {
    padding: 0; }
  .ec-layoutRole .ec-layoutRole__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: flex;
    flex-wrap: nowrap; }
  .ec-layoutRole .ec-layoutRole__main {
    width: 100%; }
  .ec-layoutRole .ec-layoutRole__mainBottom {
    background-color: #f8dddd;
    overflow: hidden; }
  .ec-layoutRole .ec-layoutRole__mainWithColumn {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__mainWithColumn {
        width: 75%; } }
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
        width: 50%; } }
  .ec-layoutRole .ec-layoutRole__left,
  .ec-layoutRole .ec-layoutRole__right {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__left,
      .ec-layoutRole .ec-layoutRole__right {
        display: block;
        width: 25%; } }

.ec-headerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto; }
  .ec-headerRole:after {
    content: " ";
    display: table; }
  .ec-headerRole:after {
    clear: both; }
  .ec-headerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerRole img {
    max-width: 100%; }
  .ec-headerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerRole *,
  .ec-headerRole *::before,
  .ec-headerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerRole img {
    width: 100%; }
  .ec-headerRole::after {
    display: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerRole:after {
      content: " ";
      display: table; }
    .ec-headerRole:after {
      clear: both; } }
  .ec-headerRole::before {
    display: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerRole {
      width: 100%; }
      .ec-headerRole:after {
        content: " ";
        display: table; }
      .ec-headerRole:after {
        clear: both; } }
  .ec-headerRole .ec-headerRole__title {
    width: 100%; }
  .ec-headerRole .ec-headerRole__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right; }
    @media only screen and (min-width: 768px) {
      .ec-headerRole .ec-headerRole__navSP {
        display: none; } }

#serach-open {
  display: none; }

#serach-open:checked + .ec-headerNaviRole__search {
  display: inline-block !important; }

.ec-headerNaviRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  position: relative;
  z-index: 1100;
  background-color: #eabdbd;
  display: flex;
  justify-content: space-around;
  padding: 0; }
  .ec-headerNaviRole:after {
    content: " ";
    display: table; }
  .ec-headerNaviRole:after {
    clear: both; }
  .ec-headerNaviRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerNaviRole img {
    max-width: 100%; }
  .ec-headerNaviRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerNaviRole *,
  .ec-headerNaviRole *::before,
  .ec-headerNaviRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerNaviRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-headerNaviRole {
      height: 86px; } }
  @media only screen and (max-width: 767px) {
    .ec-headerNaviRole {
      height: 56px; } }
  .ec-headerNaviRole .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .ec-headerNaviRole .ec-headerNaviRole__inner {
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin: 0 auto; }
  .ec-headerNaviRole .ec-headerNaviRole__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 0; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__left {
        min-width: 318px; } }
    @media only screen and (max-width: 767px) {
      .ec-headerNaviRole .ec-headerNaviRole__left {
        min-width: 260px; } }
  @media only screen and (max-width: 767px) and (max-width: 360px) {
    .ec-headerNaviRole .ec-headerNaviRole__left {
      min-width: 260px; } }
    .ec-headerNaviRole .ec-headerNaviRole__left__kamon {
      display: block;
      padding: 0;
      background-image: url(/html/template/default/assets/svg/kamon-mb.png); }
      @media only screen and (min-width: 768px) {
        .ec-headerNaviRole .ec-headerNaviRole__left__kamon {
          width: 34.36px;
          height: 33.11px;
          background-size: 34.36px 33.11px;
          margin: 0 0 0 27px; } }
      @media only screen and (max-width: 767px) {
        .ec-headerNaviRole .ec-headerNaviRole__left__kamon {
          width: 28.6px;
          height: 27.38px;
          background-size: 28.6px 27.38px;
          margin: 0 0 0 11px; } }
    .ec-headerNaviRole .ec-headerNaviRole__left__title {
      text-align: left;
      width: auto;
      height: auto;
      display: block; }
      @media only screen and (min-width: 768px) {
        .ec-headerNaviRole .ec-headerNaviRole__left__title {
          margin: -1px 0 0px 4.9px; } }
      @media only screen and (max-width: 767px) {
        .ec-headerNaviRole .ec-headerNaviRole__left__title {
          margin: 1px 0 0px 5.6px; } }
    .ec-headerNaviRole .ec-headerNaviRole__left__subtitle {
      width: calc(50% - 50px);
      font-size: 10px;
      white-space: nowrap; }
    .ec-headerNaviRole .ec-headerNaviRole__left .kamon {
      width: 29.35px;
      height: 28.08px;
      margin: 15.5px 4.6px 15.5px 17.9px; }
      @media only screen and (max-width: 767px) {
        .ec-headerNaviRole .ec-headerNaviRole__left .kamon {
          margin: 14px 4.6px 14px 9.9px; } }
    .ec-headerNaviRole .ec-headerNaviRole__left__header_logo_type {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 1em; }
      .ec-headerNaviRole .ec-headerNaviRole__left__header_logo_type__image {
        display: inline-block;
        width: 123.22px;
        height: 26.55px; }
    .ec-headerNaviRole .ec-headerNaviRole__left__subtitle {
      color: #fff;
      padding: 0px 0 0 0;
      line-height: 13px;
      font-family: serif;
      white-space: nowrap; }
      @media only screen and (min-width: 768px) {
        .ec-headerNaviRole .ec-headerNaviRole__left__subtitle {
          font-size: 12px;
          margin: 13px 0 0 11.7px; } }
      @media only screen and (max-width: 767px) {
        .ec-headerNaviRole .ec-headerNaviRole__left__subtitle {
          font-size: 10px;
          margin: 17px 0 0 8.8px; } }
  .ec-headerNaviRole .ec-headerNaviRole__search {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__search {
        display: none;
        position: absolute;
        top: -5px;
        left: -260px;
        width: 300px; }
        .ec-headerNaviRole .ec-headerNaviRole__search a {
          color: inherit;
          text-decoration: none; }
        .ec-headerNaviRole .ec-headerNaviRole__search a:hover {
          text-decoration: none; } }
  .ec-headerNaviRole .ec-headerNaviRole__openBtn__img {
    height: 100%;
    margin: 0 0 6px; }
  .ec-headerNaviRole .ec-headerNaviRole__navSP {
    display: block; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__navSP {
        display: none; }
        .ec-headerNaviRole .ec-headerNaviRole__navSP a {
          color: inherit;
          text-decoration: none; }
        .ec-headerNaviRole .ec-headerNaviRole__navSP a:hover {
          text-decoration: none; } }
  .ec-headerNaviRole .ec-headerNaviRole__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px; }
  .ec-headerNaviRole .ec-headerNaviRole__nav {
    display: inline-block;
    padding: 4px 0 0 0; }
    .ec-headerNaviRole .ec-headerNaviRole__nav a {
      color: inherit;
      text-decoration: none; }
    .ec-headerNaviRole .ec-headerNaviRole__nav a:hover {
      text-decoration: none; }
    @media only screen and (max-width: 767px) {
      .ec-headerNaviRole .ec-headerNaviRole__nav {
        display: none; } }
  .ec-headerNaviRole .ec-headerNaviRole__cart {
    display: inline-block; }
    .ec-headerNaviRole .ec-headerNaviRole__cart a {
      color: inherit;
      text-decoration: none; }
    .ec-headerNaviRole .ec-headerNaviRole__cart a:hover {
      text-decoration: none; }
  .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu {
    padding: 0 0px 0 10px;
    height: 59px;
    width: 59px;
    /* ボタン */
    /* メニュー */ }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu #menu-btn-check {
      display: none; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn {
      cursor: pointer;
      display: flex;
      height: 59px;
      width: 59px;
      justify-content: center;
      align-items: center; }
      @media only screen and (max-width: 767px) {
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn {
          height: 56px;
          width: 56px; } }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn .menu-btn__span,
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn .menu-btn__span:before,
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn .menu-btn__span:after {
        content: "";
        display: block;
        height: 2px;
        width: 28px;
        border-radius: 0px;
        background-color: #fff;
        position: absolute; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn .menu-btn__span:before {
        bottom: 10px; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .menu-btn .menu-btn__span:after {
        top: 10px; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu a {
      color: #f5f5f5;
      text-decoration: none;
      white-space: nowrap; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu li {
      padding: 20px 20px;
      list-style: none;
      text-align: left; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .btn-menu {
      border: 1px solid #999;
      background: transparent;
      color: #fff;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      line-height: 1; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_cover {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1200;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3); }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_cover .close_button {
        position: absolute;
        top: 0px;
        right: 250px;
        z-index: 1210;
        background: #333333;
        width: 40px;
        height: 40px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_cover .close_button:hover {
          opacity: 0.7; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_cover span {
        content: "";
        display: none;
        height: 2px;
        width: 20px;
        border-radius: 0px;
        background-color: #fff;
        position: absolute;
        top: 18px;
        right: 10px;
        z-index: 1210; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_cover span:nth-child(1):not(.en):not(.sub-text) {
        transform: rotate(-45deg); }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_cover span:nth-child(2) {
        transform: rotate(45deg); }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu #menu-btn-check:checked ~ .menu-btn .side_menu_cover {
      display: block; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu #menu-btn-check:checked ~ .menu-btn .side_menu_cover span {
      display: block; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav {
      display: none;
      background: #333333;
      font-family: sans-serif;
      position: absolute;
      z-index: 1210;
      overflow-x: hidden;
      overflow-y: scroll;
      text-align: left;
      top: 0;
      right: 0;
      width: 250px;
      transition: 0.5s;
      box-shadow: 0 0 10px; }
      @media screen and (min-width: 1130px) {
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav {
          right: 0px;
          top: 0; } }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav a:hover {
        opacity: 0.6; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav ul {
        padding: 0;
        margin: 0; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 {
        padding: 0px 0 25px 0 !important;
        background-color: #fff; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .sitemenu-title {
          font-weight: 600;
          padding: 15px 0 0 15px;
          font-size: 12px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .sitemenu-title .en {
            font-size: 14px;
            font-weight: 600;
            display: inline !important;
            position: initial; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 p {
          font-weight: 600;
          padding: 0 0 0 10px;
          margin: 0 !important; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 li {
          border-bottom: solid #d8d8d8 1px;
          font-weight: bold;
          font-size: 14px;
          width: auto;
          margin: 0 10px 0 10px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .sitelink {
          padding: 20px 5px 0 10px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .sitelink a {
            margin: 0 0 13px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .sitelink img {
            width: 15px;
            padding: 0 0 3px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 a {
          color: #333;
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          text-align: left; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .orderlink {
          padding: 17px 5px 0 10px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .orderlink a {
            margin: 0 0 13px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .orderlink img {
            width: 13px;
            height: auto;
            padding: 0px 1.45px 1px 1px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .EClink {
          padding: 17px 5px 0 10px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .EClink a {
            margin: 0 0 13px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .EClink img {
            width: 15px;
            height: auto;
            padding: 0px 0px 0 0px;
            margin: 0 0 0 -1px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .subtitle-margin {
          margin: 0 0 13px 20px !important; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul1 .subtitle-margin p {
            font-weight: 600; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul2 {
        padding: 20px 15px !important;
        background-color: #f5f5f5;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul2 p {
          width: 100%;
          font-size: 14px;
          text-align: initial;
          margin: 0 0 9px 0px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul2 li {
          border-bottom: solid #d8d8d8 1px;
          font-weight: bold;
          font-size: 14px;
          width: calc(50% - 7.5px);
          display: block;
          text-align: initial;
          padding: 0 !important;
          margin: 0 !important; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul2 li .sub-text {
            font-weight: normal;
            font-size: 10px;
            position: initial;
            display: inline !important;
            background: none; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul2 a {
          display: block;
          padding: 15px 0px 11px 0px !important;
          margin: 0 0 0 0 !important;
          color: #333; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1 {
        background-color: #fff;
        padding: 20px 15px !important;
        width: 100%; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1 img {
          color: #000 !important; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1 p {
          width: 100%;
          font-size: 14px;
          text-align: initial;
          margin: 0 0 9px 0px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1 a {
          color: #333; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1 input {
          border: solid #cccccc 1px;
          padding: 5px 9px;
          margin: 9px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1 input::placeholder {
          font-weight: 100;
          font-size: 10px; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .searchform {
          display: flex; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch {
          padding: 0px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch__category {
            display: none;
            width: 100px; }
            .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch__category .category_id {
              font-size: 8px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch__keyword {
            width: 100%;
            height: 36px;
            border-radius: 0; }
            .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch__keyword .search-name {
              margin: 0;
              padding: 0.2em 40px 0.5em 1em; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch__keywordBtn {
            right: 5px;
            top: 5px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__div1__search .ec-headerSearch__closeBtn {
            display: none; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul3 {
        background-color: #333333; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul3 li {
          font-weight: 300;
          font-size: 13px;
          border-bottom: solid #999999 1px; }
          .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul3 li img {
            width: 15px;
            padding: 0 0 4px 0;
            margin: 0 10px 0 0; }
      .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul4 {
        background-color: #333333;
        padding: 20px !important; }
        .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu .side_menu_nav__ul4 li {
          font-weight: 300;
          font-size: 13px;
          padding: 10px 0px;
          text-align: left; }
    .ec-headerNaviRole .ec-headerNaviRole__hamburger-menu #menu-btn-check:checked ~ .menu-btn .side_menu_nav {
      display: block;
      height: 100vh;
      cursor: initial;
      padding: 0 0 83px 0; }

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000; }
  .ec-headerNavSP .fas {
    vertical-align: top; }
  @media only screen and (min-width: 768px) {
    .ec-headerNavSP {
      display: none; } }

.ec-headerNavSP.is-active {
  display: none; }

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%; }
  .ec-headerTitle textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerTitle img {
    max-width: 100%; }
  .ec-headerTitle html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerTitle *,
  .ec-headerTitle *::before,
  .ec-headerTitle *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerTitle img {
    width: 100%; }
  .ec-headerTitle .ec-headerTitle__title {
    text-align: center; }
    .ec-headerTitle .ec-headerTitle__title h1 {
      margin: 0;
      padding: 0; }
    .ec-headerTitle .ec-headerTitle__title a {
      display: inline-block;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      color: black; }
      @media only screen and (min-width: 768px) {
        .ec-headerTitle .ec-headerTitle__title a {
          font-size: 40px; } }
      .ec-headerTitle .ec-headerTitle__title a:hover {
        opacity: 0.8; }
  .ec-headerTitle .ec-headerTitle__subtitle {
    font-size: 10px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-headerTitle .ec-headerTitle__subtitle {
        font-size: 16px;
        margin-bottom: 10px; } }
    .ec-headerTitle .ec-headerTitle__subtitle a {
      display: inline-block;
      color: #0092c4;
      text-decoration: none;
      cursor: pointer; }

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  text-align: right; }
  .ec-headerNav .ec-headerNav__item {
    color: #000;
    margin-left: 0;
    display: inline-block;
    width: 35px;
    height: 22px;
    position: relative; }
    .ec-headerNav .ec-headerNav__item:hover .ec-headerNaviRole__openBtn,
    .ec-headerNav .ec-headerNav__item:hover a .ec-headerNav__itemIcon,
    .ec-headerNav .ec-headerNav__item:hover a .ec-cartNavi__icon {
      cursor: pointer;
      -webkit-animation-name: bounce;
      animation-name: bounce;
      -webkit-transform-origin: center bottom;
      -ms-transform-origin: center bottom;
      transform-origin: center bottom;
      -webkit-animation-duration: .5s;
      animation-duration: .5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both; }

@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0); } }

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0); } }
    .ec-headerNav .ec-headerNav__item__ec-icon {
      height: 35px;
      width: 25px;
      cursor: pointer; }
  @media only screen and (max-width: 767px) {
    .ec-headerNav .ec-headerNav__item:nth-of-type(4) {
      display: block; }
    .ec-headerNav .ec-headerNav__item {
      display: none; } }
  .ec-headerNav .ec-headerNav__itemIcon {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: #fff; }
    @media only screen and (min-width: 768px) {
      .ec-headerNav .ec-headerNav__itemIcon {
        margin-right: 0;
        font-size: 20px; } }
  .ec-headerNav .ec-headerNav__itemLink {
    display: none;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: middle;
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-headerNav .ec-headerNav__itemLink {
        display: inline-block; } }

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
  position: relative; }
  .ec-headerSearch:after {
    content: " ";
    display: table; }
  .ec-headerSearch:after {
    clear: both; }
  @media only screen and (min-width: 768px) {
    .ec-headerSearch .searchform {
      display: flex; } }
  .ec-headerSearch .ec-headerSearch__category {
    float: none; }
    @media only screen and (min-width: 768px) {
      .ec-headerSearch .ec-headerSearch__category {
        float: left;
        width: 43%; } }
    .ec-headerSearch .ec-headerSearch__category .ec-select {
      overflow: hidden;
      width: 100%;
      margin: 0;
      text-align: center; }
      .ec-headerSearch .ec-headerSearch__category .ec-select select {
        width: 100%;
        cursor: pointer;
        padding: 8px 24px 8px 8px;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        appearance: none;
        color: #fff; }
        @media only screen and (min-width: 768px) {
          .ec-headerSearch .ec-headerSearch__category .ec-select select {
            max-width: 165px;
            height: 36px; } }
        .ec-headerSearch .ec-headerSearch__category .ec-select select option {
          color: #000; }
        .ec-headerSearch .ec-headerSearch__category .ec-select select::-ms-expand {
          display: none; }
      .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
        position: relative;
        border: 0;
        background: #000;
        color: #fff; }
        @media only screen and (min-width: 768px) {
          .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
            border-top-right-radius: inherit;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px; } }
        @media only screen and (max-width: 767px) {
          .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px; } }
        .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search::before {
          position: absolute;
          top: 0.8em;
          right: 0.4em;
          width: 0;
          height: 0;
          padding: 0;
          content: "";
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          pointer-events: none; }
  .ec-headerSearch .ec-headerSearch__keyword {
    color: #333333;
    border: 1px solid #ccc;
    background-color: #f6f6f6; }
    @media only screen and (min-width: 768px) {
      .ec-headerSearch .ec-headerSearch__keyword {
        width: 80%;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px; } }
    @media only screen and (max-width: 767px) {
      .ec-headerSearch .ec-headerSearch__keyword {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px; } }
    .ec-headerSearch .ec-headerSearch__keyword input[type="search"] {
      width: 100%;
      height: 34px;
      font-size: 16px;
      border: 0 none;
      padding: 0.5em 50px 0.5em 1em;
      box-shadow: none;
      background: none;
      box-sizing: border-box;
      margin-bottom: 0; }
      @media only screen and (min-width: 768px) {
        .ec-headerSearch .ec-headerSearch__keyword input[type="search"] {
          font-size: 12px; } }
    .ec-headerSearch .ec-headerSearch__keyword .ec-icon {
      width: 22px;
      height: 22px; }
  .ec-headerSearch .ec-headerSearch__keywordBtn {
    border: 0;
    background: none;
    position: absolute;
    right: 0;
    top: 5px;
    display: block;
    white-space: nowrap;
    z-index: 600; }
  .ec-headerSearch .ec-headerSearch__closeBtn {
    width: 20px;
    position: absolute;
    top: -15px;
    right: -10px; }

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  display: none; }
  .ec-categoryNaviRole:after {
    content: " ";
    display: table; }
  .ec-categoryNaviRole:after {
    clear: both; }
  .ec-categoryNaviRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-categoryNaviRole img {
    max-width: 100%; }
  .ec-categoryNaviRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-categoryNaviRole *,
  .ec-categoryNaviRole *::before,
  .ec-categoryNaviRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-categoryNaviRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-categoryNaviRole {
      display: block;
      width: 100%; }
      .ec-categoryNaviRole a {
        color: inherit;
        text-decoration: none; }
      .ec-categoryNaviRole a:hover {
        text-decoration: none; } }

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center; }

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav {
      display: inline-block; } }

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li {
      float: left;
      width: auto; } }

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #e8e8e8;
  margin: 0;
  padding: 16px;
  height: auto;
  color: #2e3233;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #e8e8e8; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li a {
      text-align: center;
      border-bottom: none; } }

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li ul {
      display: block;
      z-index: 100;
      position: absolute; } }

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 0.3s; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav__nav li ul li {
      overflow: hidden;
      height: 0; } }

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black; }

.ec-itemNav__nav > li:hover > a {
  background: #fafafa; }

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333; }

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav > li:hover > ul > li {
    overflow: visible;
    height: auto; } }

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto; }

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li ul:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px; } }

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li:hover > ul > li {
    overflow: visible;
    height: auto;
    width: auto; } }

.ec-itemNav__nav li ul li ul li a {
  background: #7d7d7d; }

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333; }

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/
.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole {
      display: none; } }
  .ec-drawerRole .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #f8f8f8; }
  .ec-drawerRole .ec-headerSearch {
    padding: 16px 8px 26px;
    background: #ebebeb;
    color: #636378; }
    .ec-drawerRole .ec-headerSearch select {
      width: 100% !important; }
  .ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 1em 10px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    background: #f8f8f8; }
  .ec-drawerRole .ec-headerCategoryArea p {
    margin-top: 0;
    margin-bottom: 0; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li a {
    border-bottom: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: black;
    font-weight: normal;
    background: #f8f8f8; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li a {
    border-bottom: 1px solid #ccc;
    padding-left: 20px;
    font-weight: normal;
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover > a {
    background: #f8f8f8; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover li:hover > a {
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li a {
    padding-left: 40px;
    color: black;
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li:hover ul li ul li a:hover {
    background: white; }
  .ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li ul li a {
    padding-left: 60px;
    font-weight: normal; }
  .ec-drawerRole .ec-headerLinkArea {
    background: black; }
    .ec-drawerRole .ec-headerLinkArea .ec-headerLink__list {
      border-top: 1px solid #ccc; }
    .ec-drawerRole .ec-headerLinkArea .ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      color: white; }
    .ec-drawerRole .ec-headerLinkArea .ec-headerLink__icon {
      display: inline-block;
      width: 28px;
      font-size: 17px; }

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000; }
  .ec-drawerRoleClose .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media only screen and (min-width: 768px) {
    .ec-drawerRoleClose {
      display: none; } }

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole.is_active {
      display: none; } }

.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all 0.3s; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRoleClose.is_active {
      display: none; } }

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all 0.3s;
  visibility: hidden; }
  @media only screen and (min-width: 768px) {
    .ec-overlayRole {
      display: none; } }

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible; }
  @media only screen and (min-width: 768px) {
    .have_curtain .ec-overlayRole {
      display: none; } }

.section-header {
  border-bottom: solid black 1px;
  margin: 16px 15px 0 15px;
  font-weight: 600;
  font-size: 11px;
  padding-bottom: 5px; }

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/
.ec-itemNavAccordion {
  display: none; }

/*ヘッダー横のサイト名の切り替え*/
body .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before {
  content: "On-line Shop"; }

body[id^="page_ordermade"] .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before {
  content: "矢のオーダーメイド"; }

body[id^="page_brand"] .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before,
body[class^="blog_site"] .ec-headerNaviRole .ec-headerNaviRole__left__subtitle::before {
  content: "Brand Site"; }

.brandsite-header-right {
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  font-family: serif;
  font-size: 13px; }
  .brandsite-header-right > li {
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 25px; }
    .brandsite-header-right > li > a {
      color: white; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
/*▼フッター拡張版の表示をオンするページの指定▼*/
#page_homepage .ec-footerRole,
#page_product_list .ec-footerRole,
#page_product_detail .ec-footerRole,
#page_help_about .ec-footerRole,
#page_help_tradelaw .ec-footerRole,
#page_help_privacy .ec-footerRole,
#page_brand .ec-footerRole,
#page_brand_about_us .ec-footerRole,
#page_brand_select_guide .ec-footerRole,
.blog.home.hfeed .ec-footerRole,
.archive.hfeed .ec-footerRole,
.search.hfeed .ec-footerRole,
.single-post .ec-footerRole {
  height: 100vh; }
  #page_homepage .ec-footerRole .ec-footerTitle,
  #page_product_list .ec-footerRole .ec-footerTitle,
  #page_product_detail .ec-footerRole .ec-footerTitle,
  #page_help_about .ec-footerRole .ec-footerTitle,
  #page_help_tradelaw .ec-footerRole .ec-footerTitle,
  #page_help_privacy .ec-footerRole .ec-footerTitle,
  #page_brand .ec-footerRole .ec-footerTitle,
  #page_brand_about_us .ec-footerRole .ec-footerTitle,
  #page_brand_select_guide .ec-footerRole .ec-footerTitle,
  .blog.home.hfeed .ec-footerRole .ec-footerTitle,
  .archive.hfeed .ec-footerRole .ec-footerTitle,
  .search.hfeed .ec-footerRole .ec-footerTitle,
  .single-post .ec-footerRole .ec-footerTitle {
    display: block; }
  #page_homepage .ec-footerRole .ec-footerNavi,
  #page_product_list .ec-footerRole .ec-footerNavi,
  #page_product_detail .ec-footerRole .ec-footerNavi,
  #page_help_about .ec-footerRole .ec-footerNavi,
  #page_help_tradelaw .ec-footerRole .ec-footerNavi,
  #page_help_privacy .ec-footerRole .ec-footerNavi,
  #page_brand .ec-footerRole .ec-footerNavi,
  #page_brand_about_us .ec-footerRole .ec-footerNavi,
  #page_brand_select_guide .ec-footerRole .ec-footerNavi,
  .blog.home.hfeed .ec-footerRole .ec-footerNavi,
  .archive.hfeed .ec-footerRole .ec-footerNavi,
  .search.hfeed .ec-footerRole .ec-footerNavi,
  .single-post .ec-footerRole .ec-footerNavi {
    display: block; }
  #page_homepage .ec-footerRole .ec-footerButton,
  #page_product_list .ec-footerRole .ec-footerButton,
  #page_product_detail .ec-footerRole .ec-footerButton,
  #page_help_about .ec-footerRole .ec-footerButton,
  #page_help_tradelaw .ec-footerRole .ec-footerButton,
  #page_help_privacy .ec-footerRole .ec-footerButton,
  #page_brand .ec-footerRole .ec-footerButton,
  #page_brand_about_us .ec-footerRole .ec-footerButton,
  #page_brand_select_guide .ec-footerRole .ec-footerButton,
  .blog.home.hfeed .ec-footerRole .ec-footerButton,
  .archive.hfeed .ec-footerRole .ec-footerButton,
  .search.hfeed .ec-footerRole .ec-footerButton,
  .single-post .ec-footerRole .ec-footerButton {
    display: flex; }
  #page_homepage .ec-footerRole .ec-footerTitle__copyright,
  #page_product_list .ec-footerRole .ec-footerTitle__copyright,
  #page_product_detail .ec-footerRole .ec-footerTitle__copyright,
  #page_help_about .ec-footerRole .ec-footerTitle__copyright,
  #page_help_tradelaw .ec-footerRole .ec-footerTitle__copyright,
  #page_help_privacy .ec-footerRole .ec-footerTitle__copyright,
  #page_brand .ec-footerRole .ec-footerTitle__copyright,
  #page_brand_about_us .ec-footerRole .ec-footerTitle__copyright,
  #page_brand_select_guide .ec-footerRole .ec-footerTitle__copyright,
  .blog.home.hfeed .ec-footerRole .ec-footerTitle__copyright,
  .archive.hfeed .ec-footerRole .ec-footerTitle__copyright,
  .search.hfeed .ec-footerRole .ec-footerTitle__copyright,
  .single-post .ec-footerRole .ec-footerTitle__copyright {
    position: absolute;
    height: auto; }
  @media screen and (max-width: 624px) {
    #page_homepage .ec-footerRole__inner,
    #page_product_list .ec-footerRole__inner,
    #page_product_detail .ec-footerRole__inner,
    #page_help_about .ec-footerRole__inner,
    #page_help_tradelaw .ec-footerRole__inner,
    #page_help_privacy .ec-footerRole__inner,
    #page_brand .ec-footerRole__inner,
    #page_brand_about_us .ec-footerRole__inner,
    #page_brand_select_guide .ec-footerRole__inner,
    .blog.home.hfeed .ec-footerRole__inner,
    .archive.hfeed .ec-footerRole__inner,
    .search.hfeed .ec-footerRole__inner,
    .single-post .ec-footerRole__inner {
      height: 100vh; } }

/*▲フッター拡張版の表示をオンするページの指定▲*/
.ec-footerRole {
  background: #eabdbd;
  height: auto; }
  @media screen and (min-width: 625px) {
    .ec-footerRole {
      padding: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; } }
  @media screen and (min-width: 625px) {
    .ec-footerRole .ec-footerRole__inner {
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.4;
      color: #333333;
      -webkit-text-size-adjust: 100%;
      width: 100%;
      padding: 0; }
      .ec-footerRole .ec-footerRole__inner:after {
        content: " ";
        display: table; }
      .ec-footerRole .ec-footerRole__inner:after {
        clear: both; }
      .ec-footerRole .ec-footerRole__inner textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif; }
      .ec-footerRole .ec-footerRole__inner img {
        max-width: 100%; }
      .ec-footerRole .ec-footerRole__inner html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
      .ec-footerRole .ec-footerRole__inner *,
      .ec-footerRole .ec-footerRole__inner *::before,
      .ec-footerRole .ec-footerRole__inner *::after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit; }
      .ec-footerRole .ec-footerRole__inner img {
        width: 100%; } }
  @media screen and (max-width: 624px) {
    .ec-footerRole .ec-footerRole__inner {
      position: relative; } }

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  display: none;
  /*noneにしておいてください*/
  padding: 0;
  color: white;
  list-style: none;
  text-align: center; }
  @media screen and (min-width: 625px) {
    .ec-footerNavi {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 60px;
      margin: 0; } }
  @media screen and (max-width: 624px) {
    .ec-footerNavi {
      display: none; } }
  .ec-footerNavi .ec-footerNavi__link {
    display: block; }
    @media screen and (min-width: 625px) {
      .ec-footerNavi .ec-footerNavi__link {
        display: inline-block; } }
    .ec-footerNavi .ec-footerNavi__link a {
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none; }
      @media screen and (min-width: 625px) {
        .ec-footerNavi .ec-footerNavi__link a {
          display: inline-block;
          border-bottom: none;
          margin: 0 10px;
          padding: 0; } }
    .ec-footerNavi .ec-footerNavi__link:hover a {
      opacity: 0.8;
      text-decoration: none; }

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  display: none;
  /*noneにしておいてください*/
  text-align: center;
  color: white; }
  @media screen and (min-width: 625px) {
    .ec-footerTitle {
      padding: 0; } }
  @media screen and (max-width: 624px) {
    .ec-footerTitle {
      padding: 30vh 0 0; } }
  @media screen and (min-width: 625px) {
    .ec-footerTitle .ec-footerTitle__logo {
      margin: 0 auto;
      width: 150px; } }
  @media screen and (max-width: 624px) {
    .ec-footerTitle .ec-footerTitle__logo {
      width: 100%;
      margin: 0 auto; } }
  @media screen and (min-width: 625px) {
    .ec-footerTitle .ec-footerTitle__logo img {
      width: 100%; } }
  @media screen and (max-width: 624px) {
    .ec-footerTitle .ec-footerTitle__logo img {
      width: 24vw; } }
  .ec-footerTitle .ec-footerTitle__title {
    display: block;
    margin-bottom: 10px; }
    .ec-footerTitle .ec-footerTitle__title a {
      color: inherit;
      text-decoration: none; }
    .ec-footerTitle .ec-footerTitle__title a:hover {
      text-decoration: none; }
    @media screen and (min-width: 625px) {
      .ec-footerTitle .ec-footerTitle__title {
        width: 400px;
        margin: 9px auto 0; } }
    @media screen and (max-width: 624px) {
      .ec-footerTitle .ec-footerTitle__title {
        width: 100%;
        margin: 2.5vw auto 0; } }
    .ec-footerTitle .ec-footerTitle__title a {
      font-size: 22px;
      color: inherit; }
      @media screen and (min-width: 625px) {
        .ec-footerTitle .ec-footerTitle__title a {
          font-size: 24px; } }
      @media screen and (max-width: 624px) {
        .ec-footerTitle .ec-footerTitle__title a {
          height: 100%;
          width: 100vw;
          display: block;
          margin: 0 auto; } }
      @media screen and (min-width: 625px) {
        .ec-footerTitle .ec-footerTitle__title a img {
          width: 100%; } }
      @media screen and (max-width: 624px) {
        .ec-footerTitle .ec-footerTitle__title a img {
          width: 64vw; } }
    .ec-footerTitle .ec-footerTitle__title:hover a {
      opacity: 0.8;
      text-decoration: none; }
  .ec-footerTitle__copyright {
    color: #fff;
    position: static;
    /*縮小表示時の指定*/
    height: 50px;
    /*縮小表示時の指定*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px; }
    @media only screen and (max-width: 767px) {
      .ec-footerTitle__copyright {
        bottom: 17.1px; } }
    @media screen and (min-width: 625px) {
      .ec-footerTitle__copyright {
        bottom: 25px;
        left: 0;
        right: 0;
        letter-spacing: 0.1em; } }
    @media screen and (max-width: 624px) {
      .ec-footerTitle__copyright {
        width: 100vw; } }

.ec-footerButton {
  display: none;
  /*noneにしておいてください*/
  justify-content: space-around; }
  @media screen and (min-width: 625px) {
    .ec-footerButton {
      margin: 0 auto;
      width: 523px;
      padding: 35px 0 0; } }
  @media screen and (max-width: 624px) {
    .ec-footerButton {
      margin: 0 auto;
      width: 92.815vw;
      padding: 5.60898vw 0 0; } }
  .ec-footerButton button {
    background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
    color: #fff;
    font-family: sans-serif;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000010;
    border: none;
    box-shadow: none; }
    .ec-footerButton button:hover {
      background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%);
      color: #fff; }
    @media screen and (min-width: 625px) {
      .ec-footerButton button {
        font-size: 18px;
        width: 234px;
        line-height: 1.7;
        border-radius: 50px; } }
    @media screen and (max-width: 624px) {
      .ec-footerButton button {
        font-size: 3.2vw;
        width: 40.5vw;
        line-height: 1.7;
        border-radius: 5vw; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@media only screen and (min-width: 768px) {
  .ec-top-img-container {
    background-color: #f8dddd;
    overflow: hidden; }
    .ec-top-img-container .ec-top-img-container__img1 {
      position: relative;
      margin: 0 auto;
      width: 100vw; }
      .ec-top-img-container .ec-top-img-container__img1__1 {
        width: 100vw;
        object-fit: cover;
        max-height: 800px; }
      .ec-top-img-container .ec-top-img-container__img1__2 {
        position: absolute;
        width: 17vw;
        top: 19.5vw;
        left: 41.5vw; } }
      @media only screen and (min-width: 768px) and (min-width: 1423px) {
        .ec-top-img-container .ec-top-img-container__img1__2 {
          width: 241.73px;
          top: 277.290px;
          left: 50%;
          margin-left: -120.865px; } }

@media only screen and (min-width: 768px) {
      .ec-top-img-container .ec-top-img-container__img1__3 {
        position: absolute;
        width: 32vw;
        bottom: 3vw;
        right: 4vw; } }
      @media only screen and (min-width: 768px) and (min-width: 1423px) {
        .ec-top-img-container .ec-top-img-container__img1__3 {
          width: 455.03px;
          bottom: 42.660px;
          right: 56.880px; } }

@media only screen and (min-width: 768px) {
      .ec-top-img-container .ec-top-img-container__img1__4 {
        position: absolute;
        width: 9vw;
        top: 2vw;
        right: 3vw; } }
      @media only screen and (min-width: 768px) and (min-width: 1423px) {
        .ec-top-img-container .ec-top-img-container__img1__4 {
          width: 127.969;
          top: 28.440;
          right: 42.660px; } }

@media only screen and (min-width: 768px) {
    .ec-top-img-container .ec-secHeading {
      background-color: #f8dddd;
      margin: 0;
      padding: 30px 20px 10px;
      max-width: 1366px;
      margin: 0 auto;
      padding: 30px 20px 10px; }
    .ec-top-img-container .ec-top-img-container__img2 {
      max-width: 1366px;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background-color: #f8dddd; }
      .ec-top-img-container .ec-top-img-container__img2 a {
        transition: all 0.25s ease 0s; }
        .ec-top-img-container .ec-top-img-container__img2 a:hover {
          opacity: 0.7; }
      .ec-top-img-container .ec-top-img-container__img2__1 {
        overflow: hidden;
        margin: 0 10px 10px 0;
        width: calc((100% / 3) - (20px /3));
        float: left; }
        .ec-top-img-container .ec-top-img-container__img2__1:nth-of-type(3) {
          margin: 0 0px 10px 0; }
        .ec-top-img-container .ec-top-img-container__img2__1 img {
          width: 100%; }
    .ec-top-img-container .ec-top-img-container__img3__1 {
      width: calc((100% / 4) - (30px /4));
      overflow: hidden;
      margin: 0 10px 10px 0;
      float: left; }
      .ec-top-img-container .ec-top-img-container__img3__1:nth-of-type(4n+7) {
        margin: 0 0px 10px 0; }
      .ec-top-img-container .ec-top-img-container__img3__1 img {
        width: 100%; } }

@media only screen and (max-width: 767px) {
  .ec-top-img-container {
    overflow: hidden;
    background: #f8dddd; }
    .ec-top-img-container .ec-top-img-container__img1 {
      position: relative;
      height: calc(100vh - 59px);
      background-color: #fff; }
      .ec-top-img-container .ec-top-img-container__img1__1 {
        height: 50vh;
        margin: 0 auto;
        display: block;
        width: 100vw;
        object-fit: cover;
        object-position: 30% 50%; }
      .ec-top-img-container .ec-top-img-container__img1__2 {
        width: 45vw;
        margin: -25.5vw auto;
        display: block; } }
      @media only screen and (max-width: 767px) and (min-width: 668px) {
        .ec-top-img-container .ec-top-img-container__img1__2 {
          width: 300px;
          margin: -170.340px auto; } }

@media only screen and (max-width: 767px) {
      .ec-top-img-container .ec-top-img-container__img1__3 {
        position: absolute;
        width: 100%;
        right: 0%;
        bottom: 0;
        margin: 30px 22px;
        max-width: 304px; }
      .ec-top-img-container .ec-top-img-container__img1__4 {
        position: absolute;
        width: 100%;
        right: 0%;
        top: 0px;
        margin: 12px 16px;
        max-width: 81px; }
    .ec-top-img-container .ec-secHeading {
      padding: 40px 10px 0; }
    .ec-top-img-container .ec-top-img-container__img2 {
      margin: 0 0 20vw 2vw; }
      .ec-top-img-container .ec-top-img-container__img2__1 {
        width: calc(100% / 2);
        overflow: hidden;
        float: left;
        padding: 0 2vw 2vw 0; }
        .ec-top-img-container .ec-top-img-container__img2__1 img {
          width: 100%;
          border-radius: 2.6664vw; }
    .ec-top-img-container .ec-top-img-container__img3__1 {
      width: calc(100% / 2);
      overflow: hidden;
      float: left;
      padding: 0 2vw 2vw 0; }
      .ec-top-img-container .ec-top-img-container__img3__1 img {
        width: 100%;
        border-radius: 2.6664vw; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin-bottom: 24px; }
  .ec-sliderRole:after {
    content: " ";
    display: table; }
  .ec-sliderRole:after {
    clear: both; }
  .ec-sliderRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-sliderRole img {
    max-width: 100%; }
  .ec-sliderRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-sliderRole *,
  .ec-sliderRole *::before,
  .ec-sliderRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-sliderRole img {
    width: 100%; }
  .ec-sliderRole ul {
    padding: 0;
    list-style: none; }

.ec-sliderItemRole {
  position: relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin-bottom: 24px; }
  .ec-sliderItemRole:after {
    content: " ";
    display: table; }
  .ec-sliderItemRole:after {
    clear: both; }
  .ec-sliderItemRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-sliderItemRole img {
    max-width: 100%; }
  .ec-sliderItemRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-sliderItemRole *,
  .ec-sliderItemRole *::before,
  .ec-sliderItemRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-sliderItemRole img {
    width: 100%; }
  .ec-sliderItemRole ul {
    padding: 0;
    list-style: none; }
  .ec-sliderItemRole .item_nav {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-sliderItemRole .item_nav {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0; } }
  .ec-sliderItemRole .item_visual {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 3px 6px #00000010; }
  .ec-sliderItemRole .item_visual__ec-icon {
    position: absolute;
    z-index: 1;
    width: 60px;
    transform: rotate(180deg); }
    @media only screen and (max-width: 767px) {
      .ec-sliderItemRole .item_visual__ec-icon {
        display: none; } }
  .ec-sliderItemRole .slideThumb {
    margin-bottom: 25px;
    width: 33%;
    opacity: 0.8;
    cursor: pointer; }
    .ec-sliderItemRole .slideThumb:focus {
      outline: none; }
    .ec-sliderItemRole .slideThumb:hover {
      opacity: 1; }
    .ec-sliderItemRole .slideThumb img {
      width: 80%;
      box-shadow: 0px 3px 6px #00000010; }
    @media only screen and (min-width: 768px) {
      .ec-sliderItemRole .slideThumb:first-child img {
        border: 3px solid #BAB3D6; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /* Deprecated https://github.com/EC-CUBE/ec-cube/pull/4220 */ }
  @media only screen and (min-width: 768px) {
    .ec-eyecatchRole {
      flex-wrap: nowrap; } }
  .ec-eyecatchRole .ec-eyecatchRole__image {
    display: block;
    margin-bottom: 40px;
    width: 100%;
    height: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__image {
        order: 2; } }
  .ec-eyecatchRole .ec-eyecatchRole__intro {
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__intro {
        padding-right: 5%;
        order: 1; } }
  .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
    margin-bottom: 0.8em;
    font-size: 16px;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
        margin-top: 45px; } }
  .ec-eyecatchRole .ec-eyecatchRole__introTitle {
    margin-bottom: 0.8em;
    font-size: 24px;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introTitle {
        margin-bottom: 1em;
        font-size: 26px; } }
  .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
        margin-bottom: 30px; } }
  .ec-eyecatchRole .ec-eyecatchRole__introDescription {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introDescription {
        margin-bottom: 30px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black; }
  .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--top:hover, .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--top:active, .ec-inlineBtn--top.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--top.disabled, .ec-inlineBtn--top[disabled],
  fieldset[disabled] .ec-inlineBtn--top {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-inlineBtn--top:hover {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-inlineBtn--top:active, .ec-inlineBtn--top.active,
  .open > .ec-inlineBtn--top.dropdown-toggle {
    color: white;
    background-color: black;
    background-image: none;
    border-color: black; }
    .ec-inlineBtn--top:active:hover, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:hover, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus,
    .open > .ec-inlineBtn--top.dropdown-toggle:hover,
    .open > .ec-inlineBtn--top.dropdown-toggle:focus,
    .open > .ec-inlineBtn--top.dropdown-toggle.focus {
      color: white;
      background-color: black;
      border-color: black; }
  .ec-inlineBtn--top.disabled:hover, .ec-inlineBtn--top.disabled:focus, .ec-inlineBtn--top.disabled.focus, .ec-inlineBtn--top[disabled]:hover, .ec-inlineBtn--top[disabled]:focus, .ec-inlineBtn--top[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--top:hover,
  fieldset[disabled] .ec-inlineBtn--top:focus,
  fieldset[disabled] .ec-inlineBtn--top.focus {
    background-color: black;
    border-color: black; }
  .ec-inlineBtn--top .badge {
    color: black;
    background-color: white; }
  .ec-inlineBtn--top .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black;
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--top:focus, .ec-blockBtn--top.focus, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--top:hover, .ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--top:active, .ec-blockBtn--top.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--top.disabled, .ec-blockBtn--top[disabled],
  fieldset[disabled] .ec-blockBtn--top {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-blockBtn--top:hover {
    color: white;
    background-color: black;
    border-color: black; }
  .ec-blockBtn--top:active, .ec-blockBtn--top.active,
  .open > .ec-blockBtn--top.dropdown-toggle {
    color: white;
    background-color: black;
    background-image: none;
    border-color: black; }
    .ec-blockBtn--top:active:hover, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:hover, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus,
    .open > .ec-blockBtn--top.dropdown-toggle:hover,
    .open > .ec-blockBtn--top.dropdown-toggle:focus,
    .open > .ec-blockBtn--top.dropdown-toggle.focus {
      color: white;
      background-color: black;
      border-color: black; }
  .ec-blockBtn--top.disabled:hover, .ec-blockBtn--top.disabled:focus, .ec-blockBtn--top.disabled.focus, .ec-blockBtn--top[disabled]:hover, .ec-blockBtn--top[disabled]:focus, .ec-blockBtn--top[disabled].focus,
  fieldset[disabled] .ec-blockBtn--top:hover,
  fieldset[disabled] .ec-blockBtn--top:focus,
  fieldset[disabled] .ec-blockBtn--top.focus {
    background-color: black;
    border-color: black; }
  .ec-blockBtn--top .badge {
    color: black;
    background-color: white; }
  .ec-blockBtn--top .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  @media only screen and (min-width: 768px) {
    .ec-blockBtn--top {
      max-width: 260px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/
/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  margin-bottom: 15px;
  color: #333333;
  font-family: 游ゴシック体; }
  @media only screen and (min-width: 768px) {
    .ec-secHeading {
      max-width: 1366px;
      margin: 0 auto 15px auto; } }
  .ec-secHeading .ec-secHeading__en {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2em; }
  .ec-secHeading .ec-secHeading__line {
    display: inline-block;
    margin: 0 20px;
    width: 1px;
    height: 14px;
    background: #333333; }
  .ec-secHeading .ec-secHeading__ja {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.15em;
    vertical-align: 2px; }

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/
.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center; }
  .ec-secHeading--tandem .ec-secHeading__en {
    display: block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2em; }
  .ec-secHeading--tandem .ec-secHeading__line {
    display: block;
    margin: 13px auto;
    width: 20px;
    height: 1px;
    background: black; }
  .ec-secHeading--tandem .ec-secHeading__ja {
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.15em;
    vertical-align: 2px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/
.ec-topicRole {
  padding: 40px 0;
  background: #f8f8f8; }
  @media only screen and (min-width: 768px) {
    .ec-topicRole {
      padding: 60px 0; } }
  .ec-topicRole .ec-topicRole__list {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-topicRole .ec-topicRole__list {
        flex-wrap: nowrap; } }
  .ec-topicRole .ec-topicRole__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 768px) {
      .ec-topicRole .ec-topicRole__listItem {
        width: calc(100% / 2); }
        .ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
          margin-right: 30px; } }
  .ec-topicRole .ec-topicRole__listItemTitle {
    margin-top: 0.5em;
    font-size: 14px;
    color: black; }
    @media only screen and (min-width: 768px) {
      .ec-topicRole .ec-topicRole__listItemTitle {
        margin-top: 1em; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/
.ec-role-newItemRole,
.ec-role-relatedItem {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  background: #f8dddd; }
  .ec-role-newItemRole:after,
  .ec-role-relatedItem:after {
    content: " ";
    display: table; }
  .ec-role-newItemRole:after,
  .ec-role-relatedItem:after {
    clear: both; }
  .ec-role-newItemRole textarea,
  .ec-role-relatedItem textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role-newItemRole img,
  .ec-role-relatedItem img {
    max-width: 100%; }
  .ec-role-newItemRole html,
  .ec-role-relatedItem html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role-newItemRole *,
  .ec-role-newItemRole *::before,
  .ec-role-newItemRole *::after,
  .ec-role-relatedItem *,
  .ec-role-relatedItem *::before,
  .ec-role-relatedItem *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-role-newItemRole img,
  .ec-role-relatedItem img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-role-newItemRole,
    .ec-role-relatedItem {
      padding: 0;
      margin: 30px 0 0 0; } }
  @media only screen and (min-width: 768px) and (min-width: 1466px) {
    .ec-role-newItemRole,
    .ec-role-relatedItem {
      padding: 0 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1465px) {
    .ec-role-newItemRole,
    .ec-role-relatedItem {
      padding: 0 0; } }
  @media only screen and (max-width: 767px) {
    .ec-role-newItemRole,
    .ec-role-relatedItem {
      padding: 0 0; } }

.ec-newItemRole {
  padding: 40px 0; }
  @media only screen and (min-width: 768px) {
    .ec-newItemRole {
      padding: 60px 0;
      background-color: #ffffff33; } }
  @media only screen and (min-width: 768px) {
    .ec-newItemRole .ec-secHeading {
      max-width: none;
      padding: 20px 0 0 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 1465px) {
    .ec-newItemRole .ec-secHeading {
      padding-left: 25px; } }
  @media only screen and (max-width: 767px) {
    .ec-newItemRole .ec-secHeading {
      padding-left: 20px; } }
  .ec-newItemRole .ec-newItemRole__list {
    /*winのスクロールバーを非表示にする対策用 - 追加CSS*/
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    /* Firefox 対応 */
    /*winのスクロールバーを非表示にする対策用 - 追加CSS*/ }
    .ec-newItemRole .ec-newItemRole__list::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari 対応 */ }
    @media only screen and (min-width: 768px) {
      .ec-newItemRole .ec-newItemRole__list {
        flex-wrap: nowrap;
        padding: 20px 0 0 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1465px) {
    .ec-newItemRole .ec-newItemRole__list {
      padding-left: 0px; } }
  .ec-newItemRole .ec-newItemRole__listItem {
    width: 48%;
    height: auto;
    flex-shrink: 0;
    position: relative;
    font-family: serif;
    font-weight: 300;
    margin: 0 15px 15px;
    /*
    &:nth-child(1) {
      @media screen and (min-width:768px){
        margin: 0 15px 0 0px;
      }
      @media screen and (max-width:767px){
        margin: 0 15px 0 40px;
      }
    }
    &:last-of-type {
      margin: 0 40px 0 15px;
      @media screen and (min-width:506px) {
      }
      @media screen and (max-width:505px) {
      }
    }
*/ }
    @media screen and (min-width: 429px) {
      .ec-newItemRole .ec-newItemRole__listItem {
        width: 300px; } }
    @media screen and (max-width: 428px) {
      .ec-newItemRole .ec-newItemRole__listItem {
        width: 70vw; } }
    .ec-newItemRole .ec-newItemRole__listItem__div {
      padding: 0;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;
      margin: 0 0 17px 0; }
    .ec-newItemRole .ec-newItemRole__listItem__div:before {
      content: "";
      display: block;
      padding-top: 100%; }
    .ec-newItemRole .ec-newItemRole__listItem img {
      border-radius: 10px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      object-fit: cover; }
      @media screen and (min-width: 429px) {
        .ec-newItemRole .ec-newItemRole__listItem img {
          width: 300px;
          height: 300px; } }
      @media screen and (max-width: 428px) {
        .ec-newItemRole .ec-newItemRole__listItem img {
          width: 70vw;
          height: 70vw; } }
    .ec-newItemRole .ec-newItemRole__listItem .ranking-icon {
      background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
      color: #fff;
      display: inline-block;
      position: absolute;
      margin: 3px;
      padding: 5px;
      border-radius: 30px;
      height: 30px;
      width: 30px;
      text-align: center; }
    .ec-newItemRole .ec-newItemRole__listItem a {
      color: #333333;
      text-decoration: none;
      display: block; }
    .ec-newItemRole .ec-newItemRole__listItem a:hover .ec-newItemRole__listItem__div {
      background-color: #ffffff; }
    .ec-newItemRole .ec-newItemRole__listItem a:hover .ec-newItemRole__listItem__div img {
      opacity: 0.5; }
    .ec-newItemRole .ec-newItemRole__listItem a:hover .ec-newItemRole__listItemCategory {
      text-decoration: underline !important; }
    .ec-newItemRole .ec-newItemRole__listItem a:hover .ec-newItemRole__listItemTitle {
      text-decoration: underline !important; }
    .ec-newItemRole .ec-newItemRole__listItem a:hover .ec-newItemRole__listItemPrice {
      text-decoration: underline !important; }
  .ec-newItemRole .ec-newItemRole__listItemHeading {
    margin-top: calc(45% - 20px); }
  @media only screen and (min-width: 768px) {
    .ec-newItemRole .ec-newItemRole__listItemCategory {
      margin: 3px 0 0 7px;
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    .ec-newItemRole .ec-newItemRole__listItemCategory {
      margin: 3px 0 0 7px;
      font-size: 14px; } }
  @media only screen and (min-width: 768px) {
    .ec-newItemRole .ec-newItemRole__listItemTitle {
      margin: 3px 0 0 5px;
      font-size: 18px; } }
  @media only screen and (max-width: 767px) {
    .ec-newItemRole .ec-newItemRole__listItemTitle {
      margin: 3px 0 0 5px;
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .ec-newItemRole .ec-newItemRole__listItemPrice {
      margin: 3px 0 0 5px;
      font-size: 18px; } }
  @media only screen and (max-width: 767px) {
    .ec-newItemRole .ec-newItemRole__listItemPrice {
      margin: 4px 0 0 5px;
      font-size: 18px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/
.ec-categoryRole {
  padding: 40px 0;
  color: black;
  background: #f8f8f8; }
  @media only screen and (min-width: 768px) {
    .ec-categoryRole {
      padding: 60px 0; } }
  .ec-categoryRole .ec-categoryRole__list {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-categoryRole .ec-categoryRole__list {
        flex-wrap: nowrap; } }
  .ec-categoryRole .ec-categoryRole__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 768px) {
      .ec-categoryRole .ec-categoryRole__listItem {
        width: calc(100% / 3); }
        .ec-categoryRole .ec-categoryRole__listItem:not(:last-of-type) {
          margin-right: 30px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/
.ec-role-newsRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  background-color: #fff2f9;
  padding: 0 20px 70px; }
  .ec-role-newsRole:after {
    content: " ";
    display: table; }
  .ec-role-newsRole:after {
    clear: both; }
  .ec-role-newsRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role-newsRole img {
    max-width: 100%; }
  .ec-role-newsRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role-newsRole *,
  .ec-role-newsRole *::before,
  .ec-role-newsRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-role-newsRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-role-newsRole {
      max-width: 1366px;
      margin: 60px auto  0 auto;
      padding-left: 100px;
      padding-right: 100px; } }
  @media only screen and (max-width: 767px) {
    .ec-role-newsRole {
      padding-bottom: 25px; } }

.ec-newsRole {
  padding: 40px 0 0; }
  @media only screen and (min-width: 768px) {
    .ec-newsRole {
      padding: 60px 0 0; } }
  .ec-newsRole .ec-newsRole__news {
    box-sizing: border-box;
    font-family: serif; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__news {
        border: 16px solid #d9e2ff;
        padding: 20px 30px;
        background-color: #fff; } }
  .ec-newsRole .ec-newsRole__newsItem {
    width: 100%; }
    .ec-newsRole .ec-newsRole__newsItem:not(:last-of-type) {
      border-bottom: 1px solid #ccc; }
    .ec-newsRole .ec-newsRole__newsItem:last-of-type {
      margin-bottom: 20px; }
      @media only screen and (min-width: 768px) {
        .ec-newsRole .ec-newsRole__newsItem:last-of-type {
          margin-bottom: 0; } }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsItem {
        padding: 20px 0; } }
  .ec-newsRole .ec-newsRole__newsHeading {
    cursor: pointer; }
    .ec-newsRole .ec-newsRole__newsHeading:hover {
      opacity: 0.5; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsHeading {
        display: flex; } }
  .ec-newsRole .ec-newsRole__newsDate {
    display: block;
    margin: 15px 0 5px;
    font-size: 12px;
    color: #333333;
    line-height: 1.8; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsDate {
        display: inline-block;
        margin: 0;
        min-width: 120px;
        font-size: 14px; } }
  .ec-newsRole .ec-newsRole__newsColumn {
    display: flex; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsColumn {
        display: inline-flex;
        min-width: calc(100% - 120px); } }
  .ec-newsRole .ec-newsRole__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: 90%;
    font-size: 14px;
    line-height: 1.6;
    color: #333333; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsTitle {
        margin-bottom: 0;
        line-height: 1.8; } }
  .ec-newsRole .ec-newsRole__newsClose {
    display: inline-block;
    width: 10%;
    position: relative; }
  .ec-newsRole .ec-newsRole__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 5px; }
  .ec-newsRole .ec-newsRole__newsDescription {
    display: none;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden; }
    @media only screen and (min-width: 768px) {
      .ec-newsRole .ec-newsRole__newsDescription {
        margin: 20px 0 0 120px;
        line-height: 1.8; } }
    .ec-newsRole .ec-newsRole__newsDescription a {
      color: #0092c4; }
  .ec-newsRole__newsItem.is_active .ec-newsRole__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px); }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0;
  font: normal normal normal 16px/27px serif; }
  @media only screen and (min-width: 768px) {
    .ec-searchnavRole {
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.4;
      color: #333333;
      -webkit-text-size-adjust: 100%;
      width: 100%; }
      .ec-searchnavRole:after {
        content: " ";
        display: table; }
      .ec-searchnavRole:after {
        clear: both; }
      .ec-searchnavRole textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif; }
      .ec-searchnavRole img {
        max-width: 100%; }
      .ec-searchnavRole html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
      .ec-searchnavRole *,
      .ec-searchnavRole *::before,
      .ec-searchnavRole *::after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit; }
      .ec-searchnavRole img {
        width: 100%; } }
  @media only screen and (max-width: 767px) {
    .ec-searchnavRole .ec-searchnavRole__topicpath {
      margin: 0 10px; }
      .ec-searchnavRole .ec-searchnavRole__topicpath .ec-topicpath {
        padding: 8px 5px 8px 5px;
        border-bottom: 1px dashed #F7ECEC; }
        .ec-searchnavRole .ec-searchnavRole__topicpath .ec-topicpath .ec-topicpath__item {
          font-size: 12px; } }
  .ec-searchnavRole .ec-searchnavRole__infos {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #333333;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    display: flex;
    border-top: 0;
    padding-top: 5px;
    flex-direction: column; }
    .ec-searchnavRole .ec-searchnavRole__infos:after {
      content: " ";
      display: table; }
    .ec-searchnavRole .ec-searchnavRole__infos:after {
      clear: both; }
    .ec-searchnavRole .ec-searchnavRole__infos textarea {
      /* for chrome fontsize bug */
      font-family: sans-serif; }
    .ec-searchnavRole .ec-searchnavRole__infos img {
      max-width: 100%; }
    .ec-searchnavRole .ec-searchnavRole__infos html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    .ec-searchnavRole .ec-searchnavRole__infos *,
    .ec-searchnavRole .ec-searchnavRole__infos *::before,
    .ec-searchnavRole .ec-searchnavRole__infos *::after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit; }
    .ec-searchnavRole .ec-searchnavRole__infos img {
      width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__infos {
        padding-left: 0;
        padding-right: 0;
        border-top: 1px dashed #F7ECEC;
        padding-top: 16px;
        flex-direction: row; } }
    @media only screen and (max-width: 767px) {
      .ec-searchnavRole .ec-searchnavRole__infos {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 10px; } }
  .ec-searchnavRole .ec-searchnavRole__counter {
    margin-bottom: 16px;
    width: 100%;
    font-size: 14px; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__counter {
        font-size: 16px;
        margin-bottom: 0;
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .ec-searchnavRole .ec-searchnavRole__counter {
        margin-bottom: 13px; } }
  .ec-searchnavRole .ec-searchnavRole__actions {
    text-align: right;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__actions {
        width: 50%; } }
    .ec-searchnavRole .ec-searchnavRole__actions .ec-select {
      margin-bottom: 35px; }
      .ec-searchnavRole .ec-searchnavRole__actions .ec-select select {
        font-size: 12px;
        padding: 8px 21px 8px 18px;
        height: auto;
        width: auto;
        cursor: pointer; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-shelfRole:after {
    content: " ";
    display: table; }
  .ec-shelfRole:after {
    clear: both; }
  .ec-shelfRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-shelfRole img {
    max-width: 100%; }
  .ec-shelfRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-shelfRole *,
  .ec-shelfRole *::before,
  .ec-shelfRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-shelfRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-shelfRole {
      padding: 0 15px; } }
  @media only screen and (max-width: 767px) {
    .ec-shelfRole {
      padding: 0 5px; } }

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .ec-shelfGrid a {
    color: inherit;
    text-decoration: none; }
  .ec-shelfGrid a:hover {
    text-decoration: none; }
  .ec-shelfGrid .ec-shelfGrid__item {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    font-family: serif;
    font-weight: 300; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item {
        width: calc((100vw - 30px) / 4);
        max-width: 334px;
        padding: 0 15px; } }
    @media only screen and (max-width: 767px) {
      .ec-shelfGrid .ec-shelfGrid__item {
        width: 50%;
        padding: 0 5px; } }
    .ec-shelfGrid .ec-shelfGrid__item a {
      color: #333333;
      text-decoration: none; }
    .ec-shelfGrid .ec-shelfGrid__item a:hover .ec-shelfGrid__item-image__div {
      background-color: #ffffff; }
    .ec-shelfGrid .ec-shelfGrid__item a:hover .ec-shelfGrid__item-image__div img {
      opacity: 0.5; }
    .ec-shelfGrid .ec-shelfGrid__item a:hover p {
      text-decoration: underline !important; }
    .ec-shelfGrid .ec-shelfGrid__item-image {
      margin-bottom: 0px;
      text-align: center; }
      .ec-shelfGrid .ec-shelfGrid__item-image__div {
        padding: 0;
        position: relative;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        margin: 0 0 10px; }
    .ec-shelfGrid .ec-shelfGrid__item img {
      box-shadow: 0px 3px 6px #00000010;
      border-radius: 10px;
      object-fit: cover;
      object-position: center center;
      box-shadow: none; }
      @media only screen and (min-width: 768px) {
        .ec-shelfGrid .ec-shelfGrid__item img {
          width: calc(((100vw - 30px) / 4) - 30px);
          height: calc(((100vw - 30px) / 4) - 30px);
          max-width: 304px;
          max-height: 304px; } }
      @media only screen and (max-width: 767px) {
        .ec-shelfGrid .ec-shelfGrid__item img {
          width: calc((100vw / 2) - 15px);
          height: calc((100vw / 2) - 15px); } }
    .ec-shelfGrid .ec-shelfGrid__item p {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 3px; }
      @media only screen and (min-width: 768px) {
        .ec-shelfGrid .ec-shelfGrid__item p {
          font-size: 17px; } }
      @media only screen and (max-width: 767px) {
        .ec-shelfGrid .ec-shelfGrid__item p {
          font-size: 14px; } }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item .ec-productRole__category-top p {
        font-size: 14px;
        margin-top: 15px; } }
    @media only screen and (max-width: 767px) {
      .ec-shelfGrid .ec-shelfGrid__item .ec-productRole__category-top p {
        font-size: 11px;
        margin-top: 10px;
        margin-bottom: 5px; } }
    .ec-shelfGrid .ec-shelfGrid__item .ec-productRole__btn {
      margin-top: auto;
      margin-bottom: 15px; }
  .ec-shelfGrid .ec-shelfGrid__title {
    margin-bottom: 7px; }
  .ec-shelfGrid .ec-shelfGrid__plice {
    font-weight: bold; }

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center; }
  .ec-shelfGridCenter a {
    color: inherit;
    text-decoration: none; }
  .ec-shelfGridCenter a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-shelfGridCenter {
      margin-left: -16px;
      margin-right: -16px; } }
  .ec-shelfGridCenter .ec-shelfGridCenter__item {
    margin-bottom: 36px;
    width: 50%; }
    .ec-shelfGridCenter .ec-shelfGridCenter__item-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center; }
      @media only screen and (min-width: 768px) {
        .ec-shelfGridCenter .ec-shelfGridCenter__item-image {
          height: 250px; } }
    .ec-shelfGridCenter .ec-shelfGridCenter__item img {
      width: auto;
      max-height: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGridCenter .ec-shelfGridCenter__item {
        padding: 0 16px;
        width: 25%; } }
    .ec-shelfGridCenter .ec-shelfGridCenter__item .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em; }
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
    padding-right: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
        padding: 0 16px; } }
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
    padding-left: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
        padding: 0 16px; } }
  .ec-shelfGridCenter .ec-shelfGridCenter__title {
    margin-bottom: 7px; }
  .ec-shelfGridCenter .ec-shelfGridCenter__plice {
    font-weight: bold; }

/*
商品一覧フッター

商品一覧 フッター に関する Project コンポーネントを定義します。

ex [商品一覧 ページャ部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.3.pager.pug
+ec-pagerRole

Styleguide 13.3

*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

ex [商品一覧、商品詳細](http://demo3.ec-cube.net/products/list)

+ec-modal

Styleguide 13.4

*/
.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%;
  font-family: sans-serif; }
  .ec-modal.small {
    width: 30%; }
  .ec-modal.full {
    width: 100%;
    height: 100%; }
  .ec-modal .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%; }
  .ec-modal .ec-modal-wrap {
    position: relative;
    border-radius: 10px;
    background-color: #F6F7F9;
    width: 90%;
    max-width: 700px;
    margin: 20px;
    padding: 44px 5px; }
    @media only screen and (min-width: 768px) {
      .ec-modal .ec-modal-wrap {
        padding: 40px 10px;
        width: 90%;
        margin: 20px auto; } }
    @media screen and (max-width: 509px) {
      .ec-modal .ec-modal-wrap {
        max-width: 335px; } }
  .ec-modal #ec-modal-header {
    padding: 0 0px;
    width: 80%;
    margin: 0 auto;
    text-align: center; }
  .ec-modal .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    height: 30px;
    width: 30px; }
    .ec-modal .ec-modal-close:hover {
      color: #4b5361;
      opacity: 0.6; }
  .ec-modal .ec-modal-box {
    text-align: center;
    padding: 0 0;
    width: 80%;
    margin: 25px auto 0; }
  .ec-modal .ec-role {
    padding: 0;
    display: flex;
    justify-content: space-between; }
    .ec-modal .ec-role:after {
      display: none; }
    @media screen and (max-width: 509px) {
      .ec-modal .ec-role {
        width: 100%;
        flex-direction: column; } }
    .ec-modal .ec-role .ec-inlineBtn--action,
    .ec-modal .ec-role .ec-inlineBtn--cancel {
      width: 48%;
      margin: 0 0%;
      padding: 21px 10px;
      line-height: 1;
      height: auto; }
      @media screen and (max-width: 509px) {
        .ec-modal .ec-role .ec-inlineBtn--action,
        .ec-modal .ec-role .ec-inlineBtn--cancel {
          width: 100%;
          margin: 0; } }
    @media screen and (max-width: 509px) {
      .ec-modal .ec-role .ec-inlineBtn--action {
        margin-bottom: 0px; } }
    .ec-modal .ec-role .ec-inlineBtn--cancel {
      background-color: #525263; }
      @media screen and (max-width: 509px) {
        .ec-modal .ec-role .ec-inlineBtn--cancel {
          margin-bottom: 20px; } }
      .ec-modal .ec-role .ec-inlineBtn--cancel:hover {
        background-color: #333333; }

.ec-modal_zoom-product {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%; }
  .ec-modal_zoom-product.small {
    width: 30%; }
  .ec-modal_zoom-product.full {
    width: 100%;
    height: 100%; }
  .ec-modal_zoom-product .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%; }
  .ec-modal_zoom-product .ec-modal-wrap {
    position: relative;
    border-radius: 2px;
    border: none;
    background-color: none;
    width: auto;
    margin: auto;
    border-radius: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-modal_zoom-product .ec-modal-wrap {
        max-width: 100%;
        max-height: 100%;
        max-width: 700px;
        margin: 20px auto;
        padding: 0; } }
  .ec-modal_zoom-product .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    height: 20px;
    width: 20px;
    z-index: 1310; }
    .ec-modal_zoom-product .ec-modal-close:hover {
      color: #4b5361;
      opacity: 0.6; }
  .ec-modal_zoom-product .ZoomImage {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
    object-fit: contain;
    border-radius: 10px; }
  .ec-modal_zoom-product .ec-modal-box {
    text-align: center; }
  .ec-modal_zoom-product .ec-role {
    margin-top: 20px; }
  .ec-modal_zoom-product .slick-track {
    width: 100% !important; }
  .ec-modal_zoom-product .slick-active {
    width: 100% !important; }
  .ec-modal_zoom-product .slick-slider {
    margin: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  font-family: serif;
  background-color: #eabdbd; }
  .ec-productRole:after {
    content: " ";
    display: table; }
  .ec-productRole:after {
    clear: both; }
  .ec-productRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-productRole img {
    max-width: 100%; }
  .ec-productRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-productRole *,
  .ec-productRole *::before,
  .ec-productRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-productRole img {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .ec-productRole .ec-grid2 {
      max-width: 365px;
      margin: 0 auto; } }
  @media only screen and (max-width: 767px) {
    .ec-productRole .ec-grid2__cell {
      position: unset; } }
  @media only screen and (max-width: 767px) {
    .ec-productRole .ec-grid2__cell:nth-child(1) {
      padding: 100px 0 0 0; } }
  .ec-productRole .ec-productRole__img {
    margin-right: 0;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__img {
        margin-right: 16px;
        margin-bottom: 0; } }
  .ec-productRole .ec-productRole__category-top {
    padding: 0 0 10px;
    font-size: 14px; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__category-top p {
        position: absolute;
        top: 110px;
        padding: 0 0 0 14px; } }
  .ec-productRole .ec-productRole__profile {
    margin-left: 0; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__profile {
        margin: 0 20px 0 20px; } }
  @media only screen and (max-width: 767px) {
    .ec-productRole .ec-productRole__title {
      padding: 0 14px; } }
  .ec-productRole .ec-productRole__title .ec-headingTitle {
    font-size: 20px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__title .ec-headingTitle {
        font-size: 32px; } }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__title .ec-headingTitle {
        position: absolute;
        top: 140px; } }
  .ec-productRole .ec-productRole__tags {
    margin-top: 16px;
    padding: 0 0 16px;
    border-bottom: 1px dotted #fff; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__tags {
        padding: 0 14px 16px; } }
    .ec-productRole .ec-productRole__tags span {
      font-size: 0.7em;
      margin: 0 60px 0 0; }
  .ec-productRole .ec-productRole__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 0.7em;
    color: #525263;
    border: solid 1px #d7dadd;
    border-radius: 3px;
    background-color: #f5f7f8;
    cursor: pointer; }
  .ec-productRole .ec-productRole__priceRegularPrice {
    padding: 0 0.8em 7px;
    opacity: 0.5;
    font-size: 0.8em;
    border-bottom: 1px dotted #fff; }
  .ec-productRole .ec-productRole__priceRegular {
    padding-top: 14px; }
  .ec-productRole .ec-productRole__priceRegularTax {
    margin-left: 5px;
    font-size: 10px; }
  .ec-productRole .ec-productRole__price {
    color: #de5d50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0;
    border-top: 1px dotted #fff;
    border-bottom: 1px dotted #fff; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__price {
        padding: 14px 0 7px; } }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__price {
        padding: 0 14px; } }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__price .ec-price__price {
        font-size: 24px;
        padding: 20px 0; } }
  .ec-productRole .ec-productRole__code {
    padding: 14px 0;
    font-size: 0.7em;
    border-bottom: 1px dotted #ccc;
    border-top: 1px dotted #ccc; }
  .ec-productRole .ec-productRole__caption {
    font-size: 0.8em;
    font-weight: 900;
    padding: 20px 50px 0 0;
    border-top: 1px dotted #fff; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__caption {
        border-top: white 1px dotted;
        margin: 20px 0 0 0;
        padding: 20px 14px; } }
  .ec-productRole .ec-productRole__category {
    padding: 14px 0;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__category {
        padding: 14px 14px; } }
    .ec-productRole .ec-productRole__category a {
      color: #000;
      border-bottom: 1px solid black; }
    .ec-productRole .ec-productRole__category ul {
      list-style: none;
      padding: 0 0 0 90px;
      margin: 0;
      font-size: 0.7em; }
    .ec-productRole .ec-productRole__category__span {
      position: absolute;
      font-size: 0.7em; }
  .ec-productRole .ec-productRole__actions {
    padding: 14px 0; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__actions {
        padding: 14px; } }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__actions .ec-select {
        display: flex; } }
    .ec-productRole .ec-productRole__actions .ec-select__caption {
      font-weight: 900;
      padding: 10px 50px 0 0; }
      @media only screen and (min-width: 768px) {
        .ec-productRole .ec-productRole__actions .ec-select__caption {
          font-size: 0.8em; } }
      @media only screen and (max-width: 767px) {
        .ec-productRole .ec-productRole__actions .ec-select__caption {
          font-size: 12px;
          padding: 10px 50px 10px 0;
          display: block; } }
    .ec-productRole .ec-productRole__actions .ec-select select {
      height: 40px;
      box-shadow: 0px 3px 6px #00000010; }
      @media only screen and (min-width: 768px) {
        .ec-productRole .ec-productRole__actions .ec-select select {
          width: 70%; } }
      @media only screen and (max-width: 767px) {
        .ec-productRole .ec-productRole__actions .ec-select select {
          width: 100%; } }
    .ec-productRole .ec-productRole__actions .ec-numberInput__caption {
      font-size: 0.8em;
      font-weight: 900;
      padding: 20px 65px 10px 0; }
      @media only screen and (max-width: 767px) {
        .ec-productRole .ec-productRole__actions .ec-numberInput__caption {
          font-size: 12px;
          padding: 10px 50px 10px 0;
          display: block; } }
    .ec-productRole .ec-productRole__actions .ec-numberInput input {
      box-shadow: 0px 3px 6px #00000010; }
  @media only screen and (min-width: 768px) {
    .ec-productRole .ec-productRole__btn {
      width: 60%;
      margin-bottom: 16px;
      min-width: 350px; } }
  @media only screen and (max-width: 767px) {
    .ec-productRole .ec-productRole__btn {
      width: 100%;
      padding: 0 10px 10px 10px; }
      .ec-productRole .ec-productRole__btn button {
        width: 100%; } }
  .ec-productRole .ec-productRole__description {
    margin-bottom: 16px;
    font-size: 0.7em; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__description {
        border-bottom: white dotted 1px;
        padding: 14px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
#form_cart.ec-cartRole {
  padding-right: 0;
  padding-left: 0; }

.ec-cartRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  /*アクションエリア*/
  /*合計金額*/
  /*数字部分*/
  /*レジに進むボタン*/
  /*お買い物を続けるボタン*/ }
  .ec-cartRole:after {
    content: " ";
    display: table; }
  .ec-cartRole:after {
    clear: both; }
  .ec-cartRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cartRole img {
    max-width: 100%; }
  .ec-cartRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cartRole *,
  .ec-cartRole *::before,
  .ec-cartRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-cartRole img {
    width: 100%; }
  .ec-cartRole::before {
    display: none; }
  .ec-cartRole .ec-cartRole__progress {
    width: 100%;
    text-align: center; }
  .ec-cartRole .ec-cartRole__error {
    width: 100%;
    text-align: center; }
    .ec-cartRole .ec-cartRole__error .ec-alert-warning {
      max-width: 80%;
      display: inline-block; }
  .ec-cartRole .ec-cartRole__totalText {
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__totalText {
        margin-bottom: 30px;
        padding: 0; } }
  .ec-cartRole .ec-cartRole__cart {
    margin: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__cart {
        margin: 0 10%; } }
  @media only screen and (min-width: 768px) {
    .ec-cartRole .only-mobile-view {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .ec-cartRole .only-mobile-view {
      width: 100%;
      font-weight: 900;
      margin: 10px 0; } }
  .ec-cartRole .ec-cartRole__cart2 {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__cart2 {
        width: 80%;
        margin: 0 10% 0 20%; } }
    @media only screen and (max-width: 767px) {
      .ec-cartRole .ec-cartRole__cart2 {
        width: 100%; }
        .ec-cartRole .ec-cartRole__cart2 .ec-cartRow {
          display: block;
          position: relative;
          border-bottom: 1px dotted #ccc; } }
  .ec-cartRole .ec-cartRole__actions {
    text-align: right;
    width: 100%;
    max-width: 305px;
    margin-top: 30px; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__actions {
        min-width: 250px;
        width: 25%;
        margin-right: 10%; } }
    .ec-cartRole .ec-cartRole__actions .ec-blockBtn--action {
      text-align: center; }
  .ec-cartRole .ec-cartRole__total {
    padding: 15px 0 30px;
    font-weight: bold;
    font-size: 16px; }
  .ec-cartRole .ec-cartRole__totalAmount {
    margin-left: 30px;
    color: #de5d50;
    font-size: 24px; }
  @media only screen and (max-width: 767px) {
    .ec-cartRole .ec-blockBtn--action {
      margin-bottom: 10px;
      height: 56px;
      line-height: 56px; } }
  @media only screen and (min-width: 768px) {
    .ec-cartRole .ec-blockBtn--action {
      margin-bottom: 10px;
      height: 56px;
      line-height: 56px; } }
  @media only screen and (max-width: 767px) {
    .ec-cartRole .ec-blockBtn--cancel {
      margin-bottom: 10px;
      height: 56px;
      line-height: 56px; } }
  @media only screen and (min-width: 768px) {
    .ec-cartRole .ec-blockBtn--cancel {
      margin-bottom: 10px;
      height: 56px;
      line-height: 56px; } }

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-cartTable {
      margin-top: 50px; }
      .ec-cartTable:nth-child(1) {
        border-top: none; } }
  @media only screen and (max-width: 767px) {
    .ec-cartTable {
      margin-top: 30px; } }

/*オーダーメイド仕様*/
.ordermade_list {
  /*全体のスタイル*/
  /*外枠レイアウト*/
  /*見出し*/
  /*オーダーメイド仕様では下記の見出しを非表示にします。*/
  /*行ブロック .row-block*/
  /*仕様テーブル内*/
  /*セル*/
  /*部位名*/
  /*サマリー*/
  /*小計*/
  /*選び直すボタン*/ }
  .ordermade_list * {
    font-weight: normal !important; }
  @media only screen and (max-width: 767px) {
    .ordermade_list {
      margin-left: 14%;
      margin-top: 10px; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list {
      width: 88%;
      margin-left: 12%;
      margin-top: 10px; } }
  @media only screen and (max-width: 767px) {
    .ordermade_list .heading {
      background: #f4f3f0;
      font-size: 14px;
      line-height: 1;
      padding: 15px 6px 10px; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .heading {
      background: #f4f3f0;
      font-size: 14px;
      line-height: 1;
      padding: 14px 12px; } }
  .ordermade_list .heading .sp {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ordermade_list .heading .sp {
        display: none; } }
  .ordermade_list .ec-cartHeader {
    display: none; }
  @media only screen and (max-width: 767px) {
    .ordermade_list .ec-cartRow {
      display: block;
      padding-left: 6px !important;
      padding-bottom: 10px;
      border-bottom: 1px #cccccc dotted;
      margin-bottom: 0; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .ec-cartRow__contentColumn2,
    .ordermade_list .ec-cartRow__amountColumn2 {
      padding: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .ordermade_list .kind_name_cell {
      padding: 10px 0 3px;
      display: inline-block;
      width: calc(100% - 78px); } }
  @media only screen and (max-width: 767px) {
    .ordermade_list .kind_name_cell p {
      text-align: left !important;
      font-size: 14px;
      font-weight: bold !important;
      padding: 0; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .kind_name_cell p {
      text-align: left !important;
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    .ordermade_list .summary_cell {
      font-size: 14px;
      display: inline-block;
      width: calc(100% - 78px); } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .summary_cell {
      font-size: 12px; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .summary_cell .ec-cartRow__summary2 div {
      padding: 2px 0px; } }
  .ordermade_list .ec-cartRow__sutbtotalSP {
    display: none; }
  @media only screen and (max-width: 767px) {
    .ordermade_list .edit_parts_cell {
      font-size: 14px;
      text-align: right;
      padding-right: 0px !important;
      display: inline-block;
      width: 73px;
      padding: 0 !important; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .edit_parts_cell {
      font-size: 14px;
      text-align: right;
      padding-right: 10px !important; } }
  @media only screen and (max-width: 767px) {
    .ordermade_list .edit_parts_cell .edit_parts_btn {
      text-align: center;
      line-height: 40px;
      height: 40px;
      color: #525263; } }
  @media only screen and (min-width: 768px) {
    .ordermade_list .edit_parts_cell .edit_parts_btn {
      text-align: center;
      line-height: 28px;
      color: #525263; } }
  .ordermade_list .edit_parts_cell .edit_parts_btn:hover {
    opacity: 0.4;
    text-decoration: none; }

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #f4f3f0; }
  @media only screen and (min-width: 768px) {
    .ec-cartHeader {
      display: table-row; } }
  .ec-cartHeader .ec-cartHeader__label {
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #f4f3f0;
    overflow-x: hidden;
    font-weight: bold; }

.ec-cartCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  padding: 0 0 100px; }
  .ec-cartCompleteRole:after {
    content: " ";
    display: table; }
  .ec-cartCompleteRole:after {
    clear: both; }
  .ec-cartCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cartCompleteRole img {
    max-width: 100%; }
  .ec-cartCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cartCompleteRole *,
  .ec-cartCompleteRole *::before,
  .ec-cartCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-cartCompleteRole img {
    width: 100%; }
  .ec-cartCompleteRole .ec-off4Grid__cell {
    width: 200px;
    margin: 0 auto !important; }

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartRow {
  display: table-row; }
  .ec-cartRow .ec-cartRow__delColumn {
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__delColumn {
        width: 12%; } }
    .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
      width: 1.5em;
      height: 1.5em; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
          width: 1em;
          height: 1em; } }
  .ec-cartRow .ec-cartRow__contentColumn {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__contentColumn {
        display: table-cell; } }
  .ec-cartRow .ec-cartRow__contentColumn2 {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__contentColumn2 {
        width: 15%;
        display: table-cell; }
        .ec-cartRow .ec-cartRow__contentColumn2 button {
          background-color: #fff;
          border: solid #b8bec4 2.5px;
          padding: 4.5px 8px; } }
    @media only screen and (max-width: 767px) {
      .ec-cartRow .ec-cartRow__contentColumn2 button {
        background-color: #f5f7f8;
        border: solid #b8bec4 1px;
        padding: 10px; } }
    .ec-cartRow .ec-cartRow__contentColumn2 p {
      font-weight: 900;
      text-align: center;
      font-family: sans-serif; }
  @media only screen and (max-width: 767px) {
    .ec-cartRow .ec-cartRow__contentColumn2 {
      border: none; }
    .ec-cartRow .ec-cartRow__contentColumn2:nth-of-type(1) {
      display: none; }
    .ec-cartRow .ec-cartRow__contentColumn2:nth-of-type(4) {
      padding: 10px; } }
  .ec-cartRow .ec-cartRow__img {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-right: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__img {
        display: inline-block;
        min-width: 80px;
        max-width: 100px;
        padding-right: 0; } }
  .ec-cartRow .ec-cartRow__summary {
    display: table-cell;
    margin-left: 5px;
    font-weight: 500;
    vertical-align: middle;
    width: 46%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__summary {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle; } }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
      margin-bottom: 5px; }
      .ec-cartRow .ec-cartRow__summary .ec-cartRow__name a {
        font-weight: 600; }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
          display: none; } }
  .ec-cartRow .ec-cartRow__summary2 {
    display: table-cell;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: middle;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__summary2 {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle; } }
  .ec-cartRow .ec-cartRow__amountColumn {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__amountColumn {
        width: 16.66666667%; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
          display: block; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
          display: none; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
          display: block; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountUpButton__icon img {
        display: block;
        margin-left: -0.4em;
        width: 0.8em;
        height: 0.8em;
        position: absolute;
        top: 28%;
        left: 50%; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon img, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon img {
        display: block;
        margin-left: -0.4em;
        width: 0.8em;
        height: 0.8em;
        position: absolute;
        top: 28%;
        left: 50%; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
      cursor: default; }
  .ec-cartRow .ec-cartRow__amountColumn2 {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__amountColumn2 {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .ec-cartRow .ec-cartRow__amountColumn2 {
        border: none; } }
  .ec-cartRow .ec-cartRow__subtotalColumn {
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__subtotalColumn {
        display: table-cell; } }

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default; }

.edit_parts_btn {
  display: inline-block;
  width: 73px;
  height: 28px;
  font-size: 12px;
  background: #F5F7F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  opacity: 1; }

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #f99;
  margin: 50px 0 100px;
  padding: 30px 10px;
  border-radius: 9px; }
  .ec-alert-warning .ec-alert-warning__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top; }
  .ec-alert-warning .ec-alert-warning__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative; }

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0; }
  .ec-orderRole:after {
    content: " ";
    display: table; }
  .ec-orderRole:after {
    clear: both; }
  .ec-orderRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-orderRole img {
    max-width: 100%; }
  .ec-orderRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-orderRole *,
  .ec-orderRole *::before,
  .ec-orderRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-orderRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-orderRole {
      margin-top: 20px;
      flex-direction: row; } }
  .ec-orderRole .ec-inlineBtn {
    font-weight: normal; }
  .ec-orderRole .ec-orderRole__detail {
    padding: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-orderRole__detail {
        padding: 0 16px;
        width: 66.66666%; } }
    .ec-orderRole .ec-orderRole__detail .ec-inlineBtn {
      border-radius: 20px;
      box-shadow: 0px 3px 6px #00000010;
      border: none; }
    .ec-orderRole .ec-orderRole__detail .ec-inlineBtn {
      border-radius: 20px;
      box-shadow: 0px 3px 6px #00000010;
      border: none; }
  .ec-orderRole .ec-orderRole__summary {
    width: 100%; }
    .ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
      display: inline-block; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-orderRole__summary {
        width: 33.33333%;
        padding: 0 16px; }
        .ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
          display: none; } }
  .ec-orderRole .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-borderedList {
        border-top: none; } }

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px; }
  .ec-orderOrder .ec-orderOrder__items {
    border-bottom: 1px dotted #ccc;
    border-top: 1px dotted #ccc; }

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 60px; }
  .ec-orderAccount p {
    margin-bottom: 0; }
  .ec-orderAccount:after {
    content: " ";
    display: table; }
  .ec-orderAccount:after {
    clear: both; }
  .ec-orderAccount .ec-orderAccount__change {
    display: inline-block;
    margin-left: 10px;
    float: right; }
  .ec-orderAccount .ec-orderAccount__account {
    margin-bottom: 16px;
    padding: 0 0 0 13px; }

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery {
  margin-bottom: 60px; }
  .ec-orderDelivery .ec-orderDelivery__title {
    padding: 10px 0 10px 13px;
    font-weight: bold;
    font-size: 18px;
    position: relative; }
  .ec-orderDelivery .ec-orderDelivery__change {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0; }
  .ec-orderDelivery .ec-orderDelivery__items {
    border-bottom: 1px dotted #ccc;
    border-top: 1px dotted #ccc; }
  .ec-orderDelivery .ec-orderDelivery__address {
    margin: 10px 0 18px;
    padding: 0 0 0 13px; }
    .ec-orderDelivery .ec-orderDelivery__address p {
      margin: 0; }
  .ec-orderDelivery .ec-orderDelivery__actions {
    padding: 0 0 0 13px; }
  .ec-orderDelivery .ec-orderDelivery__edit {
    padding: 0 0 0 13px; }

.ec-afterservice {
  margin-bottom: 60px; }
  .ec-afterservice .ec-rectHeading h2 img {
    width: 34px;
    margin: 0 10px 3px 0px; }
  .ec-afterservice .ec-rectHeading-attention {
    font-size: 12px;
    font-weight: 300;
    padding: 0 0 0 13px; }
  .ec-afterservice .ec-afterservice__description {
    font-size: 12px;
    font-weight: 300;
    padding: 10px 0 0 13px; }
  .ec-afterservice .ec-afterservice__title {
    padding: 20px 0 0 13px;
    font-weight: bold;
    font-size: 18px;
    position: relative; }
  .ec-afterservice .ec-afterservice__change {
    padding-top: 10px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0; }
  .ec-afterservice .ec-afterservice__noselect {
    padding: 0 0 30px 13px;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    color: red; }
  .ec-afterservice .store_select_btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 16px;
    text-decoration: none;
    color: #fff;
    background-color: #884595;
    border-color: #884595;
    display: block;
    width: 100%;
    height: 56px;
    line-height: 56px;
    padding-top: 0;
    padding-bottom: 0;
    width: 180px;
    border-radius: 30px;
    background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
    color: #fff;
    height: 45px;
    line-height: 45px;
    box-shadow: 0px 3px 6px #00000010;
    border: none;
    transition: all 0.25s ease 0s;
    margin: 0 0 0 13px; }
    .ec-afterservice .store_select_btn:focus, .ec-afterservice .store_select_btn.focus, .ec-afterservice .store_select_btn:active:focus, .ec-afterservice .store_select_btn:active.focus, .ec-afterservice .store_select_btn.active:focus, .ec-afterservice .store_select_btn.active.focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .ec-afterservice .store_select_btn:hover, .ec-afterservice .store_select_btn:focus, .ec-afterservice .store_select_btn.focus {
      color: #525263;
      text-decoration: none; }
    .ec-afterservice .store_select_btn:active, .ec-afterservice .store_select_btn.active {
      outline: 0;
      background-image: none;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .ec-afterservice .store_select_btn.disabled, .ec-afterservice .store_select_btn[disabled],
    fieldset[disabled] .ec-afterservice .store_select_btn {
      cursor: not-allowed;
      filter: alpha(opacity=65);
      opacity: 0.65;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .ec-afterservice .store_select_btn:focus, .ec-afterservice .store_select_btn.focus {
      color: #fff;
      background-color: #683572;
      border-color: #381d3e; }
    .ec-afterservice .store_select_btn:hover {
      color: #fff;
      background-color: #683572;
      border-color: #62326b; }
    .ec-afterservice .store_select_btn:active, .ec-afterservice .store_select_btn.active,
    .open > .ec-afterservice .store_select_btn.dropdown-toggle {
      color: #fff;
      background-color: #683572;
      background-image: none;
      border-color: #62326b; }
      .ec-afterservice .store_select_btn:active:hover, .ec-afterservice .store_select_btn:active:focus, .ec-afterservice .store_select_btn:active.focus, .ec-afterservice .store_select_btn.active:hover, .ec-afterservice .store_select_btn.active:focus, .ec-afterservice .store_select_btn.active.focus,
      .open > .ec-afterservice .store_select_btn.dropdown-toggle:hover,
      .open > .ec-afterservice .store_select_btn.dropdown-toggle:focus,
      .open > .ec-afterservice .store_select_btn.dropdown-toggle.focus {
        color: #fff;
        background-color: #522a5a;
        border-color: #381d3e; }
    .ec-afterservice .store_select_btn.disabled:hover, .ec-afterservice .store_select_btn.disabled:focus, .ec-afterservice .store_select_btn.disabled.focus, .ec-afterservice .store_select_btn[disabled]:hover, .ec-afterservice .store_select_btn[disabled]:focus, .ec-afterservice .store_select_btn[disabled].focus,
    fieldset[disabled] .ec-afterservice .store_select_btn:hover,
    fieldset[disabled] .ec-afterservice .store_select_btn:focus,
    fieldset[disabled] .ec-afterservice .store_select_btn.focus {
      background-color: #884595;
      border-color: #884595; }
    .ec-afterservice .store_select_btn .badge {
      color: #884595;
      background-color: #fff; }
    .ec-afterservice .store_select_btn .ec-icon img {
      width: 1em;
      vertical-align: text-bottom; }
    @media only screen and (max-width: 767px) {
      .ec-afterservice .store_select_btn {
        margin: 0 auto; } }
    .ec-afterservice .store_select_btn:hover {
      background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%); }
  .ec-afterservice .ec-afterservice__btn_modal {
    display: block;
    border: solid #b8bec4 1px;
    padding: 10px; }
  .ec-afterservice .non_store_select_label {
    padding: 30px 0 0 13px; }
    .ec-afterservice .non_store_select_label input {
      cursor: pointer; }

.ec-afterservice__ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%; }
  .ec-afterservice__ec-modal .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%; }
    .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap {
      width: 680px;
      height: 75%;
      position: fixed;
      border-radius: 5px;
      border: none;
      background-color: #fff;
      display: flex; }
      @media only screen and (max-width: 767px) {
        .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap {
          width: 100vw;
          bottom: 0;
          height: 80%; } }
      .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap .ec-modal-wrap-div {
        height: 100%;
        width: 100%;
        flex-shrink: 0; }
        .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap .ec-modal-wrap-div .ec-modal-wrap__header {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%; }
        .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap .ec-modal-wrap-div .ec-modal-wrap__body {
          width: 100%;
          padding: 20px 45px;
          overflow-y: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          /* Firefox 対応 */ }
          @media only screen and (max-width: 767px) {
            .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap .ec-modal-wrap-div .ec-modal-wrap__body {
              padding: 20px 15px; } }
        .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap .ec-modal-wrap-div .ec-modal-wrap__body::-webkit-scrollbar {
          display: none; }
        .ec-afterservice__ec-modal .ec-modal-overlay .ec-modal-wrap .ec-modal-wrap-div .ec-modal-wrap__footer {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%; }

.select_support_store_div .select_store_header {
  height: 110px;
  position: relative; }
  .select_support_store_div .select_store_header .select_store_header_inner {
    margin: 0 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    webkit-transform: translateY(-50%); }
    .select_support_store_div .select_store_header .select_store_header_inner .select_store_title {
      font-size: 18px;
      font-weight: bold; }
    .select_support_store_div .select_store_header .select_store_header_inner .select_store_descrption {
      font-size: 13px; }

.select_support_store_div .select_store_body {
  height: calc(100% - 190px); }
  .select_support_store_div .select_store_body .accshow {
    transition: 0.8s;
    display: flex;
    flex-wrap: wrap; }
  .select_support_store_div .select_store_body .accbox {
    /*ラベル*/
    /*アイコンを表示*/
    /*チェックは隠す*/
    /*中身を非表示にしておく*/
    /*クリックで中身表示*/
    /*アイコンを入れ替える*/ }
    .select_support_store_div .select_store_body .accbox .area_name {
      display: block;
      margin: 5px 0;
      padding: 10px 12px;
      background: #333333;
      cursor: pointer;
      transition: all 0.5s;
      color: white;
      border-radius: 4px; }
      .select_support_store_div .select_store_body .accbox .area_name:hover {
        background: #555555;
        color: #dddddd; }
    .select_support_store_div .select_store_body .accbox .area_name::before {
      padding-right: 5px;
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      font-weight: 900; }
    .select_support_store_div .select_store_body .accbox input {
      display: none; }
    .select_support_store_div .select_store_body .accbox .accshow {
      height: 0;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      transition: 0.8s;
      display: flex;
      flex-wrap: wrap; }
    .select_support_store_div .select_store_body .accbox .cssacc:checked + label + .accshow {
      height: auto;
      padding: 5px;
      opacity: 1; }
      @media only screen and (max-width: 767px) {
        .select_support_store_div .select_store_body .accbox .cssacc:checked + label + .accshow {
          padding: 0px; } }
    .select_support_store_div .select_store_body .accbox .cssacc:checked + .area_name::before {
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      font-weight: 900; }
    .select_support_store_div .select_store_body .accbox .pref_bar {
      width: 100%;
      margin: 10px; }
      .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_box {
        float: left;
        display: block;
        width: 50px;
        height: 30px;
        text-align: center; }
        .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_box .pref_checkbox {
          display: none; }
        .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_box .pref_name {
          margin: auto;
          font-size: 11px;
          line-height: 30px;
          color: #333333;
          cursor: pointer;
          padding: 0px; }
          .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_box .pref_name:hover {
            text-decoration: underline; }
      .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_checked {
        background-color: #333333;
        border-radius: 24px;
        text-align: center; }
        .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_checked .pref_name {
          color: #ffffff; }
          .select_support_store_div .select_store_body .accbox .pref_bar .pref_name_checked .pref_name:hover {
            text-decoration: none; }
    .select_support_store_div .select_store_body .accbox .pref_container {
      width: 100%; }
      .select_support_store_div .select_store_body .accbox .pref_container .pref_info {
        width: 100%;
        display: none;
        flex-wrap: wrap; }

.select_support_store_div .select_store_footer {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .select_support_store_div .select_store_footer button {
    width: 120px;
    color: #fff;
    padding: 9px;
    border: none;
    margin-right: 20px; }
  .select_support_store_div .select_store_footer .ec-modal_cancel {
    background-color: #525263;
    border-radius: 20px; }
    .select_support_store_div .select_store_footer .ec-modal_cancel:hover {
      background-color: #333333; }
  .select_support_store_div .select_store_footer .ec-modal_next {
    background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
    border-radius: 20px; }
    .select_support_store_div .select_store_footer .ec-modal_next:hover {
      background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%); }

.store_info_container {
  width: 165px;
  min-height: 170px;
  margin: 10px;
  background-color: white;
  flex-shrink: 0;
  float: left;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  overflow: hidden; }
  .store_info_container:hover {
    opacity: 0.7;
    cursor: pointer; }
  @media screen and (max-width: 584px) {
    .store_info_container {
      width: calc(50% - 8px);
      margin: 7.5px; }
      .store_info_container:nth-child(odd) {
        margin-left: 0px; }
      .store_info_container:nth-child(even) {
        margin-right: 0px; } }
  .store_info_container .store_image {
    height: 110px;
    width: 165px;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 584px) {
      .store_info_container .store_image {
        width: 100%; } }
    @media screen and (max-width: 584px) {
      .store_info_container .store_image {
        width: 100%; } }
    .store_info_container .store_image img {
      width: 165px;
      height: 110px;
      object-fit: cover; }
      @media screen and (max-width: 584px) {
        .store_info_container .store_image img {
          width: 100%; } }
      @media screen and (max-width: 584px) {
        .store_info_container .store_image img {
          width: 100%; } }
  .store_info_container .store_info {
    height: auto;
    padding: 15px 10px 12px 10px; }
    .store_info_container .store_info .store_name {
      font-weight: bold;
      font-size: 12px;
      line-height: 1.4; }
    .store_info_container .store_info .store_address {
      font-size: 10px;
      line-height: 1.3;
      margin-top: 5px; }
    .store_info_container .store_info .store_url {
      text-align: center;
      font-size: 10px;
      line-height: 1.3;
      margin-top: 10px; }
  .store_info_container .store_cheked {
    width: 54px;
    height: 18px;
    position: absolute;
    top: 98px;
    left: 5px;
    display: none;
    z-index: 2000; }

#selected_support_store {
  width: 100%; }
  #selected_support_store .store_cheked {
    display: none; }
  #selected_support_store .store_image {
    width: 110px;
    height: 110px;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    float: left;
    margin-right: 20px; }
  #selected_support_store .store_info {
    height: 110px;
    padding: 6px; }
    #selected_support_store .store_info .store_name {
      font-weight: bold;
      font-size: 12px; }
    #selected_support_store .store_info .store_address {
      font-size: 0.5vw; }
    #selected_support_store .store_info .store_url {
      font-size: 10px; }

.attention_div .attention_header {
  height: 80px;
  position: relative; }
  .attention_div .attention_header .attention_header_inner {
    margin: 0 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    webkit-transform: translateY(-50%); }
    .attention_div .attention_header .attention_header_inner .attention_title {
      font-size: 18px;
      font-weight: bold; }

.attention_div .attention_body {
  height: calc(100% - 260px); }
  .attention_div .attention_body .attention_body_inner .description_section {
    font-weight: bold;
    font-size: 16px;
    line-height: 4; }
  .attention_div .attention_body .attention_body_inner .description_sentence {
    font-size: 13px; }
  .attention_div .attention_body .attention_body_inner ul {
    margin: 15px 0px; }
  .attention_div .attention_body .attention_body_inner li {
    font-size: 13px;
    list-style: none; }

.attention_div .attention_footer {
  width: 100%;
  height: 180px; }
  .attention_div .attention_footer .attention_footer_agree {
    width: 100%;
    height: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #f8f8f8;
    padding: 10px auto; }
    .attention_div .attention_footer .attention_footer_agree span {
      font-size: 12px;
      font-weight: bold;
      display: block;
      padding: 15px 0 10px; }
    .attention_div .attention_footer .attention_footer_agree .temp_div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .attention_div .attention_footer .attention_footer_agree .temp_div label {
        padding: 8px 15px 8px 30px;
        border-radius: 5px;
        border: solid #e8e8e8 1px;
        cursor: pointer; }
      .attention_div .attention_footer .attention_footer_agree .temp_div input {
        display: none; }
      .attention_div .attention_footer .attention_footer_agree .temp_div .disagree::before,
      .attention_div .attention_footer .attention_footer_agree .temp_div .agree::before {
        content: url(/html/template/default/assets/img/ec-site/img-non-checked.svg);
        margin: 0 5px 0 -15px; }
      .attention_div .attention_footer .attention_footer_agree .temp_div input:checked + .disagree::before,
      .attention_div .attention_footer .attention_footer_agree .temp_div input:checked + .agree::before {
        content: url(/html/template/default/assets/img/ec-site/img-checked.svg);
        margin: 0 5px 0 -15px; }
      .attention_div .attention_footer .attention_footer_agree .temp_div .disagree {
        color: #d54c4c;
        background-color: #fff;
        margin: 0 20px; }
      .attention_div .attention_footer .attention_footer_agree .temp_div #disagree {
        position: absolute;
        top: 9px;
        left: 215px;
        z-index: 1; }
      .attention_div .attention_footer .attention_footer_agree .temp_div .agree {
        color: #007cff;
        background-color: #fff;
        margin: 0 20px; }
      .attention_div .attention_footer .attention_footer_agree .temp_div #agree {
        position: absolute;
        top: 9px;
        left: 377px;
        z-index: 1; }
      .attention_div .attention_footer .attention_footer_agree .temp_div #agree:checked ~ .agree {
        color: white;
        background-color: #007cff; }
      .attention_div .attention_footer .attention_footer_agree .temp_div #disagree:checked ~ .disagree {
        color: white;
        background-color: #d54c4c; }
  .attention_div .attention_footer .attention_footer_complete {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .attention_div .attention_footer .attention_footer_complete .ec-modal_prev {
      background-color: #525263;
      border-radius: 20px;
      width: 120px;
      color: #fff;
      padding: 9px;
      border: none;
      margin-right: 20px; }
      .attention_div .attention_footer .attention_footer_complete .ec-modal_prev:hover {
        background-color: #333333; }
    .attention_div .attention_footer .attention_footer_complete .ec-modal_next {
      background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
      border-radius: 20px;
      width: 120px;
      color: #fff;
      padding: 9px;
      border: none;
      margin-right: 20px; }
      .attention_div .attention_footer .attention_footer_complete .ec-modal_next:hover {
        background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%); }

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment {
  margin-bottom: 60px; }
  .ec-orderPayment .ec-radio {
    padding: 0 0 0 15px; }
  .ec-orderPayment .ec-input, .ec-orderPayment .ec-halfInput, .ec-orderPayment .ec-numberInput, .ec-orderPayment .ec-zipInput, .ec-orderPayment .ec-telInput, .ec-orderPayment .ec-select, .ec-orderPayment .ec-birth {
    padding: 10px 0 0 13px; }

/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 60px; }
  @media only screen and (min-width: 768px) {
    .ec-orderConfirm {
      margin-bottom: 0;
      padding: 0 0 20px; } }
  .ec-orderConfirm .ec-input, .ec-orderConfirm .ec-halfInput, .ec-orderConfirm .ec-numberInput, .ec-orderConfirm .ec-zipInput, .ec-orderConfirm .ec-telInput, .ec-orderConfirm .ec-select, .ec-orderConfirm .ec-birth {
    padding: 10px 0 0 13px; }
    .ec-orderConfirm .ec-input textarea, .ec-orderConfirm .ec-halfInput textarea, .ec-orderConfirm .ec-numberInput textarea, .ec-orderConfirm .ec-zipInput textarea, .ec-orderConfirm .ec-telInput textarea, .ec-orderConfirm .ec-select textarea, .ec-orderConfirm .ec-birth textarea {
      height: 96px; }

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px 100px; }
  @media only screen and (min-width: 768px) {
    .ec-AddAddress {
      margin: 0 10% 10%; } }
  .ec-AddAddress .ec-inlineBtn {
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000010;
    border: none; }
  .ec-AddAddress .ec-AddAddress__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px; }
  .ec-AddAddress .ec-AddAddress__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px; }
  .ec-AddAddress .ec-AddAddress__item {
    display: table;
    padding: 16px;
    background: #f4f4f4;
    margin-bottom: 16px; }
  .ec-AddAddress .ec-AddAddress__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%; }
    .ec-AddAddress .ec-AddAddress__itemThumb img {
      width: 100%; }
  .ec-AddAddress .ec-AddAddress__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size: 16px; }
  .ec-AddAddress .ec-AddAddress__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px; }
  .ec-AddAddress .ec-AddAddress__itemtSize {
    margin-bottom: 10px; }
  .ec-AddAddress .ec-AddAddress__select {
    margin-bottom: 5px; }
  .ec-AddAddress .ec-AddAddress__selectAddress {
    display: inline-block; }
    .ec-AddAddress .ec-AddAddress__selectAddress label {
      font-size: 16px;
      font-weight: normal; }
    .ec-AddAddress .ec-AddAddress__selectAddress select {
      min-width: 100%; }
      @media only screen and (min-width: 768px) {
        .ec-AddAddress .ec-AddAddress__selectAddress select {
          min-width: 350px; } }
  .ec-AddAddress .ec-AddAddress__selectNumber {
    display: inline-block;
    margin-left: 30px; }
    .ec-AddAddress .ec-AddAddress__selectNumber label {
      font-size: 16px;
      font-weight: normal; }
    .ec-AddAddress .ec-AddAddress__selectNumber input {
      display: inline-block;
      margin-left: 10px;
      width: 80px; }
  .ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
    margin-bottom: 8px; }
  .ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--cancel {
    background-color: #525263; }
  .ec-AddAddress .ec-AddAddress__new {
    margin-bottom: 20px; }
    .ec-AddAddress .ec-AddAddress__new .btn {
      border-radius: 20px;
      box-shadow: 0px 3px 6px #00000010;
      border: none; }

@media only screen and (min-width: 768px) {
  .ec-role-shopping-progress {
    padding: 30px 0 0; } }

.ec-role-shopping-progress .ec-pageHeader h1 {
  border-top: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole .ec-historyRole__contents {
  padding-top: 1em;
  padding-bottom: 16px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  color: #525263; }
  @media only screen and (min-width: 768px) {
    .ec-historyRole .ec-historyRole__contents {
      flex-direction: row; } }

.ec-historyRole .ec-historyRole__header {
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-historyRole .ec-historyRole__header {
      width: 33.3333%; } }

.ec-historyRole .ec-historyRole__detail {
  border-top: 1px dotted #ccc;
  width: 100%; }
  .ec-historyRole .ec-historyRole__detail .ec-imageGrid:nth-of-type(1) {
    border-top: none; }
  .ec-historyRole .ec-historyRole__detail .ec-historyRole__detailTitle {
    margin-bottom: 8px;
    font-size: 1.6rem;
    font-weight: bold; }
  .ec-historyRole .ec-historyRole__detail .ec-historyRole__detailPrice {
    margin-bottom: 8px;
    font-size: 1.6rem;
    font-weight: 500; }
  @media only screen and (min-width: 768px) {
    .ec-historyRole .ec-historyRole__detail {
      width: 66.6666%;
      border-top: none; } }

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 0.5rem;
  font-size: 1.6rem; }

.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption::after {
  display: inline-block;
  padding-left: 0.5rem;
  content: "/";
  font-weight: bold; }

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/
.ec-historyListHeader .ec-historyListHeader__date {
  font-weight: bold;
  font-size: 16px; }
  @media only screen and (min-width: 768px) {
    .ec-historyListHeader .ec-historyListHeader__date {
      font-weight: bold;
      font-size: 20px; } }

.ec-historyListHeader .ec-historyListHeader__action {
  margin: 16px 0; }
  .ec-historyListHeader .ec-historyListHeader__action a {
    font-size: 12px;
    font-weight: normal;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000010;
    border: none; }
    @media only screen and (min-width: 768px) {
      .ec-historyListHeader .ec-historyListHeader__action a {
        font-size: 14px; } }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails .ec-orderMails__item {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc; }

.ec-orderMails .ec-orderMails__time {
  margin: 0; }

.ec-orderMails .ec-orderMails__body {
  display: none; }

/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 16px; }
  .ec-orderMail .ec-orderMail__time {
    margin: 0; }
  .ec-orderMail .ec-orderMail__body {
    display: none; }
  .ec-orderMail .ec-orderMail__time {
    margin-bottom: 4px; }
  .ec-orderMail .ec-orderMail__link {
    margin-bottom: 4px; }
    .ec-orderMail .ec-orderMail__link a {
      color: #0092c4;
      text-decoration: none;
      cursor: pointer; }
    .ec-orderMail .ec-orderMail__link a:hover {
      color: #33a8d0; }
  .ec-orderMail .ec-orderMail__close a {
    color: #0092c4;
    text-decoration: none;
    cursor: pointer; }
  .ec-orderMail .ec-orderMail__close a:hover {
    color: #33a8d0; }

/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole .ec-addressRole__item {
  border-top: 1px dotted #ccc; }

.ec-addressRole .ec-addressRole__actions {
  margin-top: 32px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ccc; }
  .ec-addressRole .ec-addressRole__actions a {
    font-weight: 300; }

.ec-addressList .ec-addressList__item {
  display: table;
  width: 100%;
  position: relative;
  border-bottom: 1px dotted #ccc; }

.ec-addressList .ec-addressList__remove {
  vertical-align: middle;
  padding: 16px;
  text-align: center; }
  .ec-addressList .ec-addressList__remove .ec-icon img {
    width: 1em;
    height: 1em; }

.ec-addressList .ec-addressList__address {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  margin-right: 4em;
  width: 80%; }

.ec-addressList .ec-addressList__action {
  position: relative;
  vertical-align: middle;
  text-align: right;
  top: 27px;
  padding-right: 10px; }
  .ec-addressList .ec-addressList__action .ec-inlineBtn {
    font-weight: 300; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-forgotRole:after {
    content: " ";
    display: table; }
  .ec-forgotRole:after {
    clear: both; }
  .ec-forgotRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-forgotRole img {
    max-width: 100%; }
  .ec-forgotRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-forgotRole *,
  .ec-forgotRole *::before,
  .ec-forgotRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-forgotRole img {
    width: 100%; }
  .ec-forgotRole .ec-forgotRole__intro {
    font-size: 16px; }
  .ec-forgotRole .ec-forgotRole__form {
    margin-bottom: 16px; }
  .ec-forgotRole .ec-off4Grid__cell {
    margin: 0 auto !important;
    padding: 0 0 50px; }
    .ec-forgotRole .ec-off4Grid__cell button {
      width: 200px !important; }

.ec-forgetCompleteRole {
  padding: 0 0 100px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-registerRole:after {
    content: " ";
    display: table; }
  .ec-registerRole:after {
    clear: both; }
  .ec-registerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-registerRole img {
    max-width: 100%; }
  .ec-registerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-registerRole *,
  .ec-registerRole *::before,
  .ec-registerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-registerRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-registerRole {
      margin: 80px auto; } }
  .ec-registerRole p {
    font-family: serif; }
    @media only screen and (min-width: 768px) {
      .ec-registerRole p {
        padding: 0 0 30px; } }
  @media only screen and (max-width: 767px) {
    .ec-registerRole .ec-pageHeader h1 {
      border: none; } }
  .ec-registerRole .ec-registerRole__actions .ec-blockBtn--action {
    margin: 10px 0; }
  .ec-registerRole .ec-registerRole__actions .ec-blockBtn--cancel {
    margin: 10px 0;
    background-color: #525263; }
    .ec-registerRole .ec-registerRole__actions .ec-blockBtn--cancel:hover {
      background-color: #333333; }

.ec-registerRole__siteseal {
  margin-bottom: 16px; }
  @media only screen and (max-width: 767px) {
    .ec-registerRole__siteseal {
      padding: 0 0 30px; } }
  @media only screen and (min-width: 768px) {
    .ec-registerRole__siteseal .ec-registerRole__siteseal_cotainer {
      max-width: 150px;
      max-height: 58px;
      width: 100%;
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .ec-registerRole__siteseal .ec-registerRole__siteseal_cotainer {
      width: 100px;
      height: 39px;
      margin: 0 auto; } }
  @media only screen and (min-width: 768px) {
    .ec-registerRole__siteseal a img {
      max-width: 150px;
      max-height: 58px;
      width: 100%;
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .ec-registerRole__siteseal a img {
      width: 100px;
      height: 39px; } }

.ec-registerRole__actions {
  padding-top: 20px;
  padding: 0 0 50px;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .ec-registerRole__actions {
      text-align: left;
      margin-bottom: 100px; } }
  @media only screen and (max-width: 767px) {
    .ec-registerRole__actions {
      margin: 0 0 20px; } }
  .ec-registerRole__actions p {
    margin-bottom: 16px; }
  .ec-registerRole__actions .ec-off4Grid {
    margin-left: -15px;
    margin-right: -15px; }
    .ec-registerRole__actions .ec-off4Grid:after {
      content: " ";
      display: table; }
    .ec-registerRole__actions .ec-off4Grid:after {
      clear: both; }
    .ec-registerRole__actions .ec-off4Grid__cell {
      margin: 0; }
      @media only screen and (min-width: 768px) {
        .ec-registerRole__actions .ec-off4Grid__cell {
          margin-left: 33.33333%;
          width: 275px; } }
      @media only screen and (max-width: 767px) {
        .ec-registerRole__actions .ec-off4Grid__cell {
          width: 70%;
          margin: 0 auto; } }

.ec-blockBtn--action {
  margin-bottom: 16px; }

.ec-registerCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 0 auto 30px; }
  .ec-registerCompleteRole:after {
    content: " ";
    display: table; }
  .ec-registerCompleteRole:after {
    clear: both; }
  .ec-registerCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-registerCompleteRole img {
    max-width: 100%; }
  .ec-registerCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-registerCompleteRole *,
  .ec-registerCompleteRole *::before,
  .ec-registerCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-registerCompleteRole img {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .ec-registerCompleteRole .ec-reportHeading {
      border-top: none; } }
  .ec-registerCompleteRole .ec-blockBtn--action {
    width: 200px !important; }
  .ec-registerCompleteRole .ec-off2Grid__cell {
    margin: 0 auto; }
  .ec-registerCompleteRole .ec-off4Grid__cell {
    margin: 0 auto !important; }
    .ec-registerCompleteRole .ec-off4Grid__cell .ec-blockBtn--cancel {
      width: 200px !important;
      margin: 0 auto; }
  .ec-registerCompleteRole .ec-registerCompleteRole__go-to-top {
    margin: 0 0 200px; }
    @media only screen and (min-width: 768px) {
      .ec-registerCompleteRole .ec-registerCompleteRole__go-to-top .ec-off4Grid__cell {
        margin: 0 auto !important; } }
    .ec-registerCompleteRole .ec-registerCompleteRole__go-to-top .ec-off4Grid__cell a {
      width: 300px; }

.ec-RegisterRole__actions {
  padding: 0 0 50px; }
  .ec-RegisterRole__actions .ec-off4Grid__cell {
    width: 40%; }

.ec-role-complete h1 {
  border-top: none; }

.ec-role-complete .ec-role-complete__go-to-top {
  margin: 0 0 200px; }
  @media only screen and (min-width: 768px) {
    .ec-role-complete .ec-role-complete__go-to-top .ec-off4Grid__cell {
      margin: 0 auto !important; } }
  .ec-role-complete .ec-role-complete__go-to-top .ec-off4Grid__cell a {
    width: 300px; }

.ec-RegisterRole__actions {
  padding: 0 0 50px; }
  .ec-RegisterRole__actions .ec-off4Grid__cell {
    width: 40%; }

.ec-role-complete h1 {
  border-top: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-contactRole:after {
    content: " ";
    display: table; }
  .ec-contactRole:after {
    clear: both; }
  .ec-contactRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactRole img {
    max-width: 100%; }
  .ec-contactRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactRole *,
  .ec-contactRole *::before,
  .ec-contactRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-contactRole {
      margin: 80px auto;
      max-width: 1150px; } }
  .ec-contactRole .ec-contactRole__actions {
    padding-top: 20px; }
  .ec-contactRole p {
    margin: 16px 0; }
  .ec-contactRole .ec-off4Grid__cell {
    margin: 0 auto;
    width: auto; }
    .ec-contactRole .ec-off4Grid__cell button {
      width: 300px !important; }
  .ec-contactRole h1 {
    border-top: none; }

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-contactConfirmRole:after {
    content: " ";
    display: table; }
  .ec-contactConfirmRole:after {
    clear: both; }
  .ec-contactConfirmRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactConfirmRole img {
    max-width: 100%; }
  .ec-contactConfirmRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactConfirmRole *,
  .ec-contactConfirmRole *::before,
  .ec-contactConfirmRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactConfirmRole img {
    width: 100%; }
  .ec-contactConfirmRole .ec-contactConfirmRole__actions {
    padding-top: 20px; }
  .ec-contactConfirmRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-contactCompleteRole:after {
    content: " ";
    display: table; }
  .ec-contactCompleteRole:after {
    clear: both; }
  .ec-contactCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactCompleteRole img {
    max-width: 100%; }
  .ec-contactCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactCompleteRole *,
  .ec-contactCompleteRole *::before,
  .ec-contactCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactCompleteRole img {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .ec-contactCompleteRole {
      margin-bottom: 16px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

ex [カートSTEP2 お客様情報の入力(ゲスト購入)](http://demo3.ec-cube.net/shopping/nonmember)

Markup:
include /assets/tmpl/elements/19.3.customer.pug
+ec-customerRole
hoge

Styleguide 19.3

*/
.ec-customerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-customerRole:after {
    content: " ";
    display: table; }
  .ec-customerRole:after {
    clear: both; }
  .ec-customerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-customerRole img {
    max-width: 100%; }
  .ec-customerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-customerRole *,
  .ec-customerRole *::before,
  .ec-customerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-customerRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-customerRole {
      margin: 80px auto; } }
  .ec-customerRole .ec-customerRole__actions {
    padding-top: 20px; }
  .ec-customerRole .ec-blockBtn--action {
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-customerRole .ec-blockBtn--action {
        margin-bottom: 16px; } }
  @media only screen and (max-width: 767px) {
    .ec-customerRole .ec-off4Grid__cell {
      margin: 0 auto;
      width: 80vw; } }

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-contactConfirmRole:after {
    content: " ";
    display: table; }
  .ec-contactConfirmRole:after {
    clear: both; }
  .ec-contactConfirmRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactConfirmRole img {
    max-width: 100%; }
  .ec-contactConfirmRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactConfirmRole *,
  .ec-contactConfirmRole *::before,
  .ec-contactConfirmRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactConfirmRole img {
    width: 100%; }
  .ec-contactConfirmRole .ec-contactConfirmRole__actions {
    padding-top: 20px; }
  .ec-contactConfirmRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-contactCompleteRole:after {
    content: " ";
    display: table; }
  .ec-contactCompleteRole:after {
    clear: both; }
  .ec-contactCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactCompleteRole img {
    max-width: 100%; }
  .ec-contactCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactCompleteRole *,
  .ec-contactCompleteRole *::before,
  .ec-contactCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactCompleteRole img {
    width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://demo3.ec-cube.net/404)

Markup:
include /assets/tmpl/elements/20.1.404.pug
+ec-404Role

Styleguide 20.1

*/
.ec-404Role {
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  text-align: center;
  box-sizing: border-box; }
  .ec-404Role textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-404Role img {
    max-width: 100%; }
  .ec-404Role html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-404Role *,
  .ec-404Role *::before,
  .ec-404Role *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-404Role img {
    width: 100%; }
  .ec-404Role .ec-404Role__icon img {
    width: 1em;
    height: 1em; }
  .ec-404Role .ec-404Role__title {
    font-weight: bold;
    font-size: 25px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  text-align: center;
  padding: 0 16px 50px; }
  .ec-withdrawRole:after {
    content: " ";
    display: table; }
  .ec-withdrawRole:after {
    clear: both; }
  .ec-withdrawRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-withdrawRole img {
    max-width: 100%; }
  .ec-withdrawRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-withdrawRole *,
  .ec-withdrawRole *::before,
  .ec-withdrawRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-withdrawRole img {
    width: 100%; }
  .ec-withdrawRole .ec-withdrawRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px; }
  .ec-withdrawRole .ec-withdrawRole__description {
    margin-bottom: 32px;
    font-size: 16px; }
  .ec-withdrawRole .ec-icon img {
    width: 100px;
    height: 100px; }
  .ec-withdrawRole .ec-off4Grid {
    display: block; }
  .ec-withdrawRole .ec-off4Grid__cell {
    margin: 0 auto !important; }
    .ec-withdrawRole .ec-off4Grid__cell button {
      max-width: 350px;
      width: 100%;
      height: 56px;
      margin: 0 auto;
      background: #525263; }
      .ec-withdrawRole .ec-off4Grid__cell button:hover {
        background: #333333; }

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole {
  padding: 0 0 50px; }
  .ec-withdrawConfirmRole .ec-withdrawConfirmRole__cancel {
    margin-bottom: 20px;
    text-align: center; }
  .ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px; }
  .ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
    margin-bottom: 32px;
    font-size: 16px; }
  .ec-withdrawConfirmRole .ec-icon img {
    width: 100px;
    height: 100px; }
  .ec-withdrawConfirmRole .ec-off4Grid {
    display: block; }
  .ec-withdrawConfirmRole .ec-off4Grid__cell {
    margin: 0 auto !important; }
  .ec-withdrawConfirmRole a,
  .ec-withdrawConfirmRole button {
    max-width: 350px;
    width: 100%;
    height: 56px;
    line-height: 56px;
    margin: 0 auto; }
  .ec-withdrawConfirmRole .ec-blockBtn--action {
    background: #525263; }
    .ec-withdrawConfirmRole .ec-blockBtn--action:hover {
      background: #333333; }
  .ec-withdrawConfirmRole .ec-blockBtn--cancel {
    background: #f0f0f0;
    color: #525263; }
    .ec-withdrawConfirmRole .ec-blockBtn--cancel:hover {
      background: #dddddd; }

.ec-withdrawCompleteRole {
  padding: 0 0 50px; }
  .ec-withdrawCompleteRole .ec-off4Grid {
    display: block; }
  .ec-withdrawCompleteRole .ec-off4Grid__cell {
    margin: 0 auto !important; }
  .ec-withdrawCompleteRole .ec-blockBtn--cancel {
    max-width: 350px;
    width: 100%;
    height: 56px;
    line-height: 56px;
    margin: 0 auto;
    background: #525263; }
    .ec-withdrawCompleteRole .ec-blockBtn--cancel:hover {
      background: #333333; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  text-align: center;
  padding: 0 16px; }
  .ec-userEditCompleteRole:after {
    content: " ";
    display: table; }
  .ec-userEditCompleteRole:after {
    clear: both; }
  .ec-userEditCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-userEditCompleteRole img {
    max-width: 100%; }
  .ec-userEditCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-userEditCompleteRole *,
  .ec-userEditCompleteRole *::before,
  .ec-userEditCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-userEditCompleteRole img {
    width: 100%; }
  .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px; }
    @media only screen and (min-width: 768px) {
      .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
        font-size: 32px; } }
  .ec-userEditCompleteRole .ec-userEditCompleteRole__description {
    margin-bottom: 32px;
    font-size: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.ec-role-about {
  max-width: 1150px;
  padding: 30px 30px 300px;
  margin: 10px auto 0 auto; }
  @media only screen and (max-width: 767px) {
    .ec-role-about {
      margin: -10px auto 80px auto;
      padding: 0px 20px; } }
  .ec-role-about .font-weight600 {
    font-weight: 600 !important; }
  .ec-role-about .ec-pageHeader h1 {
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 20px 0 !important;
    font-size: 32px;
    font-weight: bold;
    border-top: none; }
    @media only screen and (max-width: 767px) {
      .ec-role-about .ec-pageHeader h1 {
        padding: 0px ​0 20px 0 !important; } }
    @media only screen and (max-width: 767px) {
      .ec-role-about .ec-pageHeader h1 {
        padding: 20px 10px;
        font-size: 22px; } }
  .ec-role-about .ec-pageHeader2 {
    padding: 50px 0; }
    @media only screen and (max-width: 767px) {
      .ec-role-about .ec-pageHeader2 {
        padding: 20px 0px; } }
    .ec-role-about .ec-pageHeader2 h1 {
      border-bottom: 1px dotted #ccc;
      margin: 0;
      padding: 20px 0;
      font-size: 32px;
      font-weight: bold; }
    .ec-role-about .ec-pageHeader2 h2 {
      font-weight: 600;
      font-size: 20px;
      margin: 30px 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-about .ec-pageHeader2 h2 {
          font-size: 15px;
          margin: 30px 0px 13px 0; } }
    .ec-role-about .ec-pageHeader2 .content-1__div {
      border: none; }
    .ec-role-about .ec-pageHeader2 p {
      padding: 0 0 10px; }
      @media only screen and (max-width: 767px) {
        .ec-role-about .ec-pageHeader2 p {
          font-size: 13px;
          line-height: 1.8; } }
    .ec-role-about .ec-pageHeader2 .attention {
      padding: 5px 60px; }
      @media only screen and (max-width: 767px) {
        .ec-role-about .ec-pageHeader2 .attention {
          font-size: 13px;
          line-height: 1.8; } }
  .ec-role-about .ec-pageHeader2-privacy p {
    padding: 0 0 10px; }
    @media only screen and (max-width: 767px) {
      .ec-role-about .ec-pageHeader2-privacy p {
        font-size: 13px;
        line-height: 1.8; } }
  .ec-role-about .content-1 {
    padding: 50px 60px 60px; }
    @media only screen and (max-width: 767px) {
      .ec-role-about .content-1 {
        padding: 0px 0px 40px; } }
    .ec-role-about .content-1__div {
      border-bottom: 1px dotted #ccc;
      padding: 1px 0 30px 0; }
    .ec-role-about .content-1 h1 {
      font-weight: 600;
      border-bottom: 1px dotted #ccc;
      padding-bottom: 0.4em;
      font-size: 26px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-about .content-1 h1 {
          font-size: 18px; } }
    .ec-role-about .content-1 h2 {
      font-weight: 600;
      font-size: 20px;
      margin: 30px 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-about .content-1 h2 {
          font-size: 15px;
          margin: 30px 0px 13px 0; } }
    .ec-role-about .content-1 p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.8; }
      @media only screen and (max-width: 767px) {
        .ec-role-about .content-1 p {
          font-size: 13px;
          line-height: 1.8; } }
  .ec-role-about .padding30px60px {
    padding: 30px 60px; }
    @media only screen and (max-width: 767px) {
      .ec-role-about .padding30px60px {
        padding: 30px 0 0 0; } }
  .ec-role-about .padding0px0px20px {
    padding: 0 0 20px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.ec-role-tradelaw {
  max-width: 1150px;
  padding: 30px 30px 300px;
  margin: 10px auto 0 auto;
  /*
  @include media_desktop {
    margin: 80px auto;
    max-width: $max-width-dt;
  }
  @include media_mobile {
    margin: 0 auto;
    padding: 30px;
  }
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;

  h1 {
    border-top: none;
  }
  */ }
  @media only screen and (max-width: 767px) {
    .ec-role-tradelaw {
      margin: 0px auto 80px auto;
      padding: 0px 20px; } }
  .ec-role-tradelaw dt,
  .ec-role-tradelaw dd {
    padding: 10px 0 !important;
    line-height: 1.5 !important; }
    @media only screen and (max-width: 767px) {
      .ec-role-tradelaw dt,
      .ec-role-tradelaw dd {
        padding: 10px 0 !important; } }
  @media only screen and (max-width: 767px) {
    .ec-role-tradelaw dt {
      padding: 10px 0 0 0 !important;
      font-size: 1.1em; } }
  @media only screen and (max-width: 767px) {
    .ec-role-tradelaw dd {
      padding: 0 0 20px 0 !important; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.ec-role-shopping-login {
  padding: 0 0 20px;
  max-width: 1150px;
  margin: 0 auto; }
  @media only screen and (max-width: 767px) {
    .ec-role-shopping-login {
      padding: 0 0 100px; } }
  .ec-role-shopping-login .ec-pageHeader h1 {
    border-top: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.ec-role-privacy {
  max-width: 1150px;
  padding: 30px 30px 300px;
  margin: 10px auto 0 auto; }
  @media only screen and (max-width: 767px) {
    .ec-role-privacy {
      margin: -10px auto 80px auto;
      padding: 0px 20px; } }
  .ec-role-privacy .font-weight600 {
    font-weight: 600 !important; }
  .ec-role-privacy .ec-pageHeader h1 {
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 20px 0 !important;
    font-size: 32px;
    font-weight: bold;
    border-top: none; }
    @media only screen and (max-width: 767px) {
      .ec-role-privacy .ec-pageHeader h1 {
        padding: 0px ​0 20px 0 !important; } }
    @media only screen and (max-width: 767px) {
      .ec-role-privacy .ec-pageHeader h1 {
        padding: 20px 10px;
        font-size: 22px; } }
  .ec-role-privacy .ec-pageHeader2 {
    padding: 50px 0; }
    @media only screen and (max-width: 767px) {
      .ec-role-privacy .ec-pageHeader2 {
        padding: 20px 0px; } }
    .ec-role-privacy .ec-pageHeader2 h1 {
      border-bottom: 1px dotted #ccc;
      margin: 0;
      padding: 20px 0;
      font-size: 32px;
      font-weight: bold; }
    .ec-role-privacy .ec-pageHeader2 .content-1__div {
      border: none; }
    .ec-role-privacy .ec-pageHeader2 p {
      padding: 0 0 10px; }
      @media only screen and (max-width: 767px) {
        .ec-role-privacy .ec-pageHeader2 p {
          font-size: 13px;
          line-height: 1.8; } }
    .ec-role-privacy .ec-pageHeader2 .attention {
      padding: 5px 60px; }
      @media only screen and (max-width: 767px) {
        .ec-role-privacy .ec-pageHeader2 .attention {
          font-size: 13px;
          line-height: 1.8; } }
  .ec-role-privacy .ec-pageHeader2-privacy p {
    padding: 0 0 10px; }
    @media only screen and (max-width: 767px) {
      .ec-role-privacy .ec-pageHeader2-privacy p {
        font-size: 13px;
        line-height: 1.8; } }
  .ec-role-privacy .content-1 {
    padding: 50px 60px 60px; }
    @media only screen and (max-width: 767px) {
      .ec-role-privacy .content-1 {
        padding: 0px 0px 40px; } }
    .ec-role-privacy .content-1__div {
      border-bottom: 1px dotted #ccc;
      padding: 1px 0 30px 0; }
    .ec-role-privacy .content-1 h1 {
      font-weight: 600;
      border-bottom: 1px dotted #ccc;
      padding-bottom: 0.4em;
      font-size: 26px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-privacy .content-1 h1 {
          font-size: 18px; } }
    .ec-role-privacy .content-1 h2 {
      font-weight: 600;
      font-size: 20px;
      margin: 30px 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-privacy .content-1 h2 {
          font-size: 15px;
          margin: 30px 0px 13px 0; } }
    .ec-role-privacy .content-1 p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.8; }
      @media only screen and (max-width: 767px) {
        .ec-role-privacy .content-1 p {
          font-size: 13px;
          line-height: 1.8; } }
  .ec-role-privacy .padding30px60px {
    padding: 30px 60px; }
    @media only screen and (max-width: 767px) {
      .ec-role-privacy .padding30px60px {
        padding: 30px 0 0 0; } }
  .ec-role-privacy .padding0px0px20px {
    padding: 0 0 20px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.ec-role-common {
  max-width: 1150px;
  padding: 30px 30px 300px;
  margin: 10px auto 0 auto; }
  @media only screen and (max-width: 767px) {
    .ec-role-common {
      margin: -10px auto 80px auto;
      padding: 0px 20px; } }
  .ec-role-common .font-weight600 {
    font-weight: 600 !important; }
  .ec-role-common .ec-pageHeader h1 {
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 20px 0 !important;
    font-size: 32px;
    font-weight: bold;
    border-top: none; }
    @media only screen and (max-width: 767px) {
      .ec-role-common .ec-pageHeader h1 {
        padding: 0px ​0 20px 0 !important; } }
    @media only screen and (max-width: 767px) {
      .ec-role-common .ec-pageHeader h1 {
        padding: 20px 10px;
        font-size: 22px; } }
  .ec-role-common .ec-pageHeader2 {
    padding: 50px 0; }
    @media only screen and (max-width: 767px) {
      .ec-role-common .ec-pageHeader2 {
        padding: 20px 0px; } }
    .ec-role-common .ec-pageHeader2 h1 {
      border-bottom: 1px dotted #ccc;
      margin: 0;
      padding: 20px 0;
      font-size: 32px;
      font-weight: bold; }
    .ec-role-common .ec-pageHeader2 .content-block__div {
      border: none; }
    .ec-role-common .ec-pageHeader2 p {
      padding: 0 0 10px; }
      @media only screen and (max-width: 767px) {
        .ec-role-common .ec-pageHeader2 p {
          font-size: 13px;
          line-height: 1.8; } }
    .ec-role-common .ec-pageHeader2 .attention {
      padding: 5px 60px; }
      @media only screen and (max-width: 767px) {
        .ec-role-common .ec-pageHeader2 .attention {
          font-size: 13px;
          line-height: 1.8; } }
  .ec-role-common .ec-pageHeader2-privacy p {
    padding: 0 0 10px; }
    @media only screen and (max-width: 767px) {
      .ec-role-common .ec-pageHeader2-privacy p {
        font-size: 13px;
        line-height: 1.8; } }
  .ec-role-common .content-block {
    padding: 50px 60px 60px; }
    @media only screen and (max-width: 767px) {
      .ec-role-common .content-block {
        padding: 0px 0px 40px; } }
    .ec-role-common .content-block__div {
      border-bottom: 1px dotted #ccc;
      padding: 1px 0 30px 0; }
    .ec-role-common .content-block h1 {
      font-weight: 600;
      border-bottom: 1px dotted #ccc;
      padding-bottom: 0.4em;
      font-size: 26px;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-common .content-block h1 {
          font-size: 18px; } }
    .ec-role-common .content-block h2 {
      font-weight: 600;
      font-size: 20px;
      margin: 30px 0; }
      @media only screen and (max-width: 767px) {
        .ec-role-common .content-block h2 {
          font-size: 15px;
          margin: 30px 0px 13px 0; } }
    .ec-role-common .content-block p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.8; }
      @media only screen and (max-width: 767px) {
        .ec-role-common .content-block p {
          font-size: 13px;
          line-height: 1.8; } }
  .ec-role-common .padding30px60px {
    padding: 30px 60px; }
    @media only screen and (max-width: 767px) {
      .ec-role-common .padding30px60px {
        padding: 30px 0 0 0; } }
  .ec-role-common .padding0px0px20px {
    padding: 0 0 20px; }

@media screen and (min-width: 768px) {
  #page_ordermade_top_cart .ordermade-bgimg, #page_ordermade_top .ordermade-bgimg {
    background-image: url(/html/template/default/assets/img/order/order-top-bg-img@2x.jpg);
    background-size: cover;
    background-position: center; }
  .background-3d {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    animation-name: fadeIn-right;
    animation-duration: 1s;
    animation-delay: 0.6s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both; }
  .top_container {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
  .ordermade-home-contents-out-wrapper {
    display: block;
    animation-name: fadeIn-right;
    animation-duration: 1s;
    animation-delay: 1.6s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both; }
  .ordermade-home-contents-head {
    display: block; }
  .ordermade-home-contents-content {
    display: block; }
  .ordermade-home-p-1 {
    text-align: center;
    padding: 0;
    margin: 0; }
    .ordermade-home-p-1 img {
      width: 30vw;
      animation-name: fadeIn-right;
      animation-duration: 1s;
      animation-delay: 0.6s;
      transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
      animation-fill-mode: both; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-home-p-1 img {
        width: 391.81px; } }

@media screen and (min-width: 768px) {
  .ordermade-home-p-2 {
    text-align: center;
    margin: 2vw 0 0 0; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .ordermade-home-p-2 {
      margin: 27.32px 0 0 0; } }

@media screen and (min-width: 768px) {
    .ordermade-home-p-2 img {
      width: 40vw;
      animation-name: fadeIn-right;
      animation-duration: 1s;
      animation-delay: 0.6s;
      transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
      animation-fill-mode: both; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-home-p-2 img {
        width: 518.11px; } }

@media screen and (min-width: 768px) {
  .ordermade-home-block {
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    width: 31vw;
    margin: 5.25vw auto 0 auto;
    border-radius: 10px;
    text-align: center;
    animation-name: fadeIn-right;
    animation-duration: 1s;
    animation-delay: 1.6s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .ordermade-home-block {
      margin: 71.715px auto 0 auto;
      width: 423.453px; } }

@media screen and (min-width: 768px) {
  .ordermade-home-p-3 {
    text-align: center;
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    font-weight: 600;
    color: #333333;
    font-size: 1.5vw;
    padding: 2.1vw 0 0 0;
    display: inline-block;
    position: relative;
    letter-spacing: 0.05em; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .ordermade-home-p-3 {
      padding: 28.686px 0 0 0;
      font-size: 20.49px; } }

@media screen and (min-width: 768px) {
    .ordermade-home-p-3 .ordermade-home-badge1 {
      position: absolute;
      top: -2vw;
      left: -6.3vw;
      width: 5.56vw; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-home-p-3 .ordermade-home-badge1 {
        top: -27.32px;
        left: -86.058px;
        width: 76px; } }

@media screen and (min-width: 768px) {
  .ordermade-home-p-4 {
    text-align: center;
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    font-weight: 500;
    font-size: 1.1vw;
    color: #ba4592;
    padding: 0.5vw 0 0 0;
    letter-spacing: 0.05em; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .ordermade-home-p-4 {
      padding: 6.83px 0 0 0;
      font-size: 15.026px; } }

@media screen and (min-width: 768px) {
  .ordermade-home-div {
    display: flex;
    justify-content: center;
    padding: 1.5vw 0 3vw 0; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .ordermade-home-div {
      padding: 20px 0 40px 0; } }

@media screen and (min-width: 768px) {
  .minimum-button {
    color: #ffffff;
    background-color: #333333;
    border: none;
    border-radius: 2vw;
    padding: 1vw 1.3vw;
    font-size: 1.1vw;
    line-height: 1;
    margin: 0 0.6vw;
    display: flex;
    align-items: center; }
    .minimum-button:hover {
      color: #ffffff;
      background: #4B4B4B; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .minimum-button {
      border-radius: 24px;
      padding: 13.66px 17.5px;
      font-size: 15px;
      margin: 0 8px; } }

@media screen and (min-width: 768px) {
    .minimum-button img {
      margin: 0 0 0 0.8vw;
      width: 1.245vw; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .minimum-button img {
        width: 11px;
        margin: 0 0 0 11px; } }

@media screen and (min-width: 768px) {
    .minimum-button:disabled {
      cursor: unset;
      opacity: 0.65; }
  .minimum-button.type2 {
    margin: 0.4vw 2vw 0; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .minimum-button.type2 {
      margin: 5.464px 27.32px 0; } }

@media screen and (min-width: 768px) {
  .ordermade-order-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    animation-name: fadeIn-right;
    animation-duration: 1s;
    animation-delay: 0s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both;
    border-radius: 10px;
    width: 35vw;
    height: 16.622vw;
    margin: 4vw auto 0 auto; } }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    .ordermade-order-type {
      width: 478.09px;
      height: 227.05px;
      margin: 54.64px auto 0 auto; } }

@media screen and (min-width: 768px) {
    .ordermade-order-type .header {
      text-align: center;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: auto;
      white-space: nowrap;
      font-size: 1.5vw;
      margin: 1.83009vw auto 0 auto; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .header {
        font-size: 20.49px;
        margin: 25px auto 0px auto; } }

@media screen and (min-width: 768px) {
    .ordermade-order-type .ordermade-home-badge2 {
      position: absolute;
      top: -3vw;
      left: -7.5vw;
      width: 5.564vw; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .ordermade-home-badge2 {
        top: -40.98px;
        left: -102.45px;
        width: 76px; } }

@media screen and (min-width: 768px) {
    .ordermade-order-type .ordermade-home-badge3 {
      position: absolute;
      top: -3vw;
      right: -7.5vw;
      width: 5.564vw; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .ordermade-home-badge3 {
        top: -40.98px;
        right: -102.45px;
        width: 76px; } }

@media screen and (min-width: 768px) {
    .ordermade-order-type .sub-header {
      font: normal normal normal 12px/26px Hiragino Kaku Gothic Pro;
      color: #d13b9d;
      font-weight: 600;
      font-size: 0.95vw;
      line-height: 1; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .sub-header {
        font-size: 13px; } }

@media screen and (min-width: 768px) {
      .ordermade-order-type .sub-header.disabled {
        opacity: 0.5; }
    .ordermade-order-type .estimate {
      font: normal normal normal 13px/29px Hiragino Kaku Gothic Pro;
      color: #333333;
      padding: 0.6vw 0 0 0;
      line-height: 1; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .estimate {
        padding: 8.2px 0 0 0; } }

@media screen and (min-width: 768px) {
      .ordermade-order-type .estimate.disabled {
        opacity: 0.7; }
    .ordermade-order-type .div-annotation {
      width: auto;
      white-space: nowrap;
      margin: 1.2vw auto 2vw auto;
      text-align: left; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .div-annotation {
        margin: 16.392px auto 27.32px auto; } }

@media screen and (min-width: 768px) {
      .ordermade-order-type .div-annotation .annotation {
        font: normal normal normal 8px/12px Hiragino Kaku Gothic Pro;
        color: #333333;
        line-height: 1.5;
        white-space: pre-wrap; }
        .ordermade-order-type .div-annotation .annotation.red {
          color: #dd5d50; }
    .ordermade-order-type .flex-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1.2vw; } }
    @media screen and (min-width: 768px) and (max-width: 1365px) {
      .ordermade-order-type .flex-horizontal {
        margin-top: 16.392px; } }

@media screen and (min-width: 768px) {
    .ordermade-order-type .flex-vertical {
      display: flex;
      flex-direction: column;
      padding: 0 1.19 0.124px; } }

@media screen and (min-width: 768px) {
  .parts-container {
    display: flex;
    overflow: hidden;
    height: 100vh; }
    .parts-container .parts-container__shaft-3d {
      width: 100vw;
      max-width: calc(100vw - 452px);
      position: relative; }
    .parts-container .parts-container__button--cart {
      position: absolute;
      bottom: 0;
      left: 0px;
      transition-property: left;
      transition-duration: 0.5s;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      width: 160px;
      height: 70px;
      border: none;
      pointer-events: none;
      opacity: 0.5; }
      .parts-container .parts-container__button--cart:hover {
        opacity: 0.8; }
    .parts-container .move {
      left: 161px; }
    .parts-container .view {
      pointer-events: inherit;
      opacity: 1; }
    .parts-container .parts-container__button--home {
      position: absolute;
      bottom: 0;
      display: none;
      left: -161px;
      transition-property: display, left;
      transition-duration: 0.5s;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      width: 160px;
      height: 70px;
      border: none; }
      .parts-container .parts-container__button--home:hover {
        opacity: 0.8; }
    .parts-container .move-none {
      left: 0px;
      display: inline; }
    .parts-container .order-parts-select {
      animation-name: fadeIn-left;
      animation-duration: 1s;
      animation-delay: 0.6s;
      transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
      animation-fill-mode: both;
      width: 18.805vh;
      max-width: 173px;
      height: 100%;
      position: absolute;
      top: 132.5px;
      left: 8px;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      pointer-events: none;
      padding: 0; }
    .parts-container .order-parts-select-button-div {
      width: 452px;
      height: 116px;
      background-color: unset;
      padding: 36px 0 35px 0;
      border-top: 1px #e5e5e5 solid;
      text-align: center; }
      .parts-container .order-parts-select-button-div .normal-button {
        width: calc(50vw - 59px);
        height: 44px;
        max-width: 167px;
        font-size: 14px;
        line-height: 44px;
        border-radius: 22px;
        padding: 0;
        margin: 0 16px;
        border: none; }
        .parts-container .order-parts-select-button-div .normal-button:hover {
          opacity: 0.75; }
      .parts-container .order-parts-select-button-div .yes {
        color: #fff;
        background-color: #333; }
      .parts-container .order-parts-select-button-div .no {
        color: #333;
        background-color: #ddd; } }

@media screen and (min-width: 768px) {
  .customizedesign-container {
    position: absolute;
    top: 0px;
    right: 0px; }
    .customizedesign-container .order-design-select {
      padding: 24px;
      height: calc(100vh - 59px);
      background-color: rgba(255, 255, 255, 0.3); }
      .customizedesign-container .order-design-select .react-tabs {
        height: 100%;
        -webkit-tap-highlight-color: transparent; }
      .customizedesign-container .order-design-select .react-tabs__tab-list {
        width: 452px;
        height: 94px;
        margin: -24px -24px 0 -24px;
        padding: 25px 0;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.3); }
      .customizedesign-container .order-design-select .react-tabs__tab {
        display: inline-block;
        position: relative;
        list-style: none;
        padding: 0;
        cursor: pointer;
        flex: 1;
        text-align: center;
        margin: 0px 12px;
        border-radius: 80px;
        font-weight: 600;
        font-size: 17px;
        line-height: 44px;
        height: 44px;
        width: 10em;
        background: transparent;
        color: #333333; }
        .customizedesign-container .order-design-select .react-tabs__tab:hover {
          background: #00000010;
          color: #333333; }
      .customizedesign-container .order-design-select .react-tabs__tab--selected {
        background: #333333;
        color: #ffffff;
        font-weight: 500; }
        .customizedesign-container .order-design-select .react-tabs__tab--selected:hover {
          background: #333333;
          color: #ffffff; }
      .customizedesign-container .order-design-select .react-tabs__tab--disabled {
        cursor: default; }
      .customizedesign-container .order-design-select .react-tabs__tab-panel {
        display: none; }
      .customizedesign-container .order-design-select .react-tabs__tab-panel--selected {
        display: block;
        height: calc(100% - 56px);
        overflow-y: scroll; }
      .customizedesign-container .order-design-select .order-design-select-container {
        padding: 0;
        margin-top: 30px;
        display: block; }
        .customizedesign-container .order-design-select .order-design-select-container__comment {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          margin: 0 0 30px 0;
          line-height: 1.6; } }

@media screen and (min-width: 768px) {
  .order-mozikokuin {
    overflow-x: scroll;
    height: calc(100vh - 59px);
    padding: 10px 15px 40px 15px; }
    .order-mozikokuin .order-mozikokuin__body {
      padding: 0 0 60px 0; }
      .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div {
        padding: 0 15px　0px 15px　; }
        .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-label-title {
          width: 402px;
          font-size: 18px;
          color: #333333;
          border-bottom: 1px #333333 solid;
          padding-bottom: 13px;
          margin: 30px auto 0 auto;
          font-weight: 500;
          line-height: 1; }
        .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-comment {
          width: 402px;
          font-size: 11px;
          color: #333333;
          margin: 20px auto 0 auto;
          font-weight: 500;
          line-height: 1.7; }
        .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-content {
          width: 422px;
          margin: 20px 0 0px 0;
          padding: 0 0 20px 0;
          display: flex;
          flex-wrap: wrap; }
          .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-content label {
            padding-bottom: 0; }
          .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-content .tab-color-switch {
            display: none; }
          .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-content .tab-color-switch:checked + .order-color-img {
            background-color: gray; }
        .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-text {
          margin: 10px 0 40px 0;
          text-align: center; }
          .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div .tab-string-text input {
            width: 90%;
            height: 30px; }
        .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div__product-container {
          display: flex; } }
        @media only screen and (min-width: 768px) and (min-width: 768px) {
          .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div__product-container {
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            padding: 0; } }
        @media only screen and (min-width: 768px) and (max-width: 767px) {
          .order-mozikokuin .order-mozikokuin__body .order-mozikokuin-select-div__product-container {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0; } }

@media screen and (min-width: 768px) {
      .order-mozikokuin .order-mozikokuin__body .product-container-text-input > .attension-describe {
        width: 402px;
        font-size: 11px;
        color: #333333;
        margin: 20px auto 30px auto;
        font-weight: 500;
        line-height: 1.7;
        display: block; }
      .order-mozikokuin .order-mozikokuin__body .product-container-text-input .text-input-label-div .text-input-label-p1 {
        display: none; }
      .order-mozikokuin .order-mozikokuin__body .product-container-text-input .text-input-label-div .text-input-label-p2 {
        font-size: 12px;
        margin: 10px 15px 10px 15px; }
      .order-mozikokuin .order-mozikokuin__body .product-container-text-input .mozikokuin-input-text {
        margin: 15px 15px 0 15px;
        height: 40px;
        font-size: 18px;
        line-height: 40px;
        width: calc(100% - 30px);
        display: block;
        border-radius: 4px;
        border: solid #999999 1px;
        padding: 0 11px;
        color: #333; }
        .order-mozikokuin .order-mozikokuin__body .product-container-text-input .mozikokuin-input-text::placeholder {
          color: #BEBEBE; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .order-yashaku-select .body {
    display: flex;
    flex-direction: column; }
    .order-yashaku-select .body > hr {
      width: 100%; }
  .order-yashaku-select-table {
    margin: 42px auto 0;
    width: 335px;
    display: block;
    line-height: 1;
    border-top: 1px solid #666666; }
    .order-yashaku-select-table > tr {
      height: 56px;
      border-bottom: 1px solid #666666; }
    .order-yashaku-select-table .table-col1 {
      width: 100px; }
    .order-yashaku-select-table span {
      margin: 0; }
    .order-yashaku-select-table .table-col2 {
      width: auto;
      font-weight: 400;
      padding: 20px 0; }
    .order-yashaku-select-table .table-col2-value {
      height: 40px;
      width: 235px;
      border: none;
      font-size: 16px;
      border-radius: 6px;
      color: #fff;
      background-color: #333;
      padding: 0 8px;
      cursor: pointer; }
      .order-yashaku-select-table .table-col2-value:disabled {
        cursor: default; }
  .order-yashaku-select .category-label2 {
    display: none; }
  .order-yashaku-select .caption {
    margin: 42px auto 0;
    width: 335px;
    display: block;
    line-height: 1.5;
    color: #333; }
  .order-yashaku-select .disabled .table-col1-label {
    color: #aaaaaa; } }

@media screen and (min-width: 768px) {
  .order-kamon {
    overflow-y: scroll;
    height: 100%; }
    .order-kamon .order-kamon__body {
      height: calc(100% - 121px); }
      .order-kamon .order-kamon__body .order-kamon-select-div {
        padding: 0 15px; }
        .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-label-title {
          width: 402px;
          font-size: 18px;
          color: #333333;
          border-bottom: 1px #333333 solid;
          padding-bottom: 13px;
          margin: 30px auto 0 auto;
          font-weight: 600;
          line-height: 1; }
        .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button {
          width: 191px;
          margin: 0 10px;
          border-radius: 4px;
          padding: 11px 11px 8px 11px;
          position: relative;
          display: block;
          background-color: #f0f0f0; }
          .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button__image-wapper {
            width: 167px;
            height: 111px;
            object-fit: cover;
            border: solid #f0f0f0 1px;
            border-radius: 0;
            margin: 0;
            padding: 0;
            text-align: center;
            background-color: #f6f7f9; }
          .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button__image {
            width: auto;
            height: 87px;
            margin-top: 13px; }
          .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button__text {
            width: 167px;
            font-size: 11px;
            line-height: 15px;
            margin: 10px 0;
            font-weight: normal; }
          .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button__icon-selected {
            display: block;
            position: absolute;
            top: 6px;
            left: 6px; }
          .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button__preview-button {
            background-color: black;
            border-radius: 20px;
            font-size: 14px;
            font-weight: normal;
            border: none;
            width: 179px;
            height: 40px;
            line-height: 40px;
            color: #ffffff;
            margin: 0 0 0px -6px; }
            .order-kamon .order-kamon__body .order-kamon-select-div .kamon-select-button__preview-button:hover {
              opacity: 0.75; }
        .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-content {
          margin: 30px 0 0px 0;
          padding: 0 0 20px 0;
          display: flex; }
          .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-content label {
            padding-bottom: 0; }
          .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-content .tab-color-switch {
            display: none; }
          .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-content .tab-color-switch:checked + .order-color-img {
            background-color: gray; }
        .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-text {
          margin: 10px 0 40px 0;
          text-align: center; }
          .order-kamon .order-kamon__body .order-kamon-select-div .tab-string-text input {
            width: 90%;
            height: 30px; }
        .order-kamon .order-kamon__body .order-kamon-select-div__product-container {
          display: flex; } }
        @media only screen and (min-width: 768px) and (min-width: 768px) {
          .order-kamon .order-kamon__body .order-kamon-select-div__product-container {
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            padding: 0; }
            .order-kamon .order-kamon__body .order-kamon-select-div__product-container > .product-thumbnail.item {
              margin: 10px;
              width: 44.93827%; } }
        @media only screen and (min-width: 768px) and (max-width: 767px) {
          .order-kamon .order-kamon__body .order-kamon-select-div__product-container {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.Modal .modal_overlay {
  position: fixed;
  z-index: 1150;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center; }
  .Modal .modal_overlay .content {
    z-index: 1250;
    padding: 1em;
    background: #fff;
    text-align: center;
    border-radius: 10px; }
  .Modal .modal_overlay .batsu {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    z-index: 1250;
    top: 0; }
    @media only screen and (min-width: 768px) {
      .Modal .modal_overlay .batsu {
        left: 0;
        padding: 0 0 0 calc(100vw - 1em - 300px - 40px); } }
    @media only screen and (max-width: 767px) {
      .Modal .modal_overlay .batsu {
        left: 0;
        padding: 0 0 0 calc(100vw - 1em - 300px - 40px); } }

.SelectGuideModal .modal_overlay {
  z-index: 1150;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  justify-content: flex-end; }
  @media only screen and (min-width: 768px) {
    .SelectGuideModal .modal_overlay {
      position: absolute; } }
  @media only screen and (max-width: 767px) {
    .SelectGuideModal .modal_overlay {
      position: fixed; } }
  .SelectGuideModal .modal_overlay .content {
    overflow-x: scroll;
    z-index: 1250;
    padding: 1em;
    background: #fff;
    text-align: center; }
    .SelectGuideModal .modal_overlay .content .ordermade_modal {
      text-align: left; }
      .SelectGuideModal .modal_overlay .content .ordermade_modal h1 {
        font-weight: 900;
        font-size: 23px;
        border-bottom: none;
        width: auto;
        display: inline-block;
        margin: 20px 0 0 0;
        line-height: 1.3; }
      .SelectGuideModal .modal_overlay .content .ordermade_modal .title {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 12px;
        line-height: 1.3;
        margin-top: 40px;
        border-bottom: solid black 1px; }
      .SelectGuideModal .modal_overlay .content .ordermade_modal p {
        margin: 10px; }
      .SelectGuideModal .modal_overlay .content .ordermade_modal img {
        margin: 10px auto;
        display: block;
        max-width: calc(100% - 20px);
        width: auto; }
  .SelectGuideModal .modal_overlay .batsu {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    z-index: 1250;
    top: 0; }
    @media only screen and (min-width: 768px) {
      .SelectGuideModal .modal_overlay .batsu {
        left: 0;
        padding: 0 0 100vh calc(100vw - 1em - 300px - 28px); } }
    @media only screen and (max-width: 767px) {
      .SelectGuideModal .modal_overlay .batsu {
        left: 0;
        padding: 0 0 100vh calc(100vw - 1em - 300px - 28px); } }

.ordermade_modal {
  width: 300px; }
  .ordermade_modal h1 {
    font-weight: 900;
    font-size: 24px;
    border-bottom: solid black 1px;
    width: 130px;
    margin: 20px auto; }
  .ordermade_modal p {
    margin: 10px; }
  .ordermade_modal button {
    background-color: #d13b9d;
    color: #fff;
    border: none;
    padding: 12px 36px 12px 39px;
    border-radius: 4px; }
    .ordermade_modal button:hover {
      opacity: 0.8; }

@media screen and (min-width: 768px) {
  .ordermade_modal {
    width: 300px; }
    .ordermade_modal h1 {
      font-weight: 900;
      font-size: 24px;
      border-bottom: solid black 1px;
      width: 130px;
      margin: 20px auto; }
    .ordermade_modal p {
      margin: 10px; }
    .ordermade_modal button {
      background-color: #d13b9d;
      color: #fff;
      border: none;
      padding: 12px 36px 12px 39px;
      border-radius: 4px; }
      .ordermade_modal button:hover {
        opacity: 0.8; } }

@media screen and (max-width: 767px) {
  #page_ordermade_top_cart .ordermade-bgimg, #page_ordermade_top .ordermade-bgimg {
    background-image: url(/html/template/default/assets/img/order/order-top-bg-img.jpg);
    background-size: cover;
    background-position: center;
    height: calc(var(--vh) * 100); }
  .background-3d {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: calc(var(--vh) * 100);
    animation-name: fadeIn-right;
    animation-duration: 1s;
    animation-delay: 0.6s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both; }
  .top_container {
    height: calc(var(--vh) * 100);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
  .ordermade-home-contents-head {
    padding-bottom: 43vw; }
  .ordermade-home-p-1 {
    padding: 0 0 0 0;
    text-align: center; }
    .ordermade-home-p-1 img {
      width: 52vw;
      animation-name: fadeIn-right;
      animation-duration: 1s;
      animation-delay: 0.6s;
      transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
      animation-fill-mode: both; }
  .ordermade-home-p-2 {
    padding: 4vw 0 0 0;
    text-align: center; }
    .ordermade-home-p-2 img {
      width: 68.8vw;
      animation-name: fadeIn-right;
      animation-duration: 1s;
      animation-delay: 0.6s;
      transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
      animation-fill-mode: both; } }
    @media screen and (max-width: 767px) and (min-width: 509px) {
      .ordermade-home-p-2 img {
        margin: 0 auto; } }

@media screen and (max-width: 767px) {
  .ordermade-home-block {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    animation-name: fadeIn-under;
    animation-duration: 1s;
    animation-delay: 1.6s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both;
    border-radius: 10px 10px 0 0; }
  .ordermade-home-p-3 {
    color: #1e1e1f;
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 5.6vw;
    padding: 0 0 0 0;
    margin: 7.5vw 0 0 0;
    position: relative;
    display: inline-block;
    line-height: 1; }
    .ordermade-home-p-3 .ordermade-home-badge1 {
      position: absolute;
      top: -14vw;
      left: -22vw;
      width: 20.267vw; }
  .ordermade-home-p-4 {
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    text-align: center;
    font-size: 3.46vw;
    font-weight: 600;
    color: #d13b9d;
    line-height: 1;
    margin-top: 2vw; }
  .ordermade-home-div {
    width: 100vw;
    padding: 7vw 0 10vw 0;
    display: flex;
    justify-content: center; }
    .ordermade-home-div .minimum-button {
      color: #ffffff;
      background-color: #333333;
      background-color: #333;
      padding: 3.5vw 3.06667vw 3.5vw 5.06667vw;
      margin: 0 1.5vw;
      font-size: 4vw;
      line-height: 1;
      border-radius: 6vw;
      color: #fff;
      border: none;
      font-weight: 300;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      display: flex;
      align-items: center; }
      .ordermade-home-div .minimum-button:hover {
        color: #ffffff;
        background: #4B4B4B; }
      .ordermade-home-div .minimum-button img {
        margin: 0 0 0 2.8vw;
        width: 4.534vw; }
  .ordermade-order-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    width: 100vw;
    padding: 0;
    background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
    animation-name: fadeIn-under;
    animation-duration: 1s;
    animation-delay: 0s;
    transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89);
    animation-fill-mode: both;
    position: absolute;
    bottom: 0;
    left: 0; }
    .ordermade-order-type .header {
      color: #333;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      font-weight: 500;
      text-align: center;
      font-size: 5.35vw;
      position: relative;
      margin: 7.5vw auto 0 auto;
      white-space: nowrap; }
    .ordermade-order-type .ordermade-home-badge2 {
      width: 20.27vw;
      position: absolute;
      top: -11vw;
      left: -25vw; }
    .ordermade-order-type .ordermade-home-badge3 {
      width: 20.27vw;
      position: absolute;
      top: -11vw;
      right: -25vw; }
    .ordermade-order-type .sub-header {
      color: #d13b9d;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      text-align: center;
      font-size: 3.4vw;
      font-weight: 600;
      line-height: 1; }
    .ordermade-order-type .estimate {
      color: #333;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      font-size: 3.46666vw;
      text-align: center;
      padding: 2.5vw 0 0 0;
      line-height: 1;
      font-weight: 300; }
    .ordermade-order-type .div-annotation {
      width: auto;
      white-space: nowrap;
      margin: 3.7vw auto 6vw auto; }
      .ordermade-order-type .div-annotation .annotation {
        color: #333;
        font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
        font-size: 2.67vw;
        margin: 0 6vw 0 6vw;
        line-height: 1.5;
        font-weight: 300;
        white-space: pre-wrap; }
        .ordermade-order-type .div-annotation .annotation.red {
          color: #dd5d50; }
    .ordermade-order-type .flex-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 3.25vw; }
    .ordermade-order-type .flex-vertical {
      display: flex;
      flex-direction: column;
      padding: 0 2vw; }
    .ordermade-order-type .minimum-button {
      color: #ffffff;
      background-color: #333333;
      padding: 3.5vw 3.06667vw 3.5vw 6.06667vw;
      font-size: 4vw;
      line-height: 1;
      border-radius: 6vw;
      border: none;
      font-weight: 200;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      margin: 1.6vw auto 0;
      display: flex;
      align-items: center; }
      .ordermade-order-type .minimum-button:hover {
        color: #ffffff;
        background: #4B4B4B; }
      .ordermade-order-type .minimum-button img {
        margin: 0 0 0 5vw;
        width: 4.534vw; }
      .ordermade-order-type .minimum-button:disabled {
        cursor: unset;
        opacity: 0.65; } }

@media screen and (max-width: 767px) {
  .parts-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(calc(var(--vh) * 100) - 59px);
    top: 59px; }
    .parts-container .parts-container__shaft-3d {
      height: 100%;
      position: relative; }
    .parts-container .parts-container__div {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      width: 100vw;
      height: 76px;
      z-index: 1000; }
    .parts-container .parts-container__button--cart {
      border: none;
      width: 33vw;
      height: 78%;
      background: none;
      color: #fff;
      position: absolute;
      right: 0; }
      .parts-container .parts-container__button--cart:hover {
        opacity: 0.8; }
    .parts-container .parts-container__button--3d {
      border: none;
      background: none;
      position: absolute;
      width: 70px;
      height: 70px;
      background: #dddddd;
      color: #6f6f6f;
      border-radius: 70px;
      top: -5px;
      right: 41.5vw;
      z-index: 950; } }
    @media only screen and (max-width: 767px) and (min-width: 768px) {
      .parts-container .parts-container__button--3d:hover {
        opacity: 0.8; } }

@media screen and (max-width: 767px) {
      .parts-container .parts-container__button--3d.current-3d-view {
        background: #EB8379;
        color: white;
        pointer-events: none; }
    .parts-container .parts-container__button--home {
      border: none;
      width: 33vw;
      height: 83%;
      background: none;
      color: #fff;
      position: absolute;
      left: 0; }
      .parts-container .parts-container__button--home:hover {
        opacity: 0.8; }
    .parts-container .order-parts-select {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-content: space-around;
      background-color: unset;
      position: absolute;
      --l-top-nav-height: calc(42px + 52px);
      --l-nav-height: calc(var(--l-top-nav-height) + 76px);
      height: calc(var(--vh) * 100 - var(--l-nav-height));
      top: var(--l-top-nav-height);
      width: 50vw;
      flex-wrap: wrap;
      pointer-events: none;
      transition: all .4s ease-in; }
      .parts-container .order-parts-select.current-3d-view {
        width: 100vw; }
    .parts-container .order-parts-select-button-div {
      background-color: unset;
      padding: 0 7px 14px 7px; }
      .parts-container .order-parts-select-button-div .normal-button {
        width: calc(50vw - 21px);
        height: 44px;
        max-width: 167px;
        font-size: 14px;
        line-height: 44px;
        border-radius: 22px;
        padding: 0;
        margin: 0 7px; }
      .parts-container .order-parts-select-button-div .yes {
        color: #fff;
        background-color: #333; }
      .parts-container .order-parts-select-button-div .no {
        color: #333;
        background-color: #ddd; } }

@media screen and (max-width: 767px) {
  .customizedesign-container {
    position: absolute;
    top: 42px;
    left: 0;
    width: 100vw;
    height: calc(calc(var(--vh) * 100) - 59px);
    z-index: 600; }
    .customizedesign-container .order-design-select {
      height: 100%;
      overflow-y: scroll; }
      .customizedesign-container .order-design-select .order-design-select-container {
        padding: 0 0 30px 0;
        margin: 30px 0 30px 0;
        display: block; }
        .customizedesign-container .order-design-select .order-design-select-container__comment {
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 22px 0;
          line-height: 1.6; }
      .customizedesign-container .order-design-select .react-tabs {
        -webkit-tap-highlight-color: transparent; }
      .customizedesign-container .order-design-select .react-tabs__tab-list {
        padding: 0px;
        margin: 0;
        flex-direction: row;
        overflow-x: scroll;
        background-color: rgba(255, 255, 255, 0.5);
        height: 56px;
        text-align: center;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 700; }
      .customizedesign-container .order-design-select .react-tabs__tab {
        border-radius: 20px;
        display: inline-block;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 0;
        margin: 12px;
        width: 10em;
        cursor: pointer;
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        background: transparent;
        color: #333333;
        font-weight: 600; }
        .customizedesign-container .order-design-select .react-tabs__tab:hover {
          background: #00000010;
          color: #333333; }
      .customizedesign-container .order-design-select .react-tabs__tab--selected {
        background: #333333;
        color: #ffffff;
        font-weight: 500; }
        .customizedesign-container .order-design-select .react-tabs__tab--selected:hover {
          background: #333333;
          color: #ffffff; }
      .customizedesign-container .order-design-select .react-tabs__tab--disabled {
        cursor: default; }
      .customizedesign-container .order-design-select .react-tabs__tab-panel {
        display: none; }
      .customizedesign-container .order-design-select .react-tabs__tab-panel--selected {
        display: block;
        overflow: scroll; }
    .customizedesign-container .react_flexbox {
      display: flex;
      justify-content: space-between;
      position: absolute;
      margin: 0 5vw;
      top: 5vw;
      width: 90vw; }
    .customizedesign-container .tab-label {
      height: 36px;
      color: #999;
      order: -1;
      cursor: pointer;
      flex: 1;
      text-align: center; }
    .customizedesign-container .tab-content {
      height: 46vw;
      width: 100vw;
      display: none;
      overflow-x: scroll; }
      .customizedesign-container .tab-content .order-img {
        width: 45vw;
        border: solid 1px;
        flex-shrink: 0; }
    .customizedesign-container .tab-label::after {
      background: #f0f0f0;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 0; }
    .customizedesign-container .tab-switch:checked + .tab-label::after {
      background: #884595; }
    .customizedesign-container .tab-switch:checked + .tab-label {
      color: #333; }
    .customizedesign-container .tab-switch:checked + .tab-label + .tab-content {
      display: flex;
      justify-content: space-between; }
    .customizedesign-container .tab-switch {
      display: none; } }

@media screen and (max-width: 767px) {
  .order-mozikokuin {
    width: 100vw;
    height: calc(calc(var(--vh) * 100) - 118px);
    display: flex;
    overflow: scroll; }
    .order-mozikokuin .attension-describe {
      display: inline-block;
      font-size: 10px;
      text-indent: -1em;
      padding-left: 1em;
      margin: 0 1em 0.5em;
      text-shadow: 0px 1px 1px #ffffff, 0px -1px 1px #ffffffff; }
    .order-mozikokuin .order-mozikokuin__button {
      position: absolute;
      top: calc(52px + 10px);
      left: 7px;
      border: none;
      background-color: rgba(0, 0, 0, 0.65);
      color: #fff;
      padding: 12px 13px;
      border-radius: 20px;
      font-size: 11px;
      font-weight: 400;
      pointer-events: auto; }
    .order-mozikokuin .width-100vw {
      width: 100vw;
      position: absolute;
      left: 0;
      bottom: 0; }
    .order-mozikokuin .react-tabs__wrapper {
      width: 100vw;
      overflow-x: scroll;
      padding: 0;
      margin: 0; }
    .order-mozikokuin .react-tabs {
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.5);
      pointer-events: auto; }
    .order-mozikokuin .react-tabs__tab-list {
      min-width: 100vw;
      list-style: none;
      margin: 14px 0px 0 0px;
      padding: 0; }
    .order-mozikokuin .react-tabs__tab {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      background-color: unset;
      padding: 0 12px;
      line-height: 31px;
      height: 31px;
      border-radius: 20px;
      display: inline-block;
      margin: 0 8px;
      cursor: pointer; }
      .order-mozikokuin .react-tabs__tab--selected {
        color: #ffffff;
        background-color: #333333;
        cursor: unset; }
    .order-mozikokuin .product-container__wrapper {
      width: 100vw;
      height: 168px;
      overflow-x: scroll;
      padding: 14px 0 0 0;
      margin: 0 0 0 0; }
      .order-mozikokuin .product-container__wrapper .react-tabs__tab-panel {
        width: 100vw;
        display: flex;
        justify-content: left; }
    .order-mozikokuin .product-container {
      white-space: nowrap;
      padding: 0 8px;
      margin: 0 auto;
      width: auto; }
    .order-mozikokuin .tab-string-label .order-color-img {
      width: 170px;
      height: 100%; }
      .order-mozikokuin .tab-string-label .order-color-img .order-color-img-img {
        background-color: skyblue;
        height: calc(60% - 8px);
        margin: 4px;
        width: calc(100% - 8px); }
      .order-mozikokuin .tab-string-label .order-color-img .order-color-img-text {
        font-size: 10px;
        text-align: center;
        height: calc(40% - 4px); }
    .order-mozikokuin .tab-string-label input {
      display: none; }
    .order-mozikokuin .tab-string-label .tab-color-switch:checked + .tab-string-label {
      background-color: black; }
    .order-mozikokuin .product-container-text-input {
      width: 100%;
      height: 136px;
      padding: 0;
      background-color: #EDEDED; }
      .order-mozikokuin .product-container-text-input .text-input-label-div {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 35px 0 0 0;
        width: calc(100vw - 40px); }
        .order-mozikokuin .product-container-text-input .text-input-label-div .text-input-label-p1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 1; }
        .order-mozikokuin .product-container-text-input .text-input-label-div .text-input-label-p2 {
          font-size: 12px;
          margin: 2px 0 0 0;
          line-height: 1; }
      .order-mozikokuin .product-container-text-input .mozikokuin-input-text {
        margin: 11px auto 0;
        height: 40px;
        width: 335px;
        display: block;
        border-radius: 4px;
        border: solid #999 1px;
        font-size: 18px;
        padding: 0 0 0 11px;
        color: #333333; }
        .order-mozikokuin .product-container-text-input .mozikokuin-input-text::placeholder {
          color: #BEBEBE; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (max-width: 767px) {
  .order-yashaku-select hr {
    margin-top: 0;
    margin-bottom: 0; }
  .order-yashaku-select .body {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5); }
  .order-yashaku-select-table {
    width: calc(100% - 30px);
    margin: 20px 15px 0;
    border-top: 1px solid #d9d9d9d9; }
    .order-yashaku-select-table > tr {
      border-bottom: 1px solid #d9d9d9d9;
      padding: 20px 0; }
      .order-yashaku-select-table > tr th, .order-yashaku-select-table > tr td {
        display: block; }
    .order-yashaku-select-table .toggleContainer {
      font-size: 13px;
      font-weight: 500; }
    .order-yashaku-select-table .table-col2-value {
      height: 35px;
      width: 100%;
      border: none;
      background: #333;
      font-size: 13px;
      font-weight: 500;
      border-radius: 6px;
      color: #ffffff;
      padding: 0 0 0 11px;
      cursor: pointer; }
      .order-yashaku-select-table .table-col2-value:disabled {
        cursor: default; }
    .order-yashaku-select-table span {
      font-size: 11px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 7px; }
  .order-yashaku-select .table-col1 {
    padding-top: 20px; }
  .order-yashaku-select .table-col2 {
    padding-bottom: 20px; }
  .order-yashaku-select .caption {
    margin: 20px 15px 0;
    font-size: 10px;
    display: block; }
  .order-yashaku-select .disabled .table-col1-label {
    color: #aaaaaa; } }

@media screen and (max-width: 767px) {
  .order-kamon {
    width: 100vw;
    height: calc(calc(var(--vh) * 100) - 118px);
    display: flex;
    overflow: scroll; }
    .order-kamon .order-kamon__button {
      position: absolute;
      top: calc(52px + 10px);
      left: 7px;
      border: none;
      background-color: rgba(0, 0, 0, 0.65);
      color: #fff;
      padding: 12px 13px;
      border-radius: 20px;
      font-size: 11px;
      font-weight: 400;
      pointer-events: auto; }
    .order-kamon .width-100vw {
      width: 100vw;
      position: absolute;
      left: 0;
      bottom: 0; }
    .order-kamon .react-tabs__wrapper {
      width: 100vw;
      overflow-x: scroll;
      padding: 0;
      margin: 0; }
    .order-kamon .react-tabs {
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.5);
      pointer-events: auto; }
    .order-kamon .react-tabs__tab-list {
      min-width: 100vw;
      list-style: none;
      margin: 14px 0px 0 0px;
      padding: 0; }
    .order-kamon .react-tabs__tab {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      background-color: unset;
      padding: 0 12px;
      line-height: 31px;
      height: 31px;
      border-radius: 20px;
      display: inline-block;
      margin: 0 8px;
      cursor: pointer; }
      .order-kamon .react-tabs__tab--selected {
        color: #ffffff;
        background-color: #333333;
        cursor: unset; }
    .order-kamon .product-container__wrapper {
      width: 100vw;
      height: 168px;
      overflow-x: scroll;
      padding: 14px 0 0 0;
      margin: 0 0 0 0; }
      .order-kamon .product-container__wrapper .react-tabs__tab-panel {
        width: 100vw;
        display: flex;
        justify-content: left; }
    .order-kamon .product-container {
      white-space: nowrap;
      padding: 0 8px;
      margin: 0 auto;
      width: auto;
      display: flex;
      flex-direction: row; }
      .order-kamon .product-container > .product-thumbnail.item {
        margin: 0;
        padding: 0 8px; }
    .order-kamon .tab-string-label .order-color-img {
      width: 170px;
      height: 100%; }
      .order-kamon .tab-string-label .order-color-img .order-color-img-img {
        background-color: skyblue;
        height: calc(60% - 8px);
        margin: 4px;
        width: calc(100% - 8px); }
      .order-kamon .tab-string-label .order-color-img .order-color-img-text {
        font-size: 10px;
        text-align: center;
        height: calc(40% - 4px); }
        .order-kamon .tab-string-label .order-color-img .order-color-img-text .kamon-preview-button {
          background-color: #884595;
          border: none;
          color: #fff;
          padding: 5% 30%;
          font-weight: 100;
          font-size: 12px;
          margin: 0; }
    .order-kamon .tab-string-label .kamon-select-button {
      position: relative;
      width: 136px;
      height: 136px;
      margin: 0 10px;
      border-radius: 16px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      padding: 0;
      overflow: unset;
      display: block;
      background-color: #ffffff; }
      .order-kamon .tab-string-label .kamon-select-button__image-wapper {
        width: 136px;
        height: 72px;
        object-fit: cover;
        border: solid #f0f0f0 1px;
        border-radius: 16px 16px 0 0;
        margin: 0;
        padding: 0;
        text-align: center;
        background-color: #f6f7f9; }
      .order-kamon .tab-string-label .kamon-select-button__image {
        width: auto;
        height: 60px;
        margin-top: 6px; }
      .order-kamon .tab-string-label .kamon-select-button__text {
        width: 127px;
        height: 26px;
        font-size: 11px;
        line-height: 14px;
        font-weight: normal;
        display: block;
        margin: 5px auto 4px auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal; } }
    @media only screen and (max-width: 767px) and (max-width: 767px) {
      .order-kamon .tab-string-label .kamon-select-button__icon-selected {
        display: none;
        position: absolute;
        top: -5px;
        left: -5px; } }

@media screen and (max-width: 767px) {
      .order-kamon .tab-string-label .kamon-select-button__preview-button {
        background-color: #333333;
        font-size: 12px;
        font-weight: normal;
        border: none;
        width: 112px;
        height: 24px;
        line-height: 1em;
        color: #ffffff;
        padding: 0;
        margin: 0 auto;
        border-radius: 30px;
        display: block; }
        .order-kamon .tab-string-label .kamon-select-button__preview-button:hover {
          opacity: 0.75; }
    .order-kamon .tab-string-label input {
      display: none; }
    .order-kamon .tab-string-label .tab-color-switch:checked + .tab-string-label {
      background-color: black; }
    .order-kamon .product-container-text-input {
      width: 100%;
      padding: 0;
      overflow-x: scroll; }
      .order-kamon .product-container-text-input .text-input-label-div {
        display: flex;
        margin: 14vw 8vw 0;
        font-size: 5vw; }
        .order-kamon .product-container-text-input .text-input-label-div .text-input-label-p2 {
          font-size: 3vw;
          margin: 3vw 0 0 11vw; }
      .order-kamon .product-container-text-input .kamon-input-text {
        margin: 0 auto;
        height: 11vw;
        width: 90vw;
        display: block;
        border-radius: 4vw;
        border: solid gray 1px; } }

.horizontal-divider {
  width: 100%;
  border-width: 2px;
  border-color: #dddddd;
  margin: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.zoom-button {
  display: none; }
  @media only screen and (min-width: 768px) {
    .zoom-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      border: none;
      border-radius: 4px;
      background-color: #ffffff;
      overflow: hidden; }
      .zoom-button .btn-zoom-style {
        width: 54px;
        height: 54px;
        border: 0;
        background-color: #ffffff;
        color: #666666;
        font-size: 28px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center; }
        .zoom-button .btn-zoom-style:hover {
          background: #eeeeee; }
        .zoom-button .btn-zoom-style:focus {
          outline: none;
          box-shadow: none; }
      .zoom-button .mt-side-10 {
        width: 46px;
        margin: 0; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.btn-3d {
  border: none;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .btn-3d {
      width: 45px;
      height: 45px; } }
  .btn-3d:hover img[src="/html/template/default/assets/img/common/3d-disable.svg"] {
    opacity: 0.7; }
  .btn-3d:hover img[src="/html/template/default/assets/img/common/3d-enable.svg"] {
    cursor: default; }
  .btn-3d img {
    width: 100%;
    height: 100%; }

.btn-2d {
  border: none;
  padding: 0;
  margin-top: 6px; }
  @media only screen and (max-width: 767px) {
    .btn-2d {
      width: 45px;
      height: 45px;
      margin-top: 5px; } }
  .btn-2d:hover img[src="/html/template/default/assets/img/common/2d-disable.svg"] {
    opacity: 0.7; }
  .btn-2d:hover img[src="/html/template/default/assets/img/common/2d-enable.svg"] {
    cursor: default; }
  .btn-2d img {
    width: 100%;
    height: 100%; }

.dimension-toggle-button {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .dimension-toggle-button {
      margin-bottom: 20px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.product-thumbnail-square {
  display: flex;
  justify-content: center;
  list-style: none; }
  @media only screen and (min-width: 768px) {
    .product-thumbnail-square {
      width: 45%;
      height: 13vw;
      max-height: 181px;
      margin: 1.5%; } }
  @media only screen and (max-width: 767px) {
    .product-thumbnail-square {
      width: 45%;
      height: 100%;
      margin: 0;
      flex-shrink: 0; }
      .product-thumbnail-square label {
        margin-bottom: initial; } }
  .product-thumbnail-square__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaa;
    cursor: pointer;
    height: 100%;
    width: 100%; }
    .product-thumbnail-square__body__images {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60%;
      margin: 4px; }
      .product-thumbnail-square__body__images__thumbnail {
        height: 100%;
        overflow: hidden; }
      .product-thumbnail-square__body__images .product-kamon {
        position: absolute;
        object-fit: cover;
        height: 25%;
        right: 2px;
        bottom: 2px; }
    .product-thumbnail-square__body__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: calc(40% - 4px);
      font-size: 10px; }
    .product-thumbnail-square__body input {
      display: none; }
    .product-thumbnail-square__body:hover {
      border-color: #884595;
      border-width: 2px; }
    .product-thumbnail-square__body.selected {
      border-color: #884595;
      border-width: 2px;
      background-color: #f0f0f0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .order-select-base-layout {
    width: 452px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px; } }

@media only screen and (max-width: 767px) {
  .order-select-base-layout {
    position: absolute;
    top: 42px;
    width: 100vw;
    pointer-events: none; } }

@media only screen and (min-width: 768px) {
  .order-select-base-layout .header {
    height: 59px; } }

@media only screen and (min-width: 768px) {
  .order-select-base-layout .body {
    background: rgba(255, 255, 255, 0.3);
    height: calc(100% - 59px); }
    .order-select-base-layout .body .design-container {
      height: calc(100vh - 59px - 94px); } }

@media only screen and (max-width: 767px) {
  .order-select-base-layout .body {
    position: relative; }
    .order-select-base-layout .body .heading {
      position: absolute;
      top: -14px;
      left: 8px;
      font-size: 12px;
      line-height: 30px;
      padding: 0 14px;
      color: #ffffff;
      background-color: #333333;
      border-radius: 4px; } }

@media only screen and (min-width: 768px) {
  .order-select-base-layout .footer__div {
    padding: 25px;
    height: 94px;
    border-top: 1px solid #e5e5e5;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between; }
    .order-select-base-layout .footer__div .back_to_top {
      height: 44px;
      line-height: 44px;
      color: #ffffff;
      background-color: #333333;
      border: none;
      border-radius: 22px;
      padding: 0 22px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }

@media only screen and (max-width: 767px) {
  .order-select-base-layout .footer__div {
    padding: 4px 16px 14px 16px;
    height: 62px;
    display: flex;
    justify-content: space-between; }
    .order-select-base-layout .footer__div .back_to_top {
      width: auto;
      display: inline-block;
      height: 44px;
      line-height: 44px;
      color: #ffffff;
      background-color: #333333;
      border: none;
      border-radius: 22px;
      padding: 0;
      margin: 0;
      font-size: 12px;
      display: flex; }
    .order-select-base-layout .footer__div .help-button {
      width: auto;
      display: inline-block;
      height: 44px;
      line-height: 44px;
      color: #333333;
      background-color: #efefef;
      border: none;
      border-radius: 22px;
      padding: 0 22px;
      margin: 0;
      font-size: 12px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .order-select-base-layout-2 {
    width: 452px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px; } }

@media only screen and (max-width: 767px) {
  .order-select-base-layout-2 {
    width: 50vw;
    height: calc(100vh - 94px);
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    right: 0px;
    top: 94px; } }

@media only screen and (min-width: 768px) {
  .order-select-base-layout-2 .body {
    height: calc(100% - 59px); } }

@media only screen and (max-width: 767px) {
  .order-select-base-layout-2 .body {
    position: relative;
    z-index: 900;
    height: 100%; }
    .order-select-base-layout-2 .body .heading {
      position: absolute;
      top: -14px;
      left: 8px;
      font-size: 12px;
      line-height: 30px;
      padding: 0 14px;
      color: #ffffff;
      background-color: #333333;
      border-radius: 4px; } }

@media only screen and (max-width: 767px) {
  .order-select-base-layout-2 .footer__div {
    padding: 4px 16px 14px 16px;
    height: 62px;
    display: flex;
    justify-content: space-between; }
    .order-select-base-layout-2 .footer__div .back_to_top {
      width: auto;
      display: inline-block;
      height: 44px;
      line-height: 44px;
      color: #ffffff;
      background-color: #333333;
      border: none;
      border-radius: 22px;
      padding: 0;
      margin: 0;
      font-size: 12px;
      display: flex; }
    .order-select-base-layout-2 .footer__div .help-button {
      width: auto;
      display: inline-block;
      height: 44px;
      line-height: 44px;
      color: #333333;
      background-color: #efefef;
      border: none;
      border-radius: 22px;
      padding: 0 22px;
      margin: 0;
      font-size: 12px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .normal-button {
    background-color: #d13b9d;
    color: #fff;
    border: none;
    padding: 12px 36px 12px 39px;
    border-radius: 4px;
    margin: 20px auto;
    width: 135px; } }

@media only screen and (max-width: 767px) {
  .normal-button {
    background-color: #d13b9d;
    color: #fff;
    border: none;
    padding: 12px 36px 12px 39px;
    border-radius: 4px;
    margin: 20px auto;
    width: 135px; } }

.cancel {
  opacity: 50%; }

.primary-button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #ffffff;
  background-color: #d13b9d;
  border-color: #ccc;
  border: none;
  font-weight: normal;
  border-radius: 4px;
  height: 44px;
  padding: 12px 20px 12px 20px;
  text-align: center; }
  .primary-button:focus, .primary-button.focus, .primary-button:active:focus, .primary-button:active.focus, .primary-button.active:focus, .primary-button.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .primary-button:active, .primary-button.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .primary-button.disabled, .primary-button[disabled],
  fieldset[disabled] .primary-button {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .primary-button:focus, .primary-button.focus {
    color: #ffffff;
    background-color: #b02981;
    border-color: #8c8c8c; }
  .primary-button:hover {
    color: #ffffff;
    background-color: #b02981;
    border-color: #adadad; }
  .primary-button:active, .primary-button.active,
  .open > .primary-button.dropdown-toggle {
    color: #ffffff;
    background-color: #b02981;
    background-image: none;
    border-color: #adadad; }
    .primary-button:active:hover, .primary-button:active:focus, .primary-button:active.focus, .primary-button.active:hover, .primary-button.active:focus, .primary-button.active.focus,
    .open > .primary-button.dropdown-toggle:hover,
    .open > .primary-button.dropdown-toggle:focus,
    .open > .primary-button.dropdown-toggle.focus {
      color: #ffffff;
      background-color: #93226c;
      border-color: #8c8c8c; }
  .primary-button.disabled:hover, .primary-button.disabled:focus, .primary-button.disabled.focus, .primary-button[disabled]:hover, .primary-button[disabled]:focus, .primary-button[disabled].focus,
  fieldset[disabled] .primary-button:hover,
  fieldset[disabled] .primary-button:focus,
  fieldset[disabled] .primary-button.focus {
    background-color: #d13b9d;
    border-color: #ccc; }
  .primary-button .badge {
    color: #d13b9d;
    background-color: #ffffff; }
  .primary-button.dark {
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 16px;
    text-decoration: none;
    color: #ffffff;
    background-color: #333333;
    border-color: #ccc;
    border: none;
    font-weight: normal;
    border-radius: 4px;
    height: 44px; }
    .primary-button.dark:focus, .primary-button.dark.focus, .primary-button.dark:active:focus, .primary-button.dark:active.focus, .primary-button.dark.active:focus, .primary-button.dark.active.focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .primary-button.dark:active, .primary-button.dark.active {
      outline: 0;
      background-image: none;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .primary-button.dark.disabled, .primary-button.dark[disabled],
    fieldset[disabled] .primary-button.dark {
      cursor: not-allowed;
      filter: alpha(opacity=65);
      opacity: 0.65;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .primary-button.dark:focus, .primary-button.dark.focus {
      color: #ffffff;
      background-color: #1a1a1a;
      border-color: #8c8c8c; }
    .primary-button.dark:hover {
      color: #ffffff;
      background-color: #1a1a1a;
      border-color: #adadad; }
    .primary-button.dark:active, .primary-button.dark.active,
    .open > .primary-button.dark.dropdown-toggle {
      color: #ffffff;
      background-color: #1a1a1a;
      background-image: none;
      border-color: #adadad; }
      .primary-button.dark:active:hover, .primary-button.dark:active:focus, .primary-button.dark:active.focus, .primary-button.dark.active:hover, .primary-button.dark.active:focus, .primary-button.dark.active.focus,
      .open > .primary-button.dark.dropdown-toggle:hover,
      .open > .primary-button.dark.dropdown-toggle:focus,
      .open > .primary-button.dark.dropdown-toggle.focus {
        color: #ffffff;
        background-color: #080808;
        border-color: #8c8c8c; }
    .primary-button.dark.disabled:hover, .primary-button.dark.disabled:focus, .primary-button.dark.disabled.focus, .primary-button.dark[disabled]:hover, .primary-button.dark[disabled]:focus, .primary-button.dark[disabled].focus,
    fieldset[disabled] .primary-button.dark:hover,
    fieldset[disabled] .primary-button.dark:focus,
    fieldset[disabled] .primary-button.dark.focus {
      background-color: #333333;
      border-color: #ccc; }
    .primary-button.dark .badge {
      color: #333333;
      background-color: #ffffff; }
  .primary-button.radius-170 {
    border-radius: 170px; }

.toggle-wrapper .toggleContainer {
  width: 100%;
  position: relative;
  background: #cbcbcb;
  border-radius: 4px; }
  .toggle-wrapper .toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 600;
    text-align: center;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    color: #ffffff;
    font-size: 1em;
    background-color: unset;
    padding: unset;
    border: none; }
    .toggle-wrapper .toggleContainer .toggleOption:hover {
      color: #333; }
    .toggle-wrapper .toggleContainer .toggleOption.selected {
      color: #ffffff;
      cursor: initial; }
      .toggle-wrapper .toggleContainer .toggleOption.selected:hover {
        color: #ffffff; }
  .toggle-wrapper .toggleContainer .toggle {
    position: absolute;
    height: calc(100% - 8px);
    width: calc(50% - 4px) !important;
    bottom: 0;
    left: 0;
    transition: all 0.4s ease-in-out;
    background: #333333;
    border-radius: 4px;
    margin: 4px; }

.toggle-button-area {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 9px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .toggle-button-area--divider {
    border-color: #000000;
    opacity: 0.1;
    margin: 10px 0px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.arrow-thumbnail-3d {
  list-style: none;
  position: relative;
  width: 335px;
  height: 286px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  border-radius: 24px;
  background-image: url(/html/template/default/assets/img/order/thumbnail-background@2x.png);
  background-size: cover;
  user-select: none; }
  .arrow-thumbnail-3d__body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 24px; }
  .arrow-thumbnail-3d__close-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: unset;
    padding: 0px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    background-color: #ffffff;
    color: #333333; }
    .arrow-thumbnail-3d__close-btn:hover {
      background-color: #f5f5f5;
      color: #333333; }
    .arrow-thumbnail-3d__close-btn .arrow-thumbnail-3d__close-btn__img {
      width: auto;
      height: 18.62px;
      margin: 0 0 2px 0px;
      display: inline-block; }
    @media (hover: hover) {
      .arrow-thumbnail-3d__close-btn {
        visibility: hidden; } }
  .arrow-thumbnail-3d:hover .arrow-thumbnail-3d__close-btn {
    visibility: visible; }
  .arrow-thumbnail-3d__customize-btn {
    position: absolute;
    z-index: 600;
    border-radius: 40px;
    color: #ffffff;
    background-color: #333333;
    border: unset;
    padding: 0 20px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 600; }
    @media screen and (min-width: 768px) {
      .arrow-thumbnail-3d__customize-btn {
        top: -10px;
        left: -10px; } }
    @media only screen and (max-width: 767px) {
      .arrow-thumbnail-3d__customize-btn {
        bottom: 10px;
        right: 10px; } }
    .arrow-thumbnail-3d__customize-btn:hover {
      color: #ffffff;
      background: #4B4B4B; }
    @media only screen and (max-width: 767px) {
      .arrow-thumbnail-3d__customize-btn {
        display: inline-block; } }
    .arrow-thumbnail-3d__customize-btn .arrow-thumbnail-3d__customize-btn__img {
      width: auto;
      height: 17px;
      margin: 0 0 3px 11.7px;
      display: inline-block; }
  @media only screen and (min-width: 768px) {
    .arrow-thumbnail-3d__selectedIcon {
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 600;
      border-radius: 40px;
      background-color: #333333;
      color: #ffffff;
      border: unset;
      padding: 0 20px;
      line-height: 44px;
      font-size: 14px;
      font-weight: 600;
      display: inline-block; } }
  @media only screen and (max-width: 767px) {
    .arrow-thumbnail-3d__selectedIcon {
      display: none; } }
  .arrow-thumbnail-3d__add-cart-btn {
    position: absolute;
    z-index: 600;
    border-radius: 40px;
    border: unset;
    padding: 0 20px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    background-color: #ffffff;
    color: #333333; }
    @media screen and (min-width: 768px) {
      .arrow-thumbnail-3d__add-cart-btn {
        bottom: 16px;
        right: 16px; } }
    @media only screen and (max-width: 767px) {
      .arrow-thumbnail-3d__add-cart-btn {
        top: 10px;
        left: 10px; } }
    .arrow-thumbnail-3d__add-cart-btn:hover {
      background-color: #f5f5f5;
      color: #333333; }
    .arrow-thumbnail-3d__add-cart-btn .arrow-thumbnail-3d__add-cart-btn__img {
      width: auto;
      height: 22.86px;
      margin: 0 0 5px 7.9px;
      display: inline-block; }
  .arrow-thumbnail-3d__title {
    width: 325px;
    margin: 12px auto 0 auto;
    font-size: 19px;
    color: #333333;
    font-weight: bold;
    line-height: 1em; }
  .arrow-thumbnail-3d__detail-text {
    width: 325px;
    margin: 7px auto 40px auto;
    font-size: 12px;
    color: #333333;
    line-height: 1.5em; }
  @media only screen and (min-width: 768px) {
    .arrow-thumbnail-3d:hover .arrow-thumbnail-3d__filter {
      display: inline-block; }
    .arrow-thumbnail-3d:hover .arrow-thumbnail-3d__close-btn {
      display: inline-block; } }

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .arrow-thumbnail-3d:hover .arrow-thumbnail-3d__customize-btn {
    display: inline-block; } }
  @media only screen and (min-width: 768px) {
    .arrow-thumbnail-3d:hover .arrow-thumbnail-3d__add-cart-btn {
      display: inline-block; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media screen and (min-width: 768px) {
  .SelectModal .modal_overlay {
    position: fixed;
    z-index: 1150;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    justify-content: flex-end; }
    .SelectModal .modal_overlay .batsu {
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      z-index: 1250;
      top: 0;
      left: 0;
      padding: 0 0 100vh calc(50vw - 40px); }
    .SelectModal .modal_overlay .content {
      overflow-x: scroll;
      z-index: 1250;
      padding: 1em;
      background: #fff;
      text-align: center;
      width: 50vw; }
      .SelectModal .modal_overlay .content .kamon-select-modal__div {
        text-align: left;
        display: flex;
        flex-wrap: wrap; }
      .SelectModal .modal_overlay .content h2 {
        font-size: 20px;
        font-weight: 600;
        border-bottom: solid black 2px; } }

@media screen and (max-width: 767px) {
  .SelectModal .modal_overlay {
    position: fixed;
    z-index: 1150;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    justify-content: flex-end; }
    .SelectModal .modal_overlay .batsu {
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      z-index: 1250;
      top: 0;
      left: 0;
      padding: 0 0 100vh calc(100vw - 80vw - 40px); }
    .SelectModal .modal_overlay .content {
      overflow-x: scroll;
      z-index: 1250;
      padding: 1em;
      background: #fff;
      text-align: center;
      width: 80vw; }
      .SelectModal .modal_overlay .content .kamon-select-modal h1 {
        text-align: left;
        margin: 2% 1.5%; }
      .SelectModal .modal_overlay .content .kamon-select-modal__div {
        text-align: left;
        display: flex;
        flex-wrap: wrap; }
      .SelectModal .modal_overlay .content h2 {
        font-size: 20px;
        font-weight: 600;
        border-bottom: solid black 2px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.display-inline-block {
  display: inline-block; }

@media only screen and (min-width: 768px) {
  .product-thumbnail-yesorno {
    width: 191px;
    height: 191px;
    cursor: pointer;
    margin: 10px;
    border-radius: 0px;
    padding: 11px;
    position: relative;
    display: block;
    background-color: rgba(255, 255, 255, 0.3); } }

@media only screen and (min-width: 768px) {
  .product-thumbnail-yesorno:hover {
    background-color: rgba(255, 255, 255, 0.45); } }

@media screen and (min-width: 1305px) {
  .product-thumbnail-yesorno {
    height: 208px; } }

@media only screen and (max-width: 767px) {
  .product-thumbnail-yesorno {
    position: relative;
    width: 136px;
    height: 136px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 16px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0;
    overflow: unset;
    display: block;
    background-color: #ffffff; } }

.product-thumbnail-yesorno input {
  display: none; }

@media only screen and (min-width: 768px) {
  .product-thumbnail-yesorno__svg {
    width: 169px;
    height: 113px;
    object-fit: cover;
    border: solid #f0f0f0 1px;
    border-radius: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f6f7f9;
    display: flex;
    align-items: center;
    justify-content: center; }
    .product-thumbnail-yesorno__svg img {
      width: auto;
      height: 87px;
      margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .product-thumbnail-yesorno__svg {
    width: 136px;
    height: 93px;
    object-fit: cover;
    border: solid #f0f0f0 1px;
    border-radius: 16px 16px 0 0;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f6f7f9; }
    .product-thumbnail-yesorno__svg img {
      width: auto;
      height: 68px;
      margin-top: 12.5px; } }

@media only screen and (min-width: 768px) {
  .product-thumbnail-yesorno__catch-text {
    width: 167px;
    height: 111px;
    display: inline-block;
    overflow: hidden;
    object-fit: cover;
    border: solid #f0f0f0 1px;
    border-radius: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f6f7f9;
    line-height: 111px;
    font-size: 27px;
    font-weight: bold; } }

@media only screen and (max-width: 767px) {
  .product-thumbnail-yesorno__catch-text {
    width: 136px;
    height: 93px;
    object-fit: cover;
    border: solid #f0f0f0 1px;
    border-radius: 16px 16px 0 0;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f6f7f9;
    line-height: 93px;
    font-size: 21px;
    font-weight: bold;
    display: block;
    overflow: hidden; } }

@media only screen and (min-width: 768px) {
  .product-thumbnail-yesorno__text1, .product-thumbnail-yesorno__text2 {
    width: 100%;
    height: 45px;
    font-size: 11px;
    line-height: 15px;
    font-weight: normal;
    display: block;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden; } }

@media only screen and (max-width: 767px) {
  .product-thumbnail-yesorno__text1, .product-thumbnail-yesorno__text2 {
    width: auto;
    height: 36px;
    font-size: 11px;
    line-height: 12px;
    font-weight: normal;
    display: block;
    margin: 4px 10px 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal; } }

.product-thumbnail-yesorno__icon-selected {
  display: none;
  position: absolute;
  top: 6px;
  left: 6px; }
  @media only screen and (max-width: 767px) {
    .product-thumbnail-yesorno__icon-selected {
      top: -5px;
      left: -5px; } }

.product-thumbnail-yesorno.selected .product-thumbnail-yesorno__icon-selected {
  display: block; }
  .product-thumbnail-yesorno.selected .product-thumbnail-yesorno__icon-selected img {
    display: block; }

.kamon-select-disable {
  opacity: 0.5;
  pointer-events: none; }

.product-thumbnail-underbar {
  width: 132px;
  border: solid #707070 1px;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .product-thumbnail-underbar {
      width: 140px; } }
  .product-thumbnail-underbar:is(.nonselectable) {
    cursor: unset; }
  .product-thumbnail-underbar .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .product-thumbnail-underbar .body img {
      border: none;
      object-fit: cover;
      margin-top: 20px;
      width: 100%; }
    .product-thumbnail-underbar .body span {
      font-size: 16px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center; }
  .product-thumbnail-underbar.selected {
    border-color: #884595;
    border-width: 2px; }
  .product-thumbnail-underbar:not(.selected):not(.nonselectable):hover {
    opacity: 0.75; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.new-recommend-button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #b78fbf;
  border-color: #ccc;
  border: none;
  font-weight: normal;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  .new-recommend-button:focus, .new-recommend-button.focus, .new-recommend-button:active:focus, .new-recommend-button:active.focus, .new-recommend-button.active:focus, .new-recommend-button.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .new-recommend-button:hover, .new-recommend-button:focus, .new-recommend-button.focus {
    color: #525263;
    text-decoration: none; }
  .new-recommend-button:active, .new-recommend-button.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .new-recommend-button.disabled, .new-recommend-button[disabled],
  fieldset[disabled] .new-recommend-button {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .new-recommend-button:focus, .new-recommend-button.focus {
    color: #fff;
    background-color: #a26fac;
    border-color: #8c8c8c; }
  .new-recommend-button:hover {
    color: #fff;
    background-color: #a26fac;
    border-color: #adadad; }
  .new-recommend-button:active, .new-recommend-button.active,
  .open > .new-recommend-button.dropdown-toggle {
    color: #fff;
    background-color: #a26fac;
    background-image: none;
    border-color: #adadad; }
    .new-recommend-button:active:hover, .new-recommend-button:active:focus, .new-recommend-button:active.focus, .new-recommend-button.active:hover, .new-recommend-button.active:focus, .new-recommend-button.active.focus,
    .open > .new-recommend-button.dropdown-toggle:hover,
    .open > .new-recommend-button.dropdown-toggle:focus,
    .open > .new-recommend-button.dropdown-toggle.focus {
      color: #fff;
      background-color: #925a9d;
      border-color: #8c8c8c; }
  .new-recommend-button.disabled:hover, .new-recommend-button.disabled:focus, .new-recommend-button.disabled.focus, .new-recommend-button[disabled]:hover, .new-recommend-button[disabled]:focus, .new-recommend-button[disabled].focus,
  fieldset[disabled] .new-recommend-button:hover,
  fieldset[disabled] .new-recommend-button:focus,
  fieldset[disabled] .new-recommend-button.focus {
    background-color: #b78fbf;
    border-color: #ccc; }
  .new-recommend-button .badge {
    color: #b78fbf;
    background-color: #fff; }
  .new-recommend-button .ec-icon img {
    width: 1em;
    vertical-align: text-bottom; }
  .new-recommend-button[direction='horizontal'] {
    flex-direction: row; }
  .new-recommend-button[direction='horizontal'] .new-recommend-button__icon {
    padding-right: 4px; }
  .new-recommend-button[direction='vertical'] {
    flex-direction: column; }
  .new-recommend-button[direction='vertical'] .new-recommend-button__icon {
    padding-bottom: 0.5%; }
  .new-recommend-button[direction='vertical'] .new-recommend-button__title {
    padding-top: 0.5%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.zero-new-recommend-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 40px;
  padding-right: 40px; }
  @media only screen and (max-width: 767px) {
    .zero-new-recommend-button {
      padding-top: 7%;
      padding-bottom: 7%;
      padding-left: 12px;
      padding-right: 12px; } }
  .zero-new-recommend-button__title {
    font-weight: 600;
    font-size: 16px; }
    @media only screen and (max-width: 320px) {
      .zero-new-recommend-button__title {
        font-size: 14px; } }
  .zero-new-recommend-button__btn {
    margin-top: 3%; }

.delete-design-dialog {
  width: 315px; }
  .delete-design-dialog .Modal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 315px;
    user-select: none; }
  .delete-design-dialog .title {
    padding: 1em;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    user-select: none; }
  .delete-design-dialog .sub-title {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 14px;
    font-weight: 300;
    user-select: none; }
  .delete-design-dialog .disable-touch {
    margin: 1em;
    pointer-events: none; }
  .delete-design-dialog .arrow-thumbnail-3d {
    width: 130px;
    height: 130px; }
    .delete-design-dialog .arrow-thumbnail-3d__selectedIcon {
      display: none; }
  .delete-design-dialog .footer {
    display: flex;
    flex-direction: column;
    width: 45%; }
    .delete-design-dialog .footer .delete {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #ffffff;
      background-color: #d13b9d;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px; }
      .delete-design-dialog .footer .delete:focus, .delete-design-dialog .footer .delete.focus, .delete-design-dialog .footer .delete:active:focus, .delete-design-dialog .footer .delete:active.focus, .delete-design-dialog .footer .delete.active:focus, .delete-design-dialog .footer .delete.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .delete-design-dialog .footer .delete:active, .delete-design-dialog .footer .delete.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .delete-design-dialog .footer .delete.disabled, .delete-design-dialog .footer .delete[disabled],
      fieldset[disabled] .delete-design-dialog .footer .delete {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .delete-design-dialog .footer .delete:focus, .delete-design-dialog .footer .delete.focus {
        color: #ffffff;
        background-color: #b02981;
        border-color: #8c8c8c; }
      .delete-design-dialog .footer .delete:hover {
        color: #ffffff;
        background-color: #b02981;
        border-color: #adadad; }
      .delete-design-dialog .footer .delete:active, .delete-design-dialog .footer .delete.active,
      .open > .delete-design-dialog .footer .delete.dropdown-toggle {
        color: #ffffff;
        background-color: #b02981;
        background-image: none;
        border-color: #adadad; }
        .delete-design-dialog .footer .delete:active:hover, .delete-design-dialog .footer .delete:active:focus, .delete-design-dialog .footer .delete:active.focus, .delete-design-dialog .footer .delete.active:hover, .delete-design-dialog .footer .delete.active:focus, .delete-design-dialog .footer .delete.active.focus,
        .open > .delete-design-dialog .footer .delete.dropdown-toggle:hover,
        .open > .delete-design-dialog .footer .delete.dropdown-toggle:focus,
        .open > .delete-design-dialog .footer .delete.dropdown-toggle.focus {
          color: #ffffff;
          background-color: #93226c;
          border-color: #8c8c8c; }
      .delete-design-dialog .footer .delete.disabled:hover, .delete-design-dialog .footer .delete.disabled:focus, .delete-design-dialog .footer .delete.disabled.focus, .delete-design-dialog .footer .delete[disabled]:hover, .delete-design-dialog .footer .delete[disabled]:focus, .delete-design-dialog .footer .delete[disabled].focus,
      fieldset[disabled] .delete-design-dialog .footer .delete:hover,
      fieldset[disabled] .delete-design-dialog .footer .delete:focus,
      fieldset[disabled] .delete-design-dialog .footer .delete.focus {
        background-color: #d13b9d;
        border-color: #ccc; }
      .delete-design-dialog .footer .delete .badge {
        color: #d13b9d;
        background-color: #ffffff; }
    .delete-design-dialog .footer .cancel {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #333333;
      background-color: #f0f0f0;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px; }
      .delete-design-dialog .footer .cancel:focus, .delete-design-dialog .footer .cancel.focus, .delete-design-dialog .footer .cancel:active:focus, .delete-design-dialog .footer .cancel:active.focus, .delete-design-dialog .footer .cancel.active:focus, .delete-design-dialog .footer .cancel.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .delete-design-dialog .footer .cancel:active, .delete-design-dialog .footer .cancel.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .delete-design-dialog .footer .cancel.disabled, .delete-design-dialog .footer .cancel[disabled],
      fieldset[disabled] .delete-design-dialog .footer .cancel {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .delete-design-dialog .footer .cancel:focus, .delete-design-dialog .footer .cancel.focus {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #8c8c8c; }
      .delete-design-dialog .footer .cancel:hover {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #adadad; }
      .delete-design-dialog .footer .cancel:active, .delete-design-dialog .footer .cancel.active,
      .open > .delete-design-dialog .footer .cancel.dropdown-toggle {
        color: #333333;
        background-color: #d7d7d7;
        background-image: none;
        border-color: #adadad; }
        .delete-design-dialog .footer .cancel:active:hover, .delete-design-dialog .footer .cancel:active:focus, .delete-design-dialog .footer .cancel:active.focus, .delete-design-dialog .footer .cancel.active:hover, .delete-design-dialog .footer .cancel.active:focus, .delete-design-dialog .footer .cancel.active.focus,
        .open > .delete-design-dialog .footer .cancel.dropdown-toggle:hover,
        .open > .delete-design-dialog .footer .cancel.dropdown-toggle:focus,
        .open > .delete-design-dialog .footer .cancel.dropdown-toggle.focus {
          color: #333333;
          background-color: #c5c5c5;
          border-color: #8c8c8c; }
      .delete-design-dialog .footer .cancel.disabled:hover, .delete-design-dialog .footer .cancel.disabled:focus, .delete-design-dialog .footer .cancel.disabled.focus, .delete-design-dialog .footer .cancel[disabled]:hover, .delete-design-dialog .footer .cancel[disabled]:focus, .delete-design-dialog .footer .cancel[disabled].focus,
      fieldset[disabled] .delete-design-dialog .footer .cancel:hover,
      fieldset[disabled] .delete-design-dialog .footer .cancel:focus,
      fieldset[disabled] .delete-design-dialog .footer .cancel.focus {
        background-color: #f0f0f0;
        border-color: #ccc; }
      .delete-design-dialog .footer .cancel .badge {
        color: #f0f0f0;
        background-color: #333333; }

.cart-in-dialog {
  width: 315px; }
  .cart-in-dialog .Modal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    width: 315px;
    user-select: none; }
  .cart-in-dialog .title {
    padding-bottom: 1.5em;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    user-select: none; }
  .cart-in-dialog .footer {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .cart-in-dialog .footer .next {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #333333;
      background-color: #f0f0f0;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px; }
      .cart-in-dialog .footer .next:focus, .cart-in-dialog .footer .next.focus, .cart-in-dialog .footer .next:active:focus, .cart-in-dialog .footer .next:active.focus, .cart-in-dialog .footer .next.active:focus, .cart-in-dialog .footer .next.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .cart-in-dialog .footer .next:active, .cart-in-dialog .footer .next.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .cart-in-dialog .footer .next.disabled, .cart-in-dialog .footer .next[disabled],
      fieldset[disabled] .cart-in-dialog .footer .next {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .cart-in-dialog .footer .next:focus, .cart-in-dialog .footer .next.focus {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #8c8c8c; }
      .cart-in-dialog .footer .next:hover {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #adadad; }
      .cart-in-dialog .footer .next:active, .cart-in-dialog .footer .next.active,
      .open > .cart-in-dialog .footer .next.dropdown-toggle {
        color: #333333;
        background-color: #d7d7d7;
        background-image: none;
        border-color: #adadad; }
        .cart-in-dialog .footer .next:active:hover, .cart-in-dialog .footer .next:active:focus, .cart-in-dialog .footer .next:active.focus, .cart-in-dialog .footer .next.active:hover, .cart-in-dialog .footer .next.active:focus, .cart-in-dialog .footer .next.active.focus,
        .open > .cart-in-dialog .footer .next.dropdown-toggle:hover,
        .open > .cart-in-dialog .footer .next.dropdown-toggle:focus,
        .open > .cart-in-dialog .footer .next.dropdown-toggle.focus {
          color: #333333;
          background-color: #c5c5c5;
          border-color: #8c8c8c; }
      .cart-in-dialog .footer .next.disabled:hover, .cart-in-dialog .footer .next.disabled:focus, .cart-in-dialog .footer .next.disabled.focus, .cart-in-dialog .footer .next[disabled]:hover, .cart-in-dialog .footer .next[disabled]:focus, .cart-in-dialog .footer .next[disabled].focus,
      fieldset[disabled] .cart-in-dialog .footer .next:hover,
      fieldset[disabled] .cart-in-dialog .footer .next:focus,
      fieldset[disabled] .cart-in-dialog .footer .next.focus {
        background-color: #f0f0f0;
        border-color: #ccc; }
      .cart-in-dialog .footer .next .badge {
        color: #f0f0f0;
        background-color: #333333; }
    .cart-in-dialog .footer .to-ec {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #333333;
      background-color: #f0f0f0;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px;
      margin-top: 24px; }
      .cart-in-dialog .footer .to-ec:focus, .cart-in-dialog .footer .to-ec.focus, .cart-in-dialog .footer .to-ec:active:focus, .cart-in-dialog .footer .to-ec:active.focus, .cart-in-dialog .footer .to-ec.active:focus, .cart-in-dialog .footer .to-ec.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .cart-in-dialog .footer .to-ec:active, .cart-in-dialog .footer .to-ec.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .cart-in-dialog .footer .to-ec.disabled, .cart-in-dialog .footer .to-ec[disabled],
      fieldset[disabled] .cart-in-dialog .footer .to-ec {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .cart-in-dialog .footer .to-ec:focus, .cart-in-dialog .footer .to-ec.focus {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #8c8c8c; }
      .cart-in-dialog .footer .to-ec:hover {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #adadad; }
      .cart-in-dialog .footer .to-ec:active, .cart-in-dialog .footer .to-ec.active,
      .open > .cart-in-dialog .footer .to-ec.dropdown-toggle {
        color: #333333;
        background-color: #d7d7d7;
        background-image: none;
        border-color: #adadad; }
        .cart-in-dialog .footer .to-ec:active:hover, .cart-in-dialog .footer .to-ec:active:focus, .cart-in-dialog .footer .to-ec:active.focus, .cart-in-dialog .footer .to-ec.active:hover, .cart-in-dialog .footer .to-ec.active:focus, .cart-in-dialog .footer .to-ec.active.focus,
        .open > .cart-in-dialog .footer .to-ec.dropdown-toggle:hover,
        .open > .cart-in-dialog .footer .to-ec.dropdown-toggle:focus,
        .open > .cart-in-dialog .footer .to-ec.dropdown-toggle.focus {
          color: #333333;
          background-color: #c5c5c5;
          border-color: #8c8c8c; }
      .cart-in-dialog .footer .to-ec.disabled:hover, .cart-in-dialog .footer .to-ec.disabled:focus, .cart-in-dialog .footer .to-ec.disabled.focus, .cart-in-dialog .footer .to-ec[disabled]:hover, .cart-in-dialog .footer .to-ec[disabled]:focus, .cart-in-dialog .footer .to-ec[disabled].focus,
      fieldset[disabled] .cart-in-dialog .footer .to-ec:hover,
      fieldset[disabled] .cart-in-dialog .footer .to-ec:focus,
      fieldset[disabled] .cart-in-dialog .footer .to-ec.focus {
        background-color: #f0f0f0;
        border-color: #ccc; }
      .cart-in-dialog .footer .to-ec .badge {
        color: #f0f0f0;
        background-color: #333333; }
    .cart-in-dialog .footer .to-cart {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #ffffff;
      background-color: #d13b9d;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px;
      margin-top: 24px; }
      .cart-in-dialog .footer .to-cart:focus, .cart-in-dialog .footer .to-cart.focus, .cart-in-dialog .footer .to-cart:active:focus, .cart-in-dialog .footer .to-cart:active.focus, .cart-in-dialog .footer .to-cart.active:focus, .cart-in-dialog .footer .to-cart.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .cart-in-dialog .footer .to-cart:active, .cart-in-dialog .footer .to-cart.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .cart-in-dialog .footer .to-cart.disabled, .cart-in-dialog .footer .to-cart[disabled],
      fieldset[disabled] .cart-in-dialog .footer .to-cart {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .cart-in-dialog .footer .to-cart:focus, .cart-in-dialog .footer .to-cart.focus {
        color: #ffffff;
        background-color: #b02981;
        border-color: #8c8c8c; }
      .cart-in-dialog .footer .to-cart:hover {
        color: #ffffff;
        background-color: #b02981;
        border-color: #adadad; }
      .cart-in-dialog .footer .to-cart:active, .cart-in-dialog .footer .to-cart.active,
      .open > .cart-in-dialog .footer .to-cart.dropdown-toggle {
        color: #ffffff;
        background-color: #b02981;
        background-image: none;
        border-color: #adadad; }
        .cart-in-dialog .footer .to-cart:active:hover, .cart-in-dialog .footer .to-cart:active:focus, .cart-in-dialog .footer .to-cart:active.focus, .cart-in-dialog .footer .to-cart.active:hover, .cart-in-dialog .footer .to-cart.active:focus, .cart-in-dialog .footer .to-cart.active.focus,
        .open > .cart-in-dialog .footer .to-cart.dropdown-toggle:hover,
        .open > .cart-in-dialog .footer .to-cart.dropdown-toggle:focus,
        .open > .cart-in-dialog .footer .to-cart.dropdown-toggle.focus {
          color: #ffffff;
          background-color: #93226c;
          border-color: #8c8c8c; }
      .cart-in-dialog .footer .to-cart.disabled:hover, .cart-in-dialog .footer .to-cart.disabled:focus, .cart-in-dialog .footer .to-cart.disabled.focus, .cart-in-dialog .footer .to-cart[disabled]:hover, .cart-in-dialog .footer .to-cart[disabled]:focus, .cart-in-dialog .footer .to-cart[disabled].focus,
      fieldset[disabled] .cart-in-dialog .footer .to-cart:hover,
      fieldset[disabled] .cart-in-dialog .footer .to-cart:focus,
      fieldset[disabled] .cart-in-dialog .footer .to-cart.focus {
        background-color: #d13b9d;
        border-color: #ccc; }
      .cart-in-dialog .footer .to-cart .badge {
        color: #d13b9d;
        background-color: #ffffff; }

.shaft-change-nock-yaziri-dialog {
  width: 315px; }
  .shaft-change-nock-yaziri-dialog .Modal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    width: 315px;
    user-select: none; }
  .shaft-change-nock-yaziri-dialog .title {
    border-bottom: 1px black solid;
    margin: 0 0 1.5em 0;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    user-select: none; }
  .shaft-change-nock-yaziri-dialog .footer {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .shaft-change-nock-yaziri-dialog .footer .change {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #ffffff;
      background-color: #d13b9d;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px;
      margin-top: 24px; }
      .shaft-change-nock-yaziri-dialog .footer .change:focus, .shaft-change-nock-yaziri-dialog .footer .change.focus, .shaft-change-nock-yaziri-dialog .footer .change:active:focus, .shaft-change-nock-yaziri-dialog .footer .change:active.focus, .shaft-change-nock-yaziri-dialog .footer .change.active:focus, .shaft-change-nock-yaziri-dialog .footer .change.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .shaft-change-nock-yaziri-dialog .footer .change:active, .shaft-change-nock-yaziri-dialog .footer .change.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .shaft-change-nock-yaziri-dialog .footer .change.disabled, .shaft-change-nock-yaziri-dialog .footer .change[disabled],
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .change {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .shaft-change-nock-yaziri-dialog .footer .change:focus, .shaft-change-nock-yaziri-dialog .footer .change.focus {
        color: #ffffff;
        background-color: #b02981;
        border-color: #8c8c8c; }
      .shaft-change-nock-yaziri-dialog .footer .change:hover {
        color: #ffffff;
        background-color: #b02981;
        border-color: #adadad; }
      .shaft-change-nock-yaziri-dialog .footer .change:active, .shaft-change-nock-yaziri-dialog .footer .change.active,
      .open > .shaft-change-nock-yaziri-dialog .footer .change.dropdown-toggle {
        color: #ffffff;
        background-color: #b02981;
        background-image: none;
        border-color: #adadad; }
        .shaft-change-nock-yaziri-dialog .footer .change:active:hover, .shaft-change-nock-yaziri-dialog .footer .change:active:focus, .shaft-change-nock-yaziri-dialog .footer .change:active.focus, .shaft-change-nock-yaziri-dialog .footer .change.active:hover, .shaft-change-nock-yaziri-dialog .footer .change.active:focus, .shaft-change-nock-yaziri-dialog .footer .change.active.focus,
        .open > .shaft-change-nock-yaziri-dialog .footer .change.dropdown-toggle:hover,
        .open > .shaft-change-nock-yaziri-dialog .footer .change.dropdown-toggle:focus,
        .open > .shaft-change-nock-yaziri-dialog .footer .change.dropdown-toggle.focus {
          color: #ffffff;
          background-color: #93226c;
          border-color: #8c8c8c; }
      .shaft-change-nock-yaziri-dialog .footer .change.disabled:hover, .shaft-change-nock-yaziri-dialog .footer .change.disabled:focus, .shaft-change-nock-yaziri-dialog .footer .change.disabled.focus, .shaft-change-nock-yaziri-dialog .footer .change[disabled]:hover, .shaft-change-nock-yaziri-dialog .footer .change[disabled]:focus, .shaft-change-nock-yaziri-dialog .footer .change[disabled].focus,
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .change:hover,
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .change:focus,
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .change.focus {
        background-color: #d13b9d;
        border-color: #ccc; }
      .shaft-change-nock-yaziri-dialog .footer .change .badge {
        color: #d13b9d;
        background-color: #ffffff; }
    .shaft-change-nock-yaziri-dialog .footer .no-change {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #333333;
      background-color: #f0f0f0;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px;
      margin-top: 24px; }
      .shaft-change-nock-yaziri-dialog .footer .no-change:focus, .shaft-change-nock-yaziri-dialog .footer .no-change.focus, .shaft-change-nock-yaziri-dialog .footer .no-change:active:focus, .shaft-change-nock-yaziri-dialog .footer .no-change:active.focus, .shaft-change-nock-yaziri-dialog .footer .no-change.active:focus, .shaft-change-nock-yaziri-dialog .footer .no-change.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .shaft-change-nock-yaziri-dialog .footer .no-change:active, .shaft-change-nock-yaziri-dialog .footer .no-change.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .shaft-change-nock-yaziri-dialog .footer .no-change.disabled, .shaft-change-nock-yaziri-dialog .footer .no-change[disabled],
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .no-change {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .shaft-change-nock-yaziri-dialog .footer .no-change:focus, .shaft-change-nock-yaziri-dialog .footer .no-change.focus {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #8c8c8c; }
      .shaft-change-nock-yaziri-dialog .footer .no-change:hover {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #adadad; }
      .shaft-change-nock-yaziri-dialog .footer .no-change:active, .shaft-change-nock-yaziri-dialog .footer .no-change.active,
      .open > .shaft-change-nock-yaziri-dialog .footer .no-change.dropdown-toggle {
        color: #333333;
        background-color: #d7d7d7;
        background-image: none;
        border-color: #adadad; }
        .shaft-change-nock-yaziri-dialog .footer .no-change:active:hover, .shaft-change-nock-yaziri-dialog .footer .no-change:active:focus, .shaft-change-nock-yaziri-dialog .footer .no-change:active.focus, .shaft-change-nock-yaziri-dialog .footer .no-change.active:hover, .shaft-change-nock-yaziri-dialog .footer .no-change.active:focus, .shaft-change-nock-yaziri-dialog .footer .no-change.active.focus,
        .open > .shaft-change-nock-yaziri-dialog .footer .no-change.dropdown-toggle:hover,
        .open > .shaft-change-nock-yaziri-dialog .footer .no-change.dropdown-toggle:focus,
        .open > .shaft-change-nock-yaziri-dialog .footer .no-change.dropdown-toggle.focus {
          color: #333333;
          background-color: #c5c5c5;
          border-color: #8c8c8c; }
      .shaft-change-nock-yaziri-dialog .footer .no-change.disabled:hover, .shaft-change-nock-yaziri-dialog .footer .no-change.disabled:focus, .shaft-change-nock-yaziri-dialog .footer .no-change.disabled.focus, .shaft-change-nock-yaziri-dialog .footer .no-change[disabled]:hover, .shaft-change-nock-yaziri-dialog .footer .no-change[disabled]:focus, .shaft-change-nock-yaziri-dialog .footer .no-change[disabled].focus,
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .no-change:hover,
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .no-change:focus,
      fieldset[disabled] .shaft-change-nock-yaziri-dialog .footer .no-change.focus {
        background-color: #f0f0f0;
        border-color: #ccc; }
      .shaft-change-nock-yaziri-dialog .footer .no-change .badge {
        color: #f0f0f0;
        background-color: #333333; }

.kamon-view-dialog {
  width: 315px; }

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  animation: spin 1s infinite linear; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

.spinner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .spinner-wrapper .spinner-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .order_3d_img {
    width: calc(100vw + 452px);
    height: 100%;
    position: absolute;
    top: 0px;
    left: -452px; }
    .order_3d_img .preview {
      width: 100%;
      height: 100%;
      opacity: 0.95; }
    .order_3d_img .ctrl-panel {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: calc(1vw + 10px + 452px);
      bottom: calc(1vw + 10px);
      z-index: 600; } }

@media only screen and (max-width: 767px) {
  .order_3d_img {
    height: 100%;
    position: relative; }
    .order_3d_img .preview {
      width: 100%;
      height: 100%;
      transform: translateX(0%);
      transition-property: transform;
      transition-duration: 500ms; }
      .order_3d_img .preview.parts-center-mode {
        transform: translateX(-25%);
        transition-property: transform;
        transition-duration: 500ms; }
    .order_3d_img .ctrl-panel {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 78px;
      bottom: calc(76px + 10px);
      z-index: 600; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.parts-complete-button {
  border: none;
  pointer-events: auto; }
  .parts-complete-button.require {
    background-color: #eb8379;
    color: #fff; }
  .parts-complete-button.elective {
    background-color: #d9d9d9;
    color: #000; }
  .parts-complete-button.success {
    background-color: #99dba9;
    color: #fff; }
  .parts-complete-button:hover {
    opacity: 0.7; }

@media only screen and (min-width: 768px) {
  .parts-complete-button {
    width: 62px;
    height: 62px;
    margin: 12.25px;
    /*        
    @media screen and (max-height:919px){
      margin: 1.33297vh;
    }
    @media screen and (max-height:859px){
      width: 7.21vh;
      height: 7.21vh;
    }
*/
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }
  @media only screen and (min-width: 768px) and (max-height: 919px) {
    .parts-complete-button {
      width: calc(((100vh - 229px) / 8) * 0.9);
      height: calc(((100vh - 229px) / 8) * 0.9);
      margin: calc(((100vh - 229px) / 8) * 0.05); } }

@media only screen and (min-width: 768px) {
    .parts-complete-button img.mark {
      width: auto;
      height: 22px;
      margin: 0px auto;
      padding: 0;
      display: block; }
    .parts-complete-button img.require,
    .parts-complete-button img.optional {
      max-width: 40px;
      max-height: 24px;
      width: 4.657vh;
      height: 2.795vh;
      margin: 0px auto 0px auto;
      padding: 0;
      display: block; }
    .parts-complete-button > .title {
      margin: 0;
      font-size: 8px;
      line-height: 1;
      font-weight: 500;
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 1.9em;
      white-space: nowrap; } }
    @media only screen and (min-width: 768px) and (max-height: 919px) {
      .parts-complete-button > .title {
        margin-top: calc(((100vh - 229px) / 8) * 0.04); } }

@media only screen and (min-width: 768px) {
      .parts-complete-button > .title .thumbnail {
        width: 142px;
        height: 36px;
        object-fit: cover;
        display: block;
        background-color: unset;
        border: none;
        padding: 0;
        margin: 0; }
      .parts-complete-button > .title figcaption {
        text-align: center; } }

@media only screen and (max-width: 767px) {
  .parts-complete-button {
    width: 46px;
    height: 46px;
    margin-top: 5px;
    margin-right: calc(50% - 56px);
    margin-bottom: 5px;
    margin-left: 10px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-property: margin;
    transition-duration: 0.5s; }
    .parts-complete-button:nth-child(even) {
      margin-right: 10px;
      margin-left: calc(50% - 56px); }
    .parts-complete-button img.mark {
      width: auto;
      height: 22px;
      margin: 0px auto;
      padding: 0;
      display: block; }
    .parts-complete-button img.require,
    .parts-complete-button img.optional {
      width: 30px;
      height: 19px;
      margin: 0px auto 0px auto;
      padding: 0;
      display: block; }
    .parts-complete-button > .title {
      margin: 0;
      font-size: 8px;
      line-height: 1;
      font-weight: 500;
      margin-top: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 1.9em;
      letter-spacing: -0.1em; }
      .parts-complete-button > .title .thumbnail {
        width: 142px;
        height: 36px;
        object-fit: cover;
        display: block;
        background-color: unset;
        border: none;
        padding: 0;
        margin: 0; }
      .parts-complete-button > .title figcaption {
        text-align: center; }
  .parts-complete-button__move {
    margin: 0 8px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .product-container {
    padding: 0;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap; }
    .product-container .container-overflow {
      overflow-x: scroll;
      height: calc(100% - 95px);
      padding: 10px 15px 40px 15px; }
    .product-container > .product-thumbnail.item {
      margin: 10px;
      width: 44.93827%; } }

@media only screen and (max-width: 767px) {
  .product-container {
    padding: 0;
    margin: 0 0 40px 0; }
    .product-container > .product-thumbnail.item {
      margin: 12px 0 0 0; }
    .product-container > .product-thumbnail.type-b {
      margin: 12px 0 0 0; }
    .product-container.two-col {
      display: grid;
      grid-template-columns: 1fr 1fr; }
    .product-container > .product-thumbnail.type-c {
      margin: 12px 0 0 0; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.order-parts-img {
  width: 100%;
  height: 42px;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (min-width: 768px) {
    .order-parts-img {
      height: 59px; } }
  @media only screen and (min-width: 768px) {
    .order-parts-img {
      justify-content: space-around; } }
  .order-parts-img .left {
    flex: 1; }
    @media only screen and (min-width: 768px) {
      .order-parts-img .left {
        display: none; } }
  .order-parts-img .center {
    flex: 1;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .order-parts-img .center {
        text-align: left;
        margin-left: 24px; } }
  .order-parts-img .right {
    flex: 1;
    margin-left: auto;
    margin-right: 8px;
    display: flex;
    justify-content: flex-end; }
    @media only screen and (min-width: 768px) {
      .order-parts-img .right {
        margin-right: 15px; } }
  .order-parts-img .page-title {
    color: #fff;
    line-height: 15px;
    font-size: 15px;
    font-weight: 300;
    white-space: nowrap; }
    @media only screen and (min-width: 768px) {
      .order-parts-img .page-title {
        line-height: 18px;
        font-size: 18px;
        font-weight: 600; } }

.order-parts-img__help-button {
  background: none;
  color: #fcfcfc;
  line-height: 20px;
  height: 20px;
  opacity: 0.8;
  font-size: 12px;
  font-weight: 300;
  border: none;
  padding: 0;
  display: flex; }
  .order-parts-img__help-button:hover {
    opacity: 0.45; }
  @media only screen and (min-width: 768px) {
    .order-parts-img__help-button {
      font-size: 14px;
      font-weight: 600; } }
  .order-parts-img__help-button .icon {
    margin-left: 7px;
    display: inline-block; }

.preview-3d-header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1100; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.design-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%; }
  @media only screen and (min-width: 768px) {
    .design-container {
      background-color: rgba(255, 255, 255, 0.3); }
      .design-container .div-select-category {
        background-color: rgba(255, 255, 255, 0.3);
        text-align: center;
        padding: 13px 3.097%;
        height: 95px;
        position: sticky;
        top: 0;
        z-index: 500; }
        .design-container .div-select-category .select-category {
          width: 45.04%;
          height: 44px;
          margin: 10px 2.358%;
          padding: 0 0em 0 19px;
          font-size: 11px;
          font-weight: bold;
          border: none;
          background-color: #f0f0f0;
          border-radius: 4px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          cursor: pointer; }
          .design-container .div-select-category .select-category span.count {
            font-size: 10px; }
          .design-container .div-select-category .select-category :first-child {
            margin-left: 25px; }
          .design-container .div-select-category .select-category :last-child {
            margin-right: 25px; }
      .design-container .container-overflow {
        overflow-y: scroll;
        padding: 10px 15px 40px 15px;
        flex-grow: 1;
        flex-basis: 0; }
      .design-container .category-label-div section {
        padding: 0 0 10px 0;
        margin: 0; }
      .design-container .category-label-div .category-label1 {
        display: none; }
      .design-container .category-label-div .category-label2 {
        width: 402px;
        font-size: 18px;
        color: #333333;
        border-bottom: 1px #333333 solid;
        padding-bottom: 13px;
        margin: 30px auto 0 auto;
        font-weight: 500;
        line-height: 1; } }
  @media only screen and (max-width: 767px) {
    .design-container .div-select-category {
      display: flex;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.3); }
      .design-container .div-select-category .select-category {
        width: calc(50vw - 20px);
        height: 27px;
        margin: 10px 10px 0px 10px;
        padding: 0 0 0 11px;
        font-size: 10px;
        font-weight: bold;
        border: none;
        background-color: #f0f0f0;
        border-radius: 6px;
        cursor: pointer; }
        .design-container .div-select-category .select-category:last-child {
          margin-bottom: 10px; }
        .design-container .div-select-category .select-category span.count {
          font-size: 10px; }
    .design-container .container-overflow {
      margin-top: 0px;
      padding: 5px 8px 0 8px;
      overflow-y: scroll;
      padding-bottom: 220px;
      flex-grow: 1;
      flex-basis: 0; }
    .design-container .category-label-div {
      background-color: unset; }
    .design-container .category-label1 {
      display: none; }
    .design-container .category-label2 {
      border-bottom: solid black 1px;
      margin: 16px auto 0 auto;
      width: 157px;
      font-weight: 600;
      font-size: 11px;
      padding-bottom: 5px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .order-total-value {
    position: absolute;
    z-index: 500;
    padding: 0;
    margin: 0;
    left: 13px;
    top: 74px; }
    .order-total-value__detail-info {
      line-height: 1;
      padding: 0;
      margin: 0;
      font-size: 12px;
      font-weight: 300; }
    .order-total-value__div {
      line-height: 1;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: left; }
      .order-total-value__div svg {
        width: 84.05px;
        height: 14.89px;
        margin: 10.4px 14.4px 0 0; }
      .order-total-value__div .price {
        padding: 0;
        margin: 11px 0 0 0;
        font-size: 17px;
        font-weight: 300; }
        .order-total-value__div .price .smaller {
          font-size: 12px;
          display: inline-block;
          margin-left: 0.5em; }
    .order-total-value__partsname {
      display: none; }
    .order-total-value__name {
      display: none;
      padding: 0 0 0 8px; } }

@media only screen and (max-width: 767px) {
  .order-total-value {
    position: absolute;
    width: 100vw;
    height: 52px;
    top: 42px;
    left: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.05); }
    .order-total-value__detail-info {
      width: 100vw;
      font-size: 12px;
      line-height: 1;
      margin: 9px 0 0 12px;
      padding: 0; }
    .order-total-value__div {
      display: flex;
      justify-content: space-between;
      width: 100vw;
      margin: 0 auto;
      padding: 0; }
      .order-total-value__div svg {
        margin: 7px 0 0 13px;
        width: 84.05px;
        height: 14.89px; }
      .order-total-value__div .price {
        width: 50vw;
        text-align: right;
        margin: 3px 5px 0 0;
        font-size: 18px; }
        .order-total-value__div .price .smaller {
          font-size: 12px; }
    .order-total-value__partsname {
      font-size: 9px;
      line-height: 14px;
      margin: 8px 0 0 12px;
      display: none; }
    .order-total-value__name {
      font-size: 9px;
      line-height: 14px;
      margin: 0px 0 0 12px;
      padding-left: 1em;
      display: none; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@media only screen and (min-width: 768px) {
  .product-thumbnail {
    list-style: none; }
    .product-thumbnail .body {
      width: 191px;
      height: 191px;
      cursor: pointer;
      margin: 0 auto;
      border-radius: 0px;
      padding: 11px;
      position: relative;
      display: block;
      background-color: rgba(255, 255, 255, 0.3); }
      .product-thumbnail .body.disabled {
        opacity: 0.4;
        cursor: unset; }
      .product-thumbnail .body:not(.disabled):hover {
        background-color: rgba(255, 255, 255, 0.45); }
        .product-thumbnail .body:not(.disabled):hover .product-thumbnail__icon-zoom {
          display: block; }
      .product-thumbnail .body input {
        display: none; }
    .product-thumbnail .selected .product-thumbnail__icon-selected {
      display: block; }
    .product-thumbnail__thumbnail {
      border: solid #f0f0f0 1px;
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: relative; }
      .product-thumbnail__thumbnail > img {
        width: 100%;
        height: 111px;
        object-fit: cover; }
      .product-thumbnail__thumbnail > .familycrest-enabled {
        position: absolute;
        bottom: 0;
        right: 0;
        color: white;
        background-color: #333333;
        font-size: 10px;
        font-weight: 600;
        font-family: sans-serif;
        padding: 2px 8px; }
    .product-thumbnail__name {
      width: 100%;
      height: 45px;
      font-size: 11px;
      line-height: 15px;
      font-weight: normal;
      display: block;
      margin-top: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #333333; }
    .product-thumbnail__icon-selected {
      display: none;
      position: absolute;
      top: 6px;
      left: 6px; }
    .product-thumbnail__icon-zoom {
      display: none;
      position: absolute;
      top: 87px;
      right: 16px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      padding: 0; }
      .product-thumbnail__icon-zoom:hover {
        opacity: 0.5; } }

@media only screen and (max-width: 767px) {
  .product-thumbnail {
    list-style: none; }
    .product-thumbnail .body {
      position: relative;
      width: 136px;
      height: 136px;
      cursor: pointer;
      margin: 0 auto;
      border-radius: 16px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      padding: 0;
      overflow: unset;
      display: block;
      background-color: #ffffff; }
      .product-thumbnail .body > input {
        display: none; }
      .product-thumbnail .body > .footer {
        background-color: unset;
        border: none;
        padding: unset;
        width: 100%; }
        .product-thumbnail .body > .footer:hover {
          opacity: 0.5; }
      .product-thumbnail .body.selected .product-thumbnail__icon-selected {
        display: block; }
    .product-thumbnail__thumbnail {
      margin: 0;
      padding: 0;
      border: none;
      border-bottom: 1px #dddddd;
      position: relative; }
      .product-thumbnail__thumbnail > img {
        width: 136px;
        height: 93px;
        border-radius: 16px 16px 0 0;
        object-fit: cover; }
      .product-thumbnail__thumbnail > .familycrest-enabled {
        position: absolute;
        bottom: 0;
        right: 0;
        color: white;
        background-color: #333333;
        font-size: 10px;
        font-weight: 600;
        font-family: sans-serif;
        padding: 2px 8px; }
    .product-thumbnail__name {
      width: 80%;
      height: 18px;
      font-size: 11px;
      line-height: 12px;
      font-weight: normal;
      display: block;
      margin-top: 4px;
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .product-thumbnail__icon-selected {
      display: none;
      position: absolute;
      top: -5px;
      left: -5px; }
    .product-thumbnail__icon-zoom {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      bottom: 9px;
      right: 10px; }
    .product-thumbnail.type-b .body {
      height: auto; }
    .product-thumbnail.type-b .product-thumbnail__thumbnail > img {
      height: 61px; }
    .product-thumbnail.type-b .product-thumbnail__name {
      height: auto; }
    .product-thumbnail.type-b .product-thumbnail__icon-zoom {
      bottom: 2px; }
    .product-thumbnail.type-c .body {
      min-width: 68px;
      max-width: 80px;
      height: auto;
      border-radius: 8px; }
    .product-thumbnail.type-c .product-thumbnail__thumbnail > img {
      min-width: 68px;
      max-width: 80px;
      height: 43px;
      border-radius: 8px 8px 0 0; }
    .product-thumbnail.type-c .product-thumbnail__name {
      height: auto;
      margin: unset; }
    .product-thumbnail.type-c .product-thumbnail__icon-zoom {
      bottom: 2px; } }

.order-featherinside-select {
  display: flex;
  flex-direction: column;
  height: 100vh; }
  .order-featherinside-select__header {
    flex-shrink: 0; }
  .order-featherinside-select__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 1vh;
    background-color: rgba(255, 255, 255, 0.5); }
  .order-featherinside-select__footer {
    flex-shrink: 0;
    bottom: 0px; }

@media only screen and (max-width: 767px) {
  .order-typezero-select__description {
    margin: 10px;
    font-size: 10px;
    font-weight: 300; } }

@media only screen and (min-width: 768px) {
  .order-typezero-select__description {
    padding: 40px 40px 10px 40px;
    line-height: 1.8; } }

.product-container {
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .product-container {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap; } }

.sa-dropdown {
  height: 27px;
  padding: 0 0 0 11px;
  font-size: 10px;
  font-weight: bold;
  border: none;
  background-color: #f0f0f0;
  text-align: center;
  border-radius: 6px; }

.sa-dropdown-area {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.order-option-dropdown--area {
  margin: 5px 5px 0px 5px; }

.order-option-dropdown--dropdown {
  color: #ffffff;
  background-color: #4b4b4b;
  margin: 5px;
  cursor: pointer; }
  .order-option-dropdown--dropdown:nth-child(2) {
    margin-top: 0px; }

.order-option-toggle--area {
  margin: 11px 10px 0px 10px;
  width: 432px; }

.order-option-toggle--toggle-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 3.097%; }
  .order-option-toggle--toggle-wrapper > .toggle-label {
    min-width: 100px;
    margin-right: auto;
    margin-bottom: 0px;
    line-height: 40px;
    font-weight: 600; }
  .order-option-toggle--toggle-wrapper > .toggleContainer {
    width: 276px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.deliver-point .ec-role {
  padding: 30px 20px 0; }

.deliver-point .ec-registerRole {
  margin: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.shipping_edit .ec-role:nth-child(1) {
  padding: 30px 20px 0; }

.shipping_edit .ec-off4Grid__cell {
  margin: 0 auto; }
  .shipping_edit .ec-off4Grid__cell button {
    margin: 0 0 5px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.shipping_multiple .ec-role:nth-child(1) {
  padding: 30px 20px 0; }

@media only screen and (max-width: 767px) {
  .shipping_multiple .ec-AddAddress__new {
    padding: 0 0 0 10vw; } }

.shipping_multiple .ec-AddAddress__new a {
  font-weight: 300; }

.shipping_multiple .ec-off4Grid__cell {
  margin: 0 auto; }
  @media only screen and (min-width: 768px) {
    .shipping_multiple .ec-off4Grid__cell {
      width: 275px; } }
  @media only screen and (max-width: 767px) {
    .shipping_multiple .ec-off4Grid__cell {
      width: 70%; } }
  .shipping_multiple .ec-off4Grid__cell button {
    margin: 0 0 5px; }

.shipping_multiple .ec-AddAddress__btn button {
  font-weight: 300; }

.shipping_multiple .delete {
  margin: 0 0 0 5px;
  font-weight: 300; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.login-mypage {
  max-width: 1150px;
  margin: 0 auto; }
  .login-mypage .ec-role:nth-child(1) {
    padding: 30px 20px 0; }
  .login-mypage .ec-off2Grid__cell {
    margin: 0 auto; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.cart-data {
  max-width: 1150px;
  margin: 0 auto; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.shopping-index {
  max-width: 1150px;
  margin: 0 auto; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.shopping-confirm {
  max-width: 1150px;
  margin: 0 auto; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.shopping-complete {
  max-width: 1150px;
  margin: 0 auto; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.product-list {
  background-color: #f8dddd;
  padding: 0 0 40px 0; }
  .product-list .ec-searchnavRole {
    max-width: 1366px;
    margin: 0 auto; }
  .product-list .ec-shelfRole {
    max-width: 1366px;
    margin: 0 auto; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw); }
  100% {
    transform: translateX(0px); } }

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw); }
  100% {
    transform: translateY(0px); } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

.slide-animation-enter {
  transform: translateX(100%); }
  .slide-animation-enter-active {
    transform: none;
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-enter-done {
    transform: none; }

.slide-animation-exit {
  transform: none; }
  .slide-animation-exit-active {
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .slide-animation-exit-done {
    transform: none; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.product-detail {
  background-color: #f8dddd;
  transition: all 0.5s ease 0s;
  /*商品詳細ブロック*/
  /*ショッピングガイドエリア*/ }

@media only screen and (min-width: 768px) and (min-width: 1210px) {
  .product-detail {
    padding: 70px 0 0 0; } }

@media only screen and (min-width: 768px) and (max-width: 1209px) {
  .product-detail {
    padding: 40px 0 0 0; } }
  @media only screen and (max-width: 767px) {
    .product-detail {
      padding: 25px 0 40px 0; } }
  .product-detail .ec-productRole,
  .product-detail .shopping-guide {
    max-width: 1150px;
    margin: 0 auto;
    background-color: initial; }
  .product-detail .ec-productRole {
    /*MB版オンリーの表示*/
    /*DT版オンリーの表示*/
    /*商品画像*/
    /*DT用サムネ*/
    /*パンくず*/
    /*商品名*/
    /*販売価格*/
    /*標準価格*/
    /*アイコンエリア*/
    /*規格選択エリア*/
    /*コメントテキストエリア（日時指定の説明）*/
    /*カートボタン*/
    /*お気に入りボタン*/
    /*商品情報エリア - 見出し部分*/
    /*商品情報エリア - カテゴリーエリア*/
    /*商品情報エリア - タグエリア*/
    /*商品詳細テキスト*/
    /*商品コード*/ }
    @media only screen and (max-width: 767px) {
      .product-detail .ec-productRole {
        padding-left: 0px;
        padding-right: 0px; }
        .product-detail .ec-productRole .ec-grid2 {
          max-width: 510px; }
          .product-detail .ec-productRole .ec-grid2 .ec-grid2__header_cell {
            margin-top: -12px; }
          .product-detail .ec-productRole .ec-grid2 .ec-grid2__cell:nth-child(1) {
            padding: 0px 0 0 0; } }
    @media only screen and (max-width: 767px) {
      .product-detail .ec-productRole .disp-mb-only {
        display: block; }
      .product-detail .ec-productRole .disp-dt-only {
        display: none; } }
    @media only screen and (min-width: 768px) {
      .product-detail .ec-productRole .disp-mb-only {
        display: none; }
      .product-detail .ec-productRole .disp-dt-only {
        display: block; } }
    @media only screen and (max-width: 767px) {
      .product-detail .ec-productRole .ec-sliderItemRole {
        padding: 0 15px; } }
    .product-detail .ec-productRole .ec-sliderItemRole .item_visual__ec-icon {
      transition: all 0.5s ease 0s;
      position: absolute;
      z-index: 1;
      width: 60px;
      transform: rotate(0deg);
      border-radius: 0 0 12px 0;
      overflow: hidden;
      right: 20px;
      top: calc(50vw - 121px); }
      @media screen and (min-width: 1150px) {
        .product-detail .ec-productRole .ec-sliderItemRole .item_visual__ec-icon {
          top: 454px; } }
    .product-detail .ec-productRole .ec-sliderItemRole .item_visual__ec-icon-img {
      cursor: zoom-in; }
      .product-detail .ec-productRole .ec-sliderItemRole .item_visual__ec-icon-img:hover {
        opacity: 0.8; }
    .product-detail .ec-productRole .ec-sliderItemRole .item_visual {
      border-radius: 12px;
      overflow: hidden; }
      .product-detail .ec-productRole .ec-sliderItemRole .item_visual * {
        transition: all 0.5s ease 0s; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-sliderItemRole .item_visual {
          overflow: unset; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-list {
          border-radius: 12px; } }
      .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-list img {
        object-fit: cover;
        object-position: center center; }

@media only screen and (min-width: 768px) and (min-width: 1150px) {
  .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-list img {
    height: 515px; } }

@media only screen and (min-width: 768px) and (max-width: 1149px) {
  .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-list img {
    height: calc(50vw - 60px); } }

@media only screen and (max-width: 767px) and (min-width: 510px) {
  .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-list img {
    height: 480px; } }

@media only screen and (max-width: 767px) and (max-width: 509px) {
  .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-list img {
    height: calc(100vw - 30px); } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-sliderItemRole .item_visual .slick-dots li.slick-active button:before {
          background-color: #C6BDEB; } }
    @media only screen and (min-width: 768px) {
      .product-detail .ec-productRole .item_nav img {
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000029;
        object-fit: cover;
        object-position: center center; } }

@media only screen and (min-width: 768px) and (min-width: 768px) and (min-width: 1150px) {
  .product-detail .ec-productRole .item_nav img {
    width: calc((515px / 3) * 0.8);
    height: calc((515px / 3) * 0.8); } }

@media only screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1149px) {
  .product-detail .ec-productRole .item_nav img {
    width: calc(((50vw - 60px) / 3) * 0.8);
    height: calc(((50vw - 60px) / 3) * 0.8); } }
    .product-detail .ec-productRole .ec-searchnavRole__topicpath {
      padding: 0px 0 21px; }
      @media only screen and (min-width: 768px) {
        .product-detail .ec-productRole .ec-searchnavRole__topicpath .ec-topicpath {
          padding: 10px 0 0px;
          border: 0;
          font-size: 14px; }
          .product-detail .ec-productRole .ec-searchnavRole__topicpath .ec-topicpath * {
            font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-searchnavRole__topicpath .ec-topicpath {
          display: none;
          border-bottom: none;
          padding: 0 15px; }
          .product-detail .ec-productRole .ec-searchnavRole__topicpath .ec-topicpath:nth-child(1) {
            display: block; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-searchnavRole__topicpath .ec-topicpath__item {
          font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .product-detail .ec-productRole__title {
        padding: 0; } }
    .product-detail .ec-productRole__title .ec-headingTitle {
      line-height: 1.4;
      color: #333333; }
      @media only screen and (min-width: 768px) {
        .product-detail .ec-productRole__title .ec-headingTitle {
          font-size: 30px !important;
          margin: 0 0 20px; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__title .ec-headingTitle {
          font-size: 22px !important;
          position: relative;
          top: unset;
          padding: 0 15px;
          margin-bottom: 15px; } }
    .product-detail .ec-productRole__price {
      border-top: 1px dashed #ffffff;
      border-bottom: none;
      padding: 25px 0 0px !important; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__price {
          margin: 0 15px;
          padding: 15px 0 0px !important; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__price .ec-price {
          font-size: 26px; } }
      @media only screen and (min-width: 768px) {
        .product-detail .ec-productRole__price .ec-price__price {
          font-size: 30px;
          padding: 0;
          line-height: 1.1; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__price .ec-price__price {
          font-size: 26px;
          padding: 0; } }
    .product-detail .ec-productRole .ec-productRole__priceRegular {
      opacity: 1 !important;
      padding: 0 !important;
      border-bottom: none !important;
      color: #333333 !important; }
      @media only screen and (min-width: 768px) {
        .product-detail .ec-productRole .ec-productRole__priceRegular {
          font-size: 16px; } }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-productRole__priceRegular {
          font-size: 14px;
          padding: 0 15px; } }
      .product-detail .ec-productRole .ec-productRole__priceRegularPrice {
        color: #333333;
        padding: 7px 0 0px;
        opacity: 1;
        font-size: 16px;
        border-bottom: none; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole .ec-productRole__priceRegularPrice {
            font-size: 14px;
            padding: 7px 15px 0; } }
        .product-detail .ec-productRole .ec-productRole__priceRegularPrice .price01-default {
          display: inline; }
        .product-detail .ec-productRole .ec-productRole__priceRegularPrice .ec-productRole__priceRegularTax {
          display: inline;
          font-size: 12px; }
    .product-detail .ec-productRole .ec-productRole__icon {
      padding-top: 10px;
      /*送料：全国無料*/ }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-productRole__icon {
          padding: 10px 15px 0 15px; } }
      .product-detail .ec-productRole .ec-productRole__icon .shippingFree {
        display: inline-block;
        padding: 0.2em 0.5em;
        color: #fff;
        background-color: #3EBDD0;
        font-size: 14px;
        border-radius: 4px;
        font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
        box-shadow: 0px 3px 6px #00000022; }
    .product-detail .ec-productRole #form1 {
      border-top: 1px dashed #ffffff;
      margin: 25px 0 0; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole #form1 {
          margin: 15px 15px 0; } }
    .product-detail .ec-productRole .ec-productRole__actions {
      padding: 30px 0 15px 0; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole .ec-productRole__actions {
          padding: 20px 0 15px 0; } }
      .product-detail .ec-productRole .ec-productRole__actions .ec-select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole .ec-productRole__actions .ec-select {
            display: block; } }
        .product-detail .ec-productRole .ec-productRole__actions .ec-select .ec-select__caption {
          padding: 0;
          width: 87px;
          font-size: 16px; }
          @media only screen and (max-width: 767px) {
            .product-detail .ec-productRole .ec-productRole__actions .ec-select .ec-select__caption {
              width: 100%; } }
        .product-detail .ec-productRole .ec-productRole__actions .ec-select select {
          padding-right: 21px;
          width: auto;
          border-radius: 5px; }
          @media only screen and (max-width: 767px) {
            .product-detail .ec-productRole .ec-productRole__actions .ec-select select {
              margin-top: 6px;
              width: 100%; } }
      .product-detail .ec-productRole .ec-productRole__actions .ec-numberInput {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole .ec-productRole__actions .ec-numberInput {
            display: block; } }
        .product-detail .ec-productRole .ec-productRole__actions .ec-numberInput .ec-numberInput__caption {
          padding: 0;
          width: 87px;
          font-size: 16px; }
          @media only screen and (max-width: 767px) {
            .product-detail .ec-productRole .ec-productRole__actions .ec-numberInput .ec-numberInput__caption {
              width: 100%; } }
        .product-detail .ec-productRole .ec-productRole__actions .ec-numberInput input {
          margin: 0;
          border: none;
          max-width: 90px;
          width: 90px;
          border-radius: 5px; }
          @media only screen and (max-width: 767px) {
            .product-detail .ec-productRole .ec-productRole__actions .ec-numberInput input {
              max-width: unset;
              width: 100%;
              margin-top: 6px; } }
    .product-detail .ec-productRole__commentText {
      font-size: 14px;
      line-height: 1.8; }
      .product-detail .ec-productRole__commentText p {
        padding: 25px 0px 0 0px; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__commentText p {
          padding: 25px 15px 0 15px; } }
    @media only screen and (max-width: 767px) {
      .product-detail .ec-productRole__btn {
        padding: 0 0 10px 0; } }
    .product-detail .ec-productRole__btn .add-cart {
      height: 56px; }
    .product-detail .ec-productRole__btn .ec-blockBtn--cancel {
      height: 56px; }
    @media only screen and (max-width: 767px) {
      .product-detail .ec-productRole button#favorite {
        margin: 0 15px 0 15px;
        width: calc(100% - 30px); } }
    .product-detail .ec-productRole__caption {
      margin: 40px 0 0 0;
      font-size: 16px;
      padding: 25px 0 0 0;
      border-top: #ffffff 1px dashed !important; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__caption {
          font-size: 16px;
          margin: 40px 15px 0 15px;
          padding: 25px 0px 0 0px; } }
    .product-detail .ec-productRole__category {
      padding: 25px 0 0px 0; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__category {
          margin: 0px 15px 0 15px;
          padding: 25px 0px 9px 0px; } }
      .product-detail .ec-productRole__category__span {
        font-size: 14px; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole__category__span {
            font-size: 12px; } }
      .product-detail .ec-productRole__category ul {
        font-size: 14px;
        padding: 0px 0 10px 100px; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole__category ul {
            font-size: 14px; } }
        .product-detail .ec-productRole__category ul a {
          color: #333333; }
        .product-detail .ec-productRole__category ul a:hover {
          opacity: 0.7; }
    .product-detail .ec-productRole__tags {
      margin: 20px 0 0 0;
      padding: 0 0 5px;
      border-bottom: none; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__tags {
          margin: 0px 15px 0 15px;
          padding: 5px 0px 19px 0px; } }
      .product-detail .ec-productRole__tags span {
        font-size: 14px;
        margin: 0 66px 0 0; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole__tags span {
            font-size: 12px; } }
      .product-detail .ec-productRole__tags li {
        font-size: 13px;
        background: #F5F7F8;
        border: none;
        font-family: sans-serif;
        padding: 9px 10px;
        line-height: 1;
        margin: 0px 5px 0 0; }
        @media only screen and (max-width: 767px) {
          .product-detail .ec-productRole__tags li {
            font-size: 12px; } }
        .product-detail .ec-productRole__tags li a {
          color: #6e6e7c; }
        .product-detail .ec-productRole__tags li a:hover {
          opacity: 0.7; }
    .product-detail .ec-productRole__description {
      margin: 20px 0 0 0;
      font-size: 16px;
      border-top: 1px dashed #fff;
      border-bottom: none !important;
      padding: 25px 0 0 0;
      line-height: 1.65; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__description {
          max-width: 480px;
          width: calc(100% - 30px);
          margin: 0px auto 0 auto;
          padding: 25px 0px 25px 0px;
          font-size: 14px; } }
    @media only screen and (min-width: 768px) {
      .product-detail .ec-productRole > .ec-productRole__description {
        border-top: none;
        margin: 60px 20px 40px 20px; } }
    .product-detail .ec-productRole__code {
      margin: 20px 0 0 0;
      font-size: 14px;
      border-top: 1px dashed #fff;
      border-bottom: none !important;
      padding: 25px 0 0 0;
      line-height: 1.65; }
      @media only screen and (max-width: 767px) {
        .product-detail .ec-productRole__code {
          margin: 0px 15px 0 15px;
          padding: 15px 0px 15px 0px;
          font-size: 12px; } }
  .product-detail .shopping-guide {
    /*もっと読むボタン*/ }
    .product-detail .shopping-guide :not(.shopping-guide__title):not(.shopping-guide__first-h1):not(h1) {
      font-size: 14px;
      line-height: 1.5; }
    .product-detail .shopping-guide ul {
      padding-bottom: 20px; }
    .product-detail .shopping-guide li {
      line-height: 1.5;
      margin-top: 10px; }
    @media only screen and (max-width: 767px) {
      .product-detail .shopping-guide__openBtn {
        bottom: 0px;
        width: 90vw;
        padding: 15px;
        font-weight: 600;
        background: linear-gradient(to right, rgba(221, 116, 186, 0.9) 0%, rgba(181, 97, 214, 0.9) 50%, rgba(103, 149, 221, 0.9) 100%); } }

.panel-overlay {
  z-index: 1150;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-end;
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  .panel-overlay--after-open {
    opacity: 1; }
  .panel-overlay--before-close {
    opacity: 0; }

.panel-modal {
  outline: none;
  z-index: 1250;
  display: flex;
  justify-content: flex-end;
  transform: translateX(100%);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  .panel-modal--after-open {
    transform: translateX(0%); }
  .panel-modal--before-close {
    /* do not transform when closing */ }

/*オーダーサイトのヘルプのみ適用／レスポンシブ対応 ----------------*/
#page_ordermade_top .order-parts-help-panel {
  max-width: 315px;
  width: calc(100vw - 49px); }

#page_ordermade_top .familycrest-list-panel {
  max-width: 315px; }
  @media only screen and (min-width: 768px) {
    #page_ordermade_top .familycrest-list-panel {
      max-width: 630px; } }

/*「オーダーメイドのヘルプ」と「ブランドサイトの矢の選び方」で共用のCSS ----------------*/
.overflow-x-scroll {
  overflow-x: scroll; }

.font-size-10 {
  font-size: 10px; }

.text-align-center {
  text-align: center; }

.order-parts-help-panel {
  background-color: #fff; }
  .order-parts-help-panel .scrollable {
    overflow-y: overlay;
    height: 100%; }

.order-parts-help-panel-close {
  width: auto;
  display: flex; }

.order-parts-help-contents {
  text-align: left;
  margin: 0 15px;
  padding-bottom: 100px; }
  .order-parts-help-contents .title {
    font-weight: 600;
    font-size: 17px;
    border-bottom: none;
    width: auto;
    display: block;
    margin: 30px 0 -8px 0;
    line-height: 1.3; }
    .order-parts-help-contents .title .sakura {
      display: none; }
  .order-parts-help-contents .subtitle {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    line-height: 1.3;
    margin-top: 40px;
    border-bottom: solid #333333 2px;
    margin-bottom: -5px; }
  .order-parts-help-contents .subheading {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    margin: 40px 0 0 0;
    padding: 0; }
  .order-parts-help-contents .paragraph {
    margin: 20px 0;
    line-height: 1.8; }
  .order-parts-help-contents .image {
    margin: 20px auto;
    display: block;
    max-width: calc(100%);
    width: auto; }

.order-parts-help-table {
  width: 753px; }
  .order-parts-help-table .header {
    border-bottom: 1px #212529 solid;
    background-color: #f0f0f0; }
    .order-parts-help-table .header > tr th {
      height: 55px;
      padding-left: 11px; }
  .order-parts-help-table .table-row {
    border-bottom: 1px #dee2e6 solid;
    background-color: #fff; }
    .order-parts-help-table .table-row > td {
      height: 44px;
      padding-left: 11px; }
  .order-parts-help-table .strong {
    font-weight: bold;
    background-color: #f0f0f0; }

.order-parts-help-divider {
  color: #999999;
  margin-top: 20px;
  margin-bottom: 20px; }

.familycrest-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 15px; }
  .familycrest-list .familycrest-item {
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    margin: 5px; }
    .familycrest-list .familycrest-item > .body > img {
      height: 126px; }

.familycrest-list-panel {
  background-color: #fff; }
  .familycrest-list-panel .scrollable {
    overflow-y: overlay;
    height: 100%; }

.familycrest-list-panel-header {
  position: sticky;
  top: 0px;
  z-index: 1100;
  background-color: rgba(255, 255, 255, 0.97);
  margin: 0 10px;
  height: 54px; }
  @media only screen and (min-width: 768px) {
    .familycrest-list-panel-header {
      margin: 0 20px;
      height: 70px; } }
  .familycrest-list-panel-header .navi-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .familycrest-list-panel-header .navi-container .left {
      flex: 1; }
      .familycrest-list-panel-header .navi-container .left .go-back {
        height: 32px;
        line-height: 1;
        padding: 6px 12px; }
    .familycrest-list-panel-header .navi-container .title {
      margin: 0;
      flex: 1;
      text-align: center;
      font-weight: bold;
      font-size: 16px; }
      @media only screen and (min-width: 768px) {
        .familycrest-list-panel-header .navi-container .title {
          font-size: 21px; } }
    .familycrest-list-panel-header .navi-container .right {
      flex: 1;
      margin-left: auto; }

.familycrest-select-panel-header {
  position: sticky;
  top: 0px;
  z-index: 1100;
  background-color: rgba(255, 255, 255, 0.97);
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center; }

@inclcude media_desktop() {
  .familycrest-select-panel-header {
    height: 70px; } }
  .familycrest-select-panel-header .title {
    font-weight: bold;
    font-size: 16px; }
    @media only screen and (min-width: 768px) {
      .familycrest-select-panel-header .title {
        font-size: 21px; } }

.alert-shipdate-dialog {
  position: fixed;
  z-index: 1150;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center; }
  .alert-shipdate-dialog .alert-shipdate-dialog__content {
    z-index: 1250;
    padding: 1em;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    width: 315px; }
    .alert-shipdate-dialog .alert-shipdate-dialog__content h1 {
      font-weight: 900;
      font-size: 24px;
      width: 130px;
      margin-top: 39px;
      margin-left: auto;
      margin-right: auto;
      text-decoration: underline; }
    .alert-shipdate-dialog .alert-shipdate-dialog__content p {
      margin-top: 24px;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 21px;
      text-align: left; }
    .alert-shipdate-dialog .alert-shipdate-dialog__content button {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #ffffff;
      background-color: #d13b9d;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px;
      padding: 12px 36px 12px 39px;
      margin: 10px 0; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button:active:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button:active.focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button:active, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button.disabled, .alert-shipdate-dialog .alert-shipdate-dialog__content button[disabled],
      fieldset[disabled] .alert-shipdate-dialog .alert-shipdate-dialog__content button {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.focus {
        color: #ffffff;
        background-color: #b02981;
        border-color: #8c8c8c; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button:hover {
        color: #ffffff;
        background-color: #b02981;
        border-color: #adadad; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button:active, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active,
      .open > .alert-shipdate-dialog .alert-shipdate-dialog__content button.dropdown-toggle {
        color: #ffffff;
        background-color: #b02981;
        background-image: none;
        border-color: #adadad; }
        .alert-shipdate-dialog .alert-shipdate-dialog__content button:active:hover, .alert-shipdate-dialog .alert-shipdate-dialog__content button:active:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button:active.focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active:hover, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.active.focus,
        .open > .alert-shipdate-dialog .alert-shipdate-dialog__content button.dropdown-toggle:hover,
        .open > .alert-shipdate-dialog .alert-shipdate-dialog__content button.dropdown-toggle:focus,
        .open > .alert-shipdate-dialog .alert-shipdate-dialog__content button.dropdown-toggle.focus {
          color: #ffffff;
          background-color: #93226c;
          border-color: #8c8c8c; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button.disabled:hover, .alert-shipdate-dialog .alert-shipdate-dialog__content button.disabled:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button.disabled.focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button[disabled]:hover, .alert-shipdate-dialog .alert-shipdate-dialog__content button[disabled]:focus, .alert-shipdate-dialog .alert-shipdate-dialog__content button[disabled].focus,
      fieldset[disabled] .alert-shipdate-dialog .alert-shipdate-dialog__content button:hover,
      fieldset[disabled] .alert-shipdate-dialog .alert-shipdate-dialog__content button:focus,
      fieldset[disabled] .alert-shipdate-dialog .alert-shipdate-dialog__content button.focus {
        background-color: #d13b9d;
        border-color: #ccc; }
      .alert-shipdate-dialog .alert-shipdate-dialog__content button .badge {
        color: #d13b9d;
        background-color: #ffffff; }

.tab-product-categorylist--scrollable-container {
  flex: 1;
  overflow-y: scroll; }

.tab-product-categorylist--header {
  height: 95px;
  cursor: pointer;
  overflow-x: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 20px;
  background-color: rgba(255, 255, 255, 0.25); }
  .tab-product-categorylist--header-link-item-wrapper {
    list-style: none;
    display: flex; }
  .tab-product-categorylist--header-link-item {
    flex: 0 0 auto;
    height: 44px;
    max-width: 140px;
    line-height: 44px;
    padding: 0 15px;
    color: #333333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 45px;
    text-decoration: none;
    font-weight: 600;
    font-size: 17px; }
    .tab-product-categorylist--header-link-item:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.1);
      color: #333333; }
  .tab-product-categorylist--header-active {
    flex: 0 0 auto;
    height: 44px;
    max-width: 140px;
    line-height: 44px;
    padding: 0 22px;
    color: #ffffff;
    background-color: #333333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 45px;
    text-decoration: none;
    font-weight: 600; }

.tab-product-categorylist--section .product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 30px; }

.section-category-label {
  width: 402px;
  font-size: 18px;
  color: #333333;
  border-bottom: 1px #333333 solid;
  padding-bottom: 13px;
  margin: 30px auto 0 auto;
  font-weight: 500;
  line-height: 1; }

.loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1300;
  display: none;
  justify-content: center;
  align-items: center; }
  .loading.active {
    display: flex; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.edit-footer-navigation-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1001; }
  @media only screen and (max-width: 767px) {
    .edit-footer-navigation-bar {
      display: flex;
      justify-content: space-around;
      align-items: center; }
      .edit-footer-navigation-bar .btn__cancel {
        transform: translate(10px, -5px); }
      .edit-footer-navigation-bar .btn__preview {
        transform: translateY(-10px); }
      .edit-footer-navigation-bar .btn__complete {
        transform: translate(-10px, -5px); } }

@media only screen and (max-width: 767px) {
  .footer-navi-btn-area {
    width: 100vw;
    height: 70px;
    background-color: #525263; } }

.footer-navi-btn {
  border: none;
  padding: 0;
  background-color: unset;
  -webkit-touch-callout: none; }
  .footer-navi-btn:not(:disabled):hover {
    opacity: 0.75; }

.footer-navi-btn-3d {
  border: none;
  background-color: unset;
  -webkit-touch-callout: none;
  text-align: center; }

.shipment-dialog {
  width: 315px; }
  .shipment-dialog .Modal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 315px;
    user-select: none; }
  .shipment-dialog .title {
    font-size: 20px;
    margin: 15px;
    border-bottom: solid black 1px;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    user-select: none; }
  .shipment-dialog .sub-title {
    font-size: 12px;
    margin: 0 30px;
    font-weight: 300;
    user-select: none; }
  .shipment-dialog .footer {
    display: flex;
    flex-direction: column;
    width: 100%; }

.zoom-dialog .Modal .content {
  padding: 20px;
  width: 415px;
  user-select: none; }

.zoom-dialog .zoom-dialog__div {
  width: 100%;
  height: auto;
  margin: 0px auto;
  background: #ededed; }
  .zoom-dialog .zoom-dialog__div img {
    object-fit: cover;
    width: 100%; }

.zoom-dialog .zoom-dialog__category {
  font-size: 8px;
  text-align: left;
  display: block;
  margin: 17px 0 0 0;
  color: #505050;
  line-height: 1; }

.zoom-dialog .zoom-dialog__name {
  background-color: #F7F7F7;
  padding: 10px;
  text-align: left;
  display: block;
  margin: 7px 0 0 0;
  line-height: 1; }

.zoom-dialog .zoom-dialog__label {
  font-size: 8px;
  text-align: left;
  display: block;
  margin: 17px 0 0 0;
  color: #505050;
  line-height: 1; }

.zoom-dialog .zoom-dialog__price {
  text-align: left;
  display: block;
  background-color: #F7F7F7;
  padding: 10px;
  margin: 7px 0 0 0;
  line-height: 1; }
  .zoom-dialog .zoom-dialog__price__comment {
    font-size: 8px;
    text-align: left;
    display: block;
    margin: 11px 0 0 0;
    color: #505050;
    line-height: 1; }

.zoom-dialog .footer {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .zoom-dialog .footer .normal-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 16px;
    text-decoration: none;
    color: #333333;
    background-color: #f0f0f0;
    border-color: #ccc;
    border: none;
    font-weight: normal;
    border-radius: 4px;
    height: 44px;
    border-radius: 45px;
    line-height: 1;
    background-color: #333333;
    padding: 15px 21px;
    color: #ffffff; }
    .zoom-dialog .footer .normal-button:focus, .zoom-dialog .footer .normal-button.focus, .zoom-dialog .footer .normal-button:active:focus, .zoom-dialog .footer .normal-button:active.focus, .zoom-dialog .footer .normal-button.active:focus, .zoom-dialog .footer .normal-button.active.focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .zoom-dialog .footer .normal-button:active, .zoom-dialog .footer .normal-button.active {
      outline: 0;
      background-image: none;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .zoom-dialog .footer .normal-button.disabled, .zoom-dialog .footer .normal-button[disabled],
    fieldset[disabled] .zoom-dialog .footer .normal-button {
      cursor: not-allowed;
      filter: alpha(opacity=65);
      opacity: 0.65;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .zoom-dialog .footer .normal-button:focus, .zoom-dialog .footer .normal-button.focus {
      color: #333333;
      background-color: #d7d7d7;
      border-color: #8c8c8c; }
    .zoom-dialog .footer .normal-button:hover {
      color: #333333;
      background-color: #d7d7d7;
      border-color: #adadad; }
    .zoom-dialog .footer .normal-button:active, .zoom-dialog .footer .normal-button.active,
    .open > .zoom-dialog .footer .normal-button.dropdown-toggle {
      color: #333333;
      background-color: #d7d7d7;
      background-image: none;
      border-color: #adadad; }
      .zoom-dialog .footer .normal-button:active:hover, .zoom-dialog .footer .normal-button:active:focus, .zoom-dialog .footer .normal-button:active.focus, .zoom-dialog .footer .normal-button.active:hover, .zoom-dialog .footer .normal-button.active:focus, .zoom-dialog .footer .normal-button.active.focus,
      .open > .zoom-dialog .footer .normal-button.dropdown-toggle:hover,
      .open > .zoom-dialog .footer .normal-button.dropdown-toggle:focus,
      .open > .zoom-dialog .footer .normal-button.dropdown-toggle.focus {
        color: #333333;
        background-color: #c5c5c5;
        border-color: #8c8c8c; }
    .zoom-dialog .footer .normal-button.disabled:hover, .zoom-dialog .footer .normal-button.disabled:focus, .zoom-dialog .footer .normal-button.disabled.focus, .zoom-dialog .footer .normal-button[disabled]:hover, .zoom-dialog .footer .normal-button[disabled]:focus, .zoom-dialog .footer .normal-button[disabled].focus,
    fieldset[disabled] .zoom-dialog .footer .normal-button:hover,
    fieldset[disabled] .zoom-dialog .footer .normal-button:focus,
    fieldset[disabled] .zoom-dialog .footer .normal-button.focus {
      background-color: #f0f0f0;
      border-color: #ccc; }
    .zoom-dialog .footer .normal-button .badge {
      color: #f0f0f0;
      background-color: #333333; }
    .zoom-dialog .footer .normal-button:hover {
      background-color: #000;
      color: #ffffff; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  .layout-right {
    width: 452px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px; } }

@media only screen and (max-width: 767px) {
  .layout-right {
    position: absolute;
    top: 0px;
    width: 100vw; } }

.basic-alert-dialog-overlay {
  z-index: 1150;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center; }

.basic-alert-dialog-modal {
  z-index: 1250;
  width: 315px;
  background-color: white;
  border-radius: 11px;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px; }
  .basic-alert-dialog-modal > .title {
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px; }
  .basic-alert-dialog-modal > .description {
    margin-top: 28px;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px; }
  .basic-alert-dialog-modal > .footer {
    margin-top: 30px;
    display: flex;
    justify-content: center; }
    .basic-alert-dialog-modal > .footer > .od-btn.default {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      border-radius: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 16px;
      text-decoration: none;
      color: #333333;
      background-color: #f0f0f0;
      border-color: #ccc;
      border: none;
      font-weight: normal;
      border-radius: 4px;
      height: 44px;
      width: 100%;
      height: 45px; }
      .basic-alert-dialog-modal > .footer > .od-btn.default:focus, .basic-alert-dialog-modal > .footer > .od-btn.default.focus, .basic-alert-dialog-modal > .footer > .od-btn.default:active:focus, .basic-alert-dialog-modal > .footer > .od-btn.default:active.focus, .basic-alert-dialog-modal > .footer > .od-btn.default.active:focus, .basic-alert-dialog-modal > .footer > .od-btn.default.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; }
      .basic-alert-dialog-modal > .footer > .od-btn.default:active, .basic-alert-dialog-modal > .footer > .od-btn.default.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .basic-alert-dialog-modal > .footer > .od-btn.default.disabled, .basic-alert-dialog-modal > .footer > .od-btn.default[disabled],
      fieldset[disabled] .basic-alert-dialog-modal > .footer > .od-btn.default {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .basic-alert-dialog-modal > .footer > .od-btn.default:focus, .basic-alert-dialog-modal > .footer > .od-btn.default.focus {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #8c8c8c; }
      .basic-alert-dialog-modal > .footer > .od-btn.default:hover {
        color: #333333;
        background-color: #d7d7d7;
        border-color: #adadad; }
      .basic-alert-dialog-modal > .footer > .od-btn.default:active, .basic-alert-dialog-modal > .footer > .od-btn.default.active,
      .open > .basic-alert-dialog-modal > .footer > .od-btn.default.dropdown-toggle {
        color: #333333;
        background-color: #d7d7d7;
        background-image: none;
        border-color: #adadad; }
        .basic-alert-dialog-modal > .footer > .od-btn.default:active:hover, .basic-alert-dialog-modal > .footer > .od-btn.default:active:focus, .basic-alert-dialog-modal > .footer > .od-btn.default:active.focus, .basic-alert-dialog-modal > .footer > .od-btn.default.active:hover, .basic-alert-dialog-modal > .footer > .od-btn.default.active:focus, .basic-alert-dialog-modal > .footer > .od-btn.default.active.focus,
        .open > .basic-alert-dialog-modal > .footer > .od-btn.default.dropdown-toggle:hover,
        .open > .basic-alert-dialog-modal > .footer > .od-btn.default.dropdown-toggle:focus,
        .open > .basic-alert-dialog-modal > .footer > .od-btn.default.dropdown-toggle.focus {
          color: #333333;
          background-color: #c5c5c5;
          border-color: #8c8c8c; }
      .basic-alert-dialog-modal > .footer > .od-btn.default.disabled:hover, .basic-alert-dialog-modal > .footer > .od-btn.default.disabled:focus, .basic-alert-dialog-modal > .footer > .od-btn.default.disabled.focus, .basic-alert-dialog-modal > .footer > .od-btn.default[disabled]:hover, .basic-alert-dialog-modal > .footer > .od-btn.default[disabled]:focus, .basic-alert-dialog-modal > .footer > .od-btn.default[disabled].focus,
      fieldset[disabled] .basic-alert-dialog-modal > .footer > .od-btn.default:hover,
      fieldset[disabled] .basic-alert-dialog-modal > .footer > .od-btn.default:focus,
      fieldset[disabled] .basic-alert-dialog-modal > .footer > .od-btn.default.focus {
        background-color: #f0f0f0;
        border-color: #ccc; }
      .basic-alert-dialog-modal > .footer > .od-btn.default .badge {
        color: #f0f0f0;
        background-color: #333333; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.back-primary-color {
  background-color: #eabdbd; }

.position-relative {
  position: relative; }

#page_brand {
  width: 100vw;
  overflow-x: hidden; }

.diagonal-background {
  position: relative; }
  .diagonal-background::before {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    display: inline-block;
    border-top: 100vh solid #eabdbd;
    border-right: 100vw solid #c6bbeb; }

.theme-background-wrap {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden; }

.theme-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-image: url("/html/template/default/assets/brandsite/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: multiply; }

.theme-background-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #77d2dd, #89d2db 17.7%, #a6d2d8 28.1%, #fed2d0 68%, #edd5c1 100%, transparent); }

.theme-background-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("/html/template/default/assets/brandsite/background-blue.png");
  background-repeat: no-repeat;
  background-size: cover;
  visibility: hidden; }

#arrow-canvas {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh; }

.gradient-bg {
  background: linear-gradient(to right, #d13b9d, #9620c5 48%, #276acf 100%, transparent); }

.gradient-txt {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.base-link {
  background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
  color: white;
  border-radius: 30px;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13px;
  line-height: 24px; }
  @media only screen and (min-width: 768px) {
    .base-link {
      padding-left: 18px;
      padding-right: 18px;
      font-size: 20px;
      line-height: 40px; } }
  .base-link .suffix-icon {
    margin-left: 6px; }
  .base-link:hover {
    background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%);
    color: white;
    text-decoration: none; }

.sticky-top-nozindex {
  position: sticky;
  top: 0px; }

.ml-22 {
  margin-left: 22px; }

.mt-36 {
  margin-top: 36px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-8 {
  margin-top: 8px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-14 {
  margin-top: 14px; }

.section-top {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  .section-top__contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto; }
    .section-top__contents .logo {
      width: 91px; }
      @media only screen and (min-width: 768px) {
        .section-top__contents .logo {
          width: 148px; } }
    .section-top__contents .logo-title {
      margin-top: 6px; }
      @media only screen and (max-width: 767px) {
        .section-top__contents .logo-title {
          max-width: 148px; } }
    .section-top__contents__links {
      display: flex;
      flex-direction: row;
      justify-content: center; }
  .section-top__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto; }
    .section-top__footer .below-navi {
      color: white; }

.section-hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .section-hero .section-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 227.24px;
    font-weight: 600;
    font-size: 8vw;
    color: rgba(255, 255, 255, 0.6); }
    @media only screen and (max-width: 767px) {
      .section-hero .section-contents {
        font-size: 13vw; } }

.section-fullorder {
  height: 70vh;
  margin-left: 14vw; }
  @media only screen and (min-width: 768px) {
    .section-fullorder {
      margin-left: 50vw; } }
  .section-fullorder .section-contents {
    max-width: 255px; }
    @media only screen and (min-width: 768px) {
      .section-fullorder .section-contents {
        max-width: 432px;
        min-height: 413.6px; } }
    .section-fullorder .section-contents .title {
      width: 68%;
      max-width: 174px; }
      @media only screen and (min-width: 768px) {
        .section-fullorder .section-contents .title {
          max-width: 296px; } }
    .section-fullorder .section-contents .comment-1 {
      width: 100%; }
    .section-fullorder .section-contents .comment-3 {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .section-fullorder .section-contents .comment-3 .comment-3-1 {
        height: 14px; }
      .section-fullorder .section-contents .comment-3 .comment-3-2 {
        height: 16px; }

.section-aboutus {
  height: 70vh; }
  .section-aboutus .section-contents {
    margin: 0 0 0 46px;
    display: flex;
    flex-direction: column;
    max-width: 232px; }
    @media only screen and (min-width: 768px) {
      .section-aboutus .section-contents {
        margin: 0 0 0 20vw; } }
    @media only screen and (min-width: 768px) {
      .section-aboutus .section-contents {
        max-width: 410px;
        min-height: 311px; } }
    .section-aboutus .section-contents .comment-1 {
      font-size: 18px;
      font-weight: 100; }
      @media only screen and (min-width: 768px) {
        .section-aboutus .section-contents .comment-1 {
          font-size: 27px; } }
    .section-aboutus .section-contents .comment-2 {
      font-size: 23px;
      font-weight: 600;
      font-family: serif; }
      @media only screen and (min-width: 768px) {
        .section-aboutus .section-contents .comment-2 {
          font-size: 40px;
          line-height: 60px; } }
    .section-aboutus .section-contents .comment-3 {
      font-size: 16px;
      font-weight: 300;
      line-height: 29px;
      height: 73px; }
      @media only screen and (max-width: 767px) {
        .section-aboutus .section-contents .comment-3 {
          max-width: 231px; } }
    .section-aboutus .section-contents .to-aboutus {
      margin-top: 30px;
      max-width: 160px; }
      @media only screen and (min-width: 768px) {
        .section-aboutus .section-contents .to-aboutus {
          max-width: 236px; } }
  .section-aboutus .mt {
    margin-top: 17px; }
    @media only screen and (min-width: 768px) {
      .section-aboutus .mt {
        margin-top: 28px; } }

.section-shop {
  height: 70vh;
  margin-left: 14vw; }
  @media only screen and (min-width: 768px) {
    .section-shop {
      margin-left: 50vw; } }
  .section-shop .section-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 453.18px;
    max-width: 274px; }
    @media only screen and (min-width: 768px) {
      .section-shop .section-contents {
        max-width: 401px; } }
    .section-shop .section-contents .comment-1 {
      width: 100%; }
    .section-shop .section-contents .title {
      margin-top: 67px;
      width: 203px; }
      @media only screen and (min-width: 768px) {
        .section-shop .section-contents .title {
          width: 296px; } }
    .section-shop .section-contents .comment-2 {
      margin-top: 25px;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .section-shop .section-contents .comment-2 {
          margin-top: 35px; } }

@media screen and (max-width: 1189px) and (min-width: 768px) {
  section#news,
  section#contents,
  section#category {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: unset; } }

section#news {
  padding-top: 50vh; }
  section#news .ec-newsRole__news {
    background: #ffffff; }
    @media only screen and (max-width: 767px) {
      section#news .ec-newsRole__news {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) {
      section#news .ec-newsRole__news {
        padding-top: 15px;
        padding-bottom: 15px; } }
  section#news .ec-newsRole__newsItem {
    border-bottom: 1px #ccc solid;
    padding: 15px;
    margin-bottom: 0px; }
  section#news .ec-newsRole__newsItem:last-child {
    border-bottom: unset; }
  @media only screen and (min-width: 768px) {
    section#news .ec-newsRole__newsDescription {
      margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .section-layout {
    max-width: 1150px;
    margin: 0 auto 0 auto; } }

@media only screen and (max-width: 767px) {
  .section-layout .ec-secHeading,
  .section-layout .section-heading {
    margin: 0 16px 15px 16px; }
  .section-layout .ec-newsRole__news,
  .section-layout .categorys-list,
  .section-layout .contents-list {
    margin: 0 28px 0 28px; } }

.contents-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .contents-list__item--wrapper {
    flex: 1;
    margin-bottom: 65px;
    max-width: 360px; }
  .contents-list__item {
    max-width: 320px; }
    .contents-list__item--thumbnail {
      max-width: 320px;
      object-fit: cover; }
    .contents-list__item--title {
      margin-top: 18px;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px; }
    .contents-list__item--description {
      margin-top: 16px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 300; }
    .contents-list__item--link {
      margin-top: 8px;
      font-size: 10px;
      line-height: 30px; }
      @media only screen and (min-width: 768px) {
        .contents-list__item--link {
          font-size: 12px;
          line-height: 36px; } }

.section-heading {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4; }
  .section-heading__separator {
    font-weight: 300;
    margin: 0 20px;
    display: inline-block;
    width: 1px;
    height: 14px; }
  .section-heading__title {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.15em;
    vertical-align: 2px; }

section#category {
  padding-bottom: 50px; }

.categorys-list {
  max-width: 1150px;
  margin: 0;
  padding: 0;
  width: auto;
  max-width: unset; }
  @media only screen and (max-width: 767px) {
    .categorys-list {
      margin: 0 0 0 2vw !important; } }
  .categorys-list__inner {
    margin: 0;
    padding: 0;
    width: 100%; }
    .categorys-list__inner::after {
      content: "";
      display: block;
      clear: both; }

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .categorys-list__inner .grid-item {
    width: calc((100% / 3) - 42px);
    padding: 0;
    float: left; }
  .categorys-list__inner .grid-item:nth-of-type(3n+1) {
    margin: 31.5px 31.5px 31.5px 0px; }
  .categorys-list__inner .grid-item:nth-of-type(3n+2) {
    margin: 31.5px; }
  .categorys-list__inner .grid-item:nth-of-type(3n+3) {
    margin: 31.5px 0px 31.5px 31.5px; } }

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .categorys-list__inner .grid-item {
    overflow: hidden;
    margin: 0 10px 10px 0;
    width: calc((100% / 3) - (20px /3));
    float: left; }
  .categorys-list__inner .grid-item:nth-of-type(3n+3) {
    margin: 0 0px 10px 0; } }
    @media only screen and (max-width: 767px) {
      .categorys-list__inner .grid-item {
        width: calc(100% / 2);
        overflow: hidden;
        float: left;
        padding: 0 2vw 2vw 0; }
        .categorys-list__inner .grid-item img {
          width: 100%;
          border-radius: 2.6664vw; } }
  .categorys-list__item {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; }
    .categorys-list__item img {
      transition: all 0.25s ease 0s;
      width: 100%; }
    .categorys-list__item:hover > img {
      opacity: 0.9;
      border-radius: 1.5vw; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.brandsite__about-us {
  font: normal normal normal 16px/27px serif; }
  .brandsite__about-us .brandsite__about-us__bgimg {
    height: 100vh;
    position: fixed;
    background-image: url(/html/template/default/assets/brandsite/background.png);
    background-size: cover;
    background-position: center center;
    width: 100%;
    top: 0;
    z-index: 10; }
  .brandsite__about-us #arrow-canvas {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 11;
    position: fixed; }
  .brandsite__about-us .brandsite__about-us__title-section {
    z-index: 100;
    position: relative;
    margin: 21.4px auto; }
    .brandsite__about-us .brandsite__about-us__title-section span:nth-child(1) {
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding: 60px 0 0; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__title-section span:nth-child(1) {
          padding: 200px 0 0;
          font-size: 13px; } }
    .brandsite__about-us .brandsite__about-us__title-section span:nth-child(2) {
      display: block;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      padding: 1vh 0 0; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__title-section span:nth-child(2) {
          font-size: 23px;
          padding: 7px 0 0 0; } }
  .brandsite__about-us .brandsite__about-us__section1 {
    z-index: 100;
    height: 900px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .brandsite__about-us .brandsite__about-us__section1 {
        height: auto;
        padding: 0px 0 100px; } }
    .brandsite__about-us .brandsite__about-us__section1 p:nth-child(1) {
      font-size: 40px;
      line-height: 60px;
      font-weight: 600;
      position: absolute;
      top: 165px;
      left: calc(50% - 504px); }
      @media screen and (max-width: 1119px) {
        .brandsite__about-us .brandsite__about-us__section1 p:nth-child(1) {
          left: 54px; } }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section1 p:nth-child(1) {
          margin-top: 190px;
          font-size: 20px;
          line-height: 30px;
          position: static;
          padding: 0 30px; } }
    .brandsite__about-us .brandsite__about-us__section1 p:nth-child(2) {
      width: 475px;
      font-size: 16px;
      font-weight: 300;
      position: absolute;
      top: 308px;
      left: calc(50% - 504px); }
      @media screen and (max-width: 1119px) {
        .brandsite__about-us .brandsite__about-us__section1 p:nth-child(2) {
          left: 54px; } }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section1 p:nth-child(2) {
          width: auto;
          position: static;
          font-size: 12px;
          padding: 0 30px;
          line-height: 2;
          margin-top: 10px; } }
  .brandsite__about-us .brandsite__about-us__section2 {
    z-index: 100;
    position: relative;
    height: 455px;
    padding: 1px 0 0; }
    .brandsite__about-us .brandsite__about-us__section2 p:nth-child(1) {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      padding: 0px 0 0;
      line-height: 1;
      margin-top: 60px; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section2 p:nth-child(1) {
          font-size: 22px;
          margin-top: 60px; } }
    .brandsite__about-us .brandsite__about-us__section2 p:nth-child(2) {
      font-weight: 600;
      font-size: 23px;
      text-align: center;
      padding: 0 0;
      margin-top: 35px;
      line-height: 1.9; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section2 p:nth-child(2) {
          font-size: 13px;
          margin-top: 17px; } }
    .brandsite__about-us .brandsite__about-us__section2 a {
      display: block;
      background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
      color: #fff;
      border: none;
      font-family: sans-serif;
      padding: 10px 15px;
      border-radius: 20px;
      margin: 0 auto; }
      .brandsite__about-us .brandsite__about-us__section2 a:hover {
        background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%);
        color: #fff;
        text-decoration: none; }
    .brandsite__about-us .brandsite__about-us__section2 a:nth-child(3) {
      padding: 15px 20px;
      margin-top: 35px;
      width: 278px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      border-radius: 100px;
      line-height: 1; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section2 a:nth-child(3) {
          padding: 10px 0px;
          font-size: 13px;
          width: 172px; } }
    .brandsite__about-us .brandsite__about-us__section2 a:nth-child(4) {
      padding: 15px 20px;
      margin-top: 20px;
      width: 396px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      border-radius: 100px;
      line-height: 1; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section2 a:nth-child(4) {
          padding: 10px 0px;
          font-size: 13px;
          width: 248px; } }
  .brandsite__about-us .brandsite__about-us__section3 {
    z-index: 100;
    position: relative;
    height: auto;
    padding: 0px 0 0px 0; }
    .brandsite__about-us .brandsite__about-us__section3 p:nth-child(1) {
      font-weight: 600;
      text-align: center;
      line-height: 1; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section3 p:nth-child(1) {
          font-size: 22px;
          margin-bottom: 16px; } }
      @media only screen and (min-width: 768px) {
        .brandsite__about-us .brandsite__about-us__section3 p:nth-child(1) {
          font-size: 33px;
          margin-bottom: 28px; } }
    .brandsite__about-us .brandsite__about-us__section3 p:nth-child(2) {
      text-align: right;
      line-height: 1;
      /*外枠のレイアウト*/
      font-family: sans-serif;
      margin: 0 auto; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section3 p:nth-child(2) {
          margin-bottom: 15px; } }
      @media only screen and (min-width: 768px) {
        .brandsite__about-us .brandsite__about-us__section3 p:nth-child(2) {
          margin-bottom: 25px; } }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section3 p:nth-child(2) {
          padding: 0 15px;
          max-width: 510px;
          font-size: 12px; } }
      @media only screen and (min-width: 768px) {
        .brandsite__about-us .brandsite__about-us__section3 p:nth-child(2) {
          font-size: 15px;
          width: 66.407vw;
          max-width: 1000px;
          font-size: 13px; } }
    .brandsite__about-us .brandsite__about-us__section3 .accshow {
      transition: 0.8s;
      display: flex;
      flex-wrap: wrap; }
    .brandsite__about-us .brandsite__about-us__section3 .accbox {
      /*外枠のレイアウト*/
      font-family: sans-serif;
      margin: 0 auto;
      /*ラベル*/
      /*アイコンを表示*/
      /*チェックは隠す*/
      /*中身を非表示にしておく*/
      /*クリックで中身表示*/
      /*アイコンを入れ替える*/ }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section3 .accbox {
          padding: 0 15px;
          max-width: 510px; } }
      @media only screen and (min-width: 768px) {
        .brandsite__about-us .brandsite__about-us__section3 .accbox {
          font-size: 15px;
          width: 66.407vw;
          max-width: 1000px; } }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .area_name {
        display: block;
        margin: 5px 0;
        padding: 10px 12px;
        background: #333333;
        cursor: pointer;
        transition: all 0.5s;
        color: white;
        border-radius: 4px; }
        .brandsite__about-us .brandsite__about-us__section3 .accbox .area_name:hover {
          background: #555555;
          color: #dddddd; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .area_name::before {
        padding-right: 5px;
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        font-weight: 900; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox input {
        display: none; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .accshow {
        height: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.8s;
        display: flex;
        flex-wrap: wrap; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .cssacc:checked + label + .accshow {
        height: auto;
        padding: 5px;
        opacity: 1; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .cssacc:checked + .area_name::before {
        font-family: "Font Awesome 5 Free";
        content: "\f078";
        font-weight: 900; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar {
        width: 100%;
        margin: 10px; }
        .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_box {
          float: left;
          display: block;
          width: 50px;
          height: 30px;
          text-align: center; }
          .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_box .pref_checkbox {
            display: none; }
          .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_box .pref_name {
            margin: auto;
            font-size: 11px;
            color: #333333;
            cursor: pointer;
            padding: 0px; }
            .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_box .pref_name:hover {
              text-decoration: underline; }
        .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_checked {
          background-color: #333333;
          border-radius: 24px;
          text-align: center; }
          .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_checked .pref_name {
            color: #ffffff; }
            .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_bar .pref_name_checked .pref_name:hover {
              text-decoration: none; }
      .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_container {
        width: 100%; }
        .brandsite__about-us .brandsite__about-us__section3 .accbox .pref_container .pref_info {
          width: 100%;
          display: none;
          flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .brandsite__about-us .brandsite__about-us__section3 .top_bg {
        width: 100%;
        height: 100px;
        display: block; } }
    @media only screen and (min-width: 768px) {
      .brandsite__about-us .brandsite__about-us__section3 .body_bg {
        background-size: 1366px;
        width: 100%;
        height: auto;
        display: block;
        padding-top: 375px;
        padding-bottom: 100px; } }
  @media only screen and (min-width: 768px) and (min-width: 1367px) {
    .brandsite__about-us .brandsite__about-us__section3 .body_bg {
      background-size: cover; } }
    @media only screen and (min-width: 768px) {
      .brandsite__about-us .brandsite__about-us__section3 .bottom_bg {
        width: 100%;
        height: 200px;
        display: block; } }
  .brandsite__about-us .brandsite__about-us__section4 {
    z-index: 100;
    position: relative;
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 140px 0 200px 0; }
    @media only screen and (max-width: 767px) {
      .brandsite__about-us .brandsite__about-us__section4 {
        margin: 0 auto 100px;
        padding: 140px 0 0px 0;
        height: auto; } }
    .brandsite__about-us .brandsite__about-us__section4 .wrapper {
      margin: 0 15px; }
    .brandsite__about-us .brandsite__about-us__section4 .inner {
      width: auto;
      max-width: 970px;
      margin: 0 auto;
      padding: 50px 15px 65px 15px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.35); }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 .inner {
          margin: 0 auto;
          padding: 30px 10px 30px 10px; } }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 .inner {
          max-width: 510px; } }
      @media only screen and (min-width: 768px) {
        .brandsite__about-us .brandsite__about-us__section4 .inner {
          width: 66.407vw;
          max-width: 1000px; } }
    .brandsite__about-us .brandsite__about-us__section4 p:nth-child(1) {
      font-size: 33px;
      font-weight: 600;
      text-align: center;
      padding: 0 0 50px; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(1) {
          font-size: 22px;
          padding: 0 0 25px; } }
    .brandsite__about-us .brandsite__about-us__section4 p:nth-child(2) {
      border-bottom: solid #cccccc 1px;
      font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      border-top: solid #cccccc 1px;
      padding: 20px; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(2) {
          font-size: 12px;
          padding: 25px 10px 25px; } }
      .brandsite__about-us .brandsite__about-us__section4 p:nth-child(2) span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px; }
        @media only screen and (max-width: 767px) {
          .brandsite__about-us .brandsite__about-us__section4 p:nth-child(2) span {
            position: initial;
            margin: 0px; } }
    .brandsite__about-us .brandsite__about-us__section4 p:nth-child(3) {
      border-bottom: solid #cccccc 1px;
      font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(3) {
          font-size: 12px;
          padding: 25px 10px 25px; } }
      .brandsite__about-us .brandsite__about-us__section4 p:nth-child(3) span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px; }
        @media only screen and (max-width: 767px) {
          .brandsite__about-us .brandsite__about-us__section4 p:nth-child(3) span {
            position: initial;
            margin: 0px; } }
    .brandsite__about-us .brandsite__about-us__section4 p:nth-child(4) {
      border-bottom: solid #cccccc 1px;
      font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(4) {
          font-size: 12px;
          padding: 25px 10px 25px; } }
      .brandsite__about-us .brandsite__about-us__section4 p:nth-child(4) span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px; }
        @media only screen and (max-width: 767px) {
          .brandsite__about-us .brandsite__about-us__section4 p:nth-child(4) span {
            font-size: 12px;
            position: initial;
            margin: 0px; } }
    .brandsite__about-us .brandsite__about-us__section4 p:nth-child(5) {
      border-bottom: solid #cccccc 1px;
      font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(5) {
          font-size: 12px;
          padding: 25px 10px 25px; } }
      .brandsite__about-us .brandsite__about-us__section4 p:nth-child(5) span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px; }
        @media only screen and (max-width: 767px) {
          .brandsite__about-us .brandsite__about-us__section4 p:nth-child(5) span {
            font-size: 12px;
            position: initial;
            margin: 0px; } }
    .brandsite__about-us .brandsite__about-us__section4 p:nth-child(6) {
      border-bottom: solid #cccccc 1px;
      font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px; }
      @media screen and (max-width: 1132px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(6) {
          padding: 20px 20px 40px; } }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 p:nth-child(6) {
          padding: 25px 10px 25px; } }
      .brandsite__about-us .brandsite__about-us__section4 p:nth-child(6) span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px; }
        @media only screen and (max-width: 767px) {
          .brandsite__about-us .brandsite__about-us__section4 p:nth-child(6) span {
            font-size: 12px;
            position: initial;
            margin: 0px; } }
    .brandsite__about-us .brandsite__about-us__section4 a {
      display: block;
      background: linear-gradient(to right, rgba(209, 59, 157, 0.7) 0%, rgba(150, 32, 197, 0.7) 50%, rgba(39, 106, 207, 0.7) 100%);
      color: #fff;
      border: none;
      font-family: sans-serif;
      padding: 10px 15px;
      border-radius: 20px;
      margin: 0 auto; }
      .brandsite__about-us .brandsite__about-us__section4 a:hover {
        background: linear-gradient(to right, #d13b9d 0%, #9620c5 50%, #276acf 100%);
        color: #fff;
        text-decoration: none; }
    .brandsite__about-us .brandsite__about-us__section4 a:nth-child(7) {
      padding: 15px 20px;
      margin-top: 35px;
      width: 278px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      border-radius: 100px;
      line-height: 1; }
      @media only screen and (max-width: 767px) {
        .brandsite__about-us .brandsite__about-us__section4 a:nth-child(7) {
          padding: 10px 0px;
          font-size: 13px;
          width: 172px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.brandsite__selectguide .top-img img {
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .brandsite__selectguide .top-img img {
      height: 121.33333vw;
      object-fit: cover;
      object-position: 32.5% 50%;
      max-height: 255px; } }
  @media only screen and (max-width: 767px) and (max-width: 449px) {
    .brandsite__selectguide .top-img img {
      max-height: unset; } }

.brandsite__selectguide .parts-discription {
  background-color: #f5f5f5;
  color: #555555;
  padding-bottom: 30px; }
  .brandsite__selectguide .parts-discription .topic-path {
    display: flex;
    font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
    font-size: 13px;
    font-weight: 300;
    padding: 10px; }
    .brandsite__selectguide .parts-discription .topic-path a {
      color: #000; }
  .brandsite__selectguide .parts-discription [class^="parts-discription__"] {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000025;
    font-family: serif;
    padding: 40px;
    width: calc(100vw - 20px);
    max-width: 748px;
    border-radius: 8px;
    margin: 0 auto 50px; }
    @media only screen and (max-width: 767px) {
      .brandsite__selectguide .parts-discription [class^="parts-discription__"] {
        padding: 40px 20px; } }
    @media screen and (max-width: 449px) {
      .brandsite__selectguide .parts-discription [class^="parts-discription__"] {
        padding: 30px 20px; } }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] .sakura {
      display: inline-block;
      width: 34px;
      margin: 0 6px 5px 0; }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] .title {
      display: inline;
      font-size: 24px;
      font-weight: 600; }
      @media screen and (max-width: 449px) {
        .brandsite__selectguide .parts-discription [class^="parts-discription__"] .title {
          font-size: 17px; } }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] .subtitle {
      font-weight: 600;
      font-size: 20px;
      border-bottom: solid #707070 1px;
      margin: 40px 0 0; }
      @media screen and (max-width: 449px) {
        .brandsite__selectguide .parts-discription [class^="parts-discription__"] .subtitle {
          font-size: 16px;
          margin: 30px 0 0; } }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] .subheading {
      font-size: 20px;
      padding-bottom: 0px;
      margin: 40px 0 0 0; }
      @media screen and (max-width: 449px) {
        .brandsite__selectguide .parts-discription [class^="parts-discription__"] .subheading {
          font-size: 16px;
          margin: 30px 0 0; } }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] [class^="discription-"] {
      font-size: 15px;
      font-weight: 300;
      margin: 10px 0 0; }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] .table-div {
      overflow-x: scroll; }
    .brandsite__selectguide .parts-discription [class^="parts-discription__"] .order-parts-help-contents {
      margin: 0; }
  .brandsite__selectguide .parts-discription__1 {
    margin: 50px auto !important; }
  .brandsite__selectguide .parts-discription__2 .shaft {
    width: 100%;
    margin: 10px 0; }
  .brandsite__selectguide .parts-discription__2 .dura {
    width: 100%;
    margin: 10px 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.brandsite__about-arrow .top-img {
  overflow: hidden; }
  .brandsite__about-arrow .top-img img {
    width: 100%; }
    @media screen and (max-width: 546px) {
      .brandsite__about-arrow .top-img img {
        width: auto; } }

.brandsite__about-arrow .topic-path {
  display: flex;
  font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
  font-size: 13px;
  font-weight: 300;
  padding: 10px; }
  .brandsite__about-arrow .topic-path a {
    color: #000; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
.brandsite__interview .top-img {
  overflow: hidden; }
  .brandsite__interview .top-img img {
    width: 100%; }
    @media screen and (max-width: 546px) {
      .brandsite__interview .top-img img {
        width: auto; } }

.brandsite__interview .topic-path {
  display: flex;
  font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
  font-size: 13px;
  font-weight: 300;
  padding: 10px; }
  .brandsite__interview .topic-path a {
    color: #000; }

.page-404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

@media only screen and (max-width: 767px) {
  .sp-none {
    display: none; } }

/* モーダル表示中に裏のScrollを止める */
html[data-modal-active="true"] {
  touch-action: none;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  overflow: hidden; }

[data-modal-active="true"] body {
  touch-action: none;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  overflow: hidden; }
