@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  font-family: $font-product;
  background-color: $color-ecsite-1;

  .ec-grid2 {
    @include media_mobile {
      max-width: $max-width-mb;
      margin: 0 auto;
    }

    &__cell {
      @include media_mobile {
        position: unset;
      }
    }
    &__cell:nth-child(1) {
      @include media_mobile {
        padding: 100px 0 0 0;
      }
    }
  }
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__category-top {
    padding: 0 0 10px;
    font-size: 14px;

    p {
      @include media_mobile {
        position: absolute;
        top: 110px;
        padding: 0 0 0 14px;
      }
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin:0 20px 0 20px;
    }
  }
  & &__title {
    @include media_mobile {
      padding: 0 14px;
    }

    .ec-headingTitle {
      font-size: 20px;
      @include media_desktop {
        font-size: 32px;
      }
      @include media_mobile {
        position: absolute;
        top: 140px;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0 0 16px;
    border-bottom: 1px dotted #fff;
    @include media_mobile {
      padding: 0 14px 16px;
    }

    span {
      font-size: 0.7em;
      margin: 0 60px 0 0;
    }
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 0.7em;
    color: #525263;
    border: solid 1px #d7dadd;
    border-radius: 3px;
    background-color: #f5f7f8;
    cursor: pointer;
  }
  & &__priceRegularPrice {
    padding: 0 0.8em 7px;
    opacity: 0.5;
    font-size: 0.8em;
    border-bottom: 1px dotted #fff;
  }
  & &__priceRegular {
    padding-top: 14px;
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: #de5d50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0;
    border-top: 1px dotted #fff;
    border-bottom: 1px dotted #fff;
    @include media_desktop {
      padding: 14px 0 7px;
    }
    @include media_mobile {
      padding: 0 14px;
    }

    .ec-price__price {
      @include media_mobile {
        font-size: 24px;
        padding: 20px 0;
      }
    }
  }
  & &__code {
    padding: 14px 0;
    font-size: 0.7em;
    border-bottom: 1px dotted #ccc;
    border-top: 1px dotted #ccc;
  }
  & &__caption {
    font-size: 0.8em;
    font-weight: 900;
    padding: 20px 50px 0 0;
    border-top: 1px dotted #fff;

    @include media_mobile {
      border-top: white 1px dotted;
      margin: 20px 0 0 0;
      padding: 20px 14px;
    }
  }
  & &__category {
    padding: 14px 0;
    position: relative;
    @include media_mobile {
      padding: 14px 14px;
    }

    a {
      color: #000;
      border-bottom: 1px solid black;
    }
    ul {
      list-style: none;
      padding: 0 0 0 90px;
      margin: 0;
      font-size: 0.7em;
    }
    &__span {
      position: absolute;
      font-size: 0.7em;
    }
  }
  & &__actions {
    padding: 14px 0;
    @include media_mobile {
      padding: 14px;
    }

    .ec-select {
      @include media_desktop {
        display: flex;
      }

      &__caption {
        font-weight: 900;
        padding: 10px 50px 0 0;
        @include media_desktop {
          font-size: 0.8em;
        }
        @include media_mobile {
          font-size: 12px;
          padding: 10px 50px 10px 0;
          display: block;
        }
      }

      select {
        height: 40px;
        box-shadow: $box-shadow;
        @include media_desktop {
          width: 70%;
        }
        @include media_mobile {
          width: 100%;
        }
      }
    }

    .ec-numberInput {
      &__caption {
        font-size: 0.8em;
        font-weight: 900;
        padding: 20px 65px 10px 0;
        @include media_mobile {
          font-size: 12px;
          padding: 10px 50px 10px 0;
          display: block;
        }
      }
      input {
        box-shadow: $box-shadow;
      }
    }
  }
  & &__btn {
    @include media_desktop {
      width: 60%;
      margin-bottom: 16px;
      min-width: 350px;
    }
    @include media_mobile {
      width: 100%;
      padding: 0 10px 10px 10px;

      button {
        width: 100%;
      }
    }
  }
  & &__description {
    margin-bottom: 16px;
    font-size: 0.7em;
    @include media_mobile {
      border-bottom: white dotted 1px;
      padding: 14px;
    }
  }
}
