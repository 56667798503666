@import "../mixins/media";

@include media_desktop() {
  .order-yashaku-select {

    .body {
      display: flex;
      flex-direction: column;

      > hr {
        width: 100%;
      }
    }

    &-table {
      margin: 42px auto 0;
      width: 335px;
      display: block;
      line-height: 1;
      border-top: 1px solid #666666;

      > tr {
        height: 56px;
        border-bottom: 1px solid #666666;
      }

      .table-col1{
        width: 100px;
      }
      & span {
        margin: 0;
      }
      .table-col2{
        width: auto;
        font-weight: 400;
        padding:20px 0;
      }

      .table-col2-value {
        height: 40px;
        width: 235px;
        border:none;
        font-size: 16px;
        border-radius: 6px;
        color: #fff;
        background-color: #333;
        padding: 0 8px;

        cursor: pointer;
        &:disabled{
          cursor: default;
        }
        
      }
    }

    .category-label2{
      display: none;
    }
    .caption {
      margin: 42px auto 0;
      width: 335px;
      display: block;
      line-height: 1.5;
      color: #333;
    }

    .disabled {
      .table-col1-label {
        color: rgb(170, 170, 170);
      }
    }
  }
}
