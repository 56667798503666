@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://demo3.ec-cube.net/404)

Markup:
include /assets/tmpl/elements/20.1.404.pug
+ec-404Role

Styleguide 20.1

*/
.ec-404Role {
  @include commonStyle();
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  text-align: center;
  box-sizing: border-box;
  & &__icon {
    img {
      width: 1em;
      height: 1em;
    }
  }
  & &__title {
    font-weight: bold;
    font-size: 25px;
  }
}
