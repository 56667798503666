@import "../mixins/media";

.layout {
  &-right {
    @include media_desktop() {
      width: 452px;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
    }
    @include media_mobile() {
      position: absolute;
      top: 0px;
      width: 100vw;
    }
  }
}

.basic-alert-dialog {
  &-overlay {
    z-index: $zindex-overlay-back;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-modal {
    z-index: $zindex-overlay-front;
    width: 315px;
    background-color: white;
    border-radius: 11px;

    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    > .title {
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    > .description {
      margin-top: 28px;

      font-family: sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    > .footer {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      > .od-btn {
        &.default {
          @include btn-od-default;
          width: 100%;
          height: 45px;
        }
      }
    }
  }
}
