@media screen and (min-width: 768px) {
  .order-mozikokuin {
    overflow-x: scroll;
    height: calc(100vh - 59px);
    padding: 10px 15px 40px 15px;

    & &__body {
      padding:0 0 60px 0;

      .order-mozikokuin-select-div {
        padding: 0 15px　0px 15px　;

        .tab-string-label-title {
          width:402px;
          font-size: 18px;
          color:#333333;
          border-bottom: 1px #333333 solid;
          padding-bottom: 13px;
          margin: 30px auto 0 auto;
          font-weight: 500;
          line-height: 1;

        }
        .tab-string-label {

        }
        .tab-string-comment{
          width:402px;
          font-size: 11px;
          color:#333333;
          margin: 20px auto 0 auto;
          font-weight: 500;
          line-height: 1.7;
        }



        .tab-string-content {
          width:422px;
          margin: 20px 0 0px 0;
          padding: 0 0 20px 0;
          display: flex;
          flex-wrap: wrap;

          label{
            padding-bottom: 0;
          }

          .tab-color-switch {
            display: none;
          }
          .tab-color-switch:checked + .order-color-img {
            background-color: gray;
          }
        }
        .tab-string-text {
          margin: 10px 0 40px 0;
          text-align: center;

          input {
            width: 90%;
            height: 30px;
          }
        }
        &__product-container {
          display: flex;

          @include media_desktop() {
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            padding: 0;
          }

          @include media_mobile() {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
          }
        }
      }
      .product-container-text-input {

        > .attension-describe {
          width: 402px;
          font-size: 11px;
          color: #333333;
          margin: 20px auto 30px auto;
          font-weight: 500;
          line-height: 1.7;
          display: block;
        }

        .text-input-label-div {

          .text-input-label-p1 {
            display: none;
          }
          .text-input-label-p2 {
            font-size: 12px;
            margin: 10px 15px 10px 15px;
          }
        }
        .mozikokuin-input-text {
          margin: 15px 15px 0 15px;
          height:  40px;
          font-size: 18px;
          line-height: 40px;
          width: calc(100% - 30px);
          display: block;
          border-radius: 4px;
          border: solid #999999 1px;
          padding: 0 11px;
          color:#333;

          &::placeholder{
            color:#BEBEBE;
          }
        }
      }

    }
  }
}
