@import "../mixins/media";

.order-select-base-layout-2 {
  @include media_desktop() {
    width: 452px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  @include media_mobile() {
    width: 50vw;
    height: calc(100vh - 94px);
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    right: 0px;
    top: 94px;
  }

  .body {
    @include media_desktop() {
      height: calc(100% - 59px);
    }
    @include media_mobile() {
      position: relative;
      z-index: 900;
      height: 100%;

      .heading {
        position: absolute;
        top: -14px;
        left: 8px;

        font-size: 12px;
        line-height: 30px;
        padding: 0 14px;
        color: #ffffff;
        background-color: #333333;
        border-radius: 4px;
      }
    }

    //テスト用 - iga
    @include media_landscape_order_made() {
      //background-color: #c00;
    }
    //テスト用 - iga
    @include media_portrait_order_made() {
      //background-color: #00c
    }
  }

  .footer {
    @include media_mobile() {
    }

    &__div {
      @include media_mobile() {
        padding: 4px 16px 14px 16px;
        height: 62px;
        display: flex;
        justify-content: space-between;

        .back_to_top {
          width: auto;
          display: inline-block;
          height: 44px;
          line-height: 44px;
          color: #ffffff;
          background-color: #333333;
          border: none;
          border-radius: 22px;
          padding: 0;
          margin: 0;
          font-size: 12px;
          display: flex;
        }
        .help-button {
          width: auto;
          display: inline-block;
          height: 44px;
          line-height: 44px;
          color: #333333;
          background-color: #efefef;
          border: none;
          border-radius: 22px;
          padding: 0 22px;
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }
}
