@import "../mixins/media";

.btn-3d {
  border: none;
  padding: 0;

  @include media_mobile() {
    width: 45px;
    height: 45px;
  }

  &:hover img[src="/html/template/default/assets/img/common/3d-disable.svg"]{
    opacity: 0.7;
  }
  &:hover img[src="/html/template/default/assets/img/common/3d-enable.svg"]{
    cursor: default;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.btn-2d {
  border: none;
  padding: 0;
  margin-top: 6px;

  @include media_mobile() {
    width: 45px;
    height: 45px;
    margin-top: 5px;
  }

  &:hover img[src="/html/template/default/assets/img/common/2d-disable.svg"]{
    opacity: 0.7;
  }
  &:hover img[src="/html/template/default/assets/img/common/2d-enable.svg"]{
    cursor: default;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.dimension-toggle-button {
  display: flex;
  flex-direction: column;

  @include media_desktop() {
    margin-bottom: 20px;
  }
}
