@import "../mixins/btn";
@import "../mixins/media";
/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/

/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  @include btn-default;
  @include ec-inlineBtn();
}
.ec-inlineBtn--primary {
  @include btn-primary;
}
.ec-inlineBtn--action {
  @include btn-action;
  border-radius: 30px;
  background: $color-ecsite-button;
  width: 40%;
  height: 45px;
  line-height: 27px;
  box-shadow: $box-shadow;
  border: none;
  &:hover{
    background: $color-ecsite-button-hover;
  }
}
.ec-inlineBtn--cancel {
  @include btn-cancel;
  border-radius: 30px;
  background: $color-ecsite-button-cancel;
  width: 40%;
  height: 45px;
  line-height: 27px;
  box-shadow: $box-shadow;
  border: none;
  &:hover{
    background: $color-ecsite-button-cancel-hover;
  }
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  @include blockBtn-default;
}
.ec-blockBtn--primary {
  @include blockBtn-primary;
}
.ec-blockBtn--action {
  @include blockBtn-action;
  border-radius: 30px;
  background: $color-ecsite-button;
  color: #fff;
  height: 45px;
  line-height: 45px;
  box-shadow: $box-shadow;
  border: none;
  transition: all 0.25s ease 0s;
  @include media_mobile {
    margin: 0 auto;
  }
  &:hover{
    background: $color-ecsite-button-hover;
  }
}
.ec-blockBtn--cancel {
  @include blockBtn-cancel;
  border-radius: 30px;
  height: 45px;
  line-height: 45px;
  background-color: $color-ecsite-button-favorite;
  box-shadow: $box-shadow;
  border: none;
  transition: all 0.25s ease 0s;
  &:hover{
    background: $color-ecsite-button-favorite-hover;
  }
}
