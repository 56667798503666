@import "./variables";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/forms";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeIn-right {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes fadeIn-left {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes fadeIn-under {
  0% {
    transform: translateY(100vw);
  }
  100% {
    transform: translateY(0px);
  }
}

@mixin fadeIn-use($name, $duration, $delay, $transition-timing-function: cubic-bezier(0.06, 0.98, 0.63, 1.89)) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  transition-timing-function: $transition-timing-function;
  animation-fill-mode: both;
}

@mixin fadeIn($display: block, $time: 150ms) {
  display: $display;
  opacity: 1;
  visibility: visible;
  animation: fadeIn $time linear 0s;
}
@mixin fadeOut($time: 150ms) {
  opacity: 0;
  visibility: hidden;
  animation: fadeOut $time linear 0s;
}

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

.slide-animation {
  &-enter {
    transform: translateX(100%);

    &-active {
      transform: none;
      transition: transform 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }

    &-done {
      transform: none;
    }
  }

  &-exit {
    transform: none;

    &-active {
      transform: translateX(100%);
      transition: transform 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }

    &-done {
      transform: none;
    }
  }
}
