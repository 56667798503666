@import "../mixins/media";

@include media_desktop {
  .order-total-value {
    position: absolute;
    z-index: 500;
    padding: 0;
    margin: 0;
    left: 13px;
    top: 74px;

    &__detail-info {
      line-height: 1;
      padding: 0;
      margin: 0;
      font-size: 12px;

      font-weight: 300;
    }
    &__div {
      line-height: 1;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: left;

      svg{
        width:84.05px;
        height:14.89px;
        margin: 10.4px 14.4px 0 0;
      }

      .price {
        padding: 0;
        margin: 11px 0 0 0;
        font-size: 17px;
        font-weight: 300;

        .smaller{
          font-size: 12px;
          display: inline-block;
          margin-left: 0.5em;
        }

      }
    }
    &__partsname {
      display: none;
    }
    &__name {
      display: none;
      padding: 0 0 0 8px;
    }
  }
}
@include media_mobile {
  .order-total-value {
    position: absolute;
    width: 100vw;
    height: 52px;
    top: 42px;
    left: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.05);

    &__detail-info {
      width: 100vw;
      font-size: 12px;
      line-height: 1;
      margin: 9px 0 0 12px;
      padding: 0;
    }
    &__div {
      display: flex;
      justify-content: space-between;
      width: 100vw;
      margin: 0 auto;
      padding: 0;

      svg {
        margin: 7px 0 0 13px;
        width: 84.05px;
        height: 14.89px;
      }
      .price {
        width: 50vw;
        text-align: right;
        margin: 3px 5px 0 0;
        font-size: 18px;

        .smaller {
          font-size: 12px;
        }
      }
    }

    &__partsname {
      font-size: 9px;
      line-height: 14px;
      margin: 8px 0 0 12px;
      display: none;
    }
    &__name {
      font-size: 9px;
      line-height: 14px;
      margin: 0px 0 0 12px;
      padding-left: 1em;
      display: none;
    }
  }
}
