@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole {
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    //border-color: #d0d0d0;
    //border-style: solid;
    //border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    @include media_desktop {
      flex-wrap: nowrap;
    }
  }

  & &__item {
    width: calc(50% - 1px);
    //border-color: #d0d0d0;
    //border-style: solid;
    //border-width: 0 1px 1px 0;
    text-align: center;
    font-weight: 300;
    
    margin: 0.5px;
    border-radius: 4px;
    overflow: hidden;

    @include media_desktop {
      border-radius: 0;
    }

    &:first-child{
      @include media_desktop {
        border-radius: 10px 0 0 10px;
      }
    }
    &:last-child{
      @include media_desktop {
        border-radius: 0 10px 10px 0;
      }
    }

    a {
      padding: 18px;
      font-size: 14px;
      width: 100%;
      display: inline-block;
      background: $color-ecsite-mypage-navlist;

      &:hover {
        background: $color-ecsite-mypage-navlist-hover;
      }
    }
    &.active a{
      background: $color-ecsite-mypage-navlist-active;
      color: #ffffff;
      &:hover{
        background: $color-ecsite-mypage-navlist-active-hover;
      }
    }

  }

}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  @include borderBottom;

  margin: 2em 0 2em 0;
  padding-bottom: 2em;

  @include media_desktop {
    margin: 3em 0 3em 0;
    padding-bottom: 3em;
  }

  p{
    margin-top: 0.75em;
  }
}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole {
  & &__header {
    margin-bottom: 16px;
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    
    @include media_mobile {
      justify-content: center;
    }
  }
  & &__item {
    margin-bottom: 8px;

    /*レスポンシブ用*/
    width: 273px;
    @media screen and (max-width:1202px) {
      width: calc((100vw - 112px) / 4);
    }
    @media screen and (max-width:767px) {
      width: calc((100vw - 32px) / 3);
    }
    @media screen and (max-width:449px) {
      width: calc((100vw - 32px) / 2);
    }
    
    
    position: relative;
    box-sizing: border-box;
    padding: 10px;

    //hover用
    a.ec-favoriteRole__itemThumb:hover .ec-favoriteRole__item-image{
      opacity: 0.5;
      position: relative;
      z-index: 1;
    }
    a.ec-favoriteRole__itemThumb:hover ~ .ec-favoriteRole__itemTitle {
      text-decoration: underline;
    }
    a.ec-favoriteRole__itemThumb:hover ~ .ec-favoriteRole__itemPrice{
      text-decoration: underline;
    }

    &-image {
      margin-bottom: 10px;
      text-align: center;

      /*レスポンシブ用*/
      height: calc(273px - 20px);
      @media screen and (max-width:1202px) {
        height: calc(((100vw - 112px) / 4) - 20px);
      }
      @media screen and (max-width:767px) {
        height: calc(((100vw - 32px) / 3) - 20px);
      }
      @media screen and (max-width:449px) {
        height: calc(((100vw - 32px) / 2) - 20px);
      }

    }
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .ec-closeBtn--circle {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 30px;
      min-width: unset;
      min-height: unset;
      z-index: 100;

      .ec-icon img {
        width: 30px;
        height: 30px;
      }

      &:hover{
        opacity:0.6;
      }
    }
  }
  & &__itemThumb {
    display: block;
    height: auto;
    margin-bottom: 8px;
  }
  & &__itemTitle {
    margin-bottom: 2px;
    font-size: 16px;
    @include media_mobile {
      font-size: 13px;
    }  }
  & &__itemPrice {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 16px;
    @include media_mobile {
      font-size: 13px;
    }
  }
}

.ec-editRole {
  .ec-off4Grid__cell {
    margin: 0 auto;
  }
}
