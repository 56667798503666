@media screen and (min-width: 768px) {
  .order-kamon {
    overflow-y: scroll;
    height: 100%;

    & &__body {
      height: calc(100% - 121px);

      .order-kamon-select-div {
        padding: 0 15px;

        .tab-string-label-title {
          width: 402px;
          font-size: 18px;
          color: #333333;
          border-bottom: 1px #333333 solid;
          padding-bottom: 13px;
          margin: 30px auto 0 auto;
          font-weight: 600;
          line-height: 1;
        }
        .tab-string-label {
        }

        //ここから 家紋の選択ボタン
        .kamon-select-button {
          width: 191px;
          //cursor: pointer;
          margin: 0 10px;
          border-radius: 4px;
          padding: 11px 11px 8px 11px;
          position: relative;
          display: block;
          background-color: #f0f0f0;

          &__image-wapper {
            width: 167px;
            height: 111px;
            object-fit: cover;
            border: solid #f0f0f0 1px; //imgがwhiteの時のため
            border-radius: 0;
            margin: 0;
            padding: 0;
            text-align: center;
            background-color: #f6f7f9;
          }

          &__image {
            width: auto;
            height: 87px;
            margin-top: 13px;
          }

          &__text {
            width: 167px;
            font-size: 11px;
            line-height: 15px;
            margin: 10px 0;
            font-weight: normal;
          }

          &__icon-selected {
            display: block;
            position: absolute;
            top: 6px;
            left: 6px;
          }

          &__preview-button {
            background-color: black;
            border-radius: 20px;
            font-size: 14px;
            font-weight: normal;
            border: none;
            width: 179px;
            height: 40px;
            line-height: 40px;
            color: #ffffff;
            margin: 0 0 0px -6px;

            &:hover {
              opacity: 0.75;
            }
          }
        }

        .tab-string-content {
          margin: 30px 0 0px 0;
          padding: 0 0 20px 0;
          display: flex;

          label {
            padding-bottom: 0;
          }

          .tab-color-switch {
            display: none;
          }
          .tab-color-switch:checked + .order-color-img {
            background-color: gray;
          }
        }
        .tab-string-text {
          margin: 10px 0 40px 0;
          text-align: center;

          input {
            width: 90%;
            height: 30px;
          }
        }

        &__product-container {
          display: flex;

          @include media_desktop() {
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            padding: 0;

            & > .product-thumbnail.item {
              margin: 10px;
              //win対策
              width: 44.93827%;
            }
          }

          @include media_mobile() {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}
