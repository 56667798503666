@media screen and (min-width: 768px) {
  .customizedesign-container {
    position: absolute;
    top: 0px;
    right: 0px;

    // refer: react-tabs/style/react-tabs.css
    .order-design-select {
      padding: 24px;
      height: calc(100vh - 59px);
      background-color: rgba(255, 255, 255, 0.3);

      .react-tabs {
        height: 100%;
        -webkit-tap-highlight-color: transparent;
      }

      .react-tabs__tab-list {
        width: 452px;
        height: 94px;
        margin: -24px -24px 0 -24px;
        padding: 25px 0;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.3);
      }

      .react-tabs__tab {
        display: inline-block;
        position: relative;
        list-style: none;
        padding: 0;
        cursor: pointer;
        flex: 1;
        text-align: center;
        margin: 0px 12px;
        border-radius: 80px;
        font-weight: 600;

        font-size: 17px;
        line-height: 44px;
        height: 44px;
        width: 10em;

        background: $ordersite-tab-button-bg;
        color: $ordersite-tab-button-color;
        &:hover{
          background: $ordersite-tab-button-bg-hover;
          color: $ordersite-tab-button-color-hover;          
        }
      }

      .react-tabs__tab--selected {
        background: $ordersite-tab-button-selected-bg;
        color: $ordersite-tab-button-selected-color;
        font-weight: 500;
        &:hover{
          background: $ordersite-tab-button-selected-bg-hover;
          color: $ordersite-tab-button-selected-color-hover;
        }
      }

      .react-tabs__tab--disabled {
        cursor: default;
      }

      .react-tabs__tab:focus {
      }

      .react-tabs__tab:focus:after {
      }

      .react-tabs__tab-panel {
        display: none;
      }

      .react-tabs__tab-panel--selected {
        display: block;
        height: calc(100% - 56px);
        overflow-y: scroll;
      }

      .order-design-select-container {
        padding: 0;
        margin-top: 30px;
        display: block;

        &__comment {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          margin: 0 0 30px 0;
          line-height: 1.6;
        }
      }
    }
  }
}
