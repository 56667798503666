@import "../mixins/media";

@include media_mobile() {
  .order-yashaku-select {
    hr {
      margin-top: 0;
      margin-bottom: 0;
    }

    .body {
      display: flex;
      flex-direction: column;
      background-color: rgba(255,255,255,0.5);
    }

    &-table {
      width: calc(100% - 30px);
      margin: 20px 15px 0;
      border-top: 1px solid #d9d9d9d9;

      > tr {
        border-bottom: 1px solid #d9d9d9d9;
        padding:20px 0;

        th,td{
          display:block;
        }

      }

      .toggleContainer{
        font-size: 13px;
        font-weight: 500;
      }
      .table-col2-value {
        height: 35px;
        width: 100%;
        border:none;
        background: #333;
        font-size: 13px;
        font-weight: 500;
        border-radius: 6px;
        color:#ffffff;
        padding:0 0 0 11px;

        cursor: pointer;
        &:disabled{
          cursor: default;
        }

      }

      span {
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 7px;
      }

    }

    .table-col1 {
      padding-top: 20px;
    }

    .table-col2 {
      padding-bottom: 20px;
    }

    .caption {
      margin: 20px 15px 0;
      font-size: 10px;
      display: block;

    }

    .disabled {
      .table-col1-label {
        color: rgb(170, 170, 170);
      }
    }
  }
}
