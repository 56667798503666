@import "../mixins/media";
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/

.ec-registerRole {
  @include container;
  max-width: $max-width-dt;
  @include media_desktop {
    margin: 80px auto;
  }

  p {
    font-family: $font-product;
    @include media_desktop {
      padding: 0 0 30px;
    }
  }
  .ec-pageHeader {
    h1 {
      @include media_mobile {
        border: none;
      }
    }
  }
  .ec-registerRole__actions {
    .ec-blockBtn--action {
      margin: 10px 0;
    }
    .ec-blockBtn--cancel {
      margin: 10px 0;
      background-color: #525263;
      &:hover{
        background-color: #333333;
      }
    }
  }
}
.ec-registerRole__siteseal {
  margin-bottom: 16px;
  @include media_mobile {
    // text-align: center;
    padding: 0 0 30px;
  }
  .ec-registerRole__siteseal_cotainer {
    @include media_desktop {
      max-width: 150px;
      max-height: 58px;
      width:100%;
      height:auto;
    }
    @include media_mobile {
      width:100px;
      height:39px;
      margin: 0 auto;
    }
  }
  a {
    img {
      @include media_desktop {
        max-width: 150px;
        max-height: 58px;
        width:100%;
        height:auto;
      }
      @include media_mobile {
        width:100px;
        height:39px;
      }
    }
  }
}
.ec-registerRole__actions {
  padding-top: 20px;
  padding: 0 0 50px;
  text-align: center;

  @include media_desktop {
    text-align: left;
    margin-bottom: 100px;
  }
  @include media_mobile {
    margin: 0 0 20px;
  }

  p {
    margin-bottom: 16px;
  }
  .ec-off4Grid {
    @include row;
    &__cell {
      margin: 0;

      @include media_desktop {
        margin-left: percentage((4 / 12));
        width: 275px;
      }
      @include media_mobile {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
}
.ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-registerCompleteRole {
  @include container;
  margin: 0 auto 30px;

  .ec-reportHeading {
    @include media_mobile {
      border-top: none;
    }
  }

  .ec-blockBtn--action {
    width: 200px !important;
  }
  .ec-off2Grid__cell {
    margin: 0 auto;
  }
  .ec-off4Grid__cell {
    margin: 0 auto !important;

    .ec-blockBtn--cancel {
      width: 200px !important;
      margin: 0 auto;
    }
  }

  & &__go-to-top {
    margin: 0 0 200px;

    .ec-off4Grid__cell {
      @include media_desktop {
        margin: 0 auto !important;
      }

      a {
        width: 300px;
      }
    }
  }
}
.ec-RegisterRole__actions {
  padding: 0 0 50px;

  .ec-off4Grid {
    &__cell {
      width: 40%;
    }
  }
}

.ec-role-complete {
  h1 {
    border-top: none;
  }

  & &__go-to-top {
    margin: 0 0 200px;

    .ec-off4Grid__cell {
      @include media_desktop {
        margin: 0 auto !important;
      }

      a {
        width: 300px;
      }
    }
  }
}
.ec-RegisterRole__actions {
  padding: 0 0 50px;

  .ec-off4Grid {
    &__cell {
      width: 40%;
    }
  }
}

.ec-role-complete {
  h1 {
    border-top: none;
  }
}
