@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/btn";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/





/*▼フッター拡張版の表示をオンするページの指定▼*/
#page_homepage/*EC => トップ*/,
#page_product_list/*EC => 商品一覧*/,
#page_product_detail/*EC => 商品詳細*/,
#page_help_about/*EC => お買い物ガイド*/,
#page_help_tradelaw/*EC => 特定商取引法に基づく表記*/,
#page_help_privacy/*EC => プライバシーポリシー*/,
#page_brand/*ブランド => トップ*/,
#page_brand_about_us/*ブランド => 咲矢弓道具について*/,
#page_brand_select_guide/*ブランド => 矢の選び方*/,
.blog.home.hfeed/*ブログ => TOP*/,
.archive.hfeed/*ブログ => カテゴリ一覧*/,
.search.hfeed/*ブログ => 検索結果*/,
.single-post/*ブログ => 記事ページ*/{

  .ec-footerRole {
    height: 100vh;

    .ec-footerTitle{
      display:block;
    }
    .ec-footerNavi{
      display:block;
    }
    .ec-footerButton{
      display:flex;
    }
    .ec-footerTitle__copyright{
      position: absolute;
      height: auto;
    }
    &__inner {
      @media screen and (max-width:624px) {
        height: 100vh;
      }
    }
  }

}
/*▲フッター拡張版の表示をオンするページの指定▲*/




.ec-footerRole {
  background: $color-ecsite-1;
  height: auto;

  @media screen and (min-width: 625px) {
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width:624px) {

  }

  & &__inner {
    @media screen and (min-width: 625px) {
      @include container;
      padding: 0;
    }
    @media screen and (max-width:624px) {
      position: relative;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  display:none;/*noneにしておいてください*/
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;
  @media screen and (min-width: 625px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    margin: 0;
  }
  @media screen and (max-width:624px) {
    display: none;
  }

  & &__link {
    display: block;

    @media screen and (min-width: 625px) {
      display: inline-block;
    }

    a {
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      @media screen and (min-width: 625px) {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
      }
    }
    &:hover {
      a {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  display:none;/*noneにしておいてください*/
  text-align: center;
  color: white;
  @media screen and (min-width: 625px) {
    padding: 0;
  }
  @media screen and (max-width:624px) {
    padding: 30vh 0 0;
  }

  & &__logo {
    @media screen and (min-width: 625px) {
      margin: 0 auto;
      width: 150px;
    }
    @media screen and (max-width:624px) {
      width: 100%;
      margin: 0 auto;
    }

    img {
      @media screen and (min-width: 625px) {
        width: 100%;
      }
      @media screen and (max-width:624px) {
        width: 24vw;
      }
    }

  }

  & &__title {
    display: block;
    margin-bottom: 10px;
    @include reset_link();
    @media screen and (min-width: 625px) {
      width: 400px;
      margin: 9px auto 0;
    }
    @media screen and (max-width:624px) {
      width: 100%;
      margin: 2.5vw auto 0;
    }

    a {
      font-size: 22px;
      color: inherit;
      @media screen and (min-width: 625px) {
        font-size: 24px;
      }
      @media screen and (max-width:624px) {
        height: 100%;
        width: 100vw;
        display: block;
        margin: 0 auto;
      }

      img {
        @media screen and (min-width: 625px) {
          width: 100%;
        }
        @media screen and (max-width:624px) {
          width: 64vw;
        }
      }
    }

    &:hover {
      a {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
  &__copyright {
    //font-size: 2.66vw;
    color: #fff;
    position: static;/*縮小表示時の指定*/
    height: 50px;/*縮小表示時の指定*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;

    @include media_mobile {
      bottom: 17.1px;
    }

    @media screen and (min-width: 625px) {

      bottom: 25px;
      left: 0;
      right: 0;
      letter-spacing: 0.1em;
    }
    @media screen and (max-width:624px) {
    
      width: 100vw;
    }
  }
}

.ec-footerButton {
  //display: flex;
  display:none;/*noneにしておいてください*/

  justify-content: space-around;

  @media screen and (min-width: 625px) {
    margin: 0 auto;
    width: 523px;
    padding: 35px 0 0;
  }
  @media screen and (max-width:624px) {
    //margin: 9vw auto 0;
    //width: 96vw;
    margin: 0 auto;
    //width: 83.815vw;
    width: 92.815vw;
    padding: 5.60898vw 0 0;
  }

  button {
    background: $color-ecsite-button;
    color: #fff;
    font-family: sans-serif;
    @include ec-inlineBtn();
    box-shadow: none;

    &:hover{
      background: $color-ecsite-button-hover;
      color: #fff;
    }

    @media screen and (min-width: 625px) {
      font-size: 18px;
      width: 234px;
      line-height: 1.7;
      border-radius: 50px;
    }
    @media screen and (max-width:624px) {
      //font-size: 3.5vw;
      //width: 44vw;
      //height: 10vw;
      //border-radius: 5vw;

      //font-size: 2.88462vw;
      //width: 37.5vw;

      font-size: 3.2vw;
      width: 40.5vw;
      line-height: 1.7;
      border-radius: 5vw;
    }

  }

  & &__1 {

  }
  & &__2 {

  }
}
