.tab-product-categorylist {

  &--scrollable-container {
    flex: 1;
    overflow-y: scroll;
  }

  &--header {
    height: 95px;

    cursor: pointer;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 20px;
    background-color: rgba(255,255,255,0.25);

    &-link-item-wrapper {
      list-style: none;
      display: flex;
    }

    &-link-item {
      flex: 0 0 auto;
      height: 44px;
      max-width: 140px;
      line-height: 44px;
      padding: 0 15px;
      color: #333333;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 45px;
      text-decoration: none;
      font-weight: 600;
      font-size: 17px;

      &:hover{
        text-decoration: none;
        background-color: rgba(0,0,0,0.1);
        color: #333333;
      }
    }

    &-active {
      flex: 0 0 auto;
      height: 44px;
      max-width: 140px;
      line-height: 44px;
      padding: 0 22px;
      color: #ffffff;
      background-color:#333333;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 45px;
      text-decoration: none;
      font-weight:600;
    }
  }

  &--section {
    .product-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 30px;
    }
  }
}

.section-category-label {
  width: 402px;
  font-size: 18px;
  color: #333333;
  border-bottom: 1px #333333 solid;
  padding-bottom: 13px;
  margin: 30px auto 0 auto;
  font-weight: 500;
  line-height: 1;
}
