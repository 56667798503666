@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/btn";

@include media_desktop {
  .ec-top-img-container {
    background-color: $color-ecsite-2;
    overflow: hidden;

    & &__img1 {
      position: relative;
      margin: 0 auto;
      width: 100vw;

      &__1 {
        width: 100vw;
        object-fit: cover;
        max-height: 800px;
      }

      &__2 {
        position: absolute;
        width: 17vw;
        top: 19.5vw;
        left: 41.5vw;
        @media screen and (min-width:1423px) {
          width: 241.73px;
          top:277.290px;
          left:50%;
          margin-left: -120.865px;
        }
      }
      &__3 {
        position: absolute;
        width: 32vw;
        bottom: 3vw;
        right: 4vw;
        @media screen and (min-width:1423px) {
          width: 455.03px;
          bottom:42.660px;
          right:56.880px;
        }
      }
      &__4 {
        position: absolute;
        width: 9vw;
        top: 2vw;
        right: 3vw;
        @media screen and (min-width:1423px) {
          width: 127.969;
          top:28.440;
          right:42.660px;
        }
      }
    }
    .ec-secHeading {
      background-color: $color-ecsite-2;
      margin: 0;
      padding: 30px 20px 10px;
      max-width: $max-width-dt-ecsite-top;
      margin: 0 auto;
      padding: 30px 20px 10px;
    }
    & &__img2 {
      max-width: $max-width-dt-ecsite-top;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background-color: $color-ecsite-2;

      a{
        transition: all 0.25s ease 0s;

        &:hover{
          opacity: 0.7;
        }
      }


      &__1 {
        overflow: hidden;
        margin: 0 10px 10px 0;
        width: calc((100% / 3) - (20px /3));
        float: left;
        &:nth-of-type(3){
          margin: 0 0px 10px 0;
        }

        img {
          width: 100%;
        }
      }
    }
    & &__img3 {

      &__1 {
        width: calc((100% / 4) - (30px /4));
        overflow: hidden;
        margin:  0 10px 10px 0;
        float: left;
        &:nth-of-type(4n+7){
          margin: 0 0px 10px 0;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

@include media_mobile {
  .ec-top-img-container {
    overflow: hidden;
    background: $color-ecsite-2;

    & &__img1 {
      position: relative;
      height: calc(100vh - 59px);
      background-color: #fff;
      @media screen and (min-width: 500px) {

      }
      @media screen and (max-width:499px) {

      }


      &__1 {
        height: 50vh;
        margin: 0 auto;
        display: block;
        width: 100vw;
        object-fit: cover;
        object-position: 30% 50%;
      }
      &__2 {
        width: 45vw;
        margin: -25.5vw auto;
        display: block;

        @media screen and (min-width: 668px) {
          width: 300px;
          margin: -170.340px auto;            
        }


      }
      &__3 {
        position: absolute;
        width: 100%;
        right: 0%;
        bottom: 0;
        margin: 30px 22px;
        max-width: 304px;
      }
      &__4 {
        position: absolute;
        width: 100%;
        right: 0%;
        top: 0px;
        margin: 12px 16px;
        max-width: 81px;
      }
    }
    .ec-secHeading {
      padding: 40px 10px 0;
    }
    & &__img2 {
      //display: flex;
      //flex-wrap: wrap;
      margin: 0 0 20vw 2vw;

      &__1 {
        width: calc(100% / 2);
        overflow: hidden;
        float: left;
        padding: 0 2vw 2vw 0;

        img {
          width: 100%;
          border-radius: 2.6664vw;
        }
      }

    }
    & &__img3 {

      &__1 {
        width: calc(100% / 2);
        overflow: hidden;
        float: left;
        padding: 0 2vw 2vw 0;

        img {
          width: 100%;
          border-radius: 2.6664vw;
        }
      }
    }
  }
}