@import "../mixins/media";
@import "../mixins/btn";

.new-recommend-button {
  @include btn-od-primary2;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &[direction='horizontal'] {
    flex-direction: row;
  }

  &[direction='horizontal'] &__icon {
    padding-right: 4px;
  }

  &[direction='vertical'] {
    flex-direction: column;
  }

  &[direction='vertical'] &__icon {
    padding-bottom: 0.5%;
  }

  &[direction='vertical'] &__title {
    padding-top: 0.5%;
  }
}
