@import "../mixins/variables";

.shipment-dialog {
  width: 315px;

  // override Modal.content
  .Modal {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

      width: 315px;
      user-select: none;
    }
  }

  .title {
    font-size: 20px;
    margin: 15px;
    border-bottom: solid black 1px;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    user-select: none;
  }

  .sub-title {
    font-size: 12px;
    margin: 0 30px;
    font-weight: 300;
    user-select: none;
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
