@import "../mixins/media";
@import "../mixins/projects";

.product-thumbnail {
  @include media_desktop() {
    list-style: none;

    .body {
      width: 191px;
      height: 191px;
      cursor: pointer;
      margin: 0 auto;
      border-radius: 0px;
      //padding: 11px;
      padding: 11px;
      position: relative;
      display: block;
      background-color: rgba(255,255,255,0.3);

      &.disabled {
        opacity: 0.4;
        cursor: unset;
      }

      &:not(.disabled):hover {
        background-color: rgba(255,255,255,0.45);

        .product-thumbnail__icon-zoom {
          display: block;
        }
      }
      input {
        display: none;
      }
    }
    .selected {
      .product-thumbnail__icon-selected {
        display: block;
      }
    }
    &__thumbnail {
      border: solid #f0f0f0 1px; //imgがwhiteの時のため
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: relative;

      > img {
        width: 100%;
        height: 111px;
        object-fit: cover;
      }

      > .familycrest-enabled {
        @include familycrest-enabled;
      }
    }
    &__name {
      width: 100%;
      height: 45px;
      font-size: 11px;
      line-height: 15px;
      font-weight: normal;
      display: block;
      margin-top: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      color:#333333;
    }
    &__icon-selected {
      display: none;
      position: absolute;
      top: 6px;
      left: 6px;
    }
    &__icon-zoom {
      display: none;
      position: absolute;
      top: 87px;
      right: 16px;

      background-color: rgba(0, 0, 0, 0);
      border: none;
      padding: 0;

      &:hover{
        opacity:0.5;
      }
    }
  }
}

@include media_mobile() {
  .product-thumbnail {
    list-style: none;

    .body {
      position: relative;
      width: 136px;
      height: 136px;
      cursor: pointer;
      margin: 0 auto;
      border-radius: 16px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      padding: 0;
      overflow: unset;
      display: block;
      background-color: #ffffff;

      > input {
        display: none;
      }

      > .footer {
        background-color: unset;
        border: none;
        padding: unset;
        width: 100%;

        &:hover {
          opacity: 0.5;
        }
      }

      &.selected {
        .product-thumbnail__icon-selected {
          display: block;
        }
      }
    }

    &__thumbnail {
      margin: 0;
      padding: 0;
      border: none;
      border-bottom: 1px #dddddd;
      position: relative;

      > img {
        width: 136px;
        height: 93px;
        border-radius: 16px 16px 0 0;
        object-fit: cover;
      }

      > .familycrest-enabled {
        @include familycrest-enabled;
      }
    }

    &__name {
      width: 80%;
      height: 18px;
      font-size: 11px;
      line-height: 12px;
      font-weight: normal;
      display: block;
      margin-top: 4px;
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__icon-selected {
      display: none;
      position: absolute;
      top: -5px;
      left: -5px;
    }

    &__icon-zoom {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      bottom: 9px;
      right: 10px;
    }

    &.type-b {
      .body {
        height: auto;
      }
    }

    &.type-b &__thumbnail {
      > img {
        height: 61px;
      }
    }

    &.type-b &__name {
      height: auto;
    }

    &.type-b &__icon-zoom {
      bottom: 2px;
    }

    &.type-c {
      .body {
        min-width: 68px;
        max-width: 80px;
        height: auto;
        border-radius: 8px;
      }
    }

    &.type-c &__thumbnail {
      > img {
        min-width: 68px;
        max-width: 80px;
        height: 43px;
        border-radius: 8px 8px 0 0;
      }
    }

    &.type-c &__name {
      height: auto;
      margin: unset;
    }

    &.type-c &__icon-zoom {
      bottom: 2px;
    }
  }
}
