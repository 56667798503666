@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";

.product-detail {
  background-color: $color-ecsite-2;
  transition: all 0.5s ease 0s;

  @include media_desktop {

    @media screen and (min-width: 1210px){
      padding: 70px 0 0 0;
    }
    @media screen and (max-width: 1209px){
      padding: 40px 0 0 0;
    }
    
  }
  @include media_mobile {
    padding: 25px 0 40px 0;
  }
    


  .ec-productRole,
  .shopping-guide {
    max-width: $max-width-dt;
    margin: 0 auto;
    background-color: initial;
  }

  
  /*商品詳細ブロック*/
  .ec-productRole{
    
    @include media_mobile {
      padding-left: 0px;
      padding-right: 0px;

      .ec-grid2{
      
        max-width: 510px;

        .ec-grid2__header_cell {
          margin-top: -12px;
        }
        
        .ec-grid2__cell:nth-child(1) {
          padding: 0px 0 0 0;
        }
  
        .ec-grid2__cell:nth-child(2){
  
        }
  
      }

    }


    /*MB版オンリーの表示*/
    @include media_mobile {
      .disp-mb-only{
        display:block;
      }
      .disp-dt-only{
        
        display:none;
      }
    }

    /*DT版オンリーの表示*/
    @include media_desktop {
      .disp-mb-only{
        display:none;
      }
      .disp-dt-only{
        
        display:block;
      }
    }    



    /*商品画像*/
    .ec-sliderItemRole{


      @include media_desktop{

      }
      @include media_mobile{
        padding: 0 15px;
      }
      

      .item_visual__ec-icon{

        transition: all 0.5s ease 0s;

        position: absolute;
        z-index: 1;
        width: 60px;
        transform: rotate(0deg);
        border-radius: 0 0 12px 0;
        overflow: hidden;
        right: 20px;

        top: calc(50vw - 121px);
        @media screen and ( min-width:1150px ){
          top: 454px;
        }
      }
      .item_visual__ec-icon-img{
        cursor: zoom-in;
        &:hover{
          opacity:0.8;
        }
      }

      .item_visual{
        border-radius: 12px;
        overflow: hidden;

        *{
          transition: all 0.5s ease 0s;
        }

        @include media_desktop{

        }
        @include media_mobile{
          overflow: unset;
        }

        .slick-list{

          @include media_desktop{

          }
          @include media_mobile{
            border-radius: 12px;
          }

          img{
            
            object-fit: cover;
            object-position: center center;

            @include media_desktop{
              @media screen and (min-width: 1150px){
                height:515px;
              }
              @media screen and (max-width: 1149px){
                height:calc(50vw - 60px);
              }
            }
            @include media_mobile{
              @media screen and (min-width: 510px){
                height:480px;
              }
              @media screen and (max-width: 509px){
                height:calc(100vw - 30px);
              }
            }
          }

        }

        .slick-dots li.slick-active button:before{

          
          @include media_desktop{

          }
          @include media_mobile{
            background-color: #C6BDEB;
          }
        }

      }
    }

    /*DT用サムネ*/
    .item_nav{

      @include media_desktop{
        img{
          border-radius: 8px;
          box-shadow: 0px 3px 6px #00000029;
          object-fit: cover;
          object-position: center center;

          @include media_desktop{
            @media screen and (min-width: 1150px){
              width:calc((515px / 3) * 0.8);
              height:calc((515px / 3) * 0.8);
            }
            @media screen and (max-width: 1149px){
              width:calc(((50vw - 60px) / 3) * 0.8);
              height:calc(((50vw - 60px) / 3) * 0.8);
            }
          }

        }
      }
    }

    
    /*パンくず*/
    .ec-searchnavRole__topicpath{
      padding:0px 0 21px;

      .ec-topicpath{

        @include media_desktop {
          
          padding:10px 0 0px;
          border: 0;
          font-size: 14px;
          
          *{
            font-size: 14px;
          }

        }
        @include media_mobile {
          display:none;
          border-bottom: none;
          padding: 0 15px;

          &:nth-child(1){
            display: block;
          }

        }

        &__item{
          @include media_mobile {
            font-size: 12px;
          }
        }

      }
    }



    /*商品名*/
    &__title{
      

      @include media_desktop {
        
      }
      @include media_mobile {
        padding: 0;
      }

      .ec-headingTitle{
        line-height: 1.4;
        color: #333333;

        @include media_desktop {
          font-size: 30px !important;
          margin: 0 0 20px;
        }
        @include media_mobile {
          font-size: 22px !important;
          position: relative;
          top: unset;
          padding: 0 15px;
          margin-bottom: 15px;
        }
      }

    }

    /*販売価格*/
    &__price{
      border-top: 1px dashed #ffffff;
      border-bottom: none;
      padding: 25px 0 0px !important;

      @include media_mobile {
        margin: 0 15px;
        padding: 15px 0 0px !important;
      }
      
      .ec-price{
        @include media_mobile {
          font-size: 26px;
        }

        &__price{
          @include media_desktop {
            font-size: 30px;
            padding: 0;
            line-height: 1.1;
          }
          @include media_mobile {
            font-size: 26px;
            padding: 0;
          }
        }
      }
    }


    /*標準価格*/
    .ec-productRole__priceRegular{
      opacity: 1 !important;
      padding: 0 !important;
      border-bottom: none !important;
      color: $clrDarkGray !important;

      @include media_desktop {
        font-size: 16px;
      }
      @include media_mobile {
        font-size: 14px;

        padding: 0 15px;
      }

      &Price{
        color:#333333;
        padding: 7px 0 0px;
        opacity: 1;
        font-size: 16px;
        border-bottom: none;

        @include media_mobile {
          font-size: 14px;
          padding: 7px 15px 0;
        }

        .price01-default{
          display: inline;
        }

        .ec-productRole__priceRegularTax{
          display: inline;
          font-size: 12px;
        }

      }

    }

    /*アイコンエリア*/
    .ec-productRole__icon {
      padding-top: 10px;

      @include media_mobile {
        padding: 10px 15px 0 15px;
      }

      /*送料：全国無料*/
      & .shippingFree{
        display: inline-block;
        padding: 0.2em 0.5em;
        color: #fff;
        background-color: #3EBDD0;
        font-size: 14px;
        border-radius: 4px;
        font-family: $font-family-HiraginoKakuGothicProN;
        box-shadow: 0px 3px 6px #00000022;
      }

    }



    /*規格選択エリア*/
    #form1{
      border-top: 1px dashed #ffffff;
      margin: 25px 0 0;

      @include media_mobile {
        margin: 15px 15px 0;
      }
    }
    .ec-productRole__actions {
      padding: 30px 0 15px 0;

      @include media_mobile {
        padding: 20px 0 15px 0;
      }

      .ec-select{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;

        @include media_mobile {
          display: block;
        }

        .ec-select__caption{
          padding: 0;
          width: 87px;
          font-size: 16px;
          @include media_mobile {
            width: 100%;
          }
        }
        select{
          //font-size: 12px;
          padding-right:21px ;
          width: auto;
          border-radius: 5px;

          @include media_mobile {
            margin-top: 6px;
            width:  100%;
          }
        }
      }
      .ec-numberInput{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;

        @include media_mobile {
          display: block;
        }

        .ec-numberInput__caption{
          padding: 0;
          width: 87px;
          font-size: 16px;
          @include media_mobile {
            width: 100%;
          }
        }

        input{
          margin: 0;
          border: none;
          max-width: 90px;
          width: 90px;
          border-radius: 5px;

          @include media_mobile {
            max-width: unset;
            width: 100%;
            margin-top: 6px;
          }
        }
      }
    }

    /*コメントテキストエリア（日時指定の説明）*/
    &__commentText{
      font-size: 14px;
      line-height: 1.8;
      p{
        padding:25px 0px 0 0px
      }
      @include media_mobile {
        p{
          padding:25px 15px 0 15px
        }
      }
    }

    /*カートボタン*/
    &__btn{

      @include media_mobile {
        padding:0 0 10px 0
      }

      .add-cart{
        height:56px;
      }

      .ec-blockBtn--cancel{
        height:56px;
      }


    }

    /*お気に入りボタン*/
    button#favorite{
      @include media_mobile {
        margin: 0 15px 0 15px;
        width:calc(100% - 30px)
      }
    }


    /*商品情報エリア - 見出し部分*/
    &__caption{
      margin:40px 0 0 0;
      font-size: 16px;
      padding: 25px 0 0 0;
      border-top: #ffffff 1px dashed !important;

      @include media_mobile {
        font-size: 16px;
        margin:40px 15px 0 15px;
        padding: 25px 0px 0 0px;
      }
    }

    /*商品情報エリア - カテゴリーエリア*/
    &__category{
      padding: 25px 0 0px 0;

      @include media_mobile {
        margin:0px 15px 0 15px;
        padding: 25px 0px 9px 0px;
      }

      &__span{
        font-size: 14px;

        @include media_mobile {
          font-size: 12px;
        }
      }
      ul{
        font-size: 14px;
        padding: 0px 0 10px 100px;

        @include media_mobile {
          font-size: 14px;
        }

        a{
          color:#333333;
        }
        a:hover{
          opacity:0.7;
        }
      }

    }

    /*商品情報エリア - タグエリア*/
    &__tags{
      margin: 20px 0 0 0;
      padding: 0 0 5px;
      border-bottom: none;

      @include media_mobile {
        margin:0px 15px 0 15px;
        padding: 5px 0px 19px 0px;
      }

      span{
        font-size: 14px;
        margin: 0 66px 0 0;

        @include media_mobile {
          font-size: 12px;
        }

      }
      li{
        font-size: 13px;
        background: #F5F7F8;
        border: none;
        font-family: sans-serif;
        padding:9px 10px;
        line-height: 1;
        margin: 0px 5px 0 0;

        @include media_mobile {
          font-size: 12px;
        }

        a{
          color:#6e6e7c;
        }
        a:hover{
          opacity:0.7;
        }
      }
    }
    
    /*商品詳細テキスト*/
    &__description{
      margin: 20px 0 0 0;
      font-size: 16px;
      border-top: 1px dashed #fff;
      border-bottom: none !important;
      padding: 25px 0 0 0;
      line-height: 1.65;

      @include media_mobile {
        max-width: 480px;
        width: calc(100% - 30px);

        margin:0px auto 0 auto;
        padding: 25px 0px 25px 0px;
        font-size: 14px;
      }
    }
    @include media_desktop {
       > .ec-productRole__description{
        border-top: none;
        margin: 60px 20px 40px 20px;
      }
    }

    /*商品コード*/
    &__code{
      margin: 20px 0 0 0;
      font-size: 14px;
      border-top: 1px dashed #fff;
      border-bottom: none !important;
      padding: 25px 0 0 0;
      line-height: 1.65;

      @include media_mobile {
        margin:0px 15px 0 15px;
        padding: 15px 0px 15px 0px;
        font-size: 12px;
      }

    }

  }



  /*ショッピングガイドエリア*/
  .shopping-guide{

    :not(.shopping-guide__title):not(.shopping-guide__first-h1):not(h1) {
      font-size: 14px;
      line-height: 1.5;
    }

    ul{

      padding-bottom: 20px;
    }

    li{
      line-height: 1.5;
      margin-top: 10px;
    }

    /*もっと読むボタン*/
    @include media_mobile {
      &__openBtn{
        bottom:0px;
        width: 90vw;
        padding: 15px;
        font-weight:600;

        background: linear-gradient(to right,
         rgba(221, 116, 186, 0.9) 0%,
          rgba(181, 97, 214, 0.9) 50%,
           rgba(103, 149, 221, 0.9) 100%);

      } 
    }

  }

}






