@import "../mixins/media";

.Modal {
  .modal_overlay {
    position: fixed;
    z-index: $zindex-overlay-back;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      z-index: $zindex-overlay-front;
      padding: 1em;
      background: #fff;
      text-align: center;
      border-radius: 10px;
    }
    .batsu {
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      z-index: $zindex-overlay-front;
      top: 0;
      @include media_desktop {
        left: 0;
        padding: 0 0 0 calc(100vw - 1em - 300px - 40px);
      }
      @include media_mobile {
        left: 0;
        padding: 0 0 0 calc(100vw - 1em - 300px - 40px);
      }
    }
  }
}

//SelectGuideModal.tsx
.SelectGuideModal {
  .modal_overlay {
    @include media_desktop {
      position: absolute;
    }
    @include media_mobile {
      position: fixed;
    }
    z-index: $zindex-overlay-back;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    justify-content: flex-end;

    .content {
      overflow-x: scroll;
      z-index: $zindex-overlay-front;
      padding: 1em;
      background: #fff;
      text-align: center;

      .ordermade_modal {
        text-align: left;

        h1 {
          font-weight: 900;
          font-size: 23px;
          border-bottom: none;
          width: auto;
          display: inline-block;
          margin: 20px 0 0 0;
          line-height: 1.3;
        }

        .title {
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 12px;
          line-height: 1.3;
          margin-top: 40px;

          border-bottom: solid black 1px;
        }

        p {
          margin: 10px;
        }
        img {
          margin: 10px auto;
          display: block;
          max-width: calc(100% - 20px);
          width: auto;
        }
      }
    }
    .batsu {
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      z-index: $zindex-overlay-front;
      top: 0;
      @include media_desktop {
        left: 0;
        padding: 0 0 100vh calc(100vw - 1em - 300px - 28px);
      }
      @include media_mobile {
        left: 0;
        padding: 0 0 100vh calc(100vw - 1em - 300px - 28px);
      }
    }
  }
}

//modal in OrderParts.tsx
.ordermade_modal {
  width: 300px;

  h1 {
    font-weight: 900;
    font-size: 24px;
    border-bottom: solid black 1px;
    width: 130px;
    margin: 20px auto;
  }
  p {
    margin: 10px;
  }
  button {
    background-color: $btn-primary3-bg;
    color: #fff;
    border: none;
    padding: 12px 36px 12px 39px;
    border-radius: 4px;
    &:hover{
      opacity: 0.8;
    }
  }
}

@media screen and (min-width: 768px) {
  //modal in OrderParts.tsx
  .ordermade_modal {
    width: 300px;

    h1 {
      font-weight: 900;
      font-size: 24px;
      border-bottom: solid black 1px;
      width: 130px;
      margin: 20px auto;
    }
    p {
      margin: 10px;
    }
    button {
      background-color: $btn-primary3-bg;
      color: #fff;
      border: none;
      padding: 12px 36px 12px 39px;
      border-radius: 4px;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}
