@import "../mixins/media";
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0;
  font: $for-font-serif;

  @include media_desktop {
    @include container;
  }
  & &__topicpath {
    @include media_desktop {
       
    }
    @include media_mobile {
      margin:0 10px;

      .ec-topicpath{
        padding: 8px 5px 8px 5px;
        border-bottom: 1px dashed #F7ECEC;

        .ec-topicpath__item{
          font-size: 12px;

        }
      }
    }
  }

  & &__infos {
    @include container;
    display: flex;
    border-top: 0;
    padding-top: 5px;
    flex-direction: column;
    @include media_desktop {
      padding-left: 0;
      padding-right: 0;
      border-top: 1px dashed #F7ECEC;
      padding-top: 16px;
      flex-direction: row;
    }
    @include media_mobile {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 10px;
    }
  }

  & &__counter {
    margin-bottom: 16px;
    width: 100%;
    font-size: 14px;

    @include media_desktop {
      font-size: 16px;
      margin-bottom: 0;
      width: 50%;
    }

    @include media_mobile {
      margin-bottom: 13px;
    }
  }

  & &__actions {
    text-align: right;
    width: 100%;
    
    @include media_desktop {
      width: 50%;
    }

    .ec-select{
      margin-bottom: 35px;
      select{
        font-size: 12px;
        padding: 8px 21px 8px 18px;
        height: auto;
        width: auto;
        cursor: pointer;
      }
      
    }

  }
}
