@import "../mixins/media";

@include media_desktop {
  //Order3d.tsx
  .order_3d_img {
    width: calc(100vw + #{$order-side-panel-width});
    height: 100%;
    position: absolute;
    top: 0px;
    left: -$order-side-panel-width;

    .preview {
      width: 100%;
      height: 100%;
      opacity: 0.95;
    }

    .ctrl-panel {
      display: flex;
      flex-direction: column;
      position: absolute;
      // 1vw is padding for parent element
      right: calc(1vw + 10px + #{$order-side-panel-width});
      bottom: calc(1vw + 10px);
      z-index: 600;
    }
  }
}

@include media_mobile {
  //Order3d.tsx
  .order_3d_img {
    height: 100%;
    position: relative;

    .preview {
      width: 100%;
      height: 100%;

      transform: translateX(0%);
      transition-property: transform;
      transition-duration: 500ms;
      &.parts-center-mode {
        transform: translateX(-25%);
        transition-property: transform;
        transition-duration: 500ms;
      }
    }

    .ctrl-panel {
      display: flex;
      flex-direction: column;
      position: absolute;
      // 1vw is padding for parent element
      right: 78px;
      bottom: calc(76px + 10px);
      z-index: 600;
    }
  }
}
