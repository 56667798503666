@import "../mixins/media";

.zero-new-recommend-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 40px;
  padding-right: 40px;
  @include media_mobile() {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;

    @include media_mobile_xs() {
      font-size: 14px;
    }
  }

  &__btn {
    margin-top: 3%;
  }
}
