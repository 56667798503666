@import "../mixins/media";
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  @include container;
  & &__intro {
    font-size: 16px;
  }
  & &__form {
    margin-bottom: 16px;
  }
  .ec-off4Grid__cell {
    margin: 0 auto !important;
    padding: 0 0 50px;

    button {
      width: 200px !important;
    }
  }
}

.ec-forgetCompleteRole {
  padding: 0 0 100px;
}
