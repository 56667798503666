.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
}

.spinner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .spinner-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
