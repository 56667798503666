/**
 * ECCUBE 固有のスタイルユーティリティ
 */

@mixin borderTop() {
  border-top: 1px dotted #ccc;
}

@mixin borderBottom() {
  border-bottom: 1px dotted #ccc;
}

@mixin reset_link() {
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
}

@mixin familycrest-enabled() {
  position: absolute;
  bottom: 0;
  right: 0;

  color: white;
  background-color: #333333;
  font-size: 10px;
  font-weight: 600;
  font-family: sans-serif;
  padding: 2px 8px;
}
