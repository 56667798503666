@import "../mixins/variables";
/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}

.icon-close {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  width: 49px;
  height: 49px;
  text-align: center;
  line-height: 49px;
  cursor: pointer;

  &:after {
    content: "×";
  }
}
