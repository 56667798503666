@import "../mixins/btn";

.cart-in-dialog {

  width: 315px;

  // override Modal.content
  .Modal {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em;

      width: 315px;
      user-select: none;
    }
  }

  .title {
    padding-bottom: 1.5em;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    user-select: none;
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .next {
      @include btn-od-default;
    }

    .to-ec {
      @include btn-od-default;
      margin-top: 24px;
    }

    .to-cart {
      @include btn-od-primary;
      margin-top: 24px;
    }
  }
}
