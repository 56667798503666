@media screen and (max-width: 767px) {
  .order-mozikokuin {
    width: 100vw;
    height: calc(calc(var(--vh) * 100) - 118px);
    display: flex;
    overflow: scroll;

    .attension-describe {
      display: inline-block;
      font-size: 10px;
      text-indent: -1em;
      padding-left: 1em;
      margin: 0 1em 0.5em;
      text-shadow: 0px 1px 1px #ffffff, 0px -1px 1px #ffffffff; 
    }

    & &__button {
      position: absolute;
      top: calc(52px + 10px);
      left: 7px;
      border: none;
      background-color: rgba(0,0,0,0.65);
      color: #fff;
      padding: 12px 13px;
      border-radius: 20px;
      font-size: 11px;
      font-weight: 400;
      pointer-events: auto;
    }

    .width-100vw {
      width: 100vw;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    //ここから タブ切り替えボタン
    .react-tabs__wrapper {
      width: 100vw;
      overflow-x: scroll;
      padding: 0;
      margin: 0;
    }

    .react-tabs {
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.5);
      pointer-events: auto;
    }

    .react-tabs__tab-list {
      min-width: 100vw;
      list-style: none;
      margin: 14px 0px 0 0px;
      padding: 0;
    }

    .react-tabs__tab {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      background-color: unset;
      padding: 0 12px;
      line-height: 31px;
      height: 31px;
      border-radius: 20px;
      display: inline-block;
      margin: 0 8px;
      cursor: pointer;

      &--selected {
        color: #ffffff;
        background-color: #333333;
        cursor: unset;
      }
    }

    //ここから オンオフボタン
    .product-container__wrapper {
      width: 100vw;
      height: 168px;
      overflow-x: scroll;
      padding: 14px 0 0 0;
      margin: 0 0 0 0;


      .react-tabs__tab-panel{
        width: 100vw;
        display: flex;
        justify-content: left;
      }
    }

    .product-container {
      white-space: nowrap;
      padding: 0 8px;
      margin: 0 auto;
      width: auto;
    }
    .tab-string-label {
      .order-color-img {
        width: 170px;
        height: 100%;

        .order-color-img-img {
          background-color: skyblue;
          height: calc(60% - 8px);
          margin: 4px;
          width: calc(100% - 8px);
        }
        .order-color-img-text {
          font-size: 10px;
          text-align: center;
          height: calc(40% - 4px);
        }
      }

      input {
        display: none;
      }
      .tab-color-switch:checked + .tab-string-label {
        background-color: black;
      }
    }

    .product-container-text-input {
      width: 100%;
      height:136px;
      padding: 0;
      background-color: #EDEDED;

      .text-input-label-div {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 35px 0 0 0;
        width: calc(100vw - 40px);

        .text-input-label-p1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
        }
        .text-input-label-p2 {
          font-size: 12px;
          margin: 2px 0 0 0;
          line-height: 1;
        }
      }
      .mozikokuin-input-text {
        margin: 11px auto 0;
        height: 40px;
        width: 335px;
        display: block;
        border-radius: 4px;
        border: solid #999 1px;
        font-size: 18px;
        padding:0 0 0 11px;
        color:#333333;

        &::placeholder{
          color:#BEBEBE;
        }
      }
    }
  }
}
