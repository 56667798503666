  .order-typezero-select {
    &__description {
      @include media_mobile {
        margin: 10px;
        font-size: 10px;
        font-weight: 300;
      }

      @include media_desktop {
        padding: 40px 40px 10px 40px;
        line-height: 1.8;
      }
    }
  }

  .product-container {
    padding: 0;

    @include media_desktop {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }
  }
