@import "../mixins/btn";
@import "../mixins/media";

.display-inline-block {
  display: inline-block;
}

.product-thumbnail-yesorno {
  @include media_desktop() {
    width: 191px;
    height: 191px;
    cursor: pointer;
    margin: 10px;
    border-radius: 0px;
    //padding: 11px;
    padding: 11px;
    position: relative;
    display: block;
    background-color: rgba(255,255,255,0.3);
  }
  &:hover {
    @include media_desktop() {
      background-color: rgba(255,255,255,0.45);
    }
  }


  @media screen and (min-width: 1305px) {
    height: 208px;
  }
  @include media_mobile() {
    position: relative;
    width: 136px;
    height: 136px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 16px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0;
    overflow: unset;
    display: block;
    background-color: #ffffff;
  }

  input {
    display: none;
  }

  &__svg {
    @include media_desktop() {
      width: 169px;
      height: 113px;
      object-fit: cover;
      border: solid #f0f0f0 1px; //imgがwhiteの時のため
      border-radius: 0;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: #f6f7f9;
      display: flex;
      align-items: center;
      justify-content: center;
      

      img {
        width: auto;
        height: 87px;
        margin-top: 0;
      }
    }

    @include media_mobile() {
      width: 136px;
      height: 93px;
      object-fit: cover;
      border: solid #f0f0f0 1px; //imgがwhiteの時のため
      border-radius: 16px 16px 0 0;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: #f6f7f9;

      img {
        width: auto;
        height: 68px;
        margin-top: 12.5px;
      }
    }
  }

  &__catch-text {
    @include media_desktop() {
      width: 167px;
      height: 111px;
      display: inline-block;
      overflow: hidden; //test code imgにheightが効かないための処置
      object-fit: cover;
      border: solid #f0f0f0 1px; //imgがwhiteの時のため
      border-radius: 0;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: #f6f7f9;

      line-height: 111px;
      font-size: 27px;
      font-weight: bold;
    }
    @include media_mobile() {
      width: 136px;
      height: 93px;
      object-fit: cover;
      border: solid #f0f0f0 1px; //imgがwhiteの時のため
      border-radius: 16px 16px 0 0;
      margin: 0;
      padding: 0;
      text-align: center;
      background-color: #f6f7f9;

      line-height: 93px;
      font-size: 21px;
      font-weight: bold;

      display: block;
      overflow: hidden;
    }
  }

  &__text1,
  &__text2 {
    @include media_desktop() {
      width: 100%;
      height: 45px;
      font-size: 11px;
      line-height: 15px;
      font-weight: normal;
      display: block;
      margin-top: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    @include media_mobile() {
      width: auto;
      height: 36px;
      font-size: 11px;
      line-height: 12px;
      font-weight: normal;
      display: block;
      margin: 4px 10px 0 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
    }
  }

  &__icon-selected {
    display: none;
    position: absolute;
    top: 6px;
    left: 6px;
    @include media_mobile() {
      top: -5px;
      left: -5px;
    }
  }

  &.selected {
    .product-thumbnail-yesorno__icon-selected {
      display: block;
      img {
        display: block;
      }
    }
  }
}
.kamon-select-disable {
  opacity: 0.5;
  pointer-events: none;
}
