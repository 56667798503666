@import url("../../../../../node_modules/normalize.css/normalize.css");

#page_ordermade_top {
  overflow: hidden;
}

body {
  color: #333;
  transition: z-index 0ms 5.28455ms;
  background: #f6f6f6;
  margin: 0;
}
a {
  text-decoration: none;
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}
p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "component/10.1.shopping-guide";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/11.4.top";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";
@import "project/22.2.about";
@import "project/22.3.tradelaw";
@import "project/22.4.shoppinglogin";
@import "project/22.5.privacy";
@import "project/22.6.common";

@import "order_pc/30.1.top";
@import "order_pc/30.3.parts";
@import "order_pc/30.6.customdesign";
@import "order_pc/30.7.mozikokuin";
@import "order_pc/30.12.yashaku";
@import "order_pc/30.13.kamon";
@import "order_pc/30.14.ordermademodal";

@import "order_mobile/31.1.top";
@import "order_mobile/31.3.parts";
@import "order_mobile/31.6.customdesign";
@import "order_mobile/31.7.mozikokuin";
@import "order_mobile/31.12.yashaku";
@import "order_mobile/31.13.kamon";

@import "component/horizontal_divider";
@import "component/zoom_button";
@import "component/dimension_toggle_button";
@import "component/product_thumbnail_square";
@import "component/order_select_base_layout";
@import "component/order_select_base_layout2";
@import "component/normal_button";
@import "component/toggle_button";
@import "component/arrow_thumbnail_3d";
@import "component/kamonselectmodal";
@import "component/product_thumbnail_yesorno";
@import "component/product_thumbnail_underbar";
@import "component/new_recommend_button";
@import "component/zero_new_recommend_button";
@import "component/delete_design_dialog";
@import "component/cart_in_dialog";
@import "component/shaft_change_nock_yaziri_dialog";
@import "component/kamon_view_dialog";
@import "component/spinner";
@import "component/order_3d_img";
@import "component/parts-complete-button";
@import "component/product_container";
@import "component/order-parts-img";
@import "component/design-container";
@import "component/order-total-value";
@import "component/product_thumbnail";
@import "component/order_featherinside_select_component";
@import "component/zero";
@import "component/dropdown";
@import "component/order_option";
@import "component/deliver-point";
@import "component/shipping_edit";
@import "component/shipping_multiple";
@import "component/login_mypage";
@import "component/cartdata";
@import "component/shopping-index";
@import "component/shopping-confirm";
@import "component/shopping-complete";
@import "component/product-list";
@import "component/product-detail";
@import "component/panel";
@import "component/_order-parts-help";
@import "component/familycrest-list";
@import "component/alert-shipdate-dialog";
@import "component/tab_product_categorylist";
@import "component/loading";

@import "edit_cart/button";
@import "component/shipment_dialog";
@import "component/zoom_dialog";
@import "component/basic_alert_dialog";

@import "brandsite/top";
@import "brandsite/aboutus";
@import "brandsite/selectguide";
@import "brandsite/aboutarrow";
@import "brandsite/interview";

.page-404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-none {
  @include media_mobile {
    display: none;
  }
}

/* モーダル表示中に裏のScrollを止める */
html {
  &[data-modal-active="true"] {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}

body {
  [data-modal-active="true"] & {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}
