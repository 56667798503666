@import "../mixins/media";

.ec-role-shopping-login {
  padding: 0 0 20px;
  max-width: $max-width-dt;
  margin: 0 auto;
  @include media_mobile {
    padding: 0 0 100px;
  }

  .ec-pageHeader {
    h1 {
      border-top: none;
    }
  }
}
