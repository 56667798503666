@import "../mixins/media";

.ec-role-privacy {
  max-width: $max-width-dt;  
  padding: 30px 30px 300px;
  margin: 10px auto 0 auto;

  @include media_mobile {
    margin: -10px auto 80px auto;
    padding: 0px 20px;
  }

  .font-weight600 {
    font-weight: 600 !important;
  }

  .ec-pageHeader {
    h1 {
      border-bottom: 1px dotted #ccc;
      margin: 0;
      padding: 20px 0 !important;

      @include media_mobile {
        padding: 0px ​0 20px 0 !important;
      }

      
      font-size: 32px;
      font-weight: bold;
      border-top: none;
      @include media_mobile {
        padding: 20px 10px;
        font-size: 22px;
      } 
    }
  }
  .ec-pageHeader2 {
    padding: 50px 0;
    @include media_mobile {
      padding: 20px 0px;
    }

    h1 {
      border-bottom: 1px dotted #ccc;
      margin: 0;
      padding: 20px 0;
      font-size: 32px;
      font-weight: bold;
    }
    .content-1 {
      &__div {
        border: none;
      }
    }
    p{
      padding: 0 0 10px;
      @include media_mobile {
        font-size: 13px;
        line-height: 1.8;
      }
    }
    .attention {
      padding: 5px 60px;
      @include media_mobile {
        font-size: 13px;
        line-height: 1.8;
      }
    }

  }

  .ec-pageHeader2-privacy {
    p {
      padding: 0 0 10px;
      @include media_mobile {
        font-size: 13px;
        line-height: 1.8;
      }
    }
  }

  .content-1 {
    padding: 50px 60px 60px;
    @include media_mobile {
      padding: 0px 0px 40px;
    }

    &__div {
      border-bottom: 1px dotted #ccc;
      padding: 1px 0 30px 0;
    }
    h1 {
      font-weight: 600;
      border-bottom: 1px dotted #ccc;
      padding-bottom: 0.4em;
      font-size: 26px;
      margin-bottom: 0;

      @include media_mobile {
        font-size: 18px;
      }
      
    }
    h2 {
      font-weight: 600;
      font-size: 20px;
      margin: 30px 0;
      @include media_mobile {
        font-size: 15px;
        margin: 30px 0px 13px 0;
      }
    }
    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.8;
      @include media_mobile {
        font-size: 13px;
        line-height: 1.8;
      }
    }
  }

  
  .padding30px60px {
    padding: 30px 60px;
    @include media_mobile {
      padding: 30px 0 0 0;
    }
  }
  .padding0px0px20px {
    padding: 0 0 20px;
  }
}
