@media screen and (max-width: 767px) {
  #page_ordermade_top_cart, #page_ordermade_top {
    .ordermade-bgimg {
      background-image: url(/html/template/default/assets/img/order/order-top-bg-img.jpg);
      background-size: cover;
      background-position: center;
      height: calc(var(--vh) * 100);
    }
  }

  .background-3d {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: calc(var(--vh) * 100);
    @include fadeIn-use(fadeIn-right, 1s, 0.6s);
  }

  .top_container {
    height: calc(var(--vh) * 100);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  //.ordermade-home-contents-out-wrapper{
  //
  //}

  .ordermade-home-contents-head {
    padding-bottom: 43vw;
  }

  //.ordermade-home-contents-content{
  //padding-bottom:1vw;
  //}

  .ordermade-home-p-1 {
    padding: 0 0 0 0;
    text-align: center;

    img {
      width: 52vw;
      @media screen and (min-width: 509px) {
        //width: 254px;
      }
      @include fadeIn-use(fadeIn-right, 1s, 0.6s);
    }
  }
  .ordermade-home-p-2 {
    padding: 4vw 0 0 0;
    text-align: center;

    img {
      width: 68.8vw;
      @media screen and (min-width: 509px) {
        //width: 458px;
        margin: 0 auto;
      }
      @include fadeIn-use(fadeIn-right, 1s, 0.6s);
    }
  }
  .ordermade-home-block {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    @include fadeIn-use(fadeIn-under, 1s, 1.6s);
    border-radius: 10px 10px 0 0;

    //@media screen and (min-width: 515px) {
    //  margin: 0;
    //}
  }
  .ordermade-home-p-3 {
    color: #1e1e1f;
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 5.6vw;
    padding: 0 0 0 0;
    margin: 7.5vw 0 0 0;
    position: relative;
    display: inline-block;
    line-height: 1;

    .ordermade-home-badge1 {
      position: absolute;
      top: -14vw;
      left: -22vw;
      width: 20.267vw;
    }
  }
  .ordermade-home-p-4 {
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    text-align: center;
    font-size: 3.46vw;
    font-weight: 600;
    color: #d13b9d;
    line-height: 1;
    margin-top: 2vw;
  }
  .ordermade-home-div {
    width: 100vw;
    padding: 7vw 0 10vw 0;

    display: flex;
    justify-content: center;

    .minimum-button {
      color: $ordersite-button-black-color;
      background-color: $ordersite-button-black-bg;
      background-color: #333;
      padding: 3.5vw 3.06667vw 3.5vw 5.06667vw;
      margin: 0 1.5vw;
      font-size: 4vw;
      line-height: 1;
      border-radius: 6vw;
      color: #fff;
      border: none;
      font-weight: 300;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;

      display: flex;
      align-items: center;

      &:hover{
        color: $ordersite-button-black-color-hover;
        background: $ordersite-button-black-bg-hover;
      }

      img {
        margin: 0 0 0 2.8vw;
        width: 4.534vw;
      }
    }
  }

  .ordermade-order-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    width: 100vw;
    padding: 0;
    background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
    @include fadeIn-use(fadeIn-under, 1s, 0s);
    position: absolute;
    bottom: 0;
    left: 0;

    & .header {
      color: #333;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      font-weight: 500;
      text-align: center;
      font-size: 5.35vw;
      position: relative;
      margin: 7.5vw auto 0 auto;
      white-space: nowrap;
    }

    & .ordermade-home-badge2 {
      width: 20.27vw;
      position: absolute;
      top: -11vw;
      left: -25vw;
    }

    & .ordermade-home-badge3 {
      width: 20.27vw;
      position: absolute;
      top: -11vw;
      right: -25vw;
    }

    & .sub-header {
      color: #d13b9d;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      text-align: center;
      font-size: 3.4vw;
      font-weight: 600;
      line-height: 1;
    }

    & .estimate {
      color: #333;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      font-size: 3.46666vw;
      text-align: center;
      padding: 2.5vw 0 0 0;
      line-height: 1;
      font-weight: 300;
    }

    & .div-annotation {
      width: auto;
      white-space: nowrap;
      margin: 3.7vw auto 6vw auto;

      & .annotation {
        color: #333;
        font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
        font-size: 2.67vw;
        margin: 0 6vw 0 6vw;
        line-height: 1.5;
        font-weight: 300;
        white-space: pre-wrap;

        &.red {
          color: #dd5d50
        }
      }
    }

    & .flex-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;

      margin-top: 3.25vw;
    }

    & .flex-vertical {
      display: flex;
      flex-direction: column;
      padding: 0 2vw;
    }

    .minimum-button {
      color: $ordersite-button-black-color;
      background-color: $ordersite-button-black-bg;
      padding: 3.5vw 3.06667vw 3.5vw 6.06667vw;
      font-size: 4vw;
      line-height: 1;
      border-radius: 6vw;
      border: none;
      font-weight: 200;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      margin: 1.6vw auto 0;

      display: flex;
      align-items: center;

      &:hover{
        color: $ordersite-button-black-color-hover;
        background: $ordersite-button-black-bg-hover;
      }

      img {
        margin: 0 0 0 5vw;
        width: 4.534vw;
      }

      &:disabled {
        cursor: unset;
        opacity: 0.65;
      }
    }
    .minimum-button.type2 {
    }
  }
}
