
.product-thumbnail-underbar {
  width: 132px;
  @include media_desktop() {
    width: 140px;
  }
  border: solid #707070 1px;
  cursor: pointer;
  &:is(.nonselectable) {
    cursor: unset;
  }
  background-color: #fff;
  box-sizing: border-box;

  & .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & img {
      border: none;
      object-fit: cover;
      margin-top: 20px;
      width: 100%;
    }

    & span {
      font-size: 16px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  &.selected {
    border-color: $color-purple;
    border-width: 2px;
  }

  &:not(.selected):not(.nonselectable):hover {
    opacity: 0.75;
  }
}
