@import "../mixins/media";

.ec-role-tradelaw {
  max-width: $max-width-dt;  
  padding: 30px 30px 300px;
  margin: 10px auto 0 auto;

  @include media_mobile {
    margin: 0px auto 80px auto;
    padding: 0px 20px;
  }

  dt,
  dd{
    padding: 10px 0!important;
    line-height: 1.5 !important;
    @include media_mobile {
      padding: 10px 0!important;
    }
  }
  dt{
    @include media_mobile {
      padding: 10px 0 0 0!important;
      font-size: 1.1em;
    }
  }
  dd{
    @include media_mobile {
      padding: 0 0 20px 0!important;
    }
  }

  /*
  @include media_desktop {
    margin: 80px auto;
    max-width: $max-width-dt;
  }
  @include media_mobile {
    margin: 0 auto;
    padding: 30px;
  }
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;

  h1 {
    border-top: none;
  }
  */
}
