@import "../mixins/media";

//DT版1366px, MB版375pxを基準に作成
.brandsite__about-us {
  font: $for-font-serif;

  & &__bgimg {
    height: 100vh;
    position: fixed;
    background-image: url(/html/template/default/assets/brandsite/background.png);
    background-size: cover;
    background-position: center center;
    width: 100%;
    top: 0;
    z-index: 10;
  }
  #arrow-canvas{
    width:100%;
    height:100vh;
    top:0;
    left:0;
    z-index: 11;
    position:fixed;
  }

  & &__title-section {
    z-index: 100;
    position: relative;
    margin:21.4px auto;
    @include media_mobile {

    }
    span:nth-child(1) {
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding: 60px 0 0;
      @include media_mobile {
        padding: 200px 0 0;
        font-size: 13px;
      }
    }
    span:nth-child(2) {
      display: block;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      padding: 1vh 0 0;
      @include media_mobile {
        font-size: 23px;
        padding: 7px 0 0 0;
      }
    }
  }
  & &__section1 {
    z-index: 100;
    height: 900px;
    position: relative;
    @include media_mobile {
      height: auto;
      padding: 0px 0 100px;
    }


    p:nth-child(1) {
      font-size: 40px;
      line-height: 60px;
      font-weight: 600;
      position: absolute;
      top: 165px;
      left: calc(50% - 504px);
      @media screen and (max-width:1119px) {
        left: 54px;
      }
      @include media_mobile {
        margin-top: 190px;
        font-size: 20px;
        line-height: 30px;
        position: static;
        padding: 0 30px;
      }
    }
    p:nth-child(2) {
      width: 475px;
      font-size: 16px;
      font-weight: 300;
      position: absolute;
      top: 308px;
      left: calc(50% - 504px);
      @media screen and (max-width:1119px) {
        left: 54px;
      }
      @include media_mobile {
        width: auto;
        position: static;
        font-size: 12px;
        padding: 0 30px;
        line-height: 2;
        margin-top: 10px;
      }
    }
  }
  & &__section2 {
    z-index: 100;
    position: relative;
    height: 455px;
    padding: 1px 0 0;


    p:nth-child(1) {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      padding: 0px 0 0;
      line-height: 1;
      margin-top: 60px;
      @include media_mobile {
        font-size: 22px;
        margin-top: 60px;
      }
    }
    p:nth-child(2) {
      font-weight: 600;
      font-size: 23px;
      text-align: center;
      padding: 0 0;
      margin-top: 35px;
      line-height: 1.9;
      @include media_mobile {
        font-size: 13px;
        margin-top: 17px;
      }
    }
    a {
      display: block;
      background: $color-ecsite-button;
      color: #fff;
      border: none;
      font-family: sans-serif;
      padding: 10px 15px;
      border-radius: 20px;
      margin: 0 auto;
      &:hover{
        background: $color-ecsite-button-hover;
        color: #fff;
        text-decoration: none;
      }
    }
    a:nth-child(3) {
      padding: 15px 20px;
      margin-top: 35px;
      width: 278px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      border-radius: 100px;
      line-height: 1;
      @include media_mobile {
        padding: 10px 0px;
        font-size: 13px;
        width: 172px;
      }
    }
    a:nth-child(4) {
      padding: 15px 20px;
      margin-top: 20px;
      width: 396px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      border-radius: 100px;
      line-height: 1;
      @include media_mobile {
        padding: 10px 0px;
        font-size: 13px;
        width: 248px;
      }
    }
  }
  & &__section3 {
    z-index: 100;
    position: relative;
    height: auto;
    padding: 0px 0 0px 0;

    p:nth-child(1) {
      font-weight: 600;
      text-align: center;
      line-height: 1;
      @include media_mobile {
        font-size: 22px;
        margin-bottom: 16px;

      }
      @include media_desktop {
        font-size: 33px;
        margin-bottom: 28px;
      }
    }
    p:nth-child(2) {
      text-align: right;
      line-height: 1;

      @include media_mobile {
        margin-bottom: 15px;
      }
      @include media_desktop {
        margin-bottom: 25px;
      }

      /*外枠のレイアウト*/
      font-family: sans-serif;
      margin: 0 auto;
      @include media_mobile {
        padding: 0 15px;
        max-width: 510px;
        font-size: 12px;
      }
      @include media_desktop {
        font-size: 15px;
        width: 66.407vw;
        max-width: 1000px;
        font-size: 13px;
      }
    }

    .accshow {
      transition: 0.8s;
      display: flex;
      flex-wrap: wrap;
    }
    .accbox {
      /*外枠のレイアウト*/
      font-family: sans-serif;
      margin: 0 auto;
      @include media_mobile {
        padding: 0 15px;
        max-width: 510px;
      }
      @include media_desktop {
        font-size: 15px;
        width: 66.407vw;
        max-width: 1000px;
      }


      /*ラベル*/
      .area_name {
        display: block;
        margin: 5px 0;
        padding: 10px 12px;
        //      font-weight: bold;
        background: #333333;
        cursor: pointer;
        transition: all 0.5s;
        color: white;
        border-radius: 4px;
        &:hover{
          background: #555555;
          color: #dddddd;
        }
      }

      /*アイコンを表示*/
      .area_name::before {
        //      content: "▶︎";
        padding-right: 5px;
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        font-weight: 900;
      }

      /*チェックは隠す*/
      input {
        display: none;
      }

      /*中身を非表示にしておく*/
      .accshow {
        height: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.8s;
        display: flex;
        flex-wrap: wrap;
      }
      /*クリックで中身表示*/
      .cssacc:checked + label + .accshow {
        height: auto;
        padding: 5px;
        opacity: 1;
      }

      /*アイコンを入れ替える*/
      .cssacc:checked + .area_name::before {
        font-family: "Font Awesome 5 Free";
        content: "\f078";
        font-weight: 900;
      }

      .pref_bar {
        width: 100%;
        margin: 10px;

        .pref_name_box {
          float: left;
          display: block;
          width: 50px;
          height: 30px;
          text-align: center;

          .pref_checkbox {
            display: none;
          }

          .pref_name {
            // display:inline-block;
            margin: auto;
            font-size: 11px;
            color: #333333;
            cursor: pointer;
            padding: 0px;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        // .pref_checkbox:checked + .pref_name::before {
        //   cursor: pointer;
        //   background-color: #333333;
        //   border-radius: 24px;
        //   text-align: center;
        //   color: #ffffff;
        // }
        .pref_name_checked {
          background-color: #333333;
          border-radius: 24px;
          text-align: center;
          .pref_name {
            color: #ffffff;
            &:hover{
              text-decoration: none;
            }
          }
        }
      }

      .pref_container {
        width: 100%;

        .pref_info {
          width: 100%;
          display: none;
          flex-wrap: wrap;
        }
      }
    }
    .top_bg{
      @include media_desktop {
        //background:linear-gradient(#ffffff00, #ffffffff);
        width: 100%;
        height: 100px;
        display: block;
      }
    }
    .body_bg{
      @include media_desktop {
        //background:url(/html/template/default/assets/brandsite/about-us_bg_japan.png)top center no-repeat #ffffff;

        background-size: 1366px;
        @media screen and (min-width:1367px) {
          background-size: cover;
        }

        width: 100%;
        height: auto;
        display: block;
        padding-top: 375px;
        padding-bottom: 100px;
      }
    }
    .bottom_bg{
      @include media_desktop {
        //background:linear-gradient(#ffffffff, #ffffff00);
        width: 100%;
        height: 200px;
        display: block;
      }
    }
  }
  & &__section4 {
    z-index: 100;
    position: relative;
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 140px 0 200px 0;

    @include media_mobile {
      margin: 0 auto 100px;
      padding: 140px 0 0px 0;
      height: auto;
    }

    .wrapper{
      margin: 0 15px;
    }
    .inner{
      width: auto;
      max-width: 970px;
      margin: 0 auto;
      padding: 50px 15px 65px 15px;
      border-radius: 8px;
      background: rgba(255,255,255,0.35);
      @include media_mobile {
        margin: 0 auto;
        padding: 30px 10px 30px 10px;
      }
      @include media_mobile {
        max-width: 510px;
      }
      @include media_desktop {
        width: 66.407vw;
        max-width: 1000px;
      }
    }

    p:nth-child(1) {
      font-size: 33px;
      font-weight: 600;
      text-align: center;
      padding: 0 0 50px;
      @include media_mobile {
        font-size: 22px;
        padding: 0 0 25px;
      }
    }
    p:nth-child(2) {
      border-bottom: solid #cccccc 1px;
      font-family: $font-family-HiraginoKakuGothicProN;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      border-top: solid #cccccc 1px;
      padding: 20px;
      @include media_mobile {
        font-size: 12px;
        padding: 25px 10px 25px;
      }

      span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px;
        @include media_mobile {
          position: initial;
          margin: 0px;
        }
      }
    }
    p:nth-child(3) {
      border-bottom: solid #cccccc 1px;
      font-family: $font-family-HiraginoKakuGothicProN;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px;
      @include media_mobile {
        font-size: 12px;
        padding: 25px 10px 25px;
      }

      span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px;
        @include media_mobile {
          position: initial;
          margin: 0px;
        }
      }
    }
    p:nth-child(4) {
      border-bottom: solid #cccccc 1px;
      font-family: $font-family-HiraginoKakuGothicProN;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px;
      @include media_mobile {
        font-size: 12px;
        padding: 25px 10px 25px;
      }

      span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px;
        @include media_mobile {
          font-size: 12px;
          position: initial;
          margin: 0px;
        }
      }
    }
    p:nth-child(5) {
      border-bottom: solid #cccccc 1px;
      font-family: $font-family-HiraginoKakuGothicProN;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px;
      @include media_mobile {
        font-size: 12px;
        padding: 25px 10px 25px;
      }

      span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px;
        @include media_mobile {
          font-size: 12px;
          position: initial;
          margin: 0px;
        }
      }
    }
    p:nth-child(6) {
      border-bottom: solid #cccccc 1px;
      font-family: $font-family-HiraginoKakuGothicProN;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 20px;
      @media screen and (max-width: 1132px) {
        padding: 20px 20px 40px;
      }
      @include media_mobile {
        padding: 25px 10px 25px;
      }

      span {
        font-size: 12px;
        line-height: 1.8;
        font-weight: 300;
        display: block;
        margin: -24px 0 0 116px;
        @include media_mobile {
          font-size: 12px;
          position: initial;
          margin: 0px;
        }
      }
    }
    a {
      display: block;
      background: $color-ecsite-button;
      color: #fff;
      border: none;
      font-family: sans-serif;
      padding: 10px 15px;
      border-radius: 20px;
      margin: 0 auto;
      &:hover{
        background: $color-ecsite-button-hover;
        color: #fff;
        text-decoration: none;
      }
    }
    a:nth-child(7) {
      padding: 15px 20px;
      margin-top: 35px;
      width: 278px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      border-radius: 100px;
      line-height: 1;
      @include media_mobile {
        padding: 10px 0px;
        font-size: 13px;
        width: 172px;
      }
    }
  }
}
