@import "../mixins/btn";
@import "../mixins/media";

.product-thumbnail-square {
  @include media_desktop() {
    width: 45%;
    height: 13vw;
    max-height: 181px;
    margin: 1.5%;
  }
  @include media_mobile() {
    width: 45%;
    height: 100%;
    margin: 0;
    flex-shrink: 0;
    // ignore forms.less css
    label {
      margin-bottom: initial;
    }
  }

  display: flex;
  justify-content: center;
  list-style: none;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaa;
    cursor: pointer;
    height: 100%;
    width: 100%;

    &__images {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60%;
      margin: 4px;

      &__thumbnail {
        height: 100%;
        overflow: hidden;
      }

      .product-kamon {
        position: absolute;
        object-fit: cover;
        height: 25%;
        right: 2px;
        bottom: 2px;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: calc(40% - 4px);
      font-size: 10px;
    }

    @include inputBtn;
  }
}
