@import "../mixins/btn";
@import "../mixins/media";

.arrow-thumbnail-3d {
  list-style: none;
  position: relative;

  width: 335px;
  height: 286px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  border-radius: 24px;
  background-image: url(/html/template/default/assets/img/order/thumbnail-background@2x.png);
  background-size: cover;

  user-select: none;

  &__body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    user-select: none;

    position: absolute;
    top: 0;
    left: 0;
    border-radius: 24px;
  }

  &__close-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    border: unset;
    padding: 0px;
    line-height: 40px;
    text-align: center;

    display: inline-block;

    background-color: $ordersite-button-white-bg;
    color: $ordersite-button-white-color;
    &:hover{
      background-color:$ordersite-button-white-bg-hover;
      color:$ordersite-button-white-color-hover;
    }

    & &__img {
      width: auto;
      height: 18.62px;
      margin: 0 0 2px 0px;
      display: inline-block;
    }

    @media (hover: hover) {
      visibility: hidden;
    }
  }

  &:hover &__close-btn {
    visibility: visible;
  }

  //&__customize-btn
  &__customize-btn {
    position: absolute;
    @media screen and (min-width: 768px) {
      top: -10px;
      left: -10px;
    }
    @include media_mobile {
      bottom: 10px;
      right: 10px;
    }
    z-index: 600;
    border-radius: 40px;
    color: $ordersite-button-black-color;
    background-color: $ordersite-button-black-bg;
    &:hover{
      color: $ordersite-button-black-color-hover;
      background: $ordersite-button-black-bg-hover;
    }
    border: unset;
    padding: 0 20px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 600;
    @include media_mobile {
      display: inline-block;
    }
    & &__img {
      width: auto;
      height: 17px;
      margin: 0 0 3px 11.7px;
      display: inline-block;
    }
  }

  //&__selectedIcon
  &__selectedIcon {
     @include media_desktop {
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 600;
      border-radius: 40px;
      background-color: #333333;
      color: #ffffff;
      border: unset;
      padding: 0 20px;
      line-height: 44px;
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
    }
    @include media_mobile {
      display: none;
    }
  }

  //&__add-cart-btn
  &__add-cart-btn {
    position: absolute;
    @media screen and (min-width: 768px) {
      bottom: 16px;
      right: 16px;
    }
    @include media_mobile {
      top: 10px;
      left: 10px;
    }
    z-index: 600;
    border-radius: 40px;
    border: unset;
    padding: 0 20px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 600;

    display: inline-block;

    background-color: $ordersite-button-white-bg;
    color: $ordersite-button-white-color;
    &:hover{
      background-color:$ordersite-button-white-bg-hover;
      color:$ordersite-button-white-color-hover;
    }

    & &__img {
      width: auto;
      height: 22.86px;
      margin: 0 0 5px 7.9px;
      display: inline-block;
    }
  }

  &__title {
    width: 325px;
    margin: 12px auto 0 auto;
    font-size: 19px;
    color: #333333;
    font-weight: bold;
    line-height: 1em;
  }
  &__detail-text {
    width: 325px;
    margin: 7px auto 40px auto;
    font-size: 12px;
    color: #333333;
    line-height: 1.5em;
  }

  @include media_desktop {
    &:hover &__filter {
      display: inline-block;
    }
    &:hover &__close-btn {
      display: inline-block;
    }

    @include media_mobile {
      &:hover &__customize-btn {
        display: inline-block;
      }
    }

    &:hover &__add-cart-btn {
      display: inline-block;
    }
  }
}
