@import "../mixins/media";

.zoom-button {
  display: none;

  @include media_desktop() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border:none;
    border-radius: 4px;
    background-color: #ffffff;
    overflow: hidden;

    .btn-zoom-style {
      width: 54px;
      height: 54px;
      border: 0;
      background-color: #ffffff;
      color: #666666;
      font-size: 28px;
      line-height: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #eeeeee;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .mt-side-10 {
      width: 46px;
      margin: 0;
    }
  }
}
