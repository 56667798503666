@import "../mixins/media";

@media screen and (min-width: 768px) {
  .SelectModal {
    .modal_overlay {
      position: fixed;
      z-index: $zindex-overlay-back;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);

      /*　画面の中央に要素を表示させる設定　*/
      display: flex;
      justify-content: flex-end;

      .batsu {
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        position: absolute;
        z-index: $zindex-overlay-front;
        top: 0;
        left: 0;
        padding: 0 0 100vh calc(50vw - 40px);
      }

      .content {
        overflow-x: scroll;
        z-index: $zindex-overlay-front;
        padding: 1em;
        background: #fff;
        text-align: center;
        width: 50vw;

        .kamon-select-modal {
          &__div {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: 600;
          border-bottom: solid black 2px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .SelectModal {
    .modal_overlay {
      position: fixed;
      z-index: $zindex-overlay-back;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);

      /*　画面の中央に要素を表示させる設定　*/
      display: flex;
      justify-content: flex-end;

      .batsu {
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        position: absolute;
        z-index: $zindex-overlay-front;
        top: 0;
        left: 0;
        padding: 0 0 100vh calc(100vw - 80vw - 40px);
      }

      .content {
        overflow-x: scroll;
        z-index: $zindex-overlay-front;
        padding: 1em;
        background: #fff;
        text-align: center;
        width: 80vw;

        .kamon-select-modal {
          h1 {
            text-align: left;
            margin: 2% 1.5%;
          }
          &__div {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: 600;
          border-bottom: solid black 2px;
        }
      }
    }
  }
}
