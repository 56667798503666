@media screen and (max-width: 767px) {
  .order-kamon {
    width: 100vw;
    height: calc(calc(var(--vh) * 100) - 118px);
    display: flex;
    overflow: scroll;

    & &__button {
      position: absolute;
      top: calc(52px + 10px);
      left:7px;
      border: none;
      background-color: rgba(0,0,0,0.65);
      color: #fff;
      padding: 12px 13px;
      border-radius: 20px;
      font-size: 11px;
      font-weight: 400;
      pointer-events: auto;
    }

    .width-100vw {
      width: 100vw;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    //ここから タブ切り替えボタン
    .react-tabs__wrapper {
      width: 100vw;
      overflow-x: scroll;
      padding: 0;
      margin: 0;
    }

    .react-tabs {
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.5);
      pointer-events: auto;
    }

    .react-tabs__tab-list {
      min-width: 100vw;
      list-style: none;
      margin: 14px 0px 0 0px;
      padding: 0;
    }

    .react-tabs__tab {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      background-color: unset;
      padding: 0 12px;
      line-height: 31px;
      height: 31px;
      border-radius: 20px;
      display: inline-block;
      margin: 0 8px;
      cursor: pointer;

      &--selected {
        color: #ffffff;
        background-color: #333333;
        cursor: unset;
      }
    }

    //ここから オンオフボタン
    .product-container__wrapper {
      width: 100vw;
      height: 168px;
      overflow-x: scroll;
      padding: 14px 0 0 0;
      margin: 0 0 0 0;


      .react-tabs__tab-panel{
        width: 100vw;
        display: flex;
        justify-content: left;
      }
    }

    .product-container {
      white-space: nowrap;
      padding:0 8px;
      margin: 0 auto;
      width: auto;
      display: flex;
      flex-direction: row;

      & > .product-thumbnail.item {
        margin: 0;
        padding: 0 8px;
      }
    }
    .tab-string-label {
      .order-color-img {
        width: 170px;
        height: 100%;

        .order-color-img-img {
          background-color: skyblue;
          height: calc(60% - 8px);
          margin: 4px;
          width: calc(100% - 8px);
        }
        .order-color-img-text {
          font-size: 10px;
          text-align: center;
          height: calc(40% - 4px);

          .kamon-preview-button {
            background-color: $color-purple;
            border: none;
            color: #fff;
            padding: 5% 30%;
            font-weight: 100;
            font-size: 12px;
            margin: 0;
          }
        }
      }

      //ここから 家紋の選択ボタン
      .kamon-select-button {
        position: relative;
        width: 136px;
        height: 136px;
        //cursor: pointer;
        margin: 0 10px;
        border-radius: 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        padding: 0;
        overflow: unset;
        display: block;
        background-color: #ffffff;

        &__image-wapper {
          width: 136px;
          height: 72px;
          object-fit: cover;
          border: solid #f0f0f0 1px; //imgがwhiteの時のため
          border-radius: 16px 16px 0 0;
          margin: 0;
          padding: 0;
          text-align: center;
          background-color: #f6f7f9;
        }

        &__image {
          width: auto;
          height: 60px;
          margin-top: 6px;
        }

        &__text {
          width: 127px;
          height: 26px;
          font-size: 11px;
          line-height: 14px;
          font-weight: normal;
          display: block;
          margin: 5px auto 4px auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: normal;
        }

        &__icon-selected {
          @include media_mobile() {
            display: none;
            //↓↓↓あとで使うかもしれないので一応書いておきます
            position: absolute;
            top: -5px;
            left: -5px;
          }
        }

        &__preview-button {
          background-color: #333333;
          font-size: 12px;
          font-weight: normal;
          border: none;
          width: 112px;
          height: 24px;
          line-height: 1em;
          color: #ffffff;
          padding: 0;
          margin: 0 auto;
          border-radius: 30px;
          display: block;

          &:hover {
            opacity: 0.75;
          }
        }
      }

      input {
        display: none;
      }
      .tab-color-switch:checked + .tab-string-label {
        background-color: black;
      }
    }

    .product-container-text-input {
      width: 100%;
      padding: 0;
      overflow-x: scroll;

      .text-input-label-div {
        display: flex;
        margin: 14vw 8vw 0;
        font-size: 5vw;

        .text-input-label-p1 {
        }
        .text-input-label-p2 {
          font-size: 3vw;
          margin: 3vw 0 0 11vw;
        }
      }
      .kamon-input-text {
        margin: 0 auto;
        height: 11vw;
        width: 90vw;
        display: block;
        border-radius: 4vw;
        border: solid gray 1px;
      }
    }
  }
}
