@import "../mixins/media";

/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/

/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  margin-bottom: 15px;
  color: $clrDarkGray;
  font-family: 游ゴシック体;
  @include media_desktop {
    max-width: $max-width-dt-ecsite-top;
    margin: 0 auto 15px auto;
  }

  & &__en {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2em;
  }
  & &__line {
    display: inline-block;
    margin: 0 20px;
    width: 1px;
    height: 14px;
    background: $clrDarkGray;
  }
  & &__ja {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.15em;
    vertical-align: 2px;
  }
}

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/

.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center;
  & .ec-secHeading__en {
    display: block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2em;
  }
  & .ec-secHeading__line {
    display: block;
    margin: 13px auto;
    width: 20px;
    height: 1px;
    background: black;
  }
  & .ec-secHeading__ja {
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.15em;
    vertical-align: 2px;
  }
}
