@import "../mixins/media";

.design-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  @include media_desktop() {
    background-color: rgba(255, 255, 255, 0.3);

    &__header {
    }

    .div-select-category {
      background-color: rgba(255, 255, 255, 0.3);
      text-align: center;
      padding: 13px 3.097%;
      height: 95px;
      position: sticky;
      top: 0;
      z-index: 500;

      .select-category {
        //width: 191px;
        width: 45.04%;
        height: 44px;
        margin: 10px 2.358%;
        padding: 0 0em 0 19px;
        font-size: 11px;
        font-weight: bold;
        border: none;
        background-color: #f0f0f0;
        border-radius: 4px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        cursor: pointer;

        //件数部分用
        span.count {
          font-size: 10px;
        }

        :first-child {
          margin-left: 25px;
        }
        :last-child {
          margin-right: 25px;
        }
      }
    }

    .container-overflow {
      overflow-y: scroll;
      padding: 10px 15px 40px 15px;
      flex-grow: 1;
      flex-basis: 0;
    }

    .category-label-div {
      section {
        padding: 0 0 10px 0;
        margin: 0;
      }

      .category-label1 {
        display: none;
      }

      .category-label2 {
        width:402px;
        font-size: 18px;
        color:#333333;
        border-bottom: 1px #333333 solid;
        padding-bottom: 13px;
        margin: 30px auto 0 auto;
        font-weight: 500;
        line-height: 1;
      }
    }
  }

  @include media_mobile() {
    .div-select-category {
      display: flex;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.3);

      .select-category {
        width: calc(50vw - 20px);
        height: 27px;
        margin: 10px 10px 0px 10px;
        padding: 0 0 0 11px;
        font-size: 10px;
        font-weight: bold;
        border: none;
        background-color: #f0f0f0;
        border-radius: 6px;

        cursor: pointer;

        &:last-child {
          margin-bottom: 10px;
        }

        //件数部分用
        span.count {
          font-size: 10px;
        }
      }
    }
    .container-overflow {
      margin-top: 0px;
      padding: 5px 8px 0 8px;
      overflow-y: scroll;
      padding-bottom: 220px;
      flex-grow: 1;
      flex-basis: 0;
    }

    .category-label-div {
      background-color: unset;
    }
    .category-label1 {
      display: none;
    }
    .category-label2 {
      border-bottom: solid black 1px;
      margin: 16px auto 0 auto;
      width:157px;
      font-weight: 600;
      font-size: 11px;
      padding-bottom: 5px;
    }
  }
}
