.toggle-wrapper {
  .toggleContainer {
    width: 100%;
    position: relative;
    background: #cbcbcb;
    border-radius: 4px;

    .toggleOption {
      display: inline-block;
      position: relative;
      z-index: 600;
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      color: #ffffff;
      font-size: 1em;

      background-color: unset;
      padding: unset;
      border: none;

      &:hover{
        color: #333;
      }

      &.selected {
        color: #ffffff;
        cursor: initial;
        &:hover{
          color: #ffffff;
        }
      }
    }

    .toggle {
      position: absolute;
      height: calc(100% - 8px);
      width: calc(50% - 4px) !important;
      bottom: 0;
      left: 0;
      transition: all 0.4s ease-in-out;
      background: #333333;
      border-radius: 4px;
      margin: 4px;
    }
  }
}

.toggle-button-area {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 9px 0px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &--divider {
    border-color: #000000;
    opacity: 0.1;
    margin: 10px 0px;
  }
}
