@import "../mixins/btn";

.shaft-change-nock-yaziri-dialog {
  width: 315px;

  // override Modal.content
  .Modal {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em;

      width: 315px;
      user-select: none;
    }
  }

  .title {
    border-bottom: 1px black solid;
    margin: 0 0 1.5em 0;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    user-select: none;
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .change {
      @include btn-od-primary;
      margin-top: 24px;
    }
    .no-change {
      @include btn-od-default;
      margin-top: 24px;
    }
  }
}
