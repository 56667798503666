@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";

.shipping_edit {
  .ec-role:nth-child(1) {
    padding: 30px 20px 0;
  }
  .ec-off4Grid__cell {
    margin: 0 auto;

    button {
      margin: 0 0 5px;
    }
  }
}
