@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";

.product-list {
  background-color: $color-ecsite-2;
  padding: 0 0 40px 0;

  .ec-searchnavRole {
    max-width: 1366px;
    margin: 0 auto;
  }
  .ec-shelfRole {
    max-width: 1366px;
    margin: 0 auto;
  }
}
