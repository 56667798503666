@import "../mixins/variables";
@import "../mixins/btn";

.delete-design-dialog {
  width: 315px;

  // override Modal.content
  .Modal {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

      width: 315px;
      user-select: none;
    }
  }

  .title {
    padding: 1em;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    user-select: none;
  }

  .sub-title {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 14px;
    font-weight: 300;
    user-select: none;
  }

  .disable-touch {
    margin: 1em;
    pointer-events: none;
  }

  .arrow-thumbnail-3d {
    width: 130px;
    height: 130px;

    &__selectedIcon {
      display: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 45%;

    .delete {
      @include btn-od-primary();
    }

    .cancel {
      @include btn-od-default();
    }
  }
}
