@import "../mixins/variables";

.panel {
  &-overlay {
    z-index: $zindex-overlay-back;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: flex-end;

    transition-property: opacity;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &-modal {
    outline: none;
    z-index: $zindex-overlay-front;
    display: flex;
    justify-content: flex-end;

    transform: translateX(100%);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    &--after-open {
      transform: translateX(0%);
    }

    &--before-close {
      /* do not transform when closing */
    }
  }
}
