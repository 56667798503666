@import "../mixins/media";

/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  @include container;
}
.ec-role:first-child { 
  padding: 30px 30px 0px;
  margin: 10px auto 0 auto;

  @include media_mobile {
    margin: -10px auto 0px auto;
    padding: 0px 20px;
  }

  .ec-pageHeader h1 {
    @include media_desktop {
      margin: 10px 0 48px;
      padding: 8px 0 18px;
    }
  }

}
.ec-role-complete,
.ec-role-activate {
  @include container;
}
.ec-role-complete,
.ec-role-activate {
  @include container;
  max-width: $max-width-dt;
}
.ec-role-relatedItem {
  background-color: $color-ecsite-2;
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/

.ec-mypageRole-common {
  //ver2
  //padding: 30px 30px 300px;
  //ver3 ↓に変更 
  padding: 40px 4px 300px;
  margin: 0 auto;
  max-width: 1150px;
  
  @include media_mobile {
    padding: 0px 0px 100px;
    margin-top: -10px;
  }

  .ec-mypageRole {
    @include mypageContainer;
    max-width: $max-width-dt;
  
    .ec-pageHeader h1 {
      font-family: $font-product;
  
      @include media_desktop {
        margin: 10px 0 48px;
        padding: 8px 0 18px;
      }
    }
  }
/*
  &.ec-mypageRole-history {
    padding: 0 0 50px;
    max-width: $max-width-dt;
    margin: 0 auto;
  
    .ec-blockBtn--action {
      text-align: center;
    }
  }
*/
}

