
.order-featherinside-select {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__header {
    flex-shrink: 0;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 1vh;
    background-color: rgba(255,255,255,0.5);
  }

  &__footer {
    flex-shrink: 0;
    bottom: 0px;
  }
}
