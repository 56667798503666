@import "../mixins/media";

.edit-footer-navigation-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: $zindex-floating;

  @include media_mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .btn__cancel {
      transform: translate(10px, -5px);
    }

    .btn__preview {
      transform: translateY(-10px);
    }

    .btn__complete {
      transform: translate(-10px, -5px);
    }
  }
}

@include media_mobile {
  .footer-navi-btn-area {
    width: 100vw;
    height: 70px;
    background-color: #525263;
  }
}

.footer-navi-btn {
  border: none;
  padding: 0;
  background-color: unset;
  -webkit-touch-callout: none;

  &:not(:disabled):hover {
    opacity: 0.75;
  }
}

.footer-navi-btn-3d {
  border: none;
  background-color: unset;
  -webkit-touch-callout: none;
  text-align: center;
}
