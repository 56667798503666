@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";

.login-mypage {
  max-width: $max-width-dt;
  margin: 0 auto;

  .ec-role:nth-child(1) {
    padding: 30px 20px 0;
  }
  .ec-off2Grid__cell {
    margin: 0 auto;
  }
}
