@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";

.shipping_multiple {
  .ec-role:nth-child(1) {
    padding: 30px 20px 0;
  }
  .ec-AddAddress__new {
    @include media_mobile {
      padding: 0 0 0 10vw;
    }

    a {
      font-weight: 300;
    }
  }
  .ec-off4Grid__cell {
    margin: 0 auto;
    @include media_desktop {
      width: 275px;
    }
    @include media_mobile {
      width: 70%;
    }

    button {
      margin: 0 0 5px;
    }
  }
  .ec-AddAddress__btn {
    button {
      font-weight: 300;
    }
  }
  .delete {
    margin: 0 0 0 5px;
    font-weight: 300;
  }
}
