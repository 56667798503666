@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/
.ec-role-newItemRole, 
.ec-role-relatedItem {
  @include container;
  background: $color-ecsite-2;

  @include media_desktop {
    padding: 0;
    margin: 30px 0 0 0 ;
    @media screen and (min-width:1466px) { 
      padding: 0 0;
    }    
    @media screen and (max-width:1465px) { 
      padding: 0 0;
    }
  }
  @include media_mobile {
    padding: 0 0;
  }
}
.ec-newItemRole {
  padding: 40px 0;

  @include media_desktop {
    padding: 60px 0;
    background-color: #ffffff33;
  }


  & .ec-secHeading {
    @include media_desktop {
      max-width: none;
      //padding: 20px 0 0 calc((100% - 1326px) / 2);
      padding: 20px 0 0 20px;
      @media screen and (max-width:1465px) { 
        padding-left: 25px;
      }
    }
    @include media_mobile {
      padding-left: 20px;
    }
  }
  
  & &__list {
    //flickityを使用する場合は下記の3行をコメントアウトする
    //display: flex;
    //overflow-x: scroll;
    //overflow-y: hidden;

  /*winのスクロールバーを非表示にする対策用 - 追加CSS*/
    -ms-overflow-style: none;    /* IE, Edge 対応 */
    scrollbar-width: none;       /* Firefox 対応 */
    &::-webkit-scrollbar{
      display:none;/* Chrome, Safari 対応 */
    }
  /*winのスクロールバーを非表示にする対策用 - 追加CSS*/

    @include media_desktop {
      flex-wrap: nowrap;
      //padding: 20px 0 0 calc((100% - 1286px) / 2);
      padding: 20px 0 0 0;
      @media screen and (max-width:1465px) { 
        //padding-left: 50px;
        padding-left: 0px;
      }
    }
    @include media_mobile {
    }
  }
  & &__listItem {
    width: 48%;
    height: auto;
    flex-shrink: 0;
    position: relative;
    font-family: $font-serif;
    font-weight: 300;
    margin: 0 15px 15px;
        
    @media screen and (min-width:429px) {
      width: 300px;
    }
    @media screen and (max-width:428px) {
      width: 70vw;
    }
//flickityを使用する場合は下記をコメントアウトする
/*
    &:nth-child(1) {
      @media screen and (min-width:768px){
        margin: 0 15px 0 0px;
      }
      @media screen and (max-width:767px){
        margin: 0 15px 0 40px;
      }
    }
    &:last-of-type {
      margin: 0 40px 0 15px;
      @media screen and (min-width:506px) {
      }
      @media screen and (max-width:505px) {
      }
    }
*/
    &__div {
      padding: 0 ;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;
      margin: 0 0 17px 0;
    }
    &__div:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
    
    img {
      border-radius: 10px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      object-fit: cover;
      @media screen and (min-width:429px) {
        width: 300px;
        height: 300px;
      }
      @media screen and (max-width:428px) {
        width: 70vw;
        height: 70vw;
      }
      

    }
    .ranking-icon {
      background: $color-ecsite-button;
      color: $color-ecsite-icon;
      display: inline-block;
      position: absolute;
      margin: 3px;
      padding: 5px;
      border-radius: 30px;
      height: 30px;
      width: 30px;
      text-align: center;
    }

    a {
      color: $clrDarkGray;
      text-decoration: none;
      display: block;
    }
    a:hover{ 

    }
    a:hover .ec-newItemRole__listItem__div{
      background-color: #ffffff;
    }
    a:hover .ec-newItemRole__listItem__div img{
      opacity: 0.5;
    }
    a:hover .ec-newItemRole__listItemCategory{
      text-decoration: underline !important;
    }
    a:hover .ec-newItemRole__listItemTitle{
      text-decoration: underline !important;
    }
    a:hover .ec-newItemRole__listItemPrice{
      text-decoration: underline !important;
    }
  }

  & &__listItemHeading {
    margin-top: calc(45% - 20px);
  }
  & &__listItemCategory {
    @include media_desktop {
      margin: 3px 0 0 7px;
      font-size: 14px;
    }
    @include media_mobile {
      margin: 3px 0 0 7px;
      font-size: 14px;
    }
  }
  & &__listItemTitle {
    @include media_desktop {
      margin: 3px 0 0 5px;
      font-size: 18px;
    }
    @include media_mobile {
      margin: 3px 0 0 5px;
      font-size: 18px;
    }
  }

  & &__listItemPrice {
    @include media_desktop {
      margin: 3px 0 0 5px;
      font-size: 18px;
    }
    @include media_mobile {
      margin: 4px 0 0 5px;
      font-size: 18px;
    }
  }
}
