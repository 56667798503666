/*オーダーサイトのヘルプのみ適用／レスポンシブ対応 ----------------*/
#page_ordermade_top {
  .order-parts-help-panel {
    max-width: 315px;
    width: calc(100vw - 49px);
  }

  .familycrest-list-panel {
    max-width: 315px;
    @include media_desktop() {
      max-width: 630px;
    }
  }
}

/*「オーダーメイドのヘルプ」と「ブランドサイトの矢の選び方」で共用のCSS ----------------*/
.overflow-x-scroll {
  overflow-x: scroll;
}

.font-size-10 {
  font-size: 10px;
}

.text-align-center {
  text-align: center
}

.order-parts-help-panel {
  background-color: #fff;

  & .scrollable {
    overflow-y: overlay;
    height: 100%;
  }
}

.order-parts-help-panel-close {
  width: auto;
  display: flex;
}

.order-parts-help-contents {
  text-align: left;
  margin: 0 15px;
  padding-bottom: 100px;
  

  .title {
    font-weight: 600;
    font-size: 17px;
    border-bottom: none;
    width: auto;
    display: block;
    margin: 30px 0 -8px 0;
    line-height: 1.3;

    .sakura{
      display: none;
    }

  }

  .subtitle {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    line-height: 1.3;
    margin-top: 40px;
    border-bottom: solid #333333 2px;
    margin-bottom: -5px;
  }

  .subheading {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    margin: 40px 0 0 0;
    padding: 0;
  }

  .paragraph {
    margin: 20px 0;
    line-height: 1.8;
  }

  .image {
    margin: 20px auto;
    display: block;
    max-width:calc(100%);
    width: auto;
  }
}

.order-parts-help-table {
  width: 753px;

  .header {
    border-bottom: 1px #212529 solid;
    background-color: #f0f0f0;

    > tr th {
      height: 55px;
      padding-left: 11px;
    }
  }

  .table-row {
    border-bottom: 1px #dee2e6 solid;
    background-color: #fff;

    > td {
      height: 44px;
      padding-left: 11px;
    }
  }

  .strong {
    font-weight: bold;
    background-color: #f0f0f0;
  }
}

.order-parts-help-divider {
  color: #999999;
  margin-top: 20px;
  margin-bottom: 20px;
}
