@media screen and (min-width: 768px) {
  #page_ordermade_top_cart, #page_ordermade_top {
    .ordermade-bgimg {
      background-image: url(/html/template/default/assets/img/order/order-top-bg-img@2x.jpg);
      background-size: cover;
      background-position: center;
    }
  }

  .background-3d {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    @include fadeIn-use(fadeIn-right, 1s, 0.6s);
  }

  .top_container {
    height: 100vh;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ordermade-home-contents-out-wrapper {
    display: block;

    @include fadeIn-use(fadeIn-right, 1s, 1.6s);
  }
  .ordermade-home-contents-head {
    display: block;
  }
  .ordermade-home-contents-content {
    display: block;
  }
  .ordermade-home-p-1 {
    text-align: center;
    padding: 0;
    margin: 0;

    img {
      width: 30vw;
      @include fadeIn-use(fadeIn-right, 1s, 0.6s);

      @media screen and (max-width: 1365px) {
        width: 391.81px;
      }
    }
  }
  .ordermade-home-p-2 {
    text-align: center;
    margin: 2vw 0 0 0;
    @media screen and (max-width: 1365px) {
      margin: 27.32px 0 0 0;
    }
    img {
      width: 40vw;
      @include fadeIn-use(fadeIn-right, 1s, 0.6s);
      @media screen and (max-width: 1365px) {
        width: 518.11px;
      }
    }
  }
  .ordermade-home-block {
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    width: 31vw;
    margin: 5.25vw auto 0 auto;
    border-radius: 10px;
    text-align: center;
    @include fadeIn-use(fadeIn-right, 1s, 1.6s);

    @media screen and (max-width: 1365px) {
      margin: 71.715px auto 0 auto;
      width: 423.453px;
    }
  }
  .ordermade-home-p-3 {
    text-align: center;
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    font-weight: 600;
    color: #333333;
    font-size: 1.5vw;
    padding: 2.1vw 0 0 0;
    display: inline-block;
    position: relative;
    letter-spacing: 0.05em;

    @media screen and (max-width: 1365px) {
      padding: 28.686px 0 0 0;
      font-size: 20.49px;
    }

    .ordermade-home-badge1 {
      position: absolute;
      top: -2vw;
      left: -6.3vw;
      width: 5.56vw;
      //min-width: 76px;
      @media screen and (max-width: 1365px) {
        top: -27.32px;
        left: -86.058px;
        width: 76px;
      }
    }
  }
  .ordermade-home-p-4 {
    text-align: center;
    font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
    font-weight: 500;
    font-size: 1.1vw;
    color: #ba4592;
    padding: 0.5vw 0 0 0;
    letter-spacing: 0.05em;

    @media screen and (max-width: 1365px) {
      padding: 6.83px 0 0 0;
      font-size: 15.026px;
    }
  }
  .ordermade-home-div {
    display: flex;
    justify-content: center;
    padding: 1.5vw 0 3vw 0;

    @media screen and (max-width: 1365px) {
      padding: 20px 0 40px 0;
    }
  }
  .minimum-button {
    color: $ordersite-button-black-color;
    background-color: $ordersite-button-black-bg;
    border: none;
    border-radius: 2vw;
    padding: 1vw 1.3vw;
    font-size: 1.1vw;

    line-height: 1;
    margin: 0 0.6vw;

    display: flex;
    align-items: center;

    &:hover{
      color: $ordersite-button-black-color-hover;
      background: $ordersite-button-black-bg-hover;
    }

    @media screen and (max-width: 1365px) {
      border-radius: 24px;
      padding: 13.66px 17.5px;
      font-size: 15px;
      margin: 0 8px;
    }

    img {
      margin: 0 0 0 0.8vw;
      width: 1.245vw;

      @media screen and (max-width: 1365px) {
        width: 11px;
        margin: 0 0 0 11px;
      }
    }

    &:disabled {
      cursor: unset;
      opacity: 0.65;
    }
  }
  .minimum-button.type2 {
    margin: 0.4vw 2vw 0;
    @media screen and (max-width: 1365px) {
      margin: 5.464px 27.32px 0;
    }
  }

  .ordermade-order-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    @include fadeIn-use(fadeIn-right, 1s, 0s);
    border-radius: 10px;

    width: 35vw;
    height: 16.622vw;
    margin: 4vw auto 0 auto;
    @media screen and (max-width: 1365px) {
      width: 478.09px;
      height: 227.05px;
      margin: 54.64px auto 0 auto;
    }

    & .header {
      text-align: center;
      font-family: "ヒラギノ角ゴシック", "メイリオ", sans-serif;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: auto;
      white-space: nowrap;
      font-size: 1.5vw;
      margin: 1.83009vw auto 0 auto;
      @media screen and (max-width: 1365px) {
        font-size: 20.49px;
        margin: 25px auto 0px auto;
      }
    }

    & .ordermade-home-badge2 {
      position: absolute;
      top: -3vw;
      left: -7.5vw;
      width: 5.564vw;
      @media screen and (max-width: 1365px) {
        top: -40.98px;
        left: -102.45px;
        width: 76px;
      }
    }
    & .ordermade-home-badge3 {
      position: absolute;
      top: -3vw;
      right: -7.5vw;
      width: 5.564vw;
      @media screen and (max-width: 1365px) {
        top: -40.98px;
        right: -102.45px;
        width: 76px;
      }
    }
    & .sub-header {
      font: normal normal normal 12px/26px Hiragino Kaku Gothic Pro;
      color: #d13b9d;
      font-weight: 600;
      font-size: 0.95vw;
      line-height: 1;
      @media screen and (max-width: 1365px) {
        font-size: 13px;
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    & .estimate {
      font: normal normal normal 13px/29px Hiragino Kaku Gothic Pro;
      color: #333333;
      padding: 0.6vw 0 0 0;
      line-height: 1;
      @media screen and (max-width: 1365px) {
        padding: 8.2px 0 0 0;
      }

      &.disabled {
        opacity: 0.7;
      }
    }

    .div-annotation {
      width: auto;
      white-space: nowrap;
      margin: 1.2vw auto 2vw auto;
      text-align: left;

      @media screen and (max-width: 1365px) {
        margin: 16.392px auto 27.32px auto;
      }

      & .annotation {
        font: normal normal normal 8px/12px Hiragino Kaku Gothic Pro;
        color: #333333;
        line-height: 1.5;
        white-space: pre-wrap;

        &.red {
          color: #dd5d50
        }
      }
    }

    & .flex-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;

      margin-top: 1.2vw;
      @media screen and (max-width: 1365px) {
        margin-top: 16.392px;
      }
    }

    & .flex-vertical {
      display: flex;
      flex-direction: column;
      padding: 0 1.19.124px;
    }
  }
}
