@import "../mixins/media";

.parts-complete-button {
  border: none;
  pointer-events: auto;

  &.require {
    background-color: #eb8379;
    color: #fff;
  }
  &.elective {
    background-color: #d9d9d9;
    color: #000;
  }
  &.success {
    background-color: #99dba9;
    color: #fff;
  }
  &:hover{
    opacity: 0.7;
  }
}

@include media_desktop {
  .parts-complete-button {
    width: 62px;
    height: 62px;
    margin: 12.25px;

    @media screen and (max-height:919px){
      width: calc(((100vh - 229px) / 8) * 0.9);
      height: calc(((100vh - 229px) / 8) * 0.9);
      margin: calc(((100vh - 229px) / 8) * 0.05);
    }
/*        
    @media screen and (max-height:919px){
      margin: 1.33297vh;
    }
    @media screen and (max-height:859px){
      width: 7.21vh;
      height: 7.21vh;
    }
*/
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    img.mark {
      //丸いアイコン用のスタイル
      width: auto;
      height: 22px;
      margin: 0px auto;
      padding: 0;
      display: block;
    }
    //「必須」用のスタイル
    img.require,
    img.optional {
      max-width: 40px;
      max-height: 24px;
      width: 4.657vh;
      height: 2.795vh;
      margin: 0px auto 0px auto;
      padding: 0;
      display: block;
    }

    > .title {
      margin: 0;
      font-size: 8px;
      line-height: 1;
      font-weight: 500;
      margin-top: 4px;
      @media screen and (max-height:919px){
        margin-top: calc(((100vh - 229px) / 8) * 0.04);
      }

      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;

      min-height: 1.9em;
      white-space: nowrap;

      //svg,
      .thumbnail {
        width: 142px;
        height: 36px;
        object-fit: cover;
        display: block;
        background-color: unset;
        border: none;
        padding: 0;
        margin: 0;
      }

      figcaption {
        text-align: center;
      }
    }
  }
}

@include media_mobile {
  .evenClass {
  //  margin: -58px 0 0 87vw !important;
  }
  .parts-complete-button {
    width: 46px;
    height: 46px;
    margin-top: 5px;
    margin-right: calc(50% - 56px);
    margin-bottom:  5px;
    margin-left:  10px;
    &:nth-child(even){
      margin-right: 10px;
      margin-left:  calc(50% - 56px);
    }
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 0;

    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    transition-property: margin;
    transition-duration: 0.5s;

    img.mark {
      //丸いアイコン用のスタイル
      width: auto;
      height: 22px;
      margin: 0px auto;
      padding: 0;
      display: block;
    }
    //「必須」用のスタイル
    img.require,
    img.optional {
      width: 30px;
      height: 19px;
      margin: 0px auto 0px auto;
      padding: 0;
      display: block;
    }

    > .title {
      margin: 0;
      font-size: 8px;
      line-height: 1;
      font-weight: 500;
      margin-top: 2px;

      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;

      min-height: 1.9em;
      letter-spacing: -0.1em;

      //svg,
      .thumbnail {
        width: 142px;
        height: 36px;
        object-fit: cover;
        display: block;
        background-color: unset;
        border: none;
        padding: 0;
        margin: 0;
      }

      figcaption {
        text-align: center;
      }
    }
  }
  .parts-complete-button__move {
    margin: 0 8px;
  }
}
