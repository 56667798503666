@import "../mixins/media";

// 家紋と刻印用

.order-select-base-layout {
  @include media_desktop() {
    width: 452px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  @include media_mobile() {
    position: absolute;
    top: 42px;
    width: 100vw;
    pointer-events: none;
  }

  // @include fadeIn-use(fadeIn-right, 1s, 0.6s);

  .header {
    @include media_desktop() {
      height: 59px;
    }
  }

  .body {
    @include media_desktop() {
      //各カスタマイズ画面
      background: rgba(255, 255, 255, 0.3);
      height: calc(100% - 59px);
      // @include fadeIn-use(fadeIn-right, 1s, 0.6s);
      .design-container {
        height: calc(100vh - 59px - 94px);
      }
    }

    @include media_mobile() {
      position: relative;

      .heading {
        position: absolute;
        top: -14px;
        left: 8px;

        font-size: 12px;
        line-height: 30px;
        padding: 0 14px;
        color: #ffffff;
        background-color: #333333;
        border-radius: 4px;
      }
    }

    //テスト用 - iga
    @include media_landscape_order_made() {
      //background-color: #c00;
    }
    //テスト用 - iga
    @include media_portrait_order_made() {
      //background-color: #00c
    }
  }

  .footer {
    @include media_desktop() {
      // @include fadeIn-use(fadeIn-right, 1s, 0.6s);
    }
    @include media_mobile() {
    }
    &__div {
      @include media_desktop() {
        padding: 25px;
        height: 94px;
        border-top: 1px solid #e5e5e5;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;

        .back_to_top {
          height: 44px;
          line-height: 44px;
          color: #ffffff;
          background-color: #333333;
          border: none;
          border-radius: 22px;
          padding: 0 22px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
      }
      @include media_mobile() {
        padding: 4px 16px 14px 16px;
        height: 62px;
        display: flex;
        justify-content: space-between;

        .back_to_top {
          width: auto;
          display: inline-block;
          height: 44px;
          line-height: 44px;
          color: #ffffff;
          background-color: #333333;
          border: none;
          border-radius: 22px;
          padding: 0;
          margin: 0;
          font-size: 12px;
          display: flex;
        }
        .help-button {
          width: auto;
          display: inline-block;
          height: 44px;
          line-height: 44px;
          color: #333333;
          background-color: #efefef;
          border: none;
          border-radius: 22px;
          padding: 0 22px;
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }
}
