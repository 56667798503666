@import "../mixins/media";

//DT版1366px, MB版375pxを基準に作成
.brandsite__about-arrow {
  .top-img {
    overflow: hidden;

    img {
      width: 100%;
      @media screen and(max-width:546px) {
        width: auto;
      }
    }
  }
  .topic-path {
    display: flex;
    font-family: $font-family-HiraginoKakuGothicProN;
    font-size: 13px;
    font-weight: 300;
    padding: 10px;
    a {
      color: #000;
    }
  }
}
