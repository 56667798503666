.sa-dropdown {
  height: 27px;
  padding: 0 0 0 11px;
  font-size: 10px;
  font-weight: bold;
  border: none;
  background-color: #f0f0f0;
  text-align: center;
  border-radius: 6px;
}

.sa-dropdown-area {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
