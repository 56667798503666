@import "../mixins/btn";
@import "../mixins/variables";

.alert-shipdate-dialog {
  position: fixed;
   z-index: 1150;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;

   & &__content {
     z-index: $zindex-overlay-front;
     padding: 1em;
     background: #fff;
     text-align: center;
     border-radius: 10px;
     width: 315px;

     h1 {
       font-weight: 900;
       font-size: 24px;
       width: 130px;
       margin-top: 39px;
       margin-left: auto;
       margin-right: auto;
       text-decoration: underline;
     }

     p {
       margin-top: 24px;
       margin-left: 30px;
       margin-right: 30px;
       margin-bottom: 21px;
       text-align: left;
     }

     button {
       @include btn-od-primary();
       padding: 12px 36px 12px 39px;
       margin: 10px 0;
     }
  }
}
