@import "../mixins/btn";
.height-auto {
  height: auto !important;
}
.shopping-guide {
  padding: 5vw 5vw 20vw;
  background-color: $color-ecsite-1;
  position: relative;

  @include media_mobile {
    height: 300px;
    overflow: hidden;
  }

  & &__openBtn {
    @include media_desktop {
      display: none;
    }
    @include media_mobile {
      background: $color-ecsite-button;
      color: #fff;
      border: none;
      padding: 10px 20px;
      width: 80vw;
      margin: 0 auto;
      display: block;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      opacity: 0.9;
    }
  }

  & &__title {
    font-size: 18px;
    font-weight: 800;
    font-family: unset;

    span {
      font-size: 8px;
      font-weight: 300;
      margin: 0 0 0 10px;
    }
  }
  h1 {
    font-size: 22px;
    font-family: $font-product;
    margin: 50px 0 20px;
  }
  &__first-h1 {
    margin: 40px 0 20px !important;
  }
  p {
    @include media_desktop {
      font-size: 8px;
      line-height: 20px;
    }
    @include media_mobile {
      font-size: 14px;
    }
    font-family: $font-product;
  }
  ul {
    font-family: $font-product;
    @include media_desktop {
      font-size: 12px;
    }
    @include media_mobile {
      font-size: 14px;
    }

    li {
      line-height: 20px;
    }
  }
  &__prefecture {
    list-style: none;
  }
}
