@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  @include container;

  @include media_desktop() {
    padding:0 15px;
  }
  @include media_mobile() {
    padding:0 5px;
  }
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  @include media_desktop {
    
  }
  & &__item {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    font-family: $font-serif;
    font-weight: 300;

    @include media_desktop() {
      width: calc((100vw - 30px) / 4);
      max-width: 334px;
      padding: 0 15px;
    }
    @include media_mobile() {
      width: 50%;
      padding: 0 5px;
    }

    a {
      color: $clrDarkGray;
      text-decoration: none;
    }
    a:hover{ 
      
    }
    a:hover .ec-shelfGrid__item-image__div{
      background-color: #ffffff;
    }
    a:hover .ec-shelfGrid__item-image__div img{
      opacity: 0.5;
    }    
    a:hover  p{
      text-decoration: underline !important;
    }

    &-image {
      margin-bottom: 0px;
      text-align: center;

      &__div {
        padding: 0;
        position: relative;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        margin: 0 0 10px;
      }

    }

    img {
      box-shadow: $box-shadow;
      border-radius: 10px;
      object-fit: cover;
      object-position: center center;
      box-shadow: none;

      @include media_desktop() {
        width: calc(((100vw - 30px) / 4) - 30px);
        height: calc(((100vw - 30px) / 4) - 30px);
        max-width: 304px;
        max-height: 304px;

      }
      @include media_mobile() {
        width: calc((100vw / 2) - 15px);
        height: calc((100vw / 2) - 15px);
        
      }

    }

    p{
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 3px;
      @include media_desktop() {
        font-size: 17px;
      }
      @include media_mobile() {
        font-size: 14px;
      }
    }

    .ec-productRole__category-top p{
      
      @include media_desktop() {
        font-size: 14px;
        margin-top: 15px;
      }
      @include media_mobile() {
        font-size: 11px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }

    .ec-productRole__btn {
      margin-top: auto;
      margin-bottom: 15px;
    }

  }

  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter {
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item {
    margin-bottom: 36px;
    width: 50%;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img {
      width: auto;
      max-height: 100%;
    }
    @include media_desktop() {
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }
  }
  & &__item:nth-child(odd) {
    padding-right: 8px;
    @include media_desktop() {
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even) {
    padding-left: 8px;
    @include media_desktop() {
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
