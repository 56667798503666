@import "../mixins/media";

@include media_desktop() {
  .product-container {
    padding: 0;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .container-overflow {
      overflow-x: scroll;
      height: calc(100% - 95px);
      padding: 10px 15px 40px 15px;
    }

    & > .product-thumbnail.item {
      margin: 10px;
      //win対策
      width: 44.93827%;
    }
  }
}

@include media_mobile() {
  .product-container {
    padding: 0;
    margin: 0 0 40px 0;

    & > .product-thumbnail.item {
      margin: 12px 0 0 0;
    }

    & > .product-thumbnail.type-b {
      margin: 12px 0 0 0;
    }

    &.two-col {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    & > .product-thumbnail.type-c {
      margin: 12px 0 0 0;
    }
  }
}
