@import "../mixins/media";
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  
  @include container;
  text-align: center;
  padding: 0 16px 50px;

  & &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
  }
  & &__description {
    margin-bottom: 32px;
    font-size: 16px;
  }
  .ec-icon {
    img {
      width: 100px;
      height: 100px;
    }
  }
  .ec-off4Grid{
    display: block;
  }
  .ec-off4Grid__cell {
    margin: 0 auto !important;

    button {
      max-width: 350px;
      width: 100%;
      height: 56px;
      margin: 0 auto;
      background: $color-ecsite-button-cancel;

      &:hover{
        background: $color-ecsite-button-cancel-hover
      }

      @include media_mobile {
        
      }
    }
  }
} /*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole {
  padding: 0 0 50px;

  & &__cancel {
    margin-bottom: 20px;
    text-align: center;
  }
  & &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
  }
  & &__description {
    margin-bottom: 32px;
    font-size: 16px;
  }
  .ec-icon {
    img {
      width: 100px;
      height: 100px;
    }
  }
  .ec-off4Grid{
    display: block;
  }
  .ec-off4Grid__cell {
    margin: 0 auto !important;
  }
  a,
  button {
    max-width: 350px;
    width: 100%;
    height: 56px;
    line-height: 56px;
    margin: 0 auto;

    
  }

  .ec-blockBtn--action {
    background: $color-ecsite-button-cancel;

    &:hover{
      background: $color-ecsite-button-cancel-hover;
    }
  }
  .ec-blockBtn--cancel {
    background: $color-ecsite-button-cancel2;
    color: $color-ecsite-button-cancel;
    &:hover{
      background:$color-ecsite-button-cancel2-hover;
    }
  }
}

.ec-withdrawCompleteRole {
  padding: 0 0 50px;

  .ec-off4Grid{
    display: block;
  }
  .ec-off4Grid__cell {
    margin: 0 auto !important;
  }

  .ec-blockBtn--cancel {
    max-width: 350px;
    width: 100%;
    height: 56px;
    line-height: 56px;
    margin: 0 auto;
    background: $color-ecsite-button-cancel;
    &:hover{
      background: $color-ecsite-button-cancel-hover;
    }
  
  }
}
