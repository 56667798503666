.familycrest-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 15px;

  & .familycrest-item {
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;

    margin: 5px;

    > .body {
      > img {
        height: 126px;
      }
    }
  }
}

.familycrest-list-panel {
  background-color: #fff;

  & .scrollable {
    overflow-y: overlay;
    height: 100%;
  }
}

.familycrest-list-panel-header {
  position: sticky;
  top: 0px;
  z-index: $zindex-header;
  background-color: rgba(255, 255, 255, 0.97);

  margin: 0 10px;
  height: 54px;
  @include media_desktop() {
    margin: 0 20px;
    height: 70px;
  }

  & .navi-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .left {
      flex: 1;

      & .go-back {
        height: 32px;
        line-height: 1;
        padding: 6px 12px;
      }
    }

    & .title {
      margin: 0;
      flex: 1;
      text-align: center;
      font-weight: bold;

      font-size: 16px;
      @include media_desktop() {
        font-size: 21px;
      }
    }

    & .right {
      flex: 1;
      margin-left: auto;
    }
  }
}

.familycrest-select-panel-header {
  position: sticky;
  top: 0px;
  z-index: $zindex-header;
  background-color: rgba(255, 255, 255, 0.97);

  height: 54px;
  @inclcude media_desktop() {
    height: 70px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  & .title {
    font-weight: bold;

    font-size: 16px;
    @include media_desktop() {
      font-size: 21px;
    }
  }
}
