@import "../mixins/media";

/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

ex [商品一覧、商品詳細](http://demo3.ec-cube.net/products/list)

+ec-modal

Styleguide 13.4

*/

.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  width: 100%;
  height: 100%;
  font-family: sans-serif;

  &.small {
    width: 30%;
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }

  .ec-modal-wrap {
    position: relative;
    border-radius: 10px;
    //border: 1px solid #333;
    background-color: #F6F7F9;
    width: 90%;
    max-width: 700px;
    margin: 20px;
    padding: 44px 5px;
    @include media_desktop {
      padding: 40px 10px;
      width: 90%;
      margin: 20px auto;
    }
    @media screen and (max-width:509px) { 
      max-width: 335px;
    }
  }

  #ec-modal-header{
    padding:0 0px;
    width:80%;
    margin: 0 auto;
    text-align:center;
  }

  .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    height: 30px;
    width: 30px;
    &:hover {
      color: #4b5361;
      opacity: 0.6;
    }
  }

  .ec-modal-box {
    text-align: center;
    padding: 0 0;
    width: 80%;
    margin: 25px auto 0;
  }

  .ec-role {
    padding:0;
    display: flex;
    justify-content:space-between;

    &:after{
      display: none;
    }


    @media screen and (max-width:509px) { 
      width: 100%;
      flex-direction: column;
    }

    .ec-inlineBtn--action,
    .ec-inlineBtn--cancel{
      width: 48%;
      margin: 0 0%;
      padding: 21px 10px;
      line-height: 1;
      height: auto;

      @media screen and (max-width:509px) { 
        width: 100%;
        margin: 0;
      }

    }
    .ec-inlineBtn--action{
      //order: 1;

      @media screen and (max-width:509px) { 
        margin-bottom: 0px;
      }
    }
    .ec-inlineBtn--cancel{
      //order: 2;
      background-color:$color-ecsite-button-cancel;

      @media screen and (max-width:509px) { 
        margin-bottom: 20px;
      }
      &:hover{
        background-color:$color-ecsite-button-cancel-hover;
      }
    }

  }
}

.ec-modal_zoom-product {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  width: 100%;
  height: 100%;

  &.small {
    width: 30%;
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
  }

  .ec-modal-wrap {
    position: relative;
    border-radius: 2px;
    border: none;
    background-color: none;
    width: auto;
    margin: auto;
    border-radius: 10px;

    @include media_desktop {
      max-width: 100%;
      max-height:100%;
      max-width: 700px;
      margin: 20px auto;
      padding: 0;
    }
  }

  .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    height: 20px;
    width: 20px;
    z-index: $zindex-modal + 10;

    &:hover {
      color: #4b5361;
      opacity: 0.6;
    }


  }

  .ZoomImage{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height:100vh;
    margin: auto;
    object-fit: contain;
    border-radius: 10px;
  }


  .ec-modal-box {
    text-align: center;
  }

  .ec-role {
    margin-top: 20px;
  }
  .slick-track {
    width: 100% !important;
  }
  .slick-active {
    width: 100% !important;
  }
  .slick-slider {
    margin: 0;
  }
}
